import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgWasherOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M12 19a6 6 0 0 0 6-6 6 6 0 1 0-6 6m2.154-5.149c.437.018.955.152 1.593.554a4.002 4.002 0 0 1-7.02.896c1.23-.014 2.27-.46 3.123-.826q.24-.104.459-.194c.699-.284 1.264-.454 1.845-.43m-3.107-1.212c-1.12.469-1.976.826-3.04.594a4 4 0 0 1 7.922-.987 4.8 4.8 0 0 0-1.691-.393c-1.03-.043-1.928.269-2.682.575z"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
      <path
        d="M15 6a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2h-1a1 1 0 0 1-1-1M7 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2"
        fill={color ? Colors[color] : 'currentColor'}
      />
      <path
        clipRule="evenodd"
        d="M6 2a3 3 0 0 0-3 3v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a3 3 0 0 0-3-3zM5 5a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v15H5z"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgWasherOutline)
const Memo = memo(ForwardRef)
export default Memo
