import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgMicrophoneOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M16 6v6c0 2.206-1.794 4-4 4s-4-1.794-4-4V6a4.006 4.006 0 0 1 3.812-3.996 1 1 0 0 1 .209-.025C14.215 1.979 16 3.783 16 6m-6 0v6c0 1.103.897 2 2 2s2-.897 2-2V6c0-1.065-.812-1.94-1.837-2.015A1 1 0 0 1 12 4c-1.103 0-2 .897-2 2m-6 6h2c0 3.309 2.691 6 6 6s6-2.691 6-6h2c0 4.073-3.061 7.436-7 7.931V22h-2v-2.069C7.061 19.436 4 16.072 4 12"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgMicrophoneOutline)
const Memo = memo(ForwardRef)
export default Memo
