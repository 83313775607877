import styled from '@emotion/styled'
import { css } from '@emotion/react'

import type { Theme } from 'scripts/react-components/themes/Theme'

const typeDiscountStyles = ({ theme }: Theme) => css`
  label: discount-info__type;
  padding: ${theme.spacings.kilo} ${theme.spacings.mega};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid ${theme.colors.n200};
  background-color: ${theme.colors.n100};
`

const typeDiscountNoBorderStyles = ({ isLast }) =>
  isLast &&
  css`
    label: discount-info__last-info;
    border-bottom: none;
  `
const textValueStyles = css`
  label: discount-info__value;
  text-wrap: nowrap;
`

const discountButtonStyles = ({ theme }: Theme) => css`
  label: discount-button;
  display: flex;
  align-items: center;
  gap: ${theme.spacings.byte};

  &:hover {
    color: ${theme.colors.g700};
  }

  span {
    text-decoration: underline;
    text-underline-offset: 2px;
    &:hover {
      color: ${theme.colors.g700};
    }
  }

  & > svg {
    margin-top: 3px;
  }
`

export const TypeDiscount = styled('div')(
  typeDiscountStyles,
  typeDiscountNoBorderStyles
)
export const TextValue = styled('div')(textValueStyles)
export const DiscountButton = styled('a')(discountButtonStyles)
