import { IconNames } from './types/icon-enum'
import { type Color } from './types/icon-colors'

type IconNameType = keyof typeof IconNames

interface SVGRProps {
  name: IconNameType
  color?: Color
}

const Icon = ({ name, color, ...props }: SVGRProps & SVGRProps) => {
  const IconComponent = IconNames[name]

  if (!IconComponent) {
    return null
  }

  return <IconComponent color={color} {...props} />
}

export default Icon
