import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

export const Wrapper = styled('div')(
  ({ theme }: Theme) => css`
    background-color: ${theme.colors.white};
    border-radius: ${theme.borderRadius.giga};
    padding: ${theme.spacings.peta};
    display: flex;
    flex-direction: column;
    gap: ${theme.spacings.peta};
  `
)

export const Title = styled('div')(
  () => css`
    display: flex;
    justify-content: space-between;
  `
)

export const Subtitle = styled('h2')(
  ({ theme }: Theme) => css`
    color: ${theme.colors.n500};
    font-size: ${theme.typography.headings.kilo.fontSize};
  `
)

export const InvoiceCard = styled('div')(
  ({ theme }: Theme) => css`
    border: 1px solid ${theme.colors.n100};
    border-radius: ${theme.borderRadius.giga};
    padding: 0 ${theme.spacings.tera} ${theme.spacings.tera};
    width: 100%;
  `
)
