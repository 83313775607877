import { toast } from '@leroy-merlin-br/backyard-react'
import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { isServerError, removeNonNumericCharacters } from 'user/utils'

import { useMyAccountContactContext } from '../../../context/my-account-contact-context'
import {
  sendCodeAuth,
  sendCodeAuthIdentity,
  updateMainContact
} from '../../../services'
import { ContactsData } from '../../../types'

type FormValues = {
  email: string
  mainCell: string
}

type UseContactsDesktopProps = {
  data: ContactsData
}

export const useContactsDesktop = ({ data }: UseContactsDesktopProps) => {
  const { contextData, updateData } = useMyAccountContactContext()

  const [editable, setEditable] = useState(true)

  const [checkPhoneForEmail, setCheckPhoneForEmail] = useState(false)
  const [checkEmailForEmail, setCheckEmailForEmail] = useState(false)
  const [editEmail, setEditEmail] = useState(false)
  const [fieldEmailValue, setFieldEmailValue] = useState()
  const [previousFieldEmailValue, setPreviousFieldEmailValue] = useState()

  const [checkEmailForPhone, setCheckEmailForPhone] = useState(false)
  const [checkPhoneForPhone, setCheckPhoneForPhone] = useState(false)
  const [editMainCell, setEditMainCell] = useState(false)
  const [fieldMainCellValue, setFieldMainCellValue] = useState()
  const [previousFieldMainCellValue, setPreviousFieldMainCellValue] = useState()

  useEffect(() => {
    setFieldEmailValue(data.email)

    setPreviousFieldEmailValue(data.email)
  }, [data.email])

  useEffect(() => {
    setFieldMainCellValue(data.mainCellphone)

    setPreviousFieldMainCellValue(data.mainCellphone)
  }, [data.mainCellphone])

  const {
    control,
    formState: { errors, isValid }
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      email: '',
      mainCell: ''
    }
  })

  const history = useHistory()

  const onCancelChangeEmail = () => {
    setFieldEmailValue(previousFieldEmailValue)

    setEditEmail(false)

    setEditable(true)

    setCheckEmailForEmail(false)
  }

  const onCancelChangePhone = () => {
    setFieldMainCellValue(previousFieldMainCellValue)

    setEditMainCell(false)

    setEditable(true)

    setCheckPhoneForPhone(false)
  }

  const handleEditEmailAndSendCodeIdentity = async () => {
    if (!editable) {
      return
    }

    try {
      await sendCodeAuthIdentity({
        type: 'phone',
        context: 'confirm_identity'
      })
    } catch (error) {
      const { status } = error as AxiosResponse

      if (status === 422) {
        return toast.critical(
          'Não foi possível enviar o código de segurança!',
          { variant: 'solid' }
        )
      }

      const hasServerError = status && isServerError(status)

      if (hasServerError) {
        toast.critical('Não foi possível enviar o código de segurança!', {
          variant: 'solid'
        })
      }
    }

    setCheckPhoneForEmail(true)
  }

  const handleEditEmailAndVerifyCodeSuccess = (identityCode: string) => {
    updateData({ ...contextData, identityCode })

    setCheckPhoneForEmail(false)

    setEditEmail(true)

    setEditable(false)
  }

  const handleValidEmailAndSendCode = async () => {
    try {
      await sendCodeAuth({
        type: 'email',
        contact: fieldEmailValue,
        context: 'confirm_device'
      })
    } catch (error) {
      const { status } = error as AxiosResponse

      if (status === 422) {
        return toast.critical(
          'Não foi possível enviar o código de segurança!',
          { variant: 'solid' }
        )
      }

      const hasServerError = status && isServerError(status)

      if (hasServerError) {
        toast.critical('Não foi possível enviar o código de segurança!', {
          variant: 'solid'
        })
      }
    }

    setCheckEmailForEmail(true)
  }

  const handleValidEmailAndVerifyCodeSuccess = async (deviceCode: string) => {
    updateData({ ...contextData, deviceCode })

    try {
      await updateMainContact({
        email: fieldEmailValue,
        identityCode: contextData.identityCode,
        deviceCode
      })

      setPreviousFieldEmailValue(fieldEmailValue)

      setEditEmail(false)

      setEditable(true)

      setCheckEmailForEmail(false)

      updateData({ ...contextData, email: fieldEmailValue })

      toast.primary('E-mail alterado com sucesso!', {
        variant: 'solid'
      })
    } catch (error) {
      const { status } = error as AxiosResponse

      setEditEmail(false)

      setEditable(true)

      setCheckEmailForEmail(false)

      setFieldEmailValue(previousFieldEmailValue)

      updateData({ ...contextData, email: previousFieldEmailValue })

      if (status === 422) {
        return toast.critical('Não foi possível trocar o e-mail!', {
          variant: 'solid'
        })
      }

      const hasServerError = status && isServerError(status)

      if (hasServerError) {
        toast.critical('Não foi possível trocar o e-mail!', {
          variant: 'solid'
        })
      }
    }
  }

  const handleEditMainCellAndSendCodeIdentity = async () => {
    if (!editable) {
      return
    }

    try {
      await sendCodeAuthIdentity({
        type: 'email',
        context: 'confirm_identity'
      })
    } catch (error) {
      const { status } = error as AxiosResponse

      if (status === 422) {
        return toast.critical(
          'Não foi possível enviar o código de segurança!',
          { variant: 'solid' }
        )
      }

      const hasServerError = status && isServerError(status)

      if (hasServerError) {
        toast.critical('Não foi possível enviar o código de segurança!', {
          variant: 'solid'
        })
      }
    }

    setCheckEmailForPhone(true)
  }

  const handleEditMainCellAndVerifyCodeSuccess = (identityCode: string) => {
    updateData({ ...contextData, identityCode })

    setCheckEmailForPhone(false)

    setEditMainCell(true)

    setEditable(false)
  }

  const handleValidMainCellAndSendCode = async () => {
    try {
      await sendCodeAuth({
        type: 'phone',
        contact: removeNonNumericCharacters(fieldMainCellValue),
        context: 'confirm_device'
      })
    } catch (error) {
      const { status } = error as AxiosResponse

      if (status === 422) {
        return toast.critical(
          'Não foi possível enviar o código de segurança!',
          { variant: 'solid' }
        )
      }

      const hasServerError = status && isServerError(status)

      if (hasServerError) {
        toast.critical('Não foi possível enviar o código de segurança!', {
          variant: 'solid'
        })
      }
    }

    setCheckPhoneForPhone(true)
  }

  const handleValidMainCellAndVerifyCodeSuccess = async (
    deviceCode: string
  ) => {
    updateData({ ...contextData, deviceCode })

    try {
      await updateMainContact({
        phone: `+55${removeNonNumericCharacters(fieldMainCellValue)}`,
        identityCode: contextData.identityCode,
        deviceCode
      })

      setPreviousFieldMainCellValue(fieldMainCellValue)

      setEditMainCell(false)

      setEditable(true)

      setCheckPhoneForPhone(false)

      updateData({ ...contextData, mainCellphone: fieldMainCellValue })

      toast.primary('Celular principal alterado com sucesso!', {
        variant: 'solid'
      })
    } catch (error) {
      const { status } = error as AxiosResponse

      if (status === 422) {
        return toast.critical('Não foi possível trocar o celular principal!', {
          variant: 'solid'
        })
      }

      const hasServerError = status && isServerError(status)

      if (hasServerError) {
        toast.critical('Não foi possível trocar o celular principal!', {
          variant: 'solid'
        })
      }
    }
  }

  return {
    checkEmailForEmail,
    checkEmailForPhone,
    checkPhoneForEmail,
    checkPhoneForPhone,
    contextData,
    control,
    editable,
    editEmail,
    editMainCell,
    errors,
    fieldEmailValue,
    fieldMainCellValue,
    handleEditEmailAndSendCodeIdentity,
    handleEditEmailAndVerifyCodeSuccess,
    handleEditMainCellAndSendCodeIdentity,
    handleEditMainCellAndVerifyCodeSuccess,
    handleValidEmailAndSendCode,
    handleValidEmailAndVerifyCodeSuccess,
    handleValidMainCellAndSendCode,
    handleValidMainCellAndVerifyCodeSuccess,
    history,
    isValid,
    onCancelChangeEmail,
    onCancelChangePhone,
    setCheckEmailForEmail,
    setCheckEmailForPhone,
    setCheckPhoneForEmail,
    setCheckPhoneForPhone,
    setFieldEmailValue,
    setFieldMainCellValue
  }
}
