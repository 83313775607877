import { Fragment, useState } from 'react'
import { ModalProvider, useModal } from '@leroy-merlin-br/backyard-react'
import { Spinner } from '@backyard-ui/core'

import emitter from 'scripts/utils/emitter'

import { StockProps } from 'shared/components/NewProductThumb/NewProductThumb.types'
import { getStockInfo } from 'shared/components/NewProductThumb/components/assisted-sale/ModalStock/services'
import { ModalStock } from 'shared/components/NewProductThumb/components/assisted-sale/ModalStock'

import { StockInfo } from './StockInfo'
import * as S from './styled'

interface StockContentInterface {
  stock: StockProps
  unit: string
  id: number
  productDescription: string
  urlImage: string
}

export type CustomError = {
  data?: {
    errors?: Array<{
      message?: string
    }>
  }
}

const StockContent = ({
  stock,
  unit,
  id,
  productDescription,
  urlImage
}: StockContentInterface) => {
  const { store, warehouse, region: unifiedStock } = stock

  const { setModal } = useModal()
  const [modalStockInfo, setModalStockInfo] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const [modalStockOpen, setModalStockOpen] = useState(false)

  const showStockInfo = async () => {
    if (unifiedStock) {
      try {
        setIsLoading(true)
        const response = await getStockInfo({ productId: id })

        setModalStockInfo(response)
      } catch (error) {
        const typedError = error as CustomError

        emitter.emit('stickyFeedback:error', {
          title: 'Ops! Ocorreu o seguinte erro:',
          content:
            typedError?.data?.errors?.[0]?.message ||
            typedError?.data?.errors?.[0]
        })
      } finally {
        setIsLoading(false)
      }

      return setModalStockOpen(!modalStockOpen)
    }

    return setModal({
      hasScroll: true,
      size: 'giga',
      children: ({ onClose }) => <StockInfo id={id} onClose={onClose} />,
      onClose: () => undefined
    })
  }

  const productUnit = unit !== 'cada' ? unit : 'peças'
  const isEmptyStockInfo = !stock

  if (isEmptyStockInfo) {
    return <S.Wrapper className="assisted-sale-product-stock" />
  }

  return (
    <Fragment>
      <S.Wrapper
        onClick={showStockInfo}
        className="assisted-sale-product-stock"
        data-testid="show-stock-info">
        {unifiedStock ? (
          <Fragment>
            <S.Item>
              {parseInt(String(unifiedStock), 10)} {productUnit} na região
            </S.Item>
            {isLoading && <Spinner size="xs" />}
          </Fragment>
        ) : (
          <Fragment>
            <S.Item>
              {parseInt(String(store), 10)} {productUnit} na loja
            </S.Item>
            <S.Item>
              {parseInt(String(warehouse), 10)} {productUnit} no CD
            </S.Item>
          </Fragment>
        )}
      </S.Wrapper>

      {modalStockOpen && modalStockInfo && (
        <ModalStock
          isOpen={modalStockOpen}
          onClose={setModalStockOpen}
          productDescription={productDescription}
          urlImage={urlImage}
          stock={modalStockInfo}
          productId={id}
          data-testid="modal-stock"
        />
      )}
    </Fragment>
  )
}

const StockContentWithProvider = (props: StockContentInterface) => (
  <ModalProvider>
    <StockContent {...props} />
  </ModalProvider>
)

export default StockContentWithProvider
