import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgZoomInOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M2 10c0-4.411 3.589-8 8-8s8 3.589 8 8c0 1.775-.595 3.5-1.689 4.898l4.396 4.396-1.414 1.414-4.396-4.396A7.95 7.95 0 0 1 10 18c-4.411 0-8-3.589-8-8m2 0c0 3.309 2.691 6 6 6s6-2.691 6-6-2.691-6-6-6-6 2.691-6 6m5-1V6h2v3h3v2h-3v3H9v-3H6V9z"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgZoomInOutline)
const Memo = memo(ForwardRef)
export default Memo
