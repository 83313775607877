import { useState } from 'react'
import { Button, Drawer } from '@backyard-ui/core'

import { SimilarProduct } from 'frontendCheckout/models/SimilarProduct.class'

import { Product } from './Product'

type props = {
  products: SimilarProduct[]
  callback: (cart: { uniqueProductsCount: number }) => void
  setIsLoading: (value: boolean) => void
}

export const SimilarProducts = ({
  products,
  callback,
  setIsLoading
}: props) => {
  const [showDrawer, setShowDrawer] = useState(false)

  const handleDrawer = (prev: boolean) => setShowDrawer(prev)

  return (
    <Drawer.Root size="xl" isOpen={showDrawer} onOpenChange={handleDrawer}>
      <Drawer.Trigger>
        <Button variant="outline">ver produtos similares</Button>
      </Drawer.Trigger>
      <Drawer.Content>
        <Drawer.CloseButton />
        <Drawer.Title>Produtos similares</Drawer.Title>
        <div className="-m-6 scrollbar scrollbar-thumb scrollbar-track overflow-x-auto h-auto">
          <div className="m-6 text-md">
            {products.map((product) => (
              <Product
                key={product.id}
                product={product}
                callback={callback}
                closeDrawer={() => setShowDrawer(false)}
                setIsLoading={setIsLoading}
              />
            ))}
          </div>
        </div>
        <Drawer.Footer>
          <Drawer.Close></Drawer.Close>
        </Drawer.Footer>
      </Drawer.Content>
    </Drawer.Root>
  )
}
