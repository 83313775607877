import { datadogRum } from '@datadog/browser-rum'

import request from 'catalog/utils/axios'

import type { Seller } from './types'

export type Response = {
  data: Seller[]
}

interface GetSellersProps {
  productId: string
  region: string
}

const getSellers = async (props: GetSellersProps) => {
  const { productId, region } = props

  return await request
    .get<Response>(`product/${productId}/sellers`, {
      params: {
        region
      }
    })
    .then(({ data }) => data.data)
    .catch(err => {
      datadogRum.addError(err)

      return []
    })
}

export default getSellers
