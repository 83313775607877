export const generateOtherSubjectsMessage = ({
  createdAt
}: {
  createdAt: string
}) => {
  return {
    text: 'Conte qual <strong>outro assunto</strong> você precisa de ajuda?',
    from: 'bot',
    time: createdAt || new Date().toISOString()
  }
}
