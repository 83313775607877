import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgDiamondOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M6.293 4.293A1 1 0 0 1 7 4h10a1 1 0 0 1 .707.293l4 4a1 1 0 0 1 .036 1.376l-9 10a1 1 0 0 1-1.486 0l-9-10a1 1 0 0 1 .036-1.376zM7.414 6 5.081 8.333l3.412.569L10.233 6zM12 6.944 10.766 9h2.468zm3.758 3.943-1.292 3.879 3.878-4.31zm3.16-2.554-3.41.569L13.765 6h2.82zM12 15.838 13.613 11h-3.226zm-3.758-4.95-2.586-.431 3.878 4.309z"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgDiamondOutline)
const Memo = memo(ForwardRef)
export default Memo
