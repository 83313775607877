import { SignupContextProvider } from 'user/signup/context/signup-context'

import { Routes } from './routes'

const App = ({
  isCaptchaEnabled,
  isCaptchaEnterpriseEnabled,
  captchaKey,
  isNewHeaderEnabled,
  verifyCodeStepOnSignup
}) => (
  <SignupContextProvider
    isCaptchaEnabled={isCaptchaEnabled === 'true'}
    isCaptchaEnterpriseEnabled={isCaptchaEnterpriseEnabled}
    captchaKey={captchaKey}>
    <Routes
      isNewHeaderEnabled={isNewHeaderEnabled}
      verifyCodeStepOnSignup={verifyCodeStepOnSignup}
    />
  </SignupContextProvider>
)

export default App
