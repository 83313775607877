import { FormEvent, KeyboardEventHandler } from 'react'
import { TextField, IconButton, Icon } from '@leroy-merlin-br/backyard-react'
import { AirplaneOutline } from '@backyard-ui/icons'

import { TrailingLoading } from '../Icons'
import * as S from './styled'
import { ChatForm, ChatFormData } from './types'

const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
  const element = event.target as HTMLInputElement
  const closestForm = element.closest('form')

  if (event.key === 'Enter' && closestForm) {
    event.preventDefault()
    closestForm.requestSubmit()
  }
}

const FormInput = ({ onSubmit, isWaiting }: ChatForm) => {
  const submitEvent = async (event: FormEvent) => {
    event.preventDefault()

    if (isWaiting) {
      return
    }

    const values: ChatFormData = {
      message: ''
    }

    const formTarget = event.target as HTMLFormElement

    const formData = new FormData(formTarget)
    formData.forEach((value: FormDataEntryValue, key: string) => {
      values[key] = value
    })

    formTarget.reset()
    onSubmit(values)
  }

  return (
    <S.Form onSubmit={submitEvent}>
      <TextField
        data-gtm-flow-name="Concierge"
        data-gtm-step-name="Start Concierge conversation"
        rows={1}
        cols={10}
        minLength={3}
        maxLength={220}
        autoFocus
        name="message"
        placeholder="Qual a sua pergunta"
        required
        renderSuffix={(renderProps) => (
          <IconButton
            {...renderProps}
            appearance="primary"
            as="button"
            label="Enviar"
            size="kilo"
            type="submit"
            variant="ghost"
          >
            {isWaiting ? (
              <TrailingLoading />
            ) : (
              <Icon color="primary" as={AirplaneOutline} />
            )}
          </IconButton>
        )}
        onKeyDown={onKeyDown}
      />
    </S.Form>
  )
}

export default FormInput
