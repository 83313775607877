import { Col, Heading, Row, Stack } from '@leroy-merlin-br/backyard-react'
import {
  ATTACHMENT_ALLOWED_FORMATS,
  ATTACHMENT_SIZE_MAX_MB,
  FormSchema,
  useFormContext
} from '../../../../contexts/FormContext'
import * as S from '../../styles/form-styled'
import InputFile from './components/InputFile'

interface InputFileItem {
  label: string
  formName: keyof FormSchema
  file: File | undefined
  afterChange: () => void
  id: string
}

export default function AttachmentForm() {
  const {
    form: { setValue, watch },
    formState: { errors },
    setAttachmentCityRegistrationIsViewOnly,
    setAttachmentBankStatementIsViewOnly,
    saveDraftWithDebounce
  } = useFormContext()

  const inputsFiles: InputFileItem[] = [
    {
      label: 'Inscrição Municipal',
      formName: 'attachmentCityRegistration',
      file: watch('attachmentCityRegistration'),
      afterChange: () => setAttachmentCityRegistrationIsViewOnly(false),
      id: 'attachment-city-registration'
    },
    {
      label: 'Comprovante Bancário',
      formName: 'attachmentBankStatement',
      file: watch('attachmentBankStatement'),
      afterChange: () => setAttachmentBankStatementIsViewOnly(false),
      id: 'attachment-bank-statement'
    }
  ]

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: InputFileItem
  ) => {
    if (!event.target.files || event.target.files.length === 0) return

    const file = event.target.files[0]
    setValue(item.formName, file)

    item.afterChange()

    saveDraftWithDebounce()
  }

  const fileHelpText = (() => {
    const formatsText = `${ATTACHMENT_ALLOWED_FORMATS.slice(0, -1).join(
      ', '
    )} e ${ATTACHMENT_ALLOWED_FORMATS.slice(-1)}`

    return `Arquivo somente nos formatos ${formatsText} com no máximo ${ATTACHMENT_SIZE_MAX_MB}MB`
  })()

  return (
    <S.Wrapper data-testid="attachment-form-component">
      <Stack space="kilo">
        <Heading size="mega" as="h2" noMargin>
          <span>Anexos</span>
        </Heading>

        <Row>
          {inputsFiles.map((item, i) => (
            <Col key={'file-input' + i}>
              <S.FieldWrapper>
                <InputFile
                  label={item.label}
                  buttonText="Escolher arquivo"
                  onChange={event => handleFileChange(event, item)}
                  accept={ATTACHMENT_ALLOWED_FORMATS.map(f => `.${f}`).join(
                    ', '
                  )}
                  fileName={item.file?.name}
                  id={item.id}
                />
                <small>{fileHelpText}</small>
                <S.Error>{errors[item.formName]?.message}</S.Error>
              </S.FieldWrapper>
            </Col>
          ))}
        </Row>
      </Stack>
    </S.Wrapper>
  )
}
