import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

interface StyleProps {
  deliveryDay: string
  isPromotional: boolean
}

const wrapperStyles = ({ theme }: Theme) => css`
  label: wrapper;

  display: flex;
  align-items: center;

  position: relative;
  top: ${theme.spacings.giga};

  width: max-content;
  margin-inline: ${theme.spacings.byte};
  border-radius: ${theme.borderRadius.tera};

  strong {
    margin-right: ${theme.spacings.bit};
    margin-left: ${theme.spacings.kilo};
    color: ${theme.colors.white};
  }

  svg {
    fill: ${theme.colors.white};
    margin-right: ${theme.spacings.kilo};
  }
`
const wrapperTodayStyles = ({ theme, deliveryDay }: Theme & StyleProps) =>
  deliveryDay === 'today' &&
  css`
    label: wrapper--mobile-today;

    background-color: ${theme.colors.v400};

    position: unset;

    margin-bottom: ${theme.spacings.bit};
  `
const wrapperTomorrowStyles = ({ theme, deliveryDay }: Theme & StyleProps) =>
  deliveryDay === 'tomorrow' &&
  css`
    label: wrapper--mobile-tomorow;

    background-color: ${theme.colors.v700};

    position: unset;

    margin-bottom: ${theme.spacings.bit};
  `

const wrapperForPromotionalProduct = ({
  theme,
  isPromotional
}: Theme & StyleProps) =>
  isPromotional &&
  css`
    label: wrapper--for-promotional-product;

    position: unset;

    margin-bottom: ${theme.spacings.bit};
  `

export const Wrapper = styled('div')(
  wrapperStyles,
  wrapperTodayStyles,
  wrapperTomorrowStyles,
  wrapperForPromotionalProduct
)
