import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'
import { hexToRgba } from 'scripts/react-components/utils/color-utils'

export const Wrapper = styled('div')(
  ({ theme }: Theme) => css`
    padding: ${theme.spacings.peta};
    padding-bottom: 0;
    background-color: ${theme.colors.white};
    display: flex;
    justify-content: space-between;
    gap: ${theme.spacings.zetta};
    border-radius: ${theme.borderRadius.giga};

    box-shadow:
      0px 2px 2px 0px ${hexToRgba(theme.colors.n500, 0.07)},
      0px 0px 1px 0px ${hexToRgba(theme.colors.n900, 0.07)},
      0px 0px 0px 1px ${hexToRgba(theme.colors.n500, 0.07)};
  `
)

export const ContainerContent = styled('div')(
  ({ theme }: Theme) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacings.tera};
    padding-bottom: ${theme.spacings.giga};
  `
)

ContainerContent.Title = styled('div')(
  ({ theme }: Theme) => css`
    ${theme.typography.headings.exa};
    font-weight: bold;
  `
)

ContainerContent.Sub = styled('div')(
  ({ theme }: Theme) => css`
    ${theme.typography.text.mega};
    color: ${theme.colors.n500};
    display: flex;
    flex-direction: column;
    gap: ${theme.spacings.mega};
  `
)

ContainerContent.BottomText = styled('div')(
  ({ theme }: Theme) => css`
    ${theme.typography.text.kilo};
    color: ${theme.colors.n500};
  `
)

export const ContainerImage = styled('div')(
  () => css`
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 0 40px;
  `
)
