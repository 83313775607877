const MESSAGES = ['Erro interno, tente novamente mais tarde']

export interface FeedbackErrorsTemplateProps {
  errors: string[] | null
}

const feedbackErrorsTemplate = (value: FeedbackErrorsTemplateProps) => {
  const errors = value.errors || MESSAGES

  return errors.map(error => error)
}

export default feedbackErrorsTemplate
