import { api } from 'frontendCheckout/services/api'
import { numberToPrice } from 'frontendCheckout/utils/formatters'

type props = {
  name: string
  unitName: string
  imagePath: string
  storeName: string
  priceFrom: number | null
  priceTo: number
  dfpMessage: string
  id: number
  skuId: string
  hasVariant: boolean
}
export class SimilarProduct implements props {
  readonly name: string
  readonly unitName: string
  readonly imagePath: string
  readonly storeName: string
  readonly priceFrom: number | null
  readonly priceTo: number
  readonly dfpMessage: string
  readonly id: number
  readonly skuId: string
  readonly hasVariant: boolean
  // eslint-disable-next-line no-useless-constructor
  constructor({
    name,
    unitName,
    imagePath,
    storeName,
    priceFrom,
    priceTo,
    dfpMessage,
    id,
    skuId,
    hasVariant
  }: props) {
    this.name = name
    this.unitName = unitName
    this.imagePath = imagePath
    this.storeName = storeName
    this.priceFrom = priceFrom
    this.priceTo = priceTo
    this.dfpMessage = dfpMessage
    this.id = id
    this.skuId = skuId
    this.hasVariant = hasVariant
  }

  get priceFromText() {
    if (!this.priceFrom) return ''
    return `1 ${this.unitName} ${numberToPrice(this.priceFrom)}`
  }

  get priceText() {
    return `1 ${this.unitName} ${numberToPrice(this.priceTo)}`
  }

  get hasDiscount() {
    return !!this.priceFrom
  }

  addToCart() {
    return api.cart.addProduct({
      products: [{ id: this.id, quantity: 1, ...(this.skuId && { sku: this.skuId })}],
      type: 'delivery',
      override: false
    })
  }
}
