import { TernarySelectorOptionsType } from '../MarketPlace/types'

export const generateTernaryMessage = ({
  message,
  createdAt
}: {
  message?: string
  createdAt: string
}) => {
  const ternaryOptions: TernarySelectorOptionsType = [
    {
      label: 'Sim',
      key: 'SIM'
    },
    {
      label: 'Não',
      key: 'NAO'
    }
  ]

  return {
    text: message ?? 'O seu problema foi resolvido?',
    from: 'bot',
    time: createdAt || new Date().toISOString(),
    ternaryOptions
  }
}

export const generateTernaryThruthyMessage = ({
  createdAt
}: {
  createdAt: string
}) => {
  return {
    text: 'Que boa notícia! Se precisar de alguma outra ajuda clique em <strong>Recomeçar conversa</strong>. Você pode consultar essa conversa na seção Conversas Encerradas.',
    from: 'bot',
    time: createdAt || new Date().toISOString()
  }
}
