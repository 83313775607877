import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgBrickOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M2 5a1 1 0 0 1 1-1h12a1 1 0 0 1 .707.293l6 6A1 1 0 0 1 22 11v8a1 1 0 0 1-1 1H9a1 1 0 0 1-.707-.293l-6-6A1 1 0 0 1 2 13zm3.414 1 4 4h9.172l-4-4zM20 12h-2v2h2zm0 4h-2v2h2zm-4 2v-2h-2v2zm-4 0v-2h-2v2zm-4-1.414-4-4V7.414l4 4zM10 12v2h2v-2zm4 0v2h2v-2z"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgBrickOutline)
const Memo = memo(ForwardRef)
export default Memo
