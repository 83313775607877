import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgFileOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M19.707 8.293a1 1 0 0 1 .226.375q0 .005.003.011.044.126.051.259.001.015.007.029.005.016.006.033v11c0 1.103-.897 2-2 2H6c-1.103 0-2-.897-2-2V4c0-1.103.897-2 2-2h7q.017 0 .033.007l.028.006a1 1 0 0 1 .289.06q.033.01.064.024a1 1 0 0 1 .293.196zM14 8h2.586L14 5.414zM6 4v16h12.002L18 10h-5a1 1 0 0 1-1-1V4zm10 10v-2H8v2zm0 4v-2H8v2zM10 8H8v2h2z"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgFileOutline)
const Memo = memo(ForwardRef)
export default Memo
