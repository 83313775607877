import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgPixOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M13.414 4.828a2 2 0 0 0-2.828 0L8.367 7.047a4 4 0 0 1 2.219 1.125l.707.707a1 1 0 0 0 1.414 0l.707-.707a4 4 0 0 1 2.219-1.125zM17.586 9h-1.343a2 2 0 0 0-1.414.586l-.708.707a3 3 0 0 1-4.242 0l-.707-.707A2 2 0 0 0 7.757 9H6.414l-1.586 1.586a2 2 0 0 0 0 2.828L6.414 15h1.343a2 2 0 0 0 1.415-.586l.707-.707a3 3 0 0 1 4.242 0l.707.707a2 2 0 0 0 1.415.586h1.343l1.586-1.586a2 2 0 0 0 0-2.828zm-1.953 7.953a4 4 0 0 1-2.219-1.125l-.707-.707a1 1 0 0 0-1.414 0l-.707.707a4 4 0 0 1-2.219 1.125l2.219 2.219a2 2 0 0 0 2.828 0zM9.172 3.414a4 4 0 0 1 5.656 0l5.758 5.758a4 4 0 0 1 0 5.656l-5.757 5.758a4 4 0 0 1-5.657 0l-5.758-5.758a4 4 0 0 1 0-5.656z"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgPixOutline)
const Memo = memo(ForwardRef)
export default Memo
