import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgHomeSolid (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        d="m21.743 12.331-9-10c-.379-.422-1.107-.422-1.486 0l-9 10a1 1 0 0 0-.17 1.076c.16.361.518.593.913.593h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a.997.997 0 0 0 .743-1.669"
        fill={color ? Colors[color] : 'currentColor'}
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgHomeSolid)
const Memo = memo(ForwardRef)
export default Memo
