import { Button, Drawer, Toaster } from '@backyard-ui/core'
import { css, Global } from '@emotion/react'
import { InvoicesType } from 'frontendMyOrders/types'
import { formatDate } from 'frontendMyOrders/utils/formatters'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Provider } from 'react-redux'

import { useUserResources } from 'scripts/react-components/shared/hooks'

import { useResize } from 'utils/hooks/use-resize'

import {
  setPanicError,
  resetProperties,
  setCanSendMessage,
  setProperties
} from './redux/properties'
import {
  ArchivedButton,
  ArchivedMessageList,
  AttachmentPreview,
  ButtonsGroup,
  Divider,
  Header,
  Notification,
  PanicError,
  SkeletonDescription,
  SkeletonFooter,
  SkeletonTitle,
  Typebar
} from '../components'
import { ButtonProps } from '../components/ButtonsGroup/ButtonsGroup'
import {
  generateCancellationMessage,
  generateConfirmOtherOptionCancellationMessage,
  generateContactEmailMessage,
  generateContactMessage,
  generateContactNumberMessage,
  generateContactOnSendMessage,
  generateDefectiveProductMessage,
  generateDeliveryInformationMessage,
  generateEndContactMessage,
  generateGetContactMessage,
  generateInactiveSellerMessage,
  generateIncorrectProductMessage,
  generateInvoiceEmptyMessage,
  generateInvoiceMessage,
  generateOtherSubjectsMessage,
  generateProductInformationMessage,
  generateReversalMessage,
  generateShiftReturnMessage,
  generateSubOptionConfirmationCancellationMessage,
  generateSubOptionOthersCancellationMessage,
  generateTransferMessage,
  interactiveChatItem
} from '../dictionary'
import {
  beginMiraklFlow,
  disclaimerToUseOnlyChat,
  sellerTimeToResponse
} from '../dictionary/mirakl'
import { generateMissingProductMessage } from '../dictionary/missing-product'
import { generateSubOptionShiftReturnMessage } from '../dictionary/shift-return'
import {
  generateTernaryMessage,
  generateTernaryThruthyMessage
} from '../dictionary/ternary'
import { Chat } from './Chat'
import { useCreateLayout, useMarketplaceChatService } from './hooks'
import {
  resetMessages,
  setMessages,
  updateTernarySelected
} from './redux/messages'
import {
  store as defaultStore,
  useAppDispatch,
  useAppSelector
} from './redux/store'
import * as S from './styled'
import {
  ApiChatItemType,
  OptionsType,
  OptionType,
  ShippingAddressType,
  StepsType,
  StepType,
  SubOptionsType,
  ThreadsType
} from './types'
import { deliveryInformationMessage } from './utils/delivery-information-message'
import { handleErrors } from './utils/handleErrors'
import { isValidEmail } from './utils/isValidEmail'

export type AppProps = {
  orderId: string
  marketplaceOrder: string
  shopName: string
  steps?: StepsType
  threads?: ThreadsType
  shippingAddress?: ShippingAddressType
  deliveryDate?: {
    date: string
    message: string | null
  }
  trackingCode?: string
  trackingUrl?: string
  invoices?: InvoicesType
  isOnMobileApp?: boolean
  closeTimeInHours: number
}

export type NativeAppProps = {
  orderId: string
  marketplaceOrder: string
  shopName?: string
  steps?: string
  threads?: string
  shippingAddress?: string
  deliveryDate?: string
  trackingCode?: string
  trackingUrl?: string
  invoices?: string
  isOnMobileApp?: 1 | 0
  closeTimeInHours: number
}

const App = ({
  orderId,
  marketplaceOrder,
  shopName,
  steps = [],
  shippingAddress,
  deliveryDate,
  trackingCode,
  trackingUrl,
  invoices,
  threads,
  isOnMobileApp,
  closeTimeInHours
}: AppProps) => {
  const useStateMessage = useState('')
  const chatContainerRef = useRef<HTMLDivElement>(null)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isFetched, setIsFetched] = useState(false)
  const [files, setFiles] = useState<File[]>([])
  const [showAttachmentPreview, setShowAttachmentPreview] = useState(false)
  const [showNotification, setShowNotification] = useState(true)
  const [buttons, setButtons] = useState<ButtonProps[]>([])
  const [showArchivedMessages, setShowArchivedMessages] = useState(false)
  const [selectedArchivedMessage, setSelectedArchivedMessage] = useState('')
  const [heightMobileContent, setHeightMobileContent] = useState('100vh')

  const [isMobile] = useResize()
  const { post, transfer, close } = useMarketplaceChatService()
  const { initializeChatMessages, initializeChatProperties } = useCreateLayout()
  const { userResources } = useUserResources()
  const { properties, options, messages } = useAppSelector(state => state)
  const { threadId, userName, chatStatus, panicError } = properties.data
  const dispatch = useAppDispatch()

  const handleDrawer = async (prev: boolean) => {
    if (isFetched) {
      setIsDrawerOpen(prev)
      setIsFetched(prev)
      dispatch(resetMessages())
      dispatch(resetProperties())
    } else {
      setIsDrawerOpen(prev)
      await getMarketplaceChat()
    }
  }

  const getMarketplaceChat = async () => {
    const response = await initializeChatProperties({
      marketplaceOrder,
      orderId,
      shopName,
      deliveryDate,
      invoices,
      shippingAddress,
      steps,
      trackingCode,
      trackingUrl,
      userEmail: userResources?.email,
      userName: userResources?.name,
      userPhone: userResources?.phone,
      threads,
      isOnMobileApp
    })

    setIsFetched(true)

    if (response) return response
  }

  const recreateContactFlow = useCallback(
    ({
      createdAt,
      subOptions,
      subOption,
      option,
      options,
      ticket,
      userResourcesEmail,
      userResourcesPhone
    }: {
      createdAt: string
      subOptions: SubOptionsType
      subOption: string
      option: string
      options: OptionsType
      ticket: {
        contact: string
        isOriginal: boolean
        type: string
        protocol: string
      }
      userResourcesEmail: string
      userResourcesPhone: string
    }) => {
      if (option === 'cancellation') {
        dispatch(
          setMessages(generateCancellationMessage(createdAt, subOptions))
        )
      }

      dispatch(updateTernarySelected('SIM'))

      dispatch(
        setProperties({
          contactSelected: ticket.contact,
          contactType: ticket.type,
          contactIsOriginal: ticket.isOriginal
        })
      )

      dispatch(updateTernarySelected(ticket.isOriginal ? 'SIM' : 'NAO'))

      if (option === 'cancellation') {
        if (subOption === 'other') {
          dispatch(
            setMessages(
              generateSubOptionOthersCancellationMessage({ createdAt })
            )
          )

          dispatch(
            setMessages(
              interactiveChatItem({
                attachments: [],
                date: createdAt,
                from: 'user',
                message: subOption
              })
            )
          )

          dispatch(
            setMessages(
              generateConfirmOtherOptionCancellationMessage({
                createdAt,
                placeholder: subOption
              })
            )
          )
        } else {
          dispatch(
            setMessages(
              generateSubOptionConfirmationCancellationMessage({
                createdAt,
                subOption,
                option,
                options
              })
            )
          )

          dispatch(updateTernarySelected('SIM'))
        }
      }

      dispatch(setMessages(generateContactMessage({ createdAt })))

      if (ticket.type === 'email') {
        dispatch(
          setMessages(
            generateContactEmailMessage({
              createdAt,
              email: userResourcesEmail
            })
          )
        )
      } else {
        dispatch(
          setMessages(
            generateContactNumberMessage({
              createdAt,
              phoneNumber: userResourcesPhone
            })
          )
        )
      }

      dispatch(updateTernarySelected(ticket.isOriginal ? 'SIM' : 'NAO'))

      if (!ticket.isOriginal) {
        dispatch(
          setMessages(
            generateGetContactMessage({ createdAt, contactType: ticket.type })
          )
        )

        dispatch(
          setMessages(
            interactiveChatItem({
              attachments: [],
              date: createdAt,
              from: 'user',
              message: ticket.contact
            })
          )
        )
      }

      dispatch(
        setMessages(
          generateEndContactMessage({
            contact: ticket.contact,
            contactType: ticket.type,
            createdAt,
            protocol: ticket.protocol
          })
        )
      )
    },
    [dispatch]
  )

  const updateLayoutChat = useCallback(() => {
    const {
      option,
      subOption,
      createdAt,
      sellerResponseTimeInHours,
      threadId,
      shippingAddressFormatted,
      apiChat,
      ticket,
      userResourcesEmail,
      userResourcesPhone,
      type
    } = properties.data

    const subOptions = options.data.find(
      currentOption => currentOption.id === option
    )?.subOptions

    if (option === 'reversal') {
      dispatch(
        setMessages(
          generateReversalMessage({
            createdAt: createdAt
          })
        )
      )

      if (type === 'ticket') {
        recreateContactFlow({
          createdAt,
          subOptions: subOptions ?? [],
          subOption: subOption ?? '',
          options: options.data,
          option: option ?? '',
          ticket,
          userResourcesEmail,
          userResourcesPhone
        })
      }
    }

    if (option === 'invoice') {
      if (invoices) {
        const accessKey = invoices[0]?.accessKey ?? ''

        dispatch(
          setMessages(
            generateInvoiceMessage({
              createdAt: createdAt,
              accessKey
            })
          )
        )
      } else {
        dispatch(
          setMessages(
            generateInvoiceEmptyMessage({
              createdAt: createdAt
            })
          )
        )
      }

      dispatch(
        setMessages(
          generateTernaryMessage({
            createdAt: createdAt
          })
        )
      )

      const currentThread = properties.data?.threads?.find(
        thread => thread.threadId === threadId
      )

      if (
        currentThread?.status === 'notify' ||
        properties.data.chatStatus === 'notify'
      ) {
        dispatch(updateTernarySelected('SIM'))

        dispatch(setMessages(generateTernaryThruthyMessage({ createdAt })))
      } else if (threadId) {
        dispatch(updateTernarySelected('NAO'))

        dispatch(
          setMessages(
            beginMiraklFlow({
              shopName: shopName,
              createdAt: createdAt
            })
          )
        )
      }
    }

    if (option === 'deliveryInformation') {
      const deliveryInformationStep = steps?.find(
        (step: StepType) => step.current
      )

      const message = deliveryInformationMessage({
        slug: deliveryInformationStep?.slug ?? '',
        shippingAddressFormatted: shippingAddressFormatted ?? '',
        date: deliveryInformationStep?.date ?? '',
        deliveryDate: deliveryDate?.date ?? '',
        trackingCode: trackingCode ?? '',
        trackingUrl: trackingUrl ?? '',
        description: deliveryInformationStep?.description ?? ''
      })

      dispatch(
        setMessages(
          generateDeliveryInformationMessage({
            message,
            createdAt: createdAt
          })
        )
      )

      dispatch(
        setMessages(
          generateTernaryMessage({
            createdAt: createdAt
          })
        )
      )

      const currentThread = properties.data?.threads?.find(
        thread => thread.threadId === threadId
      )

      if (
        currentThread?.status === 'notify' ||
        properties.data.chatStatus === 'notify'
      ) {
        dispatch(updateTernarySelected('SIM'))

        dispatch(setMessages(generateTernaryThruthyMessage({ createdAt })))
      } else if (threadId) {
        dispatch(updateTernarySelected('NAO'))

        dispatch(
          setMessages(
            beginMiraklFlow({
              shopName: shopName,
              createdAt: createdAt
            })
          )
        )
      }
    }

    if (option === 'defectiveProduct') {
      dispatch(
        setMessages(
          generateDefectiveProductMessage({
            shopName: shopName,
            createdAt: createdAt
          })
        )
      )
    }

    if (option === 'incorrectProduct') {
      dispatch(
        setMessages(
          generateIncorrectProductMessage({
            shopName: shopName,
            createdAt: createdAt
          })
        )
      )
    }

    if (option === 'cancellation') {
      if (type === 'ticket') {
        recreateContactFlow({
          createdAt,
          subOptions: subOptions ?? [],
          subOption: subOption ?? '',
          options: options.data,
          option: option ?? '',
          ticket,
          userResourcesEmail,
          userResourcesPhone
        })
      } else {
        dispatch(
          setMessages(generateCancellationMessage(createdAt, subOptions))
        )

        dispatch(
          setMessages(
            beginMiraklFlow({
              shopName: shopName,
              createdAt: createdAt
            })
          )
        )
      }
    }

    if (option === 'shiftReturn') {
      dispatch(setMessages(generateShiftReturnMessage(createdAt, subOptions)))

      dispatch(
        setMessages(
          generateSubOptionShiftReturnMessage({
            shopName: shopName,
            createdAt: createdAt
          })
        )
      )
    }

    if (option === 'productInformation') {
      dispatch(
        setMessages(
          generateProductInformationMessage({
            shopName: shopName,
            createdAt: createdAt
          })
        )
      )
    }

    if (option === 'missingProduct') {
      dispatch(
        setMessages(
          generateMissingProductMessage({
            shopName: shopName,
            createdAt: createdAt
          })
        )
      )
    }

    if (option === 'otherSubject') {
      dispatch(
        setMessages(
          generateOtherSubjectsMessage({
            createdAt: createdAt
          })
        )
      )
    }

    apiChat.forEach((chatItem: ApiChatItemType, index) => {
      dispatch(setMessages(interactiveChatItem(chatItem)))

      if (index === 0) {
        if (type === 'chat') {
          dispatch(
            setMessages(
              sellerTimeToResponse({
                shopName,
                createdAt,
                sellerResponseTimeInHours
              })
            )
          )
          dispatch(setMessages(disclaimerToUseOnlyChat({ createdAt })))
        }
      }
    })

    if (properties.data.chatStatus === 'inactive-seller') {
      dispatch(setMessages(generateInactiveSellerMessage()))
    }

    if (properties.data.chatStatus === 'transfer') {
      dispatch(setMessages(generateInactiveSellerMessage(createdAt)))
      dispatch(
        setMessages(
          interactiveChatItem({
            attachments: [],
            date: createdAt,
            from: 'bot',
            message:
              'Foi solicitado <strong>contato com a Leroy Merlin</strong>.'
          })
        )
      )
      dispatch(
        setMessages(
          generateEndContactMessage({
            contact: ticket.contact,
            contactType: ticket.type,
            createdAt: createdAt,
            recreated: true,
            protocol: ticket.protocol
          })
        )
      )
    }
  }, [
    properties.data,
    options.data,
    invoices,
    dispatch,
    deliveryDate?.date,
    shopName,
    steps,
    trackingCode,
    trackingUrl,
    recreateContactFlow
  ])

  const generateContactFlow = ({
    message,
    createdAt
  }: {
    message: string
    createdAt: string
  }) => {
    dispatch(
      setProperties({
        contactSelected: message,
        contactIsOriginal: false
      })
    )

    dispatch(
      setMessages(
        generateContactOnSendMessage({
          createdAt,
          message
        })
      )
    )
  }

  async function onSend(message: string) {
    const {
      option,
      threadId,
      subOption,
      createdAt,
      sellerResponseTimeInHours,
      contactType,
      contactIsOriginal,
      type: typeRedux
    } = properties.data
    const type = threadId
      ? typeRedux
      : options.data.find(current => current.id === option)?.type

    if (!type || !option) return

    const isTransfer = type === 'chat' && contactType

    if (isTransfer) {
      if (!isValidEmail(message) && contactType === 'email') {
        dispatch(
          setMessages(
            interactiveChatItem({
              message: 'Digite um E-mail válido.',
              from: 'bot',
              date: '',
              attachments: []
            })
          )
        )

        return
      }

      const response = await transfer({
        threadId,
        data: {
          contact: message,
          contactIsOriginal: 0,
          contactType: contactType
        },
        orderId,
        marketplaceOrder
      })

      if (response?.id) {
        dispatch(
          setProperties({
            threadId: response?.id
          })
        )

        generateContactFlow({
          message,
          createdAt
        })

        dispatch(
          setProperties({
            chatStatus: 'transfer'
          })
        )

        dispatch(
          setMessages(
            generateEndContactMessage({
              createdAt,
              contactType: contactType ?? '',
              contact: message,
              protocol: response?.ticket?.protocol
            })
          )
        )

        return
      }

      return
    }

    if (option === 'cancellation') {
      if (type === 'ticket') {
        if (!isValidEmail(message) && contactType === 'email') {
          dispatch(
            setMessages(
              interactiveChatItem({
                message: 'Digite um E-mail válido.',
                from: 'bot',
                date: '',
                attachments: []
              })
            )
          )

          return
        }

        if (contactType) {
          generateContactFlow({
            message,
            createdAt
          })
        } else {
          dispatch(
            setMessages(
              interactiveChatItem({
                message,
                from: 'user',
                date: '',
                attachments: []
              })
            )
          )

          dispatch(
            setMessages(
              generateConfirmOtherOptionCancellationMessage({
                createdAt,
                placeholder: message
              })
            )
          )

          return
        }
      }
    }

    if (option === 'reversal') {
      if (contactType) {
        if (!isValidEmail(message) && contactType === 'email') {
          dispatch(
            setMessages(
              interactiveChatItem({
                message: 'Digite um E-mail válido.',
                from: 'bot',
                date: '',
                attachments: []
              })
            )
          )

          return
        }

        generateContactFlow({
          message,
          createdAt
        })
      }
    }

    if (type === 'ticket') {
      if (contactType) {
        const response = await post(
          threadId,
          {
            type,
            option,
            subOption,
            contact: message,
            contactIsOriginal: contactIsOriginal ? 1 : 0,
            contactType: contactType
          },
          orderId,
          marketplaceOrder
        )

        if (response?.id) {
          dispatch(
            setProperties({
              threadId: response?.id,
              type: 'ticket'
            })
          )

          dispatch(
            setMessages(
              generateEndContactMessage({
                createdAt,
                contactType: contactType ?? '',
                contact: message,
                protocol: response?.ticket?.protocol
              })
            )
          )
        }
      }
    }

    if (type === 'chat') {
      const response = await post(
        threadId,
        {
          message,
          option,
          subOption,
          type,
          files
        },
        orderId,
        marketplaceOrder
      )

      if (response?.id) {
        dispatch(setMessages(interactiveChatItem(response.chat)))

        if (!threadId) {
          dispatch(
            setMessages(
              sellerTimeToResponse({
                shopName,
                sellerResponseTimeInHours
              })
            )
          )
          dispatch(setMessages(disclaimerToUseOnlyChat({ createdAt })))

          dispatch(
            setProperties({
              threadId: response.id,
              chatStatus: 'send-client',
              type: 'chat'
            })
          )
        }
      }

      setFiles([])
      setShowAttachmentPreview(false)
    }
  }

  function onUpload(file: File) {
    setFiles([file, ...files])
  }

  function onRemoveFile(index: number) {
    const newFiles = files.filter((_, i) => i !== index)
    setFiles(newFiles)
    if (newFiles.length === 0) {
      setShowAttachmentPreview(false)
    }
  }

  async function onSelectButtons(typeButton: string) {
    switch (typeButton) {
      case 'restart':
        if (isOnMobileApp || isMobile) {
          window.location.reload()
        }

        initializeChatMessages(true)
        break
      case 'close':
        await close(orderId, marketplaceOrder, threadId)
        initializeChatMessages(true)
        dispatch(resetProperties())
        dispatch(setProperties({ chatStatus: 'close' }))
        break
      case 'transfer':
        dispatch(setMessages(generateTransferMessage()))
        break
      default:
    }
  }

  const handleInitChat = async (id = '') => {
    setIsFetched(false)
    dispatch(resetMessages())

    const res = await initializeChatProperties({
      marketplaceOrder,
      orderId,
      shopName,
      deliveryDate,
      invoices,
      shippingAddress,
      steps,
      trackingCode,
      trackingUrl,
      userEmail: userResources?.email,
      userName: userResources?.name,
      userPhone: userResources?.phone,
      threads,
      threadId: id,
      isOnMobileApp
    })

    setIsFetched(true)
    setShowArchivedMessages(false)

    if (id) {
      const optionSelected = options.data.find(
        (option: OptionType) => option.id === res?.option
      )

      if (optionSelected) {
        setSelectedArchivedMessage(optionSelected.name)
      }
    }
  }

  async function onSelectArchivedMessage(id: string) {
    handleInitChat(id)
  }

  function onSelectArchivedButton() {
    setShowArchivedMessages(prevValue => {
      if (selectedArchivedMessage) {
        dispatch(resetProperties())
        setSelectedArchivedMessage('')
        return true
      } else if (prevValue) {
        setSelectedArchivedMessage('')
        dispatch(resetProperties())
        handleInitChat()
        return false
      }

      return !prevValue
    })
  }

  const archivedMessageList =
    threads?.map(thread => {
      const optionFinded = options?.data.find(
        (option: OptionType) => thread.option === option.id
      )
      const title = optionFinded ? optionFinded.name : ''
      return {
        date: thread.createdAt
          ? formatDate(thread.createdAt, 'long_weekday_day_month')
          : '',
        id: thread.threadId,
        title
      }
    }) || []

  useEffect(() => {
    if (
      chatContainerRef.current &&
      typeof chatContainerRef.current.scrollTo === 'function'
    ) {
      if (messages.data.list.length === 2) {
        chatContainerRef.current.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      } else {
        chatContainerRef.current.scrollTo({
          top: chatContainerRef.current.scrollHeight,
          behavior: 'smooth'
        })
      }
    }

    if (
      properties.data.chatStatus === 'idle' &&
      messages?.data?.list[messages?.data?.list.length - 2]?.from === 'user'
    ) {
      dispatch(setCanSendMessage(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages])

  useEffect(
    () => {
      dispatch(
        setProperties({
          canSendMessage: false
        })
      )

      if (
        messages.data.list.length <= 2 &&
        (isDrawerOpen || isOnMobileApp || isMobile)
      ) {
        if (userName && !isOnMobileApp && !isMobile) {
          initializeChatMessages()
        }
        if (threadId) {
          updateLayoutChat()
        }
      }

      const currentButtons: ButtonProps[] = []
      const isAMiraklChat =
        threadId &&
        ['send-client', 'send-seller', 'inactive-seller'].includes(chatStatus)

      if (!isNaN(new Date(properties?.data?.createdAt).getTime())) {
        const timeToCloseInMilils = 60 * 60 * (closeTimeInHours ?? 0) * 1000
        const createdAtTimestamp = new Date(properties.data.createdAt).getTime()

        if (
          isAMiraklChat &&
          createdAtTimestamp + timeToCloseInMilils < Date.now()
        ) {
          currentButtons.push({
            key: 'close',
            label: 'Encerrar conversa',
            disabled: properties.data.isLoading,
            confirmOnActionMessage:
              'Ao encerrar, consideramos que <strong>seu problema foi resolvido</strong> e não será possível enviar novas mensagens nesta conversa. Você pode consultar essa conversa na aba Conversas encerradas.'
          })
        }
      }

      if (chatStatus === 'inactive-seller') {
        currentButtons.push({
          key: 'transfer',
          label: 'Chamar Leroy Merlin',
          disabled: properties.data.isLoading
        })
      }

      if (!threadId) {
        currentButtons.push({
          key: 'restart',
          label: 'Recomeçar conversa',
          disabled: properties.data.isLoading
        })
      }

      if (isAMiraklChat) {
        dispatch(
          setProperties({
            canSendMessage: true
          })
        )
      }

      setButtons(currentButtons)
    },
    // threadId: to trigger when threadApi is called
    // userName: to trigger when userResources is called
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [threadId, userName, chatStatus]
  )

  useEffect(() => {
    if (isDrawerOpen && isMobile) {
      redirectToAppChat()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, isDrawerOpen])

  useEffect(() => {
    if ((isOnMobileApp || isMobile) && userResources?.name) {
      const getMarketplaceChatForApp = async () => {
        const response = await getMarketplaceChat()

        if (response) {
          initializeChatMessages(false, response, {
            userName: userResources?.name ?? '',
            shopName,
            createdAt: response.createdAt
          })
        }
      }

      getMarketplaceChatForApp()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnMobileApp, userResources])

  useEffect(() => {
    const heightContent = (() => {
      if (!isFetched) return 'calc(100vh - 260px)'

      const viewportHeight = window.innerHeight
      const heightNotification = window.innerWidth < 398 ? 72 : 56
      const heightArchivedButtons = 50
      const heightTitle = 121
      const heightButtons = 76
      const heightTypebar = 70

      const hasArchivedMessages = !!threads?.length

      let minimalContent =
        viewportHeight -
        heightNotification -
        heightArchivedButtons -
        heightTitle -
        heightButtons -
        heightTypebar

      if (!showNotification) minimalContent += heightNotification
      if (!hasArchivedMessages) minimalContent += heightArchivedButtons
      if (!buttons.length) minimalContent += heightButtons

      return `${minimalContent}px`
    })()

    setHeightMobileContent(heightContent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNotification, buttons])

  const renderChatComponent = () => {
    return (
      <>
        <div
          ref={chatContainerRef}
          data-testid="chat-container"
          className="-m-6 scrollbar scrollbar-thumb scrollbar-track overflow-x-auto"
          style={
            isMobile ? { height: 'calc(100vh - 428px)' } : { height: '100%' }
          }>
          <div className="m-6 text-md">
            {isFetched ? (
              <S.DescriptionWrapper>
                <Chat />
              </S.DescriptionWrapper>
            ) : (
              <SkeletonDescription />
            )}
          </div>
        </div>

        <Drawer.Footer id="drawer-footer">
          {isFetched ? (
            <div>
              <Divider />
              <S.ButtonsWrapper>
                <ButtonsGroup onClick={onSelectButtons} buttons={buttons} />
              </S.ButtonsWrapper>
              {showAttachmentPreview && files.length > 0 && (
                <AttachmentPreview files={files} onRemoveFile={onRemoveFile} />
              )}
              <Typebar
                useStateMessage={useStateMessage}
                onSendMessage={onSend}
                placeholder="Escreva uma mensagem"
                onUploadFile={onUpload}
                setShowAttachmentPreview={setShowAttachmentPreview}
                disabled={!properties.data.canSendMessage}
                isLoading={properties.data.isLoading}
              />
            </div>
          ) : (
            <SkeletonFooter />
          )}
        </Drawer.Footer>
      </>
    )
  }

  const renderArchivedGenericComponent = () => {
    return (
      <>
        {isFetched ? (
          <ArchivedMessageList
            list={archivedMessageList}
            onClick={onSelectArchivedMessage}
          />
        ) : (
          <SkeletonDescription />
        )}
      </>
    )
  }

  const renderChatAppComponent = () => {
    return (
      <>
        <div
          ref={chatContainerRef}
          data-testid="chat-container"
          className="scrollbar scrollbar-thumb scrollbar-track overflow-x-auto pb-6"
          style={{ height: heightMobileContent, margin: '-24px 0' }}>
          <div className="text-md">
            {isFetched ? (
              <S.DescriptionWrapper>
                <Chat />
              </S.DescriptionWrapper>
            ) : (
              <SkeletonDescription />
            )}
          </div>
        </div>

        <S.AppFooter>
          {isFetched ? (
            <div>
              <Divider />
              {buttons.length > 0 && (
                <S.ButtonsWrapper>
                  <ButtonsGroup onClick={onSelectButtons} buttons={buttons} />
                </S.ButtonsWrapper>
              )}
              {showAttachmentPreview && files.length > 0 && (
                <AttachmentPreview files={files} onRemoveFile={onRemoveFile} />
              )}
              <Typebar
                useStateMessage={useStateMessage}
                onSendMessage={onSend}
                placeholder="Escreva uma mensagem"
                onUploadFile={onUpload}
                setShowAttachmentPreview={setShowAttachmentPreview}
                disabled={!properties.data.canSendMessage}
                isLoading={properties.data.isLoading}
              />
            </div>
          ) : (
            <SkeletonFooter />
          )}
        </S.AppFooter>
      </>
    )
  }

  const renderHeaderComponent = () => {
    return (
      <div
        className="block font-semibold heading-xl md:heading-2xl"
        style={{ paddingBottom: '2.5rem' }}>
        {isFetched ? (
          <S.HeaderWrapper>
            <S.HeaderSpacingWrapper>
              <Header
                shopName={shopName}
                isMobile={isMobile}
                isOnMobileApp={isOnMobileApp}
              />
              <Notification
                isVisible={showNotification}
                setIsVisible={isVisible => setShowNotification(isVisible)}
                type="warning">
                Esta conversa <strong>não está integrada com Whatsapp.</strong>
              </Notification>
              {!!threads?.length && (
                <ArchivedButton
                  text={selectedArchivedMessage || 'Conversas encerradas'}
                  type={
                    showArchivedMessages || !!selectedArchivedMessage
                      ? 'back'
                      : 'forward'
                  }
                  onClick={onSelectArchivedButton}
                />
              )}
            </S.HeaderSpacingWrapper>
          </S.HeaderWrapper>
        ) : (
          <SkeletonTitle />
        )}
      </div>
    )
  }

  const renderAppComponent = ({
    showPanicError
  }: {
    showPanicError: boolean
  }) => {
    return (
      <>
        {showPanicError ? (
          <PanicError />
        ) : (
          <S.AppWrapper>
            <Toaster position="top-center" gutter={8} />
            {renderHeaderComponent()}
            {showArchivedMessages && threads
              ? renderArchivedGenericComponent()
              : renderChatAppComponent()}
          </S.AppWrapper>
        )}
      </>
    )
  }

  const renderDesktopComponent = ({
    showPanicError
  }: {
    showPanicError: boolean
  }) => {
    return (
      <>
        <Global
          styles={css`
            #drawer-content {
              padding: var(--desktop-space-inset-md) 0 0 0;
              background-color: var(--color-surface-neutral-strong);
              border-radius: 32px 32px 0 0;

              @media (min-width: 968px) {
                border-radius: var(--border-radius-lg) 0 0 0;
              }
            }

            #drawer-footer {
              display: block;
            }
          `}
        />
        <Drawer.Root
          isOpen={isDrawerOpen && !isMobile}
          onOpenChange={handleDrawer}
          placement={'right'}
          size="lg">
          {isMobile ? (
            <Button isFullWidth onClick={redirectToAppChat}>
              Falar com vendedor
            </Button>
          ) : (
            <Drawer.Trigger asChild>
              <Button isFullWidth>Falar com vendedor</Button>
            </Drawer.Trigger>
          )}

          <Drawer.Content id="drawer-content" data-testid="drawer-content">
            {showPanicError ? (
              <PanicError />
            ) : (
              <>
                <Drawer.CloseButton />
                {renderHeaderComponent()}

                {showArchivedMessages && threads
                  ? renderArchivedGenericComponent()
                  : renderChatComponent()}
              </>
            )}
          </Drawer.Content>
        </Drawer.Root>
      </>
    )
  }

  const locationFalarComVendedor = window.location.href.includes(
    'meus-pedidos/falar-com-vendedor/'
  )

  const redirectToAppChat = () => {
    window.location.href = `/meus-pedidos/falar-com-vendedor/${orderId}/shipment/${marketplaceOrder}/thread`
  }

  if (isOnMobileApp || locationFalarComVendedor) {
    return (
      <>
        {renderAppComponent({
          showPanicError:
            !!panicError && handleErrors(panicError, dispatch, setPanicError)
        })}
      </>
    )
  } else {
    return (
      <>
        {renderDesktopComponent({
          showPanicError:
            !!panicError && handleErrors(panicError, dispatch, setPanicError)
        })}
      </>
    )
  }
}

const AppWithStore = ({
  orderId,
  marketplaceOrder,
  shopName,
  steps,
  threads,
  shippingAddress,
  deliveryDate,
  trackingCode,
  trackingUrl,
  invoices,
  store = defaultStore,
  isOnMobileApp,
  closeTimeInHours
}: AppProps & { store?: typeof defaultStore }) => (
  <Provider store={store}>
    <App
      orderId={orderId}
      marketplaceOrder={marketplaceOrder}
      shopName={shopName}
      steps={steps}
      threads={threads}
      shippingAddress={shippingAddress}
      deliveryDate={deliveryDate}
      trackingCode={trackingCode}
      trackingUrl={trackingUrl}
      invoices={invoices}
      isOnMobileApp={isOnMobileApp}
      closeTimeInHours={closeTimeInHours}
    />
  </Provider>
)

export default AppWithStore
