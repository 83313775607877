import React from 'react'
import styled from '@emotion/styled'

import { SelectOption } from '../SelectOption'
import {
  TernarySelectorOptionsType,
  TernarySelectorType
} from '../../MarketPlace/types'

interface SelectTernaryOptionsGroupProps {
  options: TernarySelectorOptionsType
  selected: 'SIM' | 'NAO' | ''
  onClick: (option: TernarySelectorType) => void
}

const GroupWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  border-radius: var(--border-radius-lg);
  width: fit-content;
`

const SelectTernaryOptionsGroup: React.FC<SelectTernaryOptionsGroupProps> = ({
  options,
  onClick,
  selected
}) => {
  return (
    <GroupWrapper>
      {options.map((option, index) => (
        <SelectOption
          key={option.key}
          label={option.label}
          isSelected={option.key === selected}
          isDisabled={!!selected}
          onSelect={() => onClick(option)}
          isFirst={index === 0}
          isLast={index === options.length - 1}
        />
      ))}
    </GroupWrapper>
  )
}

export default SelectTernaryOptionsGroup
