import { Button, toast } from '@leroy-merlin-br/backyard-react'

import { useLoyaltyProfile } from 'scripts/react-components/shared/hooks'

import mask from 'utils/maskingUtils'

import * as S from './styled'

export default function FavoriteStore() {
  const { favoriteStore } = useLoyaltyProfile()

  const copyFiscalId = async () => {
    if (!favoriteStore) {
      return
    }

    try {
      await navigator.clipboard.writeText(favoriteStore?.fiscalId)

      toast.primary('CNPJ copiado com sucesso!', {
        variant: 'solid'
      })
    } catch (err) {
      toast.critical('Erro ao copiar', {
        variant: 'solid'
      })
    }
  }

  const cnpjFormatted = favoriteStore
    ? mask.maskedText('cnpj', favoriteStore?.fiscalId)
    : '-'

  const nameFormatted = favoriteStore?.name || '-'

  return (
    <S.Wrapper>
      <h3>Atenção sobre a Nota Fiscal</h3>

      <ul>
        <li>
          Deve ser emitida no CNPJ{' '}
          <strong data-testid="favorite-store-cnpj">{cnpjFormatted}</strong>{' '}
          pertencente a sua loja favorita{' '}
          <strong data-testid="favorite-store-name">{nameFormatted}</strong>
        </li>
        <li>Deve ser enviada até o dia 20 do mês vigente</li>
        <li>Deve estar no formato PDF (ex.: nomedoarquivo.pdf)</li>
      </ul>

      <Button variant="link" appearance="info" onClick={copyFiscalId}>
        Copiar CNPJ
      </Button>
    </S.Wrapper>
  )
}
