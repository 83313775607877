import { forwardRef, ComponentType, SVGProps } from 'react'

interface IconWrapperProps extends SVGProps<SVGSVGElement> {
  Icon: ComponentType<SVGProps<SVGSVGElement>>
}

const BackyardCoreIconWrapper = forwardRef<SVGSVGElement, IconWrapperProps>(
  ({ Icon, ...props }, ref) => <Icon {...props} ref={ref} />
)

BackyardCoreIconWrapper.displayName = 'BackyardCoreIconWrapper'

export default BackyardCoreIconWrapper
