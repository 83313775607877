export const BoxIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.075 5.52498L10.4084 1.77498C10.2817 1.70184 10.138 1.66333 9.99171 1.66333C9.84543 1.66333 9.70172 1.70184 9.57504 1.77498L2.90837 5.52498C2.78275 5.59945 2.67891 5.70562 2.60724 5.83286C2.53558 5.96011 2.49861 6.10395 2.50004 6.24998V13.75C2.50029 13.8976 2.53978 14.0426 2.61446 14.17C2.68913 14.2974 2.79632 14.4026 2.92504 14.475L9.59171 18.225C9.71839 18.2981 9.86209 18.3366 10.0084 18.3366C10.1547 18.3366 10.2984 18.2981 10.425 18.225L17.0917 14.475C17.2173 14.4005 17.3212 14.2943 17.3928 14.1671C17.4645 14.0398 17.5015 13.896 17.5 13.75V6.24998C17.4998 6.10231 17.4603 5.95737 17.3856 5.82998C17.3109 5.70259 17.2038 5.59733 17.075 5.52498ZM10 3.45831L15.3917 6.49164L13.15 7.74998L7.75837 4.71664L10 3.45831ZM10 9.52498L4.60837 6.49164L6.90837 5.19998L12.3 8.23331L10 9.52498ZM4.16671 7.19998L9.58337 10.2416V16.3083L4.16671 13.2666V7.19998ZM10.4167 16.3083V10.2416L15.8334 7.19998V13.2666L10.4167 16.3083Z"
      fill="#333333"
    />
  </svg>
)
