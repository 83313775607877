import cookies from 'js-cookie'
import {
  HeartOutline,
  ArchiveOutline,
  LmcvLogo,
  LogoutOutline,
  FileOutline
} from '@backyard-ui/icons'
import React from 'react'

import * as storage from 'utils/storage'

export type buildUserMenuOptions = {
  isLoyaltyElegible?: boolean
  showLogout?: boolean
}

export type userMenuItem = {
  id: string
  label: string
  link?: string
  onClick?: () => void
  icon: React.FC
}

const logoutRedirect = (target: string) => {
  const currentUrl = encodeURIComponent(window.location.href)
  window.location.href = `/${target}?redirect=${currentUrl}`

  if (storage.has('employee-notification-dispatched')) {
    storage.remove('employee-notification-dispatched')
  }

  cookies.remove('user', { domain: undefined })
  storage.removeAll('@celebre')
}

export const buildUserMenu = ({
  isLoyaltyElegible,
  showLogout
}: buildUserMenuOptions = {}): userMenuItem[] => {
  const menuItems: userMenuItem[] = [
    {
      id: 'account',
      label: 'Meus dados',
      link: '/minha-conta',
      icon: FileOutline
    },
    {
      id: 'orders',
      label: 'Meus pedidos',
      link: '/meus-pedidos',
      icon: ArchiveOutline
    },
    {
      id: 'wishes',
      label: 'Favoritos',
      link: '/minhas-listas-de-desejos',
      icon: HeartOutline
    }
  ]

  if (isLoyaltyElegible) {
    const loyaltyItem = {
      id: 'loyalty',
      label: 'Fidelidade',
      link: '/fidelidade',
      icon: LmcvLogo
    }

    menuItems.splice(2, 0, loyaltyItem)
  }

  if (showLogout) {
    const logoutItem = {
      id: 'logout',
      label: 'Sair',
      onClick: () => logoutRedirect('logout'),
      icon: LogoutOutline
    }

    menuItems.push(logoutItem)
  }

  return menuItems
}
