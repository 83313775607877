import { Skeleton as SkeletonBase } from '@backyard-ui/core'

import * as S from './styled'

function TitleWrapper() {
  return (
    <S.TitleTextContainer>
      <SkeletonBase width="auto" height="14px" radius="2xl" />
      <SkeletonBase width="280px" height="12px" radius="2xl" />
      <SkeletonBase width="100%" height="56px" radius="2xl" />
      <SkeletonBase width="100%" height="50px" radius="2xl" />
    </S.TitleTextContainer>
  )
}
function MessageWrapper() {
  return (
    <S.MessageContainer>
      <div>
        <SkeletonBase width="40px" height="40px" radius="full" />
      </div>
      <div style={{ width: '100%' }}>
        <S.Message>
          <SkeletonBase width="100px" height="12px" radius="lg" />

          <SkeletonBase width="225px" height="75px" radius="lg" />
        </S.Message>
        <S.Message alignRight>
          <SkeletonBase width="100px" height="12px" radius="lg" />

          <SkeletonBase width="225px" height="75px" radius="lg" />
        </S.Message>
      </div>
    </S.MessageContainer>
  )
}

function FooterWrapper() {
  return (
    <S.FooterTypeContainer>
      <SkeletonBase width="38px" height="38px" radius="lg" />

      <SkeletonBase
        UNSAFE_style={{ flex: 1 }}
        width="auto"
        height="38px"
        radius="lg"
      />

      <SkeletonBase width="38px" height="38px" radius="full" />
    </S.FooterTypeContainer>
  )
}

export function SkeletonTitle() {
  return (
    <S.SkeletonTitleContainer>
      <TitleWrapper />
    </S.SkeletonTitleContainer>
  )
}

export function SkeletonDescription() {
  return (
    <S.SkeletonDescriptionContainer>
      <MessageWrapper />
      <MessageWrapper />
      <MessageWrapper />
    </S.SkeletonDescriptionContainer>
  )
}

export function SkeletonFooter() {
  return (
    <S.SkeletonFooterContainer>
      <FooterWrapper />
    </S.SkeletonFooterContainer>
  )
}
