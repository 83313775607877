import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgNailOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M11.736 5.184a3 3 0 0 1 3.462.562l2.923 2.923a3 3 0 0 1 .562 3.463l-.09.18-2.726-2.726L14.281 8l-2.726-2.726zM11.453 8 10.14 6.688a2 2 0 0 1 .52-3.203l.18-.09a5 5 0 0 1 5.772.937l2.922 2.922a5 5 0 0 1 .937 5.772l-.09.18a2 2 0 0 1-3.203.52l-1.312-1.312-6.148 6.148c-.191.191-.42.342-.671.443l-4.81 1.924a1 1 0 0 1-1.3-1.3l1.924-4.81a2 2 0 0 1 .443-.671zm3 3-6.148 6.148-2.643 1.057 1.057-2.643 6.148-6.148z"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgNailOutline)
const Memo = memo(ForwardRef)
export default Memo
