import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgDollarCircleOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M6.444 3.685a10 10 0 1 1 11.112 16.63A10 10 0 0 1 6.444 3.685m1.111 14.967a8 8 0 1 0 8.89-13.305 8 8 0 0 0-8.89 13.305M10 10c0 .37 0 1 2 1 3.48 0 4 1.88 4 3 0 1.29-1 2.58-3 2.92V18h-2v-1.08c-2.34-.37-3-2-3-2.92h2c0 .14.16 1 2 1 1.38 0 2-.68 2-1s0-1-2-1c-3.48 0-4-1.88-4-3 0-1.29 1-2.58 3-2.91V6h2v1.12A3 3 0 0 1 15.4 10h-2c-.01-.36-.1-1-1.4-1s-2 .63-2 1"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgDollarCircleOutline)
const Memo = memo(ForwardRef)
export default Memo
