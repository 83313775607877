import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgArrowCircleRightOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M1.999 11.994c0-5.514 4.486-10 10-10.001C17.514 1.994 22 6.48 22 11.994c-.001 5.514-4.487 10-10 10-5.515 0-10.001-4.486-10.001-10m2 0c0 4.411 3.589 8 8.001 8 4.41 0 7.999-3.589 8-8 0-4.411-3.589-8-8.001-8.001-4.411.001-8 3.59-8 8.001m4.001-1h4V7.991l4.005 4.004L12 16v-3.006H8z"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgArrowCircleRightOutline)
const Memo = memo(ForwardRef)
export default Memo
