import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgTruckFastOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M14 6H3V4h11a2 2 0 0 1 2 2h2.465a2 2 0 0 1 1.664.89l1.535 2.303a2 2 0 0 1 .336 1.11V14a2 2 0 0 1-2 2h-.5v1a3 3 0 1 1-6 0v-1H10v1a3 3 0 1 1-6 0v-1H3v-2h11zm1.5 10v1a1 1 0 1 0 2 0v-1zm.5-2v-2h4v2zm3.798-4-1.333-2H16v2zM6 16v1a1 1 0 1 0 2 0v-1zm1-6.5H2v-2h5zm1 3H3v-2h5z"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgTruckFastOutline)
const Memo = memo(ForwardRef)
export default Memo
