export const mappedUrlParams = {
  google: ['gad_source', 'gclid'],
  leroy: ['region', 'isOnMobileApp', 'preview'],
  simplex: ['indexa_id', 'indexa']
}

export const normalizeUrlWithSponsorsParams = (location: Location) => {
  let { href, origin } = location
  let sponsorsParams = ''

  if (verifySponsersParams(href, true)) {
    let categoryUrl = origin + location.pathname

    const segmentsUrl = href.split(/\?|&/).slice(1)
    const sponsorsDictionary = getSponsorsParamsDictionary()

    sponsorsParams = segmentsUrl
      .filter(
        segmentUrl =>
          sponsorsDictionary[
            segmentUrl.split('=')[0] as keyof typeof sponsorsDictionary
          ]
      )
      .join('&')

    const urlParams = segmentsUrl
      .filter(
        segmentUrl =>
          !sponsorsDictionary[
            segmentUrl.split('=')[0] as keyof typeof sponsorsDictionary
          ]
      )
      .join('&')

    if (urlParams) {
      categoryUrl = categoryUrl + '?' + urlParams
    }

    href = categoryUrl
  }

  return { href, sponsorsParams }
}

export const getSponsorsParamsList = () => {
  return Object.keys(mappedUrlParams)
    .map(key => mappedUrlParams[key as keyof typeof mappedUrlParams])
    .flat()
}

export const getSponsorsParamsDictionary = (
  sponsorsList: string[] = getSponsorsParamsList()
) => {
  return sponsorsList.reduce((acc, current) => {
    return {
      ...acc,
      [current]: true
    }
  }, {})
}

export const verifySponsersParams = (url: string, partial = false) => {
  const sponsorsDictionary = getSponsorsParamsDictionary()

  // get search params.
  const segmentsUrl = url
    .split(/\?|&/)
    .slice(1)
    .map(param => param.split('=')[0])

  if (partial) {
    return segmentsUrl.some(
      segment => sponsorsDictionary[segment as keyof typeof sponsorsDictionary]
    )
  }

  return segmentsUrl.every(
    segment => !!sponsorsDictionary[segment as keyof typeof sponsorsDictionary]
  )
}
