import DOMPurify from 'dompurify'

import { Message as MessageType } from '../../types'
import Suggestions from '../Suggestions'
import * as S from './styled'

interface MessageProps extends MessageType {
  message: string
}

const Message = ({ message, options, type, ...rest }: MessageProps) => {
  const addGTMDataAttributes = () => {
    if (typeof message === 'string') {
      return message.replaceAll(
        '<a',
        '<a data-gtm-flow-name="Concierge" data-gtm-step-name="Click Acesse Aqui"'
      )
    }

    return message
  }

  return (
    <S.MessageWrapper type={type} {...rest}>
      <S.BaloonsContainer>
        <S.Baloon
          type={type}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(addGTMDataAttributes())
          }}
        />
        {options && <Suggestions options={options} />}
      </S.BaloonsContainer>
    </S.MessageWrapper>
  )
}

export default Message
