import { useState } from 'react'
import { useModal, Hidden, Button } from '@leroy-merlin-br/backyard-react'

import IconProductPromiseProductOnPicture from 'scripts/react-components/lmcv-pro/pages/Customers/components/Icons/IconProductPromiseProductOnPicture'
import IconBadgeDollar from 'scripts/react-components/lmcv-pro/pages/Customers/components/Icons/IconBadgeDollar'
import IconProductPromiseFashionChoice from 'scripts/react-components/lmcv-pro/pages/Customers/components/Icons/IconProductPromiseFashionChoice'

import { useLoyaltyProfile } from 'shared/hooks'

import { DocumentPro, Header as LoyaltyHeader } from 'lmcv/components'
import handleModal from 'lmcv/pages/Customers/handleModal'
import IconLoyalty from 'lmcv/components/Icons/IconLoyalty'
import ImageBanner from 'lmcv/pages/Customers/components/Icons/ImageBanner'

import * as S from './styled'

interface JoinProfessionalProps {
  status: number
  hasProfessionalRelationship: boolean
}

const JoinProfessional = ({
  status,
  hasProfessionalRelationship
}: JoinProfessionalProps) => {
  const { setModal } = useModal()

  const { loyaltyProfile } = useLoyaltyProfile()

  const [drawerVisibility, setDrawerVisibility] = useState(false)

  const isNotEligibleToEAD = status === 10 && hasProfessionalRelationship

  const showDrawer = () => {
    isNotEligibleToEAD ? handleModal(setModal) : setDrawerVisibility(true)
  }

  const closeDrawer = () => setDrawerVisibility(false)

  const onSubmit = () => document.location.reload()

  const onCatch = () => {
    closeDrawer()
    handleModal(setModal)
  }

  return (
    <>
      {loyaltyProfile && <LoyaltyHeader loyaltyProfile={loyaltyProfile} />}

      <S.Wrapper data-testid="form-header-component">
        <S.ContainerContent>
          <IconLoyalty width={80} />

          <S.ContainerContent.Title>
            Área exclusiva para profissionais da construção
          </S.ContainerContent.Title>

          <S.ContainerContent.Sub>
            Engenheiros, Arquitetos e Decoradores possuem benefícios exclusivos
            no{' '}
            <span className="highlight">
              Programa de Fidelidade Leroy Merlin Com Você
            </span>
          </S.ContainerContent.Sub>

          <S.ContainerContent.Items>
            <li>
              <IconProductPromiseProductOnPicture />
              <div>
                Cupons com{' '}
                <span className="highlight">descontos exclusivos</span>
              </div>
            </li>

            <li>
              <IconBadgeDollar />
              <div>
                Pontos que podem ser trocados por{' '}
                <span className="highlight">cashback</span>
              </div>
            </li>

            <li>
              <IconProductPromiseFashionChoice />
              <div>
                <span className="highlight">Espaço exclusivo</span> para
                atendimento em algumas lojas
              </div>
            </li>
          </S.ContainerContent.Items>

          <Button appearance="primary" onClick={showDrawer}>
            Enviar meus documentos
          </Button>
        </S.ContainerContent>

        <Hidden when={['untilKilo', 'kilo', 'mega']}>
          <S.ContainerImage>
            <ImageBanner />
          </S.ContainerImage>
        </Hidden>
      </S.Wrapper>

      <DocumentPro
        open={drawerVisibility}
        onClose={closeDrawer}
        onCancel={closeDrawer}
        onFinish={onSubmit}
        onCatch={onCatch}
      />
    </>
  )
}

export default JoinProfessional
