import { Flex, Icon, Spacer, Spinner, Text } from '@backyard-ui/core'
import { ArchiveOutline } from '@backyard-ui/icons'

import styles from './CardSimpleInfo.styles'

interface CardSimpleInfoProps {
  isLoading?: boolean
  title: string
  description: string
  error?: string
}

const CardSimpleInfo = ({
  isLoading = false,
  title = '',
  description = '',
  error = ''
}: CardSimpleInfoProps) => {
  const classNames = styles()

  if (isLoading) {
    return (
      <div className={classNames.container()}>
        <Flex justify="center">
          <Spinner data-testid="simple-info-loading" />
        </Flex>
      </div>
    )
  }

  if (error) {
    return (
      <div className={classNames.container()}>
        <Flex justify="center">
          <Text size="sm" color="red-400">
            {error}
          </Text>
        </Flex>
      </div>
    )
  }

  return (
    <div className={classNames.container()}>
      <Flex justify="space-between">
        <Flex align="center">
          <div>
            <Icon color="current">
              <ArchiveOutline fill="#41A017" />
            </Icon>
            <Spacer size={4} />
          </div>

          <Flex direction="column">
            <Text weight="bold">{title}</Text>
            <Text size="sm" color="neutral-700">
              {description}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </div>
  )
}

export default CardSimpleInfo
