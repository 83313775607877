import { history as historyRouter } from 'instantsearch.js/es/lib/routers'
import type { UiState } from 'instantsearch.js'

import { normalizeRefinementList, normalizeGoogleParams } from './proxy.utils'
import { normalizeUrlWithSponsorsParams } from './category/utils/sponsorsUrlParams'

type RouteState = {
  query: string
  term?: string
  page: string
  refinementList?: object
  hierarchicalMenu?: object
  toggle?: object
  range?: object
  sortBy?: string
}

const ROUTE_DEFAULT_STATE: RouteState = {
  query: '',
  term: '',
  page: '1',
  refinementList: undefined,
  hierarchicalMenu: undefined,
  toggle: undefined,
  range: undefined,
  sortBy: ''
}

const router = historyRouter<RouteState>({
  cleanUrlOnDispose: false,
  windowTitle ({ term }) {
    return term ? `Resultados da Busca por: ${term}` : ''
  },
  createURL (params): string {
    const { qsModule, routeState, location } = params
    const {
      origin,
      href,
      protocol,
      hostname,
      port = '',
      pathname,
      hash
    } = location

    const portWithPrefix = port === '' ? '' : `:${port}`
    const urlParts = location.href.match(/^(.*?)\/search/)
    const baseUrl =
      (urlParts && urlParts[0]) ||
      `${protocol}//${hostname}${portWithPrefix}/search`

    const sponsorsQueryString = new URLSearchParams(
      normalizeUrlWithSponsorsParams(location).sponsorsParams
    )

    const sponsorsParams = Object.fromEntries(sponsorsQueryString.entries())

    if (!urlParts?.length) {
      return href
    }

    const queryParameters: Partial<RouteState> = {}

    if (routeState?.term !== ROUTE_DEFAULT_STATE.term) {
      queryParameters.term = routeState.term
    }

    if (routeState?.page !== ROUTE_DEFAULT_STATE.page) {
      queryParameters.page = routeState.page
    }

    if (routeState?.hierarchicalMenu !== ROUTE_DEFAULT_STATE.hierarchicalMenu) {
      queryParameters.hierarchicalMenu = routeState.hierarchicalMenu
    }

    if (routeState?.refinementList !== ROUTE_DEFAULT_STATE.refinementList) {
      queryParameters.refinementList = routeState.refinementList
    }

    if (routeState?.toggle !== ROUTE_DEFAULT_STATE.toggle) {
      queryParameters.toggle = routeState.toggle
    }

    if (routeState?.range !== ROUTE_DEFAULT_STATE.range) {
      queryParameters.range = routeState.range
    }

    if (routeState?.sortBy !== ROUTE_DEFAULT_STATE.sortBy) {
      queryParameters.sortBy = routeState.sortBy
    }

    const queryString = qsModule.stringify(
      {
        ...queryParameters,
        searchTerm: routeState.term,
        searchType: 'default',
        ...sponsorsParams
      },
      {
        addQueryPrefix: true,
        arrayFormat: 'repeat'
      }
    )

    if (routeState.term) {
      return `${baseUrl}${queryString}${hash}`
    }

    return `${origin}${pathname}${hash}`
  },
  parseURL (params): RouteState {
    const { qsModule, location } = params

    const URL = normalizeGoogleParams(location.search.slice(1))
    const queryParameters = qsModule.parse(URL, {})

    const {
      term = '',
      page = 1,
      hierarchicalMenu = null,
      refinementList = {},
      toggle = {},
      range = {},
      sortBy = ''
    } = queryParameters

    return {
      query: decodeURIComponent(term as string),
      page: page as string,
      hierarchicalMenu: (hierarchicalMenu as string)?.split('/'),
      refinementList: normalizeRefinementList(refinementList as {}),
      toggle: toggle as string[],
      range: range as string[],
      sortBy: sortBy as string
    }
  }
})

const getStateMapping = (params: { indexName: string }) => {
  const { indexName } = params

  return {
    stateToRoute (uiState: UiState) {
      const {
        query,
        page,
        hierarchicalMenu,
        refinementList,
        toggle,
        range,
        sortBy
      } = uiState[indexName]

      return {
        query,
        term: query,
        page: page && String(page),
        hierarchicalMenu:
          hierarchicalMenu &&
          hierarchicalMenu['hierarchicalCategories.lvl0'] &&
          hierarchicalMenu['hierarchicalCategories.lvl0'].join('/'),
        refinementList,
        toggle,
        range,
        sortBy
      }
    },
    routeToState (state: RouteState) {
      const {
        query,
        page,
        hierarchicalMenu,
        refinementList,
        toggle,
        range,
        sortBy
      } = state

      return {
        [indexName]: {
          query,
          page: Number(page),
          hierarchicalMenu: {
            'hierarchicalCategories.lvl0': hierarchicalMenu
          },
          refinementList,
          toggle,
          range,
          sortBy
        }
      }
    }
  }
}

const getRouting = (indexName: string) => ({
  router,
  stateMapping: getStateMapping({ indexName })
})

export default getRouting
