import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgDoorOpenOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M10.792 21.978A1 1 0 0 1 10 21v-1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h4V3a1.003 1.003 0 0 1 1.242-.97l8 2A1 1 0 0 1 20 5v14a1 1 0 0 1-.758.97l-8 2a1 1 0 0 1-.45.008M18 5.781l-6-1.5v15.438l6-1.5zM7 6v12h3V6zm8 6.188a1.001 1.001 0 0 1-2 .001v-.377a1 1 0 0 1 2-.001z"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgDoorOpenOutline)
const Memo = memo(ForwardRef)
export default Memo
