import styled from '@emotion/styled'
import React from 'react'

import ArchivedMessageItem from '../ArchivedMessageItem/ArchivedMessageItem'

type Message = {
  id: string
  title: string
  date: string
  text?: string
}

type ArchivedMessageListProps = {
  list: Message[]
  onClick: (id: string) => void
}

const ArchivedMessageListWrapper = styled.div`
  display: flex;
  flex-direction: column;

  overflow-y: auto;
`

const ArchivedMessage = styled.div`
  border-top: 1px solid var(--color-border-neutral-interactive);
  &:last-child {
    border-bottom: 1px solid var(--color-border-neutral-interactive);
  }
`

const ArchivedMessageList: React.FC<ArchivedMessageListProps> = ({
  list,
  onClick
}) => {
  return (
    <ArchivedMessageListWrapper>
      {list.map(item => (
        <ArchivedMessage key={item.id}>
          <ArchivedMessageItem data={item} onClick={onClick}>
            {item?.text ?? 'Conversa encerrada'}
          </ArchivedMessageItem>
        </ArchivedMessage>
      ))}
    </ArchivedMessageListWrapper>
  )
}

export default ArchivedMessageList
