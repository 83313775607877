import { tv } from 'tailwind-variants'

export default tv({
  base: '',
  slots: {
    divider: `
      border-b 
      border-gray-200 
      mt-8
      mb-4
    `
  }
})
