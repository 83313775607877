import { useState } from 'react'
import { Button, Stack } from '@backyard-ui/core'
import { SubmitHandler, useForm } from 'react-hook-form'

import * as S from './styled'
import { AssessmentFormData, AssessmentProps } from './types'

const Assessment = ({ onSubmit }: AssessmentProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
    trigger
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      scale: 0,
      details: null
    }
  })

  const submitEvent: SubmitHandler<AssessmentFormData> = async (
    dataForm,
    event
  ) => {
    event?.preventDefault()
    setIsSubmitting(true)

    const values: AssessmentFormData = {
      scale: dataForm.scale,
      details: dataForm?.details
    }

    onSubmit(values)

    setIsSubmitting(false)
  }

  const hasError = Object.keys(errors).length > 0

  return (
    <S.AssessmentWrapper>
      <S.DisclaimerText>
        Em uma escala de 1 a 6, pode nos dizer o quanto a assistente virtual te
        ajudou?
      </S.DisclaimerText>
      <S.FormWrapper onSubmit={handleSubmit(submitEvent)}>
        <div>
          <Stack spacing="2" justify="center">
            {new Array(6).fill({}).map((_, i) => {
              return (
                <input
                  key={`feedback-scale-${i}`}
                  id={`feedback-scale-${i}`}
                  type="radio"
                  required
                  value={i}
                  {...register('scale', { required: true })}
                  onChange={() => {
                    trigger('scale')
                  }}
                />
              )
            })}
          </Stack>
          <S.FormRadioGroupLabel>
            <span>Pouco</span>
            <span>Muito</span>
          </S.FormRadioGroupLabel>
        </div>
        <S.TextField
          minLength={3}
          maxLength={220}
          placeholder="Se for possível, escreva aqui sua opinião"
          {...register('details')}
          onChange={() => {
            trigger('details')
          }}
        />
        <S.ButtonWrapper>
          <Button
            data-gtm-flow-name="Concierge"
            data-gtm-step-name="Send Concierge feedback"
            appearance="primary"
            type="submit"
            variant="solid"
            size="xs"
            radius="2xl"
            isDisabled={!isValid || hasError || isSubmitting}
            isLoading={isSubmitting}
          >
            Enviar
          </Button>
        </S.ButtonWrapper>
      </S.FormWrapper>
    </S.AssessmentWrapper>
  )
}

export default Assessment
