import { SelectTernaryOptionsGroup } from 'frontendMyOrders/plugins/Chat/components'
import {
  TernarySelectorOptionsType,
  TernarySelectorType
} from 'frontendMyOrders/plugins/Chat/MarketPlace/types'

const TernaryOptions = ({
  ternaryOptions,
  onTernaryOptionClick,
  ternarySelected
}: {
  ternaryOptions: TernarySelectorOptionsType
  onTernaryOptionClick: (ternaryOption: TernarySelectorType) => void
  ternarySelected: 'SIM' | 'NAO' | ''
}) => {
  return (
    <SelectTernaryOptionsGroup
      options={ternaryOptions}
      onClick={onTernaryOptionClick}
      selected={ternarySelected}
    />
  )
}

export default TernaryOptions
