import { Heading, Text, Stack, Spinner } from '@leroy-merlin-br/backyard-react'
import { ChevronRight } from '@backyard-ui/icons'
import { FC } from 'react'

import * as dataLayer from 'scripts/utils/data-layer'

import { Layout } from '../Layout'
import { OptionToRecovery } from '../../context/recovery-context'
import * as S from './styled'
import {
  OptionType,
  OptionsToReceiveSecurityCodeStepProps,
  useOptionsToReceiveSecurityCodeStep
} from './useOptionsToReceiveSecurityCodeStep'

const OptionsToReceiveSecurityCodeStep: FC<
  OptionsToReceiveSecurityCodeStepProps
> = ({ onNextStep }) => {
  const { selectedOption, contextData, options, handleOptionSelectedClick } =
    useOptionsToReceiveSecurityCodeStep({ onNextStep })

  return (
    <Layout description="Escolha como quer redefinir sua senha">
      <S.OptionsWrapper aaa={!!selectedOption}>
        {contextData.optionsToRecovery.map(
          (option: OptionToRecovery, index: number) => {
            const { icon, title } = options[option.type as OptionType]

            const isActive = selectedOption?.hash === option?.hash

            return (
              <S.Option
                key={`password-recovery-option-${index}`}
                onClick={() =>
                  !selectedOption && handleOptionSelectedClick(option)
                }
                active={isActive}
                disabled={!!selectedOption}
                // Esse id está sendo utilizado para o tagueamento
                id={`${option.type}-option`}
                data-cy={`select-${option.type}-to-receive-code`}>
                {icon}

                <S.OptionText>
                  <Heading as="h3" size="kilo" noMargin>
                    {title}
                  </Heading>
                  <Text size="mega" noMargin>
                    {option.contact}
                  </Text>
                </S.OptionText>

                <S.OptionIconWrapper>
                  {isActive ? (
                    <Spinner size="mega" />
                  ) : (
                    <ChevronRight
                      width={18}
                      height={18}
                      data-gtm-element-id={`click-chevron-right-${option.type}`}
                    />
                  )}
                </S.OptionIconWrapper>
              </S.Option>
            )
          }
        )}
      </S.OptionsWrapper>

      <Stack alignX="center" space="kilo">
        <a
          href="https://privacyportal-eu.onetrust.com/webform/b1bfff13-ac00-4c6f-a1a3-06f2aaf34c5e/f81ef4f1-4c3f-4c2f-84ea-495818ed76c4"
          target="_blank"
          // Esse id está sendo utilizado para o tagueamento
          id="options-step-change-email-phone-link"
          onClick={dataLayer.recoveryChangeEmailLinkClick}
          rel="noreferrer">
          <Text size="mega" noMargin color="p600">
            Clique aqui para alterar o e-mail
          </Text>
        </a>

        <a
          href="/login"
          // Esse id está sendo utilizado para o tagueamento
          id="options-step-back-to-login-link"
          onClick={dataLayer.recoveryBackToLoginLinkClick}>
          <Text size="mega" noMargin color="p600">
            Voltar ao login
          </Text>
        </a>
      </Stack>
    </Layout>
  )
}

export default OptionsToReceiveSecurityCodeStep
