import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgShowOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M2.052 11.684C2.073 11.617 4.367 5 12 5s9.927 6.617 9.949 6.684l.105.316-.106.316C21.927 12.383 19.633 19 12 19s-9.927-6.617-9.949-6.684L1.946 12zM4.074 12c.502 1.154 2.575 5 7.926 5 5.348 0 7.422-3.842 7.926-5-.502-1.154-2.575-5-7.926-5-5.348 0-7.422 3.842-7.926 5m5.81-2.116A3.02 3.02 0 0 1 12 9c1.641 0 3 1.359 3 3s-1.359 3-3 3a3.02 3.02 0 0 1-3-3 3.02 3.02 0 0 1 .884-2.116"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgShowOutline)
const Memo = memo(ForwardRef)
export default Memo
