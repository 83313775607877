import { ChatOutline } from '@backyard-ui/icons'

import { WhatsAppIcon } from 'shared/icons/WhatsAppIcon'

import { Body, Header } from './Components'
import styles from './styles'

interface ChatModalOptionsProps {
  onClose?: () => void
  onSalesAssistantClick: () => void
}

const ChatModalOptions = ({
  onSalesAssistantClick,
  onClose
}: ChatModalOptionsProps) => (
  <div data-testid="chat-modal-options" className="flex">
    <div className={styles().base()}>
      <Header onClose={onClose} />

      <div className={styles().body()}>
        <div className={styles().item()} data-testid="chat-modal-option-1">
          <Body
            TitleIcon={<WhatsAppIcon />}
            title="Compre através do nosso WhatsApp"
            descriptions={[
              'Nosso atendimento é de segunda a sexta, das 8h às 18h, e aos sábados, das 8h às 14h.',
              'Compre 24h pelo nosso catálogo digital no WhatsApp 😉'
            ]}
            buttonType="whatsapp"
            buttonText="Central de vendas"
            buttonUrl="https://api.whatsapp.com/send?phone=551140071380"
          />
        </div>

        <hr className={styles().divider()} />

        <div className={styles().item()} data-testid="chat-modal-option-2">
          <Body
            TitleIcon={<ChatOutline />}
            title="Duvidas ou informações"
            descriptions={[
              'Nosso concierge é uma inteligência artificial que te guia na compra! Busca ágil, dúvidas resolvidas, estoque confirmado e o produto ideal. Converse com ele!'
            ]}
            buttonType="chat"
            buttonText="Assistente de vendas"
            onClick={onSalesAssistantClick}
          />
        </div>
      </div>
    </div>
  </div>
)

export default ChatModalOptions
