import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgBoneOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        d="M19.049 4.951a3.95 3.95 0 0 0-1.028-1.801c-1.51-1.51-4.146-1.51-5.656 0a4.01 4.01 0 0 0-.618 4.86l-3.714 3.714c-1.505-.89-3.591-.649-4.86.618a4.004 4.004 0 0 0 0 5.657c.498.497 1.12.852 1.8 1.028.178.681.53 1.302 1.028 1.8A3.97 3.97 0 0 0 8.829 22a3.98 3.98 0 0 0 2.828-1.172 4.01 4.01 0 0 0 .617-4.859l3.714-3.714c1.507.891 3.593.65 4.861-.619a4.003 4.003 0 0 0 0-5.656 3.94 3.94 0 0 0-1.8-1.029m.387 5.271c-.756.755-2.073.756-2.829 0l-.707-.707-6.363 6.364.707.707a2.003 2.003 0 0 1 0 2.828c-.757.757-2.074.755-2.829 0a1.96 1.96 0 0 1-.571-1.31l-.047-.9-.9-.047a1.97 1.97 0 0 1-1.895-1.985c0-.53.21-1.04.585-1.415A1.99 1.99 0 0 1 6 13.172a2 2 0 0 1 1.414.585l.707.707 6.364-6.363-.707-.707a2.003 2.003 0 0 1 0-2.829c.756-.754 2.072-.754 2.828 0 .343.343.546.809.572 1.312l.048.897.897.048c.503.026.969.229 1.312.572.377.378.585.88.585 1.414s-.207 1.036-.584 1.414"
        fill={color ? Colors[color] : 'currentColor'}
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgBoneOutline)
const Memo = memo(ForwardRef)
export default Memo
