import { useCallback } from 'react'

import * as S from './styled'

const OptionInput = ({
  id,
  product,
  sku,
  selectedSeller,
  onChange,
  isAssistedSale
}) => {
  const buildButtonStockDisabled = useCallback(() => {
    if (isAssistedSale) {
      return sku.consultButtons?.assistedSaleStock ? 'false' : 'true'
    }

    return sku.consultButtons?.ecommerceStock ? 'false' : 'true'
  }, [isAssistedSale, sku])

  return (
    <S.OptionInput
      type="radio"
      id={id}
      name="buybox-option-radio"
      autoComplete="off"
      checked={selectedSeller?.id === sku.id}
      onChange={onChange}
      data-is-outlet={Boolean(sku.isOutlet)}
      data-is-assisted-sale={isAssistedSale}
      data-selected-seller={JSON.stringify(sku)}
      data-product-name={product.name}
      data-product-id={product.id}
      data-product-brand={product.brand}
      data-category={product.category}
      data-branded-installments-amount={sku.brandedInstallmentsAmount}
      data-branded-installments-value={sku.brandedInstallmentsValue}
      data-discount-percentage={sku.discountPercentage}
      data-discount-type={sku.discountType}
      data-payment-methods-discount={sku.paymentMethodsDiscount}
      data-unit={product.unit}
      data-suggested-price-integers={sku.price?.suggested?.integers}
      data-suggested-price-decimals={sku.price?.suggested?.decimals}
      data-to-price-integers={sku.price?.to?.integers}
      data-to-price-decimals={sku.price?.to?.decimals}
      data-from-price-integers={sku.price?.from?.integers}
      data-from-price-decimals={sku.price?.from?.decimals}
      data-region-price-integers={sku.price?.regionPrice?.integers}
      data-region-price-decimals={sku.price?.regionPrice?.decimals}
      data-installments-amount={sku.installmentsAmount}
      data-installments-value={sku.installmentsValue}
      data-installments-total-value={sku.installmentsTotalValue}
      data-purchase-button-assisted-sale-disabled={
        sku.purchaseButtons?.assistedSale ? 'false' : 'true'
      }
      data-purchase-button-ecommerce-disabled={
        sku.purchaseButtons?.ecommerce ? 'false' : 'true'
      }
      data-purchase-button-pickup-in-store-disabled={
        sku.purchaseButtons?.pickupInStore ? 'false' : 'true'
      }
      data-consult-button-ecommerce-stock-disabled={buildButtonStockDisabled()}
      data-seller-radio
      data-shop-name={sku.shop?.name}
      data-limit-quantity={sku.limitQuantity}
      data-shop-offer-id={sku.id !== product.id && sku.id}
      data-shop-id={sku.shop?.id}
      data-feature-installment-discount={
        sku.featureInstallmentDiscount ? 'true' : 'false'
      }
      data-testid="option-input"
    />
  )
}

export default OptionInput
