import { Text } from '@leroy-merlin-br/backyard-react'
import { useMyOrdersContext } from 'frontendMyOrders/context/MyOrdersContext'
import type {
  ShippingAddressType,
  StepsType,
  ThreadsType
} from 'frontendMyOrders/plugins/Chat/MarketPlace/types'
import { InvoicesType } from 'frontendMyOrders/types'

import { Shift } from '../Shift'
import { ShippingInfo } from '../ShippingInfo'
import * as S from './styled'

type HeaderProps = {
  type: string
  quantity: number
  deliveryDate: {
    date: string
    message: string
  }
  shift: string
  invoices?: InvoicesType
  isStandaloneService: boolean
  orderId?: string
  marketplaceOrder?: string
  shopName?: string
  steps?: StepsType
  shippingAddress?: ShippingAddressType
  trackingCode?: string
  trackingUrl?: string
  threads: ThreadsType
  closeTimeInHours: number
}

const Header = ({
  type,
  quantity,
  deliveryDate,
  invoices,
  shift,
  isStandaloneService,
  orderId,
  marketplaceOrder,
  shopName,
  steps,
  shippingAddress,
  trackingCode,
  trackingUrl,
  threads,
  closeTimeInHours
}: HeaderProps) => {
  const { plugins } = useMyOrdersContext()

  const { MarketplaceChat } = plugins

  return (
    <S.Wrapper>
      <S.Type>
        <Text as="strong" size="mega" isBold noMargin color="g700">
          {type}
        </Text>
      </S.Type>

      <S.Quantity>
        <Text size="kilo" noMargin>
          Itens
        </Text>
        <Text as="strong" size="kilo" isBold noMargin>
          {quantity}
        </Text>
      </S.Quantity>

      <S.Shipping>
        <ShippingInfo
          type={type}
          date={deliveryDate?.date}
          isStandaloneService={isStandaloneService}
        />
      </S.Shipping>

      <S.Shift>{shift && <Shift type={type} shift={shift} />}</S.Shift>

      <S.Chat>
        {MarketplaceChat && orderId && shopName && marketplaceOrder && (
          <MarketplaceChat
            orderId={orderId}
            marketplaceOrder={marketplaceOrder}
            shopName={shopName}
            steps={steps}
            shippingAddress={shippingAddress}
            deliveryDate={deliveryDate}
            trackingCode={trackingCode}
            trackingUrl={trackingUrl}
            invoices={invoices}
            threads={threads}
            closeTimeInHours={closeTimeInHours}
          />
        )}
      </S.Chat>
    </S.Wrapper>
  )
}

export default Header
