import {
  Col,
  Inline,
  Radio,
  Stack,
  Text,
  TextField
} from '@leroy-merlin-br/backyard-react'
import { useFormContext } from '../../../../../../contexts/FormContext'
import * as S from '../../../../styles/form-styled'
import NumberFormat from 'react-number-format'
import { handlePhoneFormat } from '../../../../../../../../../../../../../user/utils'

export default function Representative() {
  const {
    form: { register, watch, setValue },
    formState: { errors },
    legalEntity,
    companyFormIsDisabled,
    saveDraftWithDebounce
  } = useFormContext()

  const companyIsRepresentative = watch('companyIsRepresentative')
  const companyRepresentativeDocumentNumber = watch(
    'companyRepresentativeDocumentNumber'
  )
  const companyRepresentativePhoneNumber = watch(
    'companyRepresentativePhoneNumber'
  )

  const showRadioSelect = Boolean(legalEntity) || !companyFormIsDisabled
  const showFields = companyIsRepresentative === false

  const handleRadioChange = (value: boolean) => {
    setValue('companyIsRepresentative', value)
    saveDraftWithDebounce()
  }

  return (
    <>
      {showRadioSelect && (
        <Col>
          <S.FieldWrapper>
            <Stack space="byte">
              <label>
                <Text isBold>Você é o representante da empresa?</Text>
              </label>
              <Inline space="peta">
                <Radio
                  value="0"
                  name="companyIsRepresentative"
                  isChecked={companyIsRepresentative === false}
                  onChange={() => handleRadioChange(false)}>
                  Não
                </Radio>
                <Radio
                  value="1"
                  name="companyIsRepresentative"
                  isChecked={companyIsRepresentative === true}
                  onChange={() => handleRadioChange(true)}>
                  Sim
                </Radio>
              </Inline>
            </Stack>
          </S.FieldWrapper>
        </Col>
      )}

      {showFields && (
        <>
          <Col size={{ giga: 6 }}>
            <S.FieldWrapper>
              <TextField
                label="Nome do representante legal"
                {...register('companyRepresentativeName')}
                hint={errors.companyRepresentativeName?.message}
              />
            </S.FieldWrapper>
          </Col>

          <Col size={{ giga: 6 }}>
            <S.FieldWrapper>
              <NumberFormat
                customInput={TextField}
                label="CPF"
                placeholder="xxx.xxx.xxx-xx"
                hint={errors.companyRepresentativeDocumentNumber?.message}
                format="###.###.###-##"
                value={companyRepresentativeDocumentNumber}
                {...register('companyRepresentativeDocumentNumber', {
                  onChange: event => {
                    setValue(
                      'companyRepresentativeDocumentNumber',
                      event.target.value
                    )
                  }
                })}
              />
            </S.FieldWrapper>
          </Col>

          <Col size={{ giga: 6 }}>
            <S.FieldWrapper>
              <TextField
                label="E-mail"
                {...register('companyRepresentativeEmail')}
                hint={errors.companyRepresentativeEmail?.message}
              />
            </S.FieldWrapper>
          </Col>

          <Col size={{ giga: 6 }}>
            <S.FieldWrapper>
              <NumberFormat
                customInput={TextField}
                label="Telefone"
                hint={errors.companyRepresentativePhoneNumber?.message}
                format={handlePhoneFormat}
                value={companyRepresentativePhoneNumber}
                {...register('companyRepresentativePhoneNumber', {
                  onChange: event => {
                    setValue(
                      'companyRepresentativePhoneNumber',
                      event.target.value
                    )
                  }
                })}
              />
            </S.FieldWrapper>
          </Col>
        </>
      )}
    </>
  )
}
