import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgWarningOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M2 12C2 6.486 6.465 2 11.953 2 17.493 2 22 6.486 22 12s-4.486 10-10 10S2 17.514 2 12m2 0c0 4.411 3.589 8 8 8s8-3.589 8-8-3.609-8-8.047-8C7.567 4 4 7.589 4 12m9-5h-2v7h2zm0 10v-2h-2v2z"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgWarningOutline)
const Memo = memo(ForwardRef)
export default Memo
