import React from 'react'

import Typography, { TypographyProps } from '../Typography'

type TextType = Omit<TypographyProps, 'variant'> & {
  size?: 'xs' | 'sm' | 'md'
  type?: 'default' | 'strong' | 'emphasis'
}

function Text ({ size = 'xs', type, children, ...rest }: TextType) {
  if (React.isValidElement(children)) {
    throw new Error('Text component does not accept React elements as children')
  }

  return (
    <Typography variant={`body-${size}`} type={type} {...rest}>
      {children}
    </Typography>
  )
}

export default Text
