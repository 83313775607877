import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
  Dialog,
  Text,
  useModal,
  Button,
  ButtonGroup
} from '@leroy-merlin-br/backyard-react'
import { flattenDeep } from 'lodash'

import emitter from 'scripts/utils/emitter'
import {
  fetchCartShipping,
  removeProductFromCart,
  getitemsAvailableStores
} from 'scripts/api/cart'
import { errorOnCartPage } from 'scripts/utils/data-layer'

import { useGTMShipping } from 'shared/tracker/hooks'

import { handleSpinner } from 'site/Cart/services'
import {
  PostalCode,
  Section,
  CartSkeleton,
  Summary,
  EmptyCart,
  Error
} from 'site/Cart/components'
import { removeServiceFromCart } from 'site/Cart/components/Product/StandaloneService/actions'
import { ERRORS } from 'site/Cart/services/constants'

import { tracker as appTracker, cartEventApp } from 'utils/app'
import formatPriceToBRL from 'utils/formatPriceToBRL'

import { Actions } from 'checkout/redux/modules/crossServices'
import { Actions as CartActions } from 'checkout/redux/modules/cart'
import * as dataLayer from 'checkout/services/data-layer'
import getUserContext from 'checkout/utils/getUserContext'

import { ChangeCartType } from 'newCheckout/pages/Cart/components/ChangeCartType'

import { ShippingCart } from 'frontendCheckout/models/ShippingCart.class'
import { useViewCart } from 'frontendCheckout/tracker/hooks'
import * as tracker from 'frontendCheckout/tracker'
import { useStandaloneService } from 'frontendCheckout/hooks'
import * as algonomy from 'frontendCheckout/services/algonomy'

import * as S from './styled'
import { getProductsServices } from './util'
import { buildDataLayerObject } from './utils'

const setRegionOnApp = ({ name: regionName, slug: region }) => {
  if (!window.ReactNativeWebView) {
    return
  }

  window.ReactNativeWebView.postMessage(
    JSON.stringify({
      type: 'updateRegion',
      data: {
        regionName,
        region
      }
    })
  )

  window.ReactNativeWebView.postMessage(
    JSON.stringify({
      type: 'setRegion',
      data: {
        name: regionName,
        slug: region
      }
    })
  )
}
const Cart = ({
  regionInit,
  regionName,
  isChangeCartTypeEnabled,
  isHybridEnabled,
  isSimilarProductsEnabled
}) => {
  const [cart, setCart] = useState({})
  const [shippingCart, setShippingCart] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [shouldFetchShipments, setShouldFetchShipments] = useState(true)
  const [itemsAvailableStores, setItemsAvailableStores] = useState([])
  const { setModal } = useModal()

  const dispatch = useDispatch()
  const { setViewCart } = useViewCart()

  const region = useSelector(state => state.cart.region, shallowEqual)
  const stateShippings = useSelector(state => state.cart.shippings)
  const stateServices = useSelector(state => state.cart.services)

  const { handleShippingsChange } = useGTMShipping()

  const { isStandaloneServiceCart } = useStandaloneService({
    services: stateServices,
    isCartWithoutProducts: !stateShippings?.length
  })

  const freightEstimateForStandAloneService = isStandaloneServiceCart ? 'Em até 4 dias úteis' : 'De 1 à 3 dias úteis'

  const removeStandaloneService = async (callback, service) => {
    callback()
    handleSpinner({ action: 'show', useSpinner: true })
    await removeServiceFromCart({
      category: service.categoryTree,
      name: service.name,
      id: service.id,
      prevQuantity: service.quantity,
      price: service.price,
      regionId: service.regionId,
      serviceId: service.productId
    })
    fetchPriceShipping()
  }

  const removeItem = async (callback, product) => {
    try {
      setIsLoading(true)
      await removeProductFromCart(product.id)

      dataLayer.removeFromCart({
        category: product.categoryTree,
        price: product.price?.to,
        productBrand: product.brand,
        productId: product.productId,
        productName: product.name,
        quantity: product.quantity,
        regionId: product.regionId
      })

      const hasOffer = product.price?.from > product.price?.to
      const isByPaymentMethod =
        hasOffer && Boolean(product.discountByPaymentMethod)
      const discountType = isByPaymentMethod ? 'by-payment-method' : 'offer'
      const discount = parseFloat(
        (product.price?.from - product.price?.to).toFixed(2)
      )
      const deliveryType = product.type === 'delivery' ? 'delivery' : 'pickup'

      tracker.pushRemoveFromCart({
        pageType: 'cart',
        product: {
          id: product.productId,
          productId: null,
          name: product.name,
          price: product.price?.to,
          discount: hasOffer ? discount : 0,
          discountType: hasOffer ? discountType : undefined,
          brand: product.brand,
          categoryTree: product.categoryTree,
          seller: product.shopName,
          quantity: product.quantity
        },
        deliveryType,
        zipCode: ''
      })

      product.services
        .filter(service => service.relatedProduct.id === product.id)
        .forEach(({ serviceId, name, price, quantity, serviceBankName, relatedProduct }) => {
          dataLayer.removeFromCart({
            category: serviceBankName,
            price: price?.to,
            productBrand: 'Serviço',
            productId: serviceId,
            productName: name,
            quantity: quantity,
            regionId: region.id
          })

          tracker.pushRemoveFromCart({
            pageType: 'cart',
            product: {
              id: serviceId,
              productId: relatedProduct?.productId,
              name: name,
              price: price?.to,
              discount: 0,
              discountType: undefined,
              brand: 'Leroy Merlin',
              categoryTree: 'serviço',
              seller: 'Leroy Merlin',
              quantity: quantity
            },
            deliveryType: 'delivery',
            zipCode: ''
          })
        })

      fetchPriceShipping(cart.zipCode)
      return callback()
    } catch (error) {
      errorOnCartPage('clique_remover_item', 'Erro ao remover item do carrinho')
    }
  }

  const RemoveModal = async (item, remove) => {
    setModal({
      children: ({ onClose }) => (
        <Dialog
          data-cy="cart-remove-product-dialog"
          onClose={onClose}
          title="Atenção!"
          footer={
            <ButtonGroup align="flex-end">
              <Button
                appearance="critical"
                onClick={async () => {
                  await remove(onClose, item)
                }}
                variant="ghost"
                size="kilo"
              >
                Excluir
              </Button>
              <Button size="kilo" onClick={onClose}>
                Manter na lista
              </Button>
            </ButtonGroup>
          }
        >
          <Text noMargin>Deseja mesmo excluir esse item da lista?</Text>
        </Dialog>
      )
    })
  }

  const onRemove = product => {
    RemoveModal(product, removeItem)
  }

  const onRemoveStandaloneService = service => {
    RemoveModal(service, removeStandaloneService)
  }

  const hasCEPErrors = errors =>
    errors && ERRORS.CEP.some(error => errors[error])

  const getCEPErrors = errors => ERRORS.CEP.find(error => errors[error])

  const openPriceShippingErrorDialog = useCallback(
    (message, needReload) => {
      function handleClose (onClose) {
        if (needReload) {
          location.replace('/carrinho')
        }
        onClose()
      }

      setModal({
        children: ({ onClose }) => (
          <Dialog
            data-cy="fetch-price-shipping-error-dialog"
            onClose={() => handleClose(onClose)}
            title="Ops!"
            footer={
              <ButtonGroup align="flex-end">
                <Button
                  onClick={() => handleClose(onClose)}
                  variant="ghost"
                  size="kilo"
                >
                  Ok
                </Button>
              </ButtonGroup>
            }
          >
            <Text noMargin>{message}</Text>
          </Dialog>
        )
      })
    },
    [setModal]
  )

  useEffect(() => {
    if (!hasCEPErrors(cart.errors)) {
      return
    }

    setModal({
      children: ({ onClose }) => (
        <Dialog
          data-cy="cart-cep-dialog"
          onClose={onClose}
          title="Atenção!"
          footer={
            <ButtonGroup align="flex-end">
              <Button onClick={onClose} variant="ghost" size="kilo">
                Confirmar
              </Button>
            </ButtonGroup>
          }
        >
          <Text>{cart.errors[getCEPErrors(cart.errors)][0]}</Text>
        </Dialog>
      )
    })
  }, [cart, setModal])

  const updateCartWhenServiceWasAdded = useCallback(cart => {
    if (cart) {
      setCart(cart)
    }
  }, [])

  const setServicesPerItem = useCallback(
    async cart => {
      const shipments = await getProductsServices(cart, region.id)
      dispatch(Actions.clearServices())

      shipments.forEach(shipment => {
        dispatch(Actions.updateList(shipment))
      })
    },
    [dispatch, region]
  )

  const findItemsAvailableStores = async cart => {
    try {
      const storeId = cart.shippings.find(
        shipping => shipping.type === 'pickupInStore'
      )?.storeId
      const itemsAvailableStores = (await getitemsAvailableStores(cart.id)).data
        .data

      if (storeId) {
        itemsAvailableStores.forEach(itemAvailableStores => {
          const store = itemAvailableStores.stores.find(
            store => store.storeId === storeId
          )
          itemAvailableStores.stores = store ? [store] : []
          itemAvailableStores.storeAlreadySelected = true
        })
      }

      setItemsAvailableStores(itemsAvailableStores)
    } catch (error) {
      setItemsAvailableStores([])
    }
  }

  const changeLoading = (value) => {
    if (value) handleSpinner({ useSpinner: true, action: 'show' })
    else handleSpinner({ useSpinner: true, action: 'hide' })
  }

  const fetchPriceShipping = useCallback(
    async (postalCode, zipCodeToDL) => {
      const useSpinner = cart !== null
      handleSpinner({ useSpinner, action: 'show' })

      try {
        const response = await fetchCartShipping(postalCode)
        if (isSimilarProductsEnabled) {
          const shippingCart = ShippingCart.createFromShipmentApi(response)
          await shippingCart.fetchSimilarProductsForProductsWithError()
          setShippingCart(shippingCart)
        }

        setServicesPerItem(response)

        setCart(response)
        await findItemsAvailableStores(response)

        setIsLoading(false)
        handleSpinner({ useSpinner })

        const servicesValue = response.servicesSubTotal ? response.servicesSubTotal : 0

        setViewCart({
          shippings: response.shippings,
          services: response.services,
          zipCode: response.zipCode,
          freightCost: response.freightCost,
          cart: {
            value: response.subTotal + servicesValue,
            discount: response.discountTotal
          }
        })

        emitter.emit('cart:update', { count: response.uniqueProductsCount })
        cartEventApp.updateCart(response.id, response.uniqueProductsCount)

        if (response.shippings) {
          const newShippingArray = response.shippings.map(shipping => ({ ...shipping, items: shipping.shipments?.[0]?.items || [] }))
          dispatch(CartActions.setShippings(newShippingArray))
        }

        if (response.services) {
          dispatch(CartActions.setServices(response.services))
        }

        if (response.region) {
          dispatch(CartActions.setRegion(response.region))

          emitter.emit('cart:region-changed', {
            region: response.region.id,
            regionName: response.region.name,
            locationSource: 'user'
          })
        }

        if (response.zipCode) {
          handleShippingsChange({
            pageType: 'cart',
            shippings: response.shippings,
            zipCode: response.zipCode
          })
        }

        if (zipCodeToDL) {
          const getModalitiesDimension = modalities =>
            modalities
              .reduce((acc, { cost, type }) => {
                const formattedCost = formatPriceToBRL(cost).replace(/\s/g, '')
                return [...acc, `${type}_${formattedCost}`]
              }, [])
              .join(' - ')

          const productsMap = response.shippings.map(shipping =>
            shipping.shipments.map(shipment => {
              const modalities = getModalitiesDimension(shipment.modalities)
              return shipment.items.map(product => ({
                name: product.name,
                shipmentOptions: modalities
              }))
            })
          )

          const products = flattenDeep(productsMap)

          dataLayer.cepCarrinho({ products })
        }

        appTracker.freightCosts(response)
      } catch (error) {
        if (!error?.isAxiosError) {
          return
        }

        const {
          response: {
            data: { message, needReload, region }
          }
        } = error

        if (cart.length) setCart({})

        setIsLoading(false)

        if (region) {
          setRegionOnApp(region)
        }

        handleSpinner({ useSpinner })

        openPriceShippingErrorDialog(message, needReload)
        errorOnCartPage('validacao_cep', 'Não foi possível calcular o frete')
      }
    },
    [
      cart,
      dispatch,
      setServicesPerItem,
      openPriceShippingErrorDialog,
      setViewCart,
      handleShippingsChange
    ]
  )

  const updateShipment = data => {
    emitter.emit('cart:update', { count: data.uniqueProductsCount })
    setShouldFetchShipments(true)
  }

  const sendToDataLayer = ({ type, storeId }) => {
    const dataToDataLayer = buildDataLayerObject({
      type,
      storeId,
      cart,
      region
    })

    dataLayer.handleChangeCartTypeDatalayer({
      ...dataToDataLayer,
      shipmentType: dataToDataLayer.shipmentType
    })
  }

  const handleChange = data => {
    sendToDataLayer({ ...data })
    fetchPriceShipping()
  }

  useEffect(() => {
    if (cart && Object.keys(cart).length > 0) {
      const products = cart?.shippings?.flatMap(shipping => shipping.shipments.flatMap(shipment => shipment.items)) || []
      algonomy.sendCartInfo(products)
    }
  }, [cart])

  useEffect(() => {
    let callShipmentsAPI
    if (shouldFetchShipments) {
      callShipmentsAPI = setTimeout(async () => {
        const zipCode = cart.zipCode || ''
        await fetchPriceShipping(zipCode)

        setShouldFetchShipments(false)
      }, 1000)
    }

    return () => {
      clearTimeout(callShipmentsAPI)
    }
  }, [shouldFetchShipments, cart, fetchPriceShipping])

  useEffect(() => {
    emitter.on('cart:products-replace', () => {
      setShouldFetchShipments(true)
    })
  }, [])

  useEffect(() => {
    emitter.on('cart:service-change', cart => {
      updateCartWhenServiceWasAdded(cart)
    })
  }, [updateCartWhenServiceWasAdded])

  useEffect(() => {
    if (regionInit) {
      dispatch(CartActions.setRegion({ id: regionInit, name: regionName }))
    }
  }, [regionInit, regionName, dispatch])

  if (isLoading) {
    return <CartSkeleton />
  }

  const getStandaloneService = services => {
    if (!services) return []

    return services
      .filter(service => !service.relatedProduct?.id)
      .map(service => ({
        translatedUnit: 'un',
        productUrl: `/service_${service.serviceId}`,
        ...service
      }))
  }

  const {
    subTotal,
    discountTotal,
    cheapestPayMethodTotal,
    freightCost,
    servicesSubTotal,
    total,
    zipCode,
    shippings,
    id: cartId,
    paymentMethodsSummary,
    paymentMethods,
    totalInstallments,
    services,
    totalDiscountsByType,
    firstCheapestPaymentMethod
  } = cart || {}

  const hasCart = cart !== null && (!!shippings?.length || !!services?.length)

  const standAloneService = getStandaloneService(services)

  return (
    <S.Wrapper>
      <S.CartSection>
        {!isHybridEnabled &&
          isChangeCartTypeEnabled &&
          hasCart &&
          shippings[0]?.type &&
          cartId && (
            <ChangeCartType
              cartId={cartId}
              type={shippings[0].type}
              onChange={handleChange}
              storeId={shippings[0].storeId}
              cep={zipCode}
              cart={cart}
            />
        )}

        {cart.errors && <Error errors={cart.errors} />}

        {hasCart &&
          shippings.find(shipping => shipping.type !== 'pickupInStore') && (
            <PostalCode
              errors={cart.errors}
              zipCode={zipCode}
              fetchPriceShipping={fetchPriceShipping}
            />
        )}

        {shippings?.map(({ type, shipments, storeId, storeName }) => {
          return shipments?.map(({ items, modalities }, i) => (
            <Section
              key={`${type}-${i}`}
              freightCost={freightCost}
              storeName={storeName}
              modalities={modalities}
              onRemove={onRemove}
              products={items}
              type={type}
              updateProduct={updateShipment}
              services={cart.services}
              regionId={region.id}
              cartId={cartId}
              onChange={handleChange}
              changeItemTypeCallback={fetchPriceShipping}
              fetchPriceShipping={fetchPriceShipping}
              itemsAvailableStores={itemsAvailableStores}
              storeId={storeId}
              isChangeCartTypeEnabled={isChangeCartTypeEnabled}
              isHybridEnabled={isHybridEnabled}
              zipCode={zipCode}
              shippingCart={shippingCart}
              forceRemove={removeItem}
              setIsLoading={changeLoading}
              isSimilarProductsEnabled={isSimilarProductsEnabled}
            />
          ))
        })}

        {standAloneService.length > 0 && (
          <Section
            key={`service-${i}`}
            freightCost={freightCost}
            storeName={'Leroy Merlin'}
            modalities={[{ type: 'override', message: freightEstimateForStandAloneService }]}
            onRemove={onRemoveStandaloneService}
            products={standAloneService}
            type={'services'}
            updateProduct={updateShipment}
            services={[]}
            regionId={region.id}
            cartId={cartId}
            onChange={handleChange}
            changeItemTypeCallback={fetchPriceShipping}
            fetchPriceShipping={fetchPriceShipping}
            itemsAvailableStores={itemsAvailableStores}
            storeId={null}
            isStandaloneService={true}
            isChangeCartTypeEnabled={isChangeCartTypeEnabled}
            isHybridEnabled={isHybridEnabled}
          />
        )}

        {!hasCart && <EmptyCart />}
      </S.CartSection>

      {hasCart && (
        <div style={{ flex: 1 }}>
          <Summary
            data-testid="summary-component"
            cart={cart}
            services={cart.services}
            context={getUserContext()}
            discount={discountTotal}
            cheapestPayMethodTotal={cheapestPayMethodTotal}
            canBePurchased={cart.canBePurchased}
            freight={freightCost}
            subtotal={subTotal}
            servicesSubTotal={servicesSubTotal}
            total={total}
            regionId={region.id}
            paymentMethodsSummary={paymentMethodsSummary}
            paymentMethods={paymentMethods}
            totalInstallments={totalInstallments}
            totalDiscountsByType={totalDiscountsByType}
            firstCheapestPaymentMethod={firstCheapestPaymentMethod}
            zipCode={zipCode}
          />
        </div>
      )}
    </S.Wrapper>
  )
}

export default Cart
