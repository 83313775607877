import { ComponentProps } from 'react'
import { Navigation } from '@leroy-merlin-br/backpack-header'

import type { HeaderMenu } from 'scripts/react-components/api/Header/categories'

type HeaderBackpackNavigation = ComponentProps<typeof Navigation>
export type HeaderBackpackMenus = HeaderBackpackNavigation['data']

export const remapMenu = (menus: HeaderMenu[]): HeaderBackpackMenus =>
  menus.map(menu => {
    const remapedItems = menu.items?.map(item => ({
      id: item.id,
      name: item.name,
      url: item.url,
      hasChildren: item.hasChildren
    }))

    return {
      id: menu.id,
      name: menu.name,
      url: menu.url,
      items: remapedItems,
      hasChildren: Boolean(remapedItems)
    }
  })
