import { FC } from 'react'
import { Checkbox, Text } from '@leroy-merlin-br/backyard-react'

import * as S from '../styled'

interface OptInProFieldProps {
  defaultChecked: boolean
  disabled: boolean
  onClick: () => void
}

const OptInProField: FC<OptInProFieldProps> = ({
  defaultChecked,
  disabled,
  onClick
}) => {
  return (
    <S.Field>
      <S.CheckboxWrapper>
        <Checkbox
          color="n900"
          defaultChecked={defaultChecked}
          disabled={disabled}
          onClick={onClick}>
          <S.CheckboxInnerContent>
            <Text isBold noMargin color="n900" size="mega">
              Sou profissional da construção civil.
            </Text>

            <S.CheckboxInnerText>
              Profissionais das áreas de engenharia, arquitetura, decoração, ou
              mesmo quem atua como pedreiro ou pedreira, etc tem vantagens
              únicas como carteira de clientes e acesso à eventos.
            </S.CheckboxInnerText>
          </S.CheckboxInnerContent>
        </Checkbox>
      </S.CheckboxWrapper>
    </S.Field>
  )
}

export default OptInProField
