import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgLmvcOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M11.415 4.656a1 1 0 0 1 1.17 0l7 5.056a1 1 0 0 1 .415.81V18a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-7.477a1 1 0 0 1 .415-.811zm2.342-1.621a3 3 0 0 0-3.514 0l-7 5.056A3 3 0 0 0 2 10.523V18a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-7.477a3 3 0 0 0-1.244-2.432zm1.871 4.833a.2.2 0 0 0-.284 0l-1.956 1.978a.2.2 0 0 0 .142.34h3.912a.2.2 0 0 0 .142-.34zm-.234 3.007c-.838 0-1.588.312-2.126.905-.539.591-.856 1.45-.856 2.527 0 1.105.317 1.962.856 2.544.54.583 1.29.876 2.126.876 1.262 0 2.36-.834 2.666-1.985l.041-.154-1.6-.323-.047.112c-.199.465-.564.758-1.117.758-.346 0-.62-.164-.815-.47-.198-.312-.313-.775-.313-1.358 0-.582.115-1.049.314-1.365.194-.31.469-.475.814-.475.515 0 .85.334 1.009.806l.046.136 1.638-.473-.024-.134c-.175-.96-1.139-1.927-2.612-1.927m-7.627.152H5.614v1.429h.859l1.877 5.12h1.82l2.402-6.55h-1.784l-1.505 4.402z"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgLmvcOutline)
const Memo = memo(ForwardRef)
export default Memo
