import { useEffect, useCallback, useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Text, SubHeading, Button } from '@leroy-merlin-br/backyard-react'

import { usePrevious } from 'utils/hooks/use-previous'

import * as dataLayer from 'checkout/services/data-layer'
import PriceInfo from 'checkout/components/Cart/Prices'
import { Quantity } from 'checkout/components/Quantity'
import { Actions } from 'checkout/redux/modules/crossServices'

import * as tracker from 'frontendCheckout/tracker'

import FullBadge from '../Icons/SquareBadge'
import TermsOfUse from '../TermsOfUse'
import ContentNotification from './ContentNotification'
import * as S from './styled'

const Content = ({
  skuId,
  productQuantity = 1,
  relatedId,
  productName,
  serviceBankName = null,
  id,
  serviceId,
  productId,
  regionId,
  name,
  packaging = 1,
  packagingCount = 1,
  unit = 'un',
  price,
  isAvailable = false,
  isAdded = false,
  acceptanceTerms,
  errors = {}
}) => {
  const [showTerms, setShowTerms] = useState(false)
  const [serviceQuantity, setServiceQuantity] = useState(productQuantity)

  const previousServiceQuantity = usePrevious(serviceQuantity)

  const serviceDatalayerObject = useMemo(() => {
    return {
      serviceId,
      serviceName: name,
      price,
      category: serviceBankName,
      productName,
      productId
    }
  }, [serviceId, name, price, serviceBankName, productName, productId])

  const addServiceToDataLayer = useCallback(
    diffAmount => {
      dataLayer.addToCart({
        category: serviceDatalayerObject.category,
        price: serviceDatalayerObject.price?.to,
        productBrand: 'Serviço',
        productId: serviceDatalayerObject.serviceId,
        productName: serviceDatalayerObject.serviceName,
        quantity: diffAmount,
        regionId,
        shipmentType: 'delivery',
        storeId: 'Leroy Merlin',
        marketPlace: 'Leroy Merlin',
        relatedProduct: serviceDatalayerObject.productName
      })

      tracker.pushAddToCart({
        pageType: 'cart',
        product: {
          id: serviceDatalayerObject.serviceId,
          productId: serviceDatalayerObject.productId,
          name: serviceDatalayerObject.serviceName,
          price: serviceDatalayerObject.price?.to,
          discount: 0,
          discountType: undefined,
          brand: 'Leroy Merlin',
          categoryTree: 'serviço',
          seller: 'Leroy Merlin',
          quantity: diffAmount
        },
        deliveryType: 'delivery',
        zipCode: ''
      })
    },
    [serviceDatalayerObject, regionId]
  )

  const removeServiceFromDataLayer = useCallback(
    diffAmount => {
      dataLayer.removeFromCart({
        category: serviceDatalayerObject.category,
        price: serviceDatalayerObject.price?.to,
        productBrand: 'Serviço',
        productId: serviceDatalayerObject.serviceId,
        productName: serviceDatalayerObject.serviceName,
        quantity: diffAmount,
        regionId
      })

      tracker.pushRemoveFromCart({
        pageType: 'cart',
        product: {
          id: serviceDatalayerObject.serviceId,
          productId: serviceDatalayerObject.productId,
          name: serviceDatalayerObject.serviceName,
          price: serviceDatalayerObject.price?.to,
          discount: 0,
          discountType: undefined,
          brand: 'Leroy Merlin',
          categoryTree: 'serviço',
          seller: 'Leroy Merlin',
          quantity: diffAmount
        },
        deliveryType: 'delivery',
        zipCode: ''
      })
    },
    [serviceDatalayerObject, regionId]
  )

  const updateServiceDatalayer = useCallback(
    newQuantity => {
      if (newQuantity > previousServiceQuantity) {
        const diffAmount = newQuantity - previousServiceQuantity
        addServiceToDataLayer(diffAmount)
      }

      if (newQuantity < previousServiceQuantity) {
        const diffAmount = previousServiceQuantity - newQuantity
        removeServiceFromDataLayer(diffAmount)
      }
    },
    [previousServiceQuantity, addServiceToDataLayer, removeServiceFromDataLayer]
  )

  const dispatch = useDispatch()

  const deleteService = useCallback(() => {
    removeServiceFromDataLayer(productQuantity)
    dispatch(Actions.serviceDelete(id))
  }, [dispatch, id, productQuantity, removeServiceFromDataLayer])

  const dispatchPostQuantity = useCallback(
    value => {
      dispatch(
        Actions.servicePostQuantity({
          value,
          skuId,
          productQuantity,
          id: serviceId,
          relatedId
        })
      )
    },
    [dispatch, skuId, productQuantity, serviceId, relatedId]
  )

  const handleInputChange = useCallback(
    value => {
      if (!value || value < 0) {
        return
      }

      if (value > productQuantity) {
        setServiceQuantity(productQuantity)
        return
      }

      setServiceQuantity(value)
    },
    [productQuantity]
  )

  const updateServiceQuantity = useCallback(
    ({ value }) => {
      const newServiceQuantity = serviceQuantity + value

      if (
        newServiceQuantity > 0 &&
        newServiceQuantity !== serviceQuantity &&
        newServiceQuantity <= productQuantity
      ) {
        setServiceQuantity(newServiceQuantity)
      }

      if (newServiceQuantity > productQuantity) {
        setServiceQuantity(productQuantity)
      }
    },
    [serviceQuantity, productQuantity]
  )

  const showServiceQuantityWarning = useMemo(() => {
    return productQuantity > serviceQuantity && isAdded
  }, [productQuantity, serviceQuantity, isAdded])

  const changeShowTermsStatus = useCallback(
    () => setShowTerms(status => !status),
    []
  )

  useEffect(() => {
    setServiceQuantity(productQuantity)
  }, [productQuantity])

  useEffect(() => {
    const isEqualPreviousQuantity = serviceQuantity === previousServiceQuantity

    if (!isAdded || isEqualPreviousQuantity) {
      return
    }

    dispatchPostQuantity(serviceQuantity)
    updateServiceDatalayer(serviceQuantity)
  }, [
    isAdded,
    serviceQuantity,
    previousServiceQuantity,
    dispatchPostQuantity,
    updateServiceDatalayer
  ])

  if (!isAvailable) {
    return null
  }

  return (
    <S.Content>
      <S.TakeTogether>
        <Text as="span" size="giga" noMargin color="n900">
          Aproveite e leve junto:
        </Text>
      </S.TakeTogether>
      <S.Container errors={errors}>
        <S.Badge>
          <FullBadge />
        </S.Badge>
        <S.Column>
          <S.ServiceTitleWrapper>
            <SubHeading align="center" noMargin>
              Serviço de Instalação
            </SubHeading>
          </S.ServiceTitleWrapper>
          <S.Row>
            <S.Column>
              <S.Row>
                <S.ServiceName color="n900" size="xl" noMargin>
                  {name}
                </S.ServiceName>
                {isAdded && (
                  <S.RemoveService onClick={deleteService}>
                    <i className="glyph glyph-x" />
                  </S.RemoveService>
                )}
              </S.Row>
              <S.SellerName>
                <S.ForProduct>
                  <Text as="span" size="kilo" noMargin color="n300">
                    Para o produto:{' '}
                  </Text>
                </S.ForProduct>
                <S.Description>
                  <SubHeading noMargin color="n600">
                    {productName}
                  </SubHeading>
                </S.Description>
                <S.UnityFromSP>
                  <Text color="n300">
                    {productQuantity} unidades / em São Paulo
                  </Text>
                </S.UnityFromSP>
              </S.SellerName>
            </S.Column>
            {!isAdded && (
              <Button onClick={changeShowTermsStatus} variant="outline">
                Adicionar
              </Button>
            )}
          </S.Row>
          <S.Footer isAdded={isAdded}>
            {isAdded && (
              <S.QuantityWrapper>
                <Quantity
                  packaging={packaging}
                  packagingCount={serviceQuantity}
                  productMaxStock={productQuantity}
                  translatedUnit="un"
                  quantity={productQuantity}
                  updateQuantity={updateServiceQuantity}
                  useFormatQuantity={serviceQuantity}
                  handleInputChange={handleInputChange}
                />
              </S.QuantityWrapper>
            )}
            <PriceInfo
              packaging={packaging}
              packagingCount={packagingCount}
              translatedUnit={unit}
              quantity={productQuantity}
              price={price}
              productQuantity={productQuantity}
              isAdded={isAdded}
            />
            {isAdded ? (
              <S.ProductWrapper>
                <S.ForProduct>
                  <Text color="n300">Para o produto:</Text>
                </S.ForProduct>
                <S.ProductDescription>
                  <Text as="span" size="kilo" noMargin color="n900">
                    {productName}
                  </Text>
                </S.ProductDescription>
                <Text as="span" size="kilo" noMargin color="n900">
                  Previsão de instalação:{' '}
                  <Text as="strong" size="kilo" noMargin isBold color="n900">
                    a partir de 3 dias úteis após a entrega dos produtos
                  </Text>
                </Text>
              </S.ProductWrapper>
            ) : (
              <Button
                onClick={changeShowTermsStatus}
                variant="outline"
                data-add-services>
                Adicionar
              </Button>
            )}
          </S.Footer>
        </S.Column>
      </S.Container>
      <TermsOfUse
        acceptanceTerms={acceptanceTerms}
        serviceId={serviceId}
        name={name}
        showTerms={showTerms}
        productQuantity={productQuantity}
        onClose={changeShowTermsStatus}
        onClickCallBack={value => {
          dispatchPostQuantity(value)
          addServiceToDataLayer(productQuantity)
        }}
      />
      <ContentNotification
        itemName="serviço de instalação"
        isQuantityNotEqual={showServiceQuantityWarning}
        errors={errors}
      />
    </S.Content>
  )
}

export default Content
