import { SelectOptionsGroup } from 'frontendMyOrders/plugins/Chat/components'

import { OptionsType, SelectOptionType, SubOptionsType } from '../../types'
import { useAppSelector } from '../../redux/store'

const Options = ({
  options,
  onOptionClick,
  isSubOption
}: {
  options: OptionsType | SubOptionsType
  onOptionClick: (option: SelectOptionType | string) => void
  isSubOption?: boolean
}) => {
  const properties = useAppSelector(state => state.properties)

  const selectedOption = isSubOption
    ? properties.data.subOption
    : properties.data.option

  return (
    <SelectOptionsGroup
      options={options.map(option => ({
        key: option.id,
        label: option.name
      }))}
      onClick={onOptionClick}
      selected={selectedOption || ''}
    />
  )
}

export default Options
