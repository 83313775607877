import { FC } from 'react'
import { Text, TextProps } from '@leroy-merlin-br/backyard-react'

export type LegalTextProps = Partial<TextProps>

export const LegalText: FC<LegalTextProps> = props => (
  <Text {...props}>
    Ao prosseguir, você declara que é maior de idade e que leu e está de acordo
    com as{' '}
    <Text {...props} as="a" color="p700" decoration="underline">
      <a
        href="/institucional/termos-e-condicoes-de-uso-do-site"
        target="_blank">
        Condições de Uso
      </a>
    </Text>
    {' e '}
    <Text {...props} as="a" color="p700" decoration="underline">
      <a href="/institucional/politica-de-privacidade" target="_blank">
        Política de Privacidade
      </a>
    </Text>{' '}
    da Leroy Merlin.
  </Text>
)
