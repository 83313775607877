import Axios, { CancelToken, CancelTokenSource } from 'axios'
import Cookies from 'js-cookie'

import { ChatRequest, ChatRequestConfig, AssessmentRequestData } from './types'
import { API_ENDPOINTS } from './constants'
import { axiosInstance } from './api'

const cancelToken = Axios.CancelToken
let cancelTokenSource: CancelTokenSource

const generateCancelToken = (): CancelToken => {
  cancelTokenSource = cancelToken.source()
  return cancelTokenSource.token
}

const cancelRequest = (why = 'Request canceled') => {
  cancelTokenSource?.cancel(why)
}

const generateHeaders = () => {
  const apiToken = Cookies.get('api_token')

  const headers: Record<string, string> = {
    'Content-Type': 'application/json'
  }

  if (apiToken) {
    headers.Authorization = `Bearer ${apiToken}`
  }

  return headers
}

const chat = async (
  requestData: ChatRequest,
  { streamSubscribe }: ChatRequestConfig
) => {
  axiosInstance
    .post(API_ENDPOINTS.chat, JSON.stringify(requestData), {
      cancelToken: generateCancelToken(),
      responseType: 'stream',
      withCredentials: true,
      headers: generateHeaders(),
      onDownloadProgress(progressEvent): void {
        const partial = progressEvent?.target?.response

        if (
          partial &&
          streamSubscribe &&
          progressEvent?.target.readyState !== 4
        ) {
          streamSubscribe.notify(partial)
        }
      }
    })
    .then(response => {
      streamSubscribe.notify(response?.data)
      streamSubscribe.done({ data: response?.data, isSuccess: true })
    })
    .catch(error => {
      if (Axios.isCancel(error)) {
        streamSubscribe.done()
        return
      }

      streamSubscribe.notify(
        'Me desculpe, mas estamos com uma inconsistência no sistema. ' +
          'Aguarde um momento para que eu possa te ajudar. ' +
          'Ou se preferir, você pode usar o campo de busca do site para encontrar os produtos que precisa.'
      )
      streamSubscribe.done()
    })
}

const feedback = async (requestData: AssessmentRequestData) =>
  axiosInstance.post(API_ENDPOINTS.feedback, {
    scale: requestData.scale,
    sessionId: requestData.sessionId,
    details: requestData.details
  })

export const Apis = {
  chat,
  cancelRequest,
  feedback
}

export default Apis
