import { Icon, Spinner } from '@backyard-ui/core'
import styled from '@emotion/styled'
import {
  ShareOutline,
  UploadOutline
} from 'frontendMyOrders/user-interface/icons/components'
import { UserInterface } from 'frontendMyOrders/user-interface/typography'
import { useCallback, useEffect, useRef, useState } from 'react'
import type {
  ChangeEvent,
  KeyboardEvent,
  Dispatch,
  SetStateAction
} from 'react'

type TypebarProps = {
  useStateMessage: [string, Dispatch<SetStateAction<string>>]
  onSendMessage: (message: string) => void
  onUploadFile: (file: File) => void
  placeholder?: string
  disabled?: boolean
  isLoading?: boolean
  setShowAttachmentPreview: (show: boolean) => void
}

type TypebarStyledProps = {
  disabledButton?: boolean
  isLoading?: boolean
}

const CanNotSendMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  background-color: var(--color-surface-neutral-stronger);
`

const TypebarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--desktop-space-inset-md);
  background-color: var(--color-surface-neutral-stronger);
  gap: var(--desktop-space-inset-md);
`

const Textarea = styled.textarea`
  flex: 1;
  resize: none;
  border-radius: 5px;
  overflow-y: auto;
  padding: var(--desktop-space-inset-xs) var(--desktop-space-inset-sm);
  border: none;
  outline: none;
  max-height: 120px;
  min-height: 36px;
  height: 36px;
  color: var(--color-text-neutral-strong);
  font: var(--type-desktop-ui-sm-default);
  color: var(--color-text-neutral-default);
`

const ButtonWrapper = styled.button<{
  disabledButton: boolean
  isLoading: boolean
}>`
  width: 38px;
  height: 38px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius-pill);
  padding: var(--desktop-space-inset-sm);
  justify-content: center;
  transition: background-color 0.3s ease;

  ${({ disabledButton }: TypebarStyledProps) =>
    disabledButton
      ? `
    cursor: not-allowed;
    background-color: var(--color-surface-neutral-strong);
    `
      : `
    cursor: pointer;
    background-color: var(--color-surface-accent-default);

    &:hover {
    background-color: var(--color-surface-accent-strong);
    }
  `}

  ${({ isLoading }: TypebarStyledProps) =>
    isLoading &&
    `
    cursor: not-allowed;
    padding: var(--desktop-space-inset-xs);
    background-color: var(--color-surface-accent-default);
    `}
`

const IconWrapper = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
`

const HiddenFileInput = styled.input`
  display: none;
`

const Typebar = ({
  useStateMessage,
  onSendMessage,
  onUploadFile,
  placeholder,
  disabled = false,
  isLoading = false,
  setShowAttachmentPreview
}: TypebarProps) => {
  const [message, setMessage] = useStateMessage
  const [disabledButton, setDisabledButton] = useState(true)
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const handleSendMessage = useCallback(async () => {
    if (message && !disabledButton && !isLoading) {
      await onSendMessage(message)
      setMessage('')
    }
  }, [message, onSendMessage, disabledButton, isLoading, setMessage])

  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      if (event.shiftKey) {
        event.preventDefault()
        setMessage(prev => prev + '\n')
      } else {
        event.preventDefault()
        if (message.trim()) {
          handleSendMessage()
        }
      }
    }
  }

  const onChangeMessage = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      const text = e.target.value
      setMessage(text)
    },
    [setMessage]
  )

  const handleFileUpload = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target
      if (files && files.length > 0) {
        const file = files[0]
        onUploadFile(file)
        setShowAttachmentPreview(true)
        if (!message.length) {
          setMessage('Anexo')
        }
        event.target.value = ''
      }
    },
    [onUploadFile, setShowAttachmentPreview, message.length, setMessage]
  )

  const handleClickIconUpload = useCallback(() => {
    fileInputRef.current?.click()
  }, [])

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }

    setDisabledButton(!message || message.trim().length < 3)
  }, [message, isLoading])

  if (disabled) {
    return (
      <CanNotSendMessageWrapper>
        <UserInterface appearance="neutral-soft" size="sm">
          Você não pode escrever mensagens nesse momento
        </UserInterface>
      </CanNotSendMessageWrapper>
    )
  }

  return (
    <TypebarWrapper>
      <IconWrapper aria-label="Upload">
        <Icon
          size="lg"
          onClick={handleClickIconUpload}
          data-testid="upload-button">
          <UploadOutline color="icon-neutral-soft" />
        </Icon>
      </IconWrapper>
      <HiddenFileInput
        data-testid="hidden-file-input"
        ref={fileInputRef}
        type="file"
        accept="image/*,video/*"
        onChange={handleFileUpload}
      />
      <Textarea
        ref={textareaRef}
        rows={1}
        placeholder={placeholder}
        value={message}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => onChangeMessage(e)}
        data-testid="textarea"
        onKeyDown={handleKeyDown}
      />
      <ButtonWrapper
        onClick={handleSendMessage}
        disabledButton={disabledButton}
        isLoading={isLoading}
        aria-label="Send"
        data-testid="send-button">
        {isLoading ? (
          <Spinner color="white" />
        ) : (
          <Icon size="lg">
            <ShareOutline color="icon-neutral-inverse" />
          </Icon>
        )}
      </ButtonWrapper>
    </TypebarWrapper>
  )
}

export default Typebar
