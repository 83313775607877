import { ReactNode, useState } from 'react'
import { Icon } from '@backyard-ui/core'
import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { XOutline } from 'frontendMyOrders/user-interface/icons/components'
import { UserInterface } from 'frontendMyOrders/user-interface/typography'

type TypeProps = 'info' | 'success' | 'warning' | 'danger'

type NotificationProps = {
  type?: TypeProps
  children: ReactNode
  setIsVisible: (isVisible: boolean) => void
  isVisible: boolean
}

type NotificationWrapperProps = NotificationProps & {
  isFadingOut: boolean
}

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`

const typeStyles = {
  info: css`
    background-color: var(--color-surface-info-soft);
  `,
  success: css`
    background-color: var(--color-surface-success-soft);
  `,
  warning: css`
    background-color: var(--color-surface-warning-soft);
  `,
  danger: css`
    background-color: var(--color-surface-danger-soft);
  `
}

const NotificationWrapper = styled.div<NotificationWrapperProps>`
  ${({ type = 'info', isFadingOut }: NotificationWrapperProps) => css`
    ${typeStyles[type]};
    border-radius: var(--border-radius-lg);
    display: flex;
    align-items: center;
    gap: var(--desktop-space-inset-xs);
    padding: var(--desktop-space-inset-md);
    width: 100%;
    transition:
      opacity 0.3s ease,
      transform 0.3s ease;
    animation: ${isFadingOut
      ? css`
          ${fadeOut} 0.3s forwards
        `
      : 'none'};
  `}
`

const TextWrapper = styled.div`
  ${({ type }: { type: TypeProps }) => typeStyles[type]};
  flex: 1;
`

const ButtonWrapper = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
`

const Notification = ({
  type = 'info',
  setIsVisible,
  isVisible,
  children
}: NotificationProps) => {
  const [isFadingOut, setIsFadingOut] = useState(false)

  const handleClose = () => {
    setIsFadingOut(true)
    setTimeout(() => {
      setIsVisible(false)
    }, 300)
  }

  if (!isVisible) return null

  const NotificationStyled = NotificationWrapper.withComponent('div')

  return (
    <NotificationStyled
      isFadingOut={isFadingOut}
      type={type}
      data-testid="chat-notification">
      <TextWrapper>
        <UserInterface size="sm" appearance={`${type}-default`}>
          {children}
        </UserInterface>
      </TextWrapper>
      <ButtonWrapper onClick={handleClose}>
        <Icon size="lg">
          <XOutline color={`icon-semantic-${type}`} />
        </Icon>
      </ButtonWrapper>
    </NotificationStyled>
  )
}

export default Notification
