import cookies from 'js-cookie'

import emitter from 'scripts/utils/emitter'

import { userResourcesApi } from 'api'

class Resources {
  constructor() {
    this.emitter = emitter
  }

  init() {
    return this.getUserResources()
  }

  getUserResources() {
    return userResourcesApi
      .storedGetUserResources()
      .then(this.onGetUserResourcesSuccess.bind(this))
      .catch(this.onGetUserResourcesFailure.bind(this))
  }

  onGetUserResourcesSuccess(data) {
    this.emitter.emit('user-resources:done', data)

    if (data?.name) {
      cookies.set('user', data, {
        domain: undefined,
        sameSite: 'Strict',
        secure: true
      })
    }

    window.sessionStorage.setItem('userInfo', JSON.stringify({ ...data }))
  }

  onGetUserResourcesFailure(data) {
    this.emitter.emit('user-resources:fail', data)
  }
}

export default () => new Resources().init()

export { Resources as Component }
