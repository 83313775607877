import { createSlice } from '@reduxjs/toolkit'

import { OptionsType, OptionType } from '../types'

const initialState: { data: OptionsType } = {
  data: [
    {
      type: 'chat',
      id: '',
      name: '',
      subOptions: [
        {
          id: '',
          name: ''
        }
      ]
    }
  ]
}

export const optionsSlice = createSlice({
  name: 'Options',
  initialState,
  reducers: {
    setOptions: (state, action) => {
      const optionsBeginChat = [
        'missingProduct',
        'productInformation',
        'otherSubject',
        'defectiveProduct',
        'incorrectProduct'
      ]

      const optionsBeginChatWithConfirmation = [
        'deliveryInformation',
        'invoice'
      ]

      const optionsBeginChatWhenConfirmContact = ['cancellation', 'reversal']

      const optionsBeginChatWithSubOption = ['shiftReturn']

      const newOptions = action.payload.map((option: OptionType) => {
        if (optionsBeginChat.includes(option.id)) {
          return {
            ...option,
            beginChat: true
          }
        }

        if (optionsBeginChatWithConfirmation.includes(option.id)) {
          return {
            ...option,
            beginChatWithConfirmation: true
          }
        }

        if (optionsBeginChatWithSubOption.includes(option.id)) {
          return {
            ...option,
            beginChatWithSubOption: true
          }
        }

        if (option.type === 'chat' && option.id === 'cancellation') {
          return {
            ...option,
            beginChatWithSubOption: true
          }
        }

        if (
          option.type === 'ticket' &&
          optionsBeginChatWhenConfirmContact.includes(option.id)
        ) {
          return {
            ...option,
            beginChatWhenConfirmContact: true
          }
        }

        return option
      })
      state.data = newOptions
    }
  }
})

export const { setOptions } = optionsSlice.actions

export default optionsSlice.reducer
