import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgPlantOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M11.632 4.129C12.339 2.89 13.705 2 16 2h2c1.06 0 2.08.88 1.953 2.101-.073.704-.27 1.666-.865 2.47C18.449 7.433 17.436 8 16 8h-3v5h3.674a2 2 0 0 1 1.923 2.55l-1.428 5A2 2 0 0 1 15.246 22H8.754a2 2 0 0 1-1.923-1.45l-1.428-5A2 2 0 0 1 7.326 13H11v-2H8c-1.436 0-2.45-.567-3.088-1.43-.595-.803-.792-1.765-.865-2.469C3.92 5.88 4.94 5 5.999 5H8c1.314 0 2.323.292 3.078.774.08-.508.237-1.091.554-1.645M13.068 6H16c.843 0 1.243-.298 1.48-.62.265-.356.407-.852.472-1.38H16c-1.705 0-2.34.61-2.632 1.121a2.7 2.7 0 0 0-.3.879m-5.742 9h9.348l-1.428 5H8.754zM8 9h2.932a2.7 2.7 0 0 0-.3-.879C10.339 7.61 9.705 7 8 7H6.048c.065.528.207 1.024.471 1.38.238.322.638.62 1.481.62"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgPlantOutline)
const Memo = memo(ForwardRef)
export default Memo
