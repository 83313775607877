import { useSelector, shallowEqual } from 'react-redux'
import { Hidden, Card, Text } from '@leroy-merlin-br/backyard-react'
import { Button } from '@backyard-ui/core'
import { ChevronRightOutline } from 'frontendMyOrders/user-interface/icons/components'

import { ContextVisibility } from 'shared/components/ContextVisibility'

import { useStandaloneService } from 'frontendCheckout/hooks'

import { Title } from '../Title'
import * as S from './styled'

const Header = ({ regionName }) => {
  const region = useSelector(state => state.cart.region, shallowEqual)

  const stateShippings = useSelector(state => state.cart.shippings)
  const stateServices = useSelector(state => state.cart.services)

  const { isStandaloneServiceCart } = useStandaloneService({
    services: stateServices,
    isCartWithoutProducts: !stateShippings[0]?.products?.length
  })

  return (
    <S.Wrapper>
      <Card>
        <S.Content>
          <div>
            <Title size="peta">Meu carrinho</Title>
            <ContextVisibility when={['ecommerce']}>
              <Hidden when={['untilKilo', 'kilo']}>
                <Text size="mega" color="n400" noMargin>
                  Sua localização é {region.name || regionName}
                  {!isStandaloneServiceCart &&
                    ', você pode retirar seus produto nas Lojas da região'}
                  {'. '}
                  <a href="/institucional/politica-de-entrega">
                    Política de entrega.
                  </a>
                </Text>
              </Hidden>
            </ContextVisibility>
          </div>

          <Button
            onClick={() => (window.location.href = '/')}
            variant="outline"
            iconRight={<ChevronRightOutline />}>
            Continuar comprando
          </Button>
        </S.Content>
      </Card>
    </S.Wrapper>
  )
}

export default Header
