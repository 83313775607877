import {
  Button,
  Checkbox,
  Heading,
  Inline,
  Stack
} from '@leroy-merlin-br/backyard-react'
import { AttachmentForm } from './components/AttachmentForm'
import { BankForm } from './components/BankForm'
import { CompanyForm } from './components/CompanyForm'
import { ContentHeader } from './components/ContentHeader'
import { useFormContext } from '../../contexts/FormContext'
import * as S from './styles/styled'
import * as FormS from './styles/form-styled'
import { Spinner } from '@leroy-merlin-br/backyard-react'
import { ARCHITECT_CONTRACT_FILE_URL } from 'scripts/constants/loyalty/cashback'
import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useResize } from 'scripts/react-components/utils/hooks/use-resize'

export default function Content() {
  const {
    onSubmit,
    legalEntity,
    isSubmitting,
    isSavingDraft,
    companyFormIsDisabled,
    form: { register, handleSubmit },
    formState: { errors },
    saveDraft
  } = useFormContext()

  const [isMobile] = useResize()

  const showForm = Boolean(legalEntity) || !companyFormIsDisabled

  const contractUrl = ARCHITECT_CONTRACT_FILE_URL

  return (
    <S.Wrapper data-testid="form-content-component">
      <ContentHeader />

      <Stack space="mega">
        <form>
          <Stack space="mega">
            <CompanyForm />

            {showForm && (
              <>
                <BankForm />
                <AttachmentForm />

                <S.ContractCheckWrapper>
                  <S.ContractCheck>
                    <Checkbox
                      crossOrigin=""
                      {...register('contractCheck')}
                      data-testid="contract-check"
                    />

                    <span>
                      Declaro que li e concordo com o{' '}
                      <a href={contractUrl} target="_blank">
                        Código de Conduta da Leroy Merlin
                      </a>
                    </span>
                  </S.ContractCheck>
                  <FormS.Error>{errors.contractCheck?.message}</FormS.Error>
                </S.ContractCheckWrapper>

                <S.Actions isMobile={isMobile}>
                  <Button
                    type="button"
                    variant="outline"
                    onClick={saveDraft}
                    disabled={isSavingDraft}
                    data-testid="save-draft-button">
                    {isSavingDraft ? (
                      <Inline alignY="center">
                        <Spinner
                          size="giga"
                          appearance="info"
                          data-testid="save-draft-spinner"
                        />
                        <span>Salvando...</span>
                      </Inline>
                    ) : (
                      <span>Salvar e enviar depois</span>
                    )}
                  </Button>

                  <Button
                    type="button"
                    onClick={handleSubmit(onSubmit, console.warn)}
                    disabled={isSubmitting}
                    data-testid="submit-button">
                    {isSubmitting ? (
                      <Inline alignY="center">
                        <Spinner
                          size="giga"
                          appearance="info"
                          data-testid="submitting-spinner"
                        />
                        <span>Enviando...</span>
                      </Inline>
                    ) : (
                      <span>Enviar dados</span>
                    )}
                  </Button>
                </S.Actions>
              </>
            )}
          </Stack>
        </form>
      </Stack>
    </S.Wrapper>
  )
}
