import {
  ContactSelectorOptionsType,
  TernarySelectorOptionsType
} from '../MarketPlace/types'

export const generateContactMessage = ({
  createdAt
}: {
  createdAt: string
}) => {
  const contactOptions: ContactSelectorOptionsType = [
    {
      label: 'E-mail',
      key: 'email'
    },
    {
      label: 'Telefone',
      key: 'phone'
    },
    {
      label: 'Whatsapp',
      key: 'whatsapp'
    }
  ]

  return {
    text: 'Em breve a equipe da Leroy Merlin entrará em contato com você. Escolha o canal que você deseja receber esse contato.',
    from: 'bot',
    time: createdAt || new Date().toISOString(),
    contactOptions
  }
}

export const generateContactNumberMessage = ({
  createdAt,
  phoneNumber,
  type
}: {
  createdAt: string
  phoneNumber: string
  type: 'phone' | 'whatsapp'
}) => {
  const ternaryOptions: TernarySelectorOptionsType = [
    {
      label: 'Sim',
      key: 'SIM'
    },
    {
      label: 'Não',
      key: 'NAO'
    }
  ]

  return {
    text: `Você confirma o seu ${type === 'phone' ? 'telefone' : 'whatsapp'} <strong>${phoneNumber}</strong>?`,
    from: 'bot',
    time: createdAt || new Date().toISOString(),
    ternaryOptions
  }
}

export const generateContactEmailMessage = ({
  createdAt,
  email
}: {
  createdAt: string
  email: string
}) => {
  const ternaryOptions: TernarySelectorOptionsType = [
    {
      label: 'Sim',
      key: 'SIM'
    },
    {
      label: 'Não',
      key: 'NAO'
    }
  ]

  return {
    text: `Você confirma o seu e-mail <strong>${email}</strong>?`,
    from: 'bot',
    time: createdAt || new Date().toISOString(),
    ternaryOptions
  }
}

export const generateEndContactMessage = ({
  createdAt,
  contactType,
  contact,
  recreated,
  protocol
}: {
  createdAt: string
  contactType: string
  contact: string
  recreated?: boolean
  protocol?: string
}) => {
  const type = contactType === 'email' ? 'e-mail' : 'telefone'

  return {
    text: `${recreated ? '' : 'Obrigado por chegar até aqui! '} <br /><br />Seu número de protocolo é: <strong>${protocol}</strong><br /><br />Em <strong>até 6 horas</strong> a equipe da <strong>Leroy Merlin</strong> entrará em contato com <strong>${type} ${contact}</strong> para continuar com sua solicitação.<br /><br />Lembre-se, atendemos de <strong>segunda à sexta</strong> das <strong>8h às 20h</strong>.`,
    from: 'bot',
    time: createdAt || new Date().toISOString()
  }
}

export const generateContactOnSendMessage = ({
  createdAt,
  message
}: {
  createdAt: string
  message: string
}) => {
  return {
    text: message,
    from: 'user',
    time: createdAt || new Date().toISOString()
  }
}

export const generateGetContactMessage = ({
  createdAt,
  contactType
}: {
  createdAt: string
  contactType: string
}) => {
  return {
    text: `Digite abaixo o número de <strong>${contactType === 'email' ? ' e-mail' : 'telefone'}</strong> que você prefere receber o contato da equipe da Leroy Merlin.`,
    from: 'bot',
    time: createdAt || new Date().toISOString()
  }
}

export const generateInactiveSellerMessage = (createdAt?: string) => {
  return {
    text: 'Infelizmente o vendedor não entrou mais em contato, caso queira falar com a Leroy Merlin, clique no botão Chamar operador Leroy Merlin',
    from: 'bot',
    time: createdAt || new Date().toISOString()
  }
}

export const generateTransferMessage = () => {
  const contactOptions: ContactSelectorOptionsType = [
    {
      label: 'E-mail',
      key: 'email'
    },
    {
      label: 'Telefone',
      key: 'phone'
    },
    {
      label: 'Whatsapp',
      key: 'whatsapp'
    }
  ]

  return {
    text: 'Agora a equipe da Leroy Merlin vai te ajudar. Escolha o canal que você deseja receber contato.',
    from: 'bot',
    time: new Date().toISOString(),
    contactOptions
  }
}
