import styled from '@emotion/styled'
import { UserInterface } from 'frontendMyOrders/user-interface/typography'
import Typography from 'frontendMyOrders/user-interface/typography/Typography'
import { memo, PropsWithChildren } from 'react'

type Message = {
  id: string
  title: string
  date: string
}

type ArchivedMessageItemProps = {
  data: Message
  onClick: (id: string) => void
}

const archivedMessageItemWrapperStyled = styled.a`
  display: flex;
  flex-direction: column;
  gap: var(--desktop-space-inset-xs);
  cursor: pointer;
  background: var(--color-surface-neutral-strong);
  transition: background-color 0.3s ease;

  padding: var(--desktop-space-inset-lg) var(--desktop-space-inset-xs);

  @media (min-width: 968px) {
    padding: var(--desktop-space-inset-lg) var(--desktop-space-inset-xl);
  }

  &:hover {
    background: var(--color-surface-neutral-stronger);
  }
`
const ArchivedMessageItemWrapper =
  archivedMessageItemWrapperStyled.withComponent('div')

const textWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const TitleWrapper = textWrapperStyled.withComponent('div')

const ArchivedMessageItem = memo(
  ({
    onClick,
    data,
    children
  }: PropsWithChildren<ArchivedMessageItemProps>) => {
    return (
      <ArchivedMessageItemWrapper
        data-testid="chat-archivedMessageItem"
        onClick={() => onClick(data.id)}>
        <TitleWrapper>
          <Typography
            variant="body-sm"
            type="strong"
            appearance="neutral-strong">
            {data.title}
          </Typography>
          <Typography variant="body-sm" appearance="neutral-soft">
            {data.date}
          </Typography>
        </TitleWrapper>
        {children && (
          <UserInterface appearance="neutral-strong" size="sm">
            {children}
          </UserInterface>
        )}
      </ArchivedMessageItemWrapper>
    )
  }
)

export default ArchivedMessageItem
