import { SVGProps } from 'react'

export default function IconProductPromiseFashionChoice(
  props: SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M20.36 6.56003C20.2915 6.36894 20.1854 6.19351 20.048 6.04409C19.9106 5.89467 19.7447 5.77428 19.56 5.69003L16 4.00003L15.92 3.85003C15.6822 3.517 15.3231 3.29074 14.92 3.22003L10.61 2.46003C10.4331 2.26441 10.2078 2.11889 9.95673 2.03813C9.70567 1.95737 9.43776 1.94422 9.18 2.00003L4.79 2.80003C4.59549 2.83384 4.40956 2.90573 4.24289 3.01155C4.07622 3.11738 3.93207 3.25507 3.81873 3.41672C3.70538 3.57838 3.62507 3.76082 3.58239 3.95358C3.5397 4.14634 3.53549 4.34564 3.57 4.54003L6.43 20.77C6.49513 21.1152 6.67855 21.4268 6.94868 21.6513C7.21881 21.8758 7.55876 21.9991 7.91 22C7.99639 22.0099 8.08361 22.0099 8.17 22L12.57 21.23L12.68 21.18C12.8619 21.1786 13.04 21.1275 13.1951 21.0324C13.3501 20.9372 13.4763 20.8016 13.56 20.64L20.31 7.74003C20.4807 7.36845 20.4986 6.94471 20.36 6.56003ZM12.68 13.24L11.16 4.60003L14.09 5.12003L12.68 13.24ZM5.68 4.68003L9 4.08003L11.69 19.33L8.28 19.93L5.68 4.68003ZM14.53 14.62L15.93 6.20003L18.28 7.30003L14.53 14.62Z"
        fill="black"
      />
      <path
        d="M9.63 18.38C10.1823 18.38 10.63 17.9323 10.63 17.38C10.63 16.8277 10.1823 16.38 9.63 16.38C9.07771 16.38 8.63 16.8277 8.63 17.38C8.63 17.9323 9.07771 18.38 9.63 18.38Z"
        fill="black"
      />
    </svg>
  )
}
