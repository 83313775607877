import { FC, ReactNode } from 'react'
import {
  Heading,
  Icon,
  Inline,
  Stack,
  Text
} from '@leroy-merlin-br/backyard-react'
import { ChevronLeft } from '@backyard-ui/icons'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from '@backyard-ui/hooks'

import * as S from './styled'

export type LayoutProps = {
  children: ReactNode
}

const Layout: FC<LayoutProps> = ({ children }) => {
  const history = useHistory()
  const isMobile = useMediaQuery('md')

  const onPreviousStep = () => {
    if (history.location.pathname === '/alterar-senha') {
      history.replace('/')
      return
    } else if (
      history.location.pathname ===
      '/alterar-senha/verificacao-codigo-seguranca'
    ) {
      history.goBack()
      return
    }

    history.replace('/alterar-senha')
  }

  return (
    <S.Wrapper>
      {isMobile && (
        <S.LinkWrapper>
          <Text onClick={onPreviousStep}>
            <Inline space="bit" alignY="baseline">
              <Icon as={ChevronLeft} size="kilo" />
              <Text size="mega" noMargin>
                voltar
              </Text>
            </Inline>
          </Text>
        </S.LinkWrapper>
      )}

      <S.SubWrapper>
        <Stack space="byte">
          <Heading size="peta" noMargin>
            Dados de acesso
          </Heading>
          {children}
        </Stack>
      </S.SubWrapper>
    </S.Wrapper>
  )
}

export default Layout
