import React from 'react'
import { Icon } from '@backyard-ui/core'
import {
  ImageOutline,
  XOutline
} from 'frontendMyOrders/user-interface/icons/components'
import { Text } from 'frontendMyOrders/user-interface/typography'
import { byteToMegaByte } from 'frontendMyOrders/utils/formatters'

import {
  AttachmentContainer,
  AttachmentContent,
  ImageWrapper,
  IconWrapper,
  AttachmentInfo
} from './styled'

type AttachmentPreviewProps = {
  files: File[]
  onRemoveFile: (index: number) => void
}

const AttachmentPreview: React.FC<AttachmentPreviewProps> = ({
  files,
  onRemoveFile
}) => {
  if (files.length < 1) {
    return null
  }

  return (
    <AttachmentContainer>
      {files.map((file, index) => (
        <AttachmentContent data-testid="attachment-content" key={index}>
          <ImageWrapper>
            <Icon size="md">
              <ImageOutline color="icon-semantic-accent" />
            </Icon>
          </ImageWrapper>
          <AttachmentInfo data-testid="text">
            <Text innerText={file.name} appearance="accent-strong" size="sm" />
            <Text
              innerText={byteToMegaByte(file.size)}
              appearance="accent-strong"
              size="sm"
            />
          </AttachmentInfo>
          <IconWrapper
            data-testid="icon-wrapper"
            onClick={() => onRemoveFile(index)}>
            <Icon size="md">
              <XOutline color="icon-semantic-accent" />
            </Icon>
          </IconWrapper>
        </AttachmentContent>
      ))}
    </AttachmentContainer>
  )
}

export default AttachmentPreview
