import { useCallback, useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'

import * as cartRepository from 'scripts/api/cart'
import { cartEventApp } from 'scripts/react-components/utils/app'

import { Quantity } from 'checkout/components/Quantity'
import * as dataLayer from 'checkout/services/data-layer'

import { getItemDiscount } from 'frontendCheckout/tracker/utils'
import * as tracker from 'frontendCheckout/tracker'

const Container = ({
  pageType,
  id,
  productId,
  name,
  price,
  discountByPaymentMethod,
  brand,
  categoryTree,
  shopName,
  type,
  packaging,
  packagingCount,
  skuId,
  storeId,
  updateProduct,
  onError,
  onSuccess,
  fromCart,
  regionId,
  zipCode
}) => {
  const [currentPackaging, setPackaging] = useState(packagingCount)
  const [productMaxStock, setProductMaxStock] = useState(Infinity)
  const [isReadyToUpdate, setIsReadyToUpdate] = useState(false)
  const [isProductRemoved, setIsProductRemoved] = useState(undefined)
  const [diffAmount, setDiffAmount] = useState(0)

  const useFormatQuantity = useMemo(() => {
    return (
      Math.round((packaging * currentPackaging + Number.EPSILON) * 100) / 100
    )
  }, [currentPackaging, packaging])

  const handleInputChange = useCallback(
    value => {
      try {
        if (value && value > 0) {
          const diffAmount =
            currentPackaging > value
              ? (currentPackaging - value) * -1
              : value - currentPackaging
          setIsReadyToUpdate(true)
          setDiffAmount(diffAmount)
          setPackaging(value)
        }
      } catch (e) {
        setPackaging(p => p)
      }
    },
    [currentPackaging]
  )

  const updateQuantity = useCallback(
    ({ value, isDecreasing = false }) => {
      const newPackaging =
        (parseInt(currentPackaging * 1000) + parseInt(value * 1000)) / 1000
      setDiffAmount(d => d + value)

      if (isDecreasing && newPackaging > productMaxStock) {
        setPackaging(newPackaging)
      }

      if (newPackaging > 0 && newPackaging <= productMaxStock) {
        setPackaging(newPackaging)
      }

      setIsReadyToUpdate(true)
      setIsProductRemoved(isDecreasing)
    },
    [currentPackaging, productMaxStock]
  )

  const dataLayerTrigger = useCallback(
    maxStockDiff => {
      const calculatePrice = (packaging, price) => {
        const finalPrice = packaging * price

        return finalPrice.toFixed(2)
      }

      const shipmentType = type.includes('pickupInStore')
        ? 'pickup-in-store'
        : 'delivery'

      if (diffAmount === 0 || maxStockDiff === 0) return

      const hasOffer = price?.from > price?.to
      const isByPaymentMethod = hasOffer && Boolean(discountByPaymentMethod)
      const discountType = isByPaymentMethod ? 'by-payment-method' : 'offer'
      const discount = getItemDiscount(price?.to, price?.from)
      const deliveryType = shipmentType === 'delivery' ? 'delivery' : 'pickup'

      const diff =
        maxStockDiff && maxStockDiff < diffAmount ? maxStockDiff : diffAmount

      if (diff > 0) {
        dataLayer.addToCart({
          category: categoryTree,
          price: calculatePrice(packaging, price?.to),
          productBrand: brand,
          productId,
          productName: name,
          quantity: maxStockDiff || diffAmount,
          regionId,
          shipmentType: shipmentType,
          storeId: storeId || '',
          marketPlace: shopName || ''
        })

        tracker.pushAddToCart({
          pageType,
          product: {
            id: productId,
            productId: null,
            name,
            price: price?.to,
            discount: hasOffer ? discount : 0,
            discountType: hasOffer ? discountType : undefined,
            brand,
            categoryTree,
            seller: shopName,
            quantity: maxStockDiff || diffAmount
          },
          deliveryType,
          zipCode
        })
      } else {
        dataLayer.removeFromCart({
          category: categoryTree,
          price: calculatePrice(packaging, price?.to),
          productBrand: brand,
          productId,
          productName: name,
          quantity: maxStockDiff ? maxStockDiff * -1 : diffAmount * -1,
          regionId
        })

        tracker.pushRemoveFromCart({
          pageType,
          product: {
            id: productId,
            productId: null,
            name,
            price: price?.to,
            discount: hasOffer ? discount : 0,
            discountType: hasOffer ? discountType : undefined,
            brand,
            categoryTree,
            seller: shopName,
            quantity: maxStockDiff ? maxStockDiff * -1 : diffAmount * -1
          },
          deliveryType,
          zipCode
        })
      }
    },
    [
      diffAmount,
      categoryTree,
      type,
      shopName,
      packaging,
      price,
      discountByPaymentMethod,
      brand,
      productId,
      name,
      regionId,
      storeId,
      pageType,
      zipCode
    ]
  )

  useEffect(() => {
    setIsReadyToUpdate(true)

    return () => {
      setIsReadyToUpdate(false)
    }
  }, [])

  useEffect(() => {
    if (isReadyToUpdate && packagingCount !== currentPackaging) {
      cartRepository
        .putProductsInCart({
          type,
          storeId: storeId || null,
          products: [
            {
              id,
              skuId: skuId || null,
              quantity: currentPackaging
            }
          ]
        })
        .then(response => {
          const { data } = response
          try {
            dataLayerTrigger()
            cartEventApp.updateCart(data.id, data.uniqueProductsCount)
          } catch (err) {
            console.error(err)
          }

          updateProduct({ currentId: id, ...data })
          onSuccess({ data, currentPackaging, productMaxStock })
        })
        .catch(async ({ response: { data } }) => {
          const maxStock = data?.stock.find(product => product.id === id)?.max

          if (maxStock) {
            setPackaging(maxStock)
            setProductMaxStock(maxStock)

            const initialQuantity = currentPackaging - diffAmount
            const maxStockDiff = maxStock - initialQuantity
            dataLayerTrigger(null, maxStockDiff)
          }

          if (data?.errors) {
            await onError(data, diffAmount)
          }
        })
        .finally(() => {
          setDiffAmount(0)
        })
    }
  }, [
    currentPackaging,
    type,
    onError,
    onSuccess,
    productMaxStock,
    packagingCount,
    skuId,
    storeId,
    updateProduct,
    dataLayerTrigger,
    id,
    isReadyToUpdate,
    diffAmount
  ])

  useEffect(() => {
    setIsReadyToUpdate(false)
    setPackaging(packagingCount)
  }, [packagingCount])

  return (
    <Quantity
      fromCart={fromCart}
      useFormatQuantity={useFormatQuantity}
      packaging={packaging}
      updateQuantity={updateQuantity}
      handleInputChange={handleInputChange}
      packagingCount={packagingCount}
      productMaxStock={productMaxStock}
    />
  )
}

Container.propTypes = {
  pageType: PropTypes.oneOf(['product', 'service', 'minicart', 'cart']),
  id: PropTypes.string,
  quantity: PropTypes.number,
  packaging: PropTypes.number,
  skuId: PropTypes.string,
  storeId: PropTypes.string,
  type: PropTypes.string,
  updateProduct: PropTypes.func,
  zipCode: PropTypes.string
}

Container.defaultProps = {
  onError: () => {},
  onSuccess: () => {}
}

export default Container
