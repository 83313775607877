import {
  Button,
  Dialog,
  Modal,
  Text,
  TextField
} from '@leroy-merlin-br/backyard-react'
import { ChangeEvent, FC } from 'react'
import { Link } from 'react-router-dom'
import { Controller } from 'react-hook-form'
import { Popover } from 'user/components'
import { handlePhoneFormat, removeNonNumericCharacters } from 'user/utils'

import { NumberField, Shell } from 'shared/components'

import mask from 'utils/maskingUtils'
import * as validator from 'utils/validators/validators'

import { Layout } from '../../../components/Layout'
import { VerifyLayout } from '../../../components/VerifyLayout'
import * as S from './styled'
import { ContactsData, Phone } from '../../../types'
import { useContactsDesktop } from './useContactsDesktop'

export type ContactsDesktopProps = {
  data: ContactsData
  loading: boolean
  handleCancelDeleteNumber: () => void
  handleDeletePhoneConfirm: (phone: Phone) => void
  handleDeletePhoneSubmit: (isDesktop: boolean) => void
  deletePhone: Phone
  deleteLoading: boolean
}

const ContactsDesktop: FC<ContactsDesktopProps> = ({
  loading,
  data,
  handleCancelDeleteNumber,
  handleDeletePhoneConfirm,
  handleDeletePhoneSubmit,
  deletePhone,
  deleteLoading
}) => {
  const {
    checkEmailForEmail,
    checkPhoneForEmail,
    checkEmailForPhone,
    checkPhoneForPhone,
    contextData,
    control,
    editable,
    editEmail,
    editMainCell,
    errors,
    fieldEmailValue,
    fieldMainCellValue,
    handleEditEmailAndSendCodeIdentity,
    handleEditEmailAndVerifyCodeSuccess,
    handleEditMainCellAndSendCodeIdentity,
    handleEditMainCellAndVerifyCodeSuccess,
    handleValidEmailAndSendCode,
    handleValidEmailAndVerifyCodeSuccess,
    handleValidMainCellAndSendCode,
    handleValidMainCellAndVerifyCodeSuccess,
    history,
    isValid,
    onCancelChangeEmail,
    onCancelChangePhone,
    setCheckEmailForEmail,
    setCheckEmailForPhone,
    setCheckPhoneForEmail,
    setFieldEmailValue,
    setFieldMainCellValue,
    setCheckPhoneForPhone
  } = useContactsDesktop({ data })

  return (
    <>
      <Layout description="Contatos" backLink="/">
        <S.Wrapper>
          <S.Flex>
            <S.Label>
              <Text size="mega" noMargin isBold>
                e-mail
              </Text>
            </S.Label>

            {!editEmail && (
              <div>
                <S.TextWrapper>
                  {loading ? (
                    <Shell borderRadius="kilo" height={24} width={200} />
                  ) : (
                    <Text noMargin>{fieldEmailValue}</Text>
                  )}
                </S.TextWrapper>
              </div>
            )}
          </S.Flex>

          {!editEmail && loading ? (
            <Shell borderRadius="kilo" height={20} width={38} />
          ) : (
            <S.Flex>
              <button
                onClick={handleEditEmailAndSendCodeIdentity}
                data-gtm-element-id="click-edit-email-button">
                <Text
                  noMargin
                  isBold
                  color={editable ? 'p700' : 'n300'}
                  size="kilo">
                  <span
                    style={{ cursor: editable ? 'pointer' : 'not-allowed' }}>
                    editar
                  </span>
                </Text>
              </button>
            </S.Flex>
          )}

          {editEmail && (
            <S.EditableData>
              <Controller
                name="email"
                rules={{
                  required: 'Insira seu e-mail',
                  validate: (value: string) =>
                    validator.isEmail(value) || 'E-mail inválido'
                }}
                control={control}
                state={Boolean(errors.email) && 'invalid'}
                hint={errors.email?.message}
                render={({
                  field: { onChange, name, ref },
                  fieldState: { error }
                }) => (
                  <TextField
                    name={name}
                    ref={ref}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      setFieldEmailValue(event.target.value)

                      onChange(event)
                    }}
                    value={fieldEmailValue}
                    state={error ? 'invalid' : undefined}
                    hint={error?.message}
                    size="kilo"
                  />
                )}
              />

              <S.Flex>
                <S.CancelWrapper onClick={onCancelChangeEmail}>
                  <Text size="kilo" color="r600" noMargin isBold>
                    Cancelar
                  </Text>
                </S.CancelWrapper>

                <S.ButtonWrapper>
                  <Button
                    data-gtm-element-id="click-save-email-button"
                    onClick={handleValidEmailAndSendCode}
                    isDisabled={
                      !isValid || contextData.email === fieldEmailValue
                    }
                    size="kilo"
                    type="button">
                    Salvar e validar e-mail
                  </Button>
                </S.ButtonWrapper>
              </S.Flex>
            </S.EditableData>
          )}
        </S.Wrapper>

        {fieldMainCellValue && (
          <S.Wrapper>
            <S.Flex>
              <S.Label>
                <Popover
                  list={{
                    id: 'click-phone-tooltip',
                    title: 'Seu celular é usado para:',
                    items: [
                      'Garantir mais segurança para sua conta',
                      'Contato rápido para informar sobre seu pedido',
                      'Envio de novidades e ofertas se você aceitar'
                    ]
                  }}
                  placement="bottom">
                  <Text noMargin>
                    <Text as="span" isBold>
                      Celular
                    </Text>
                    {' - principal'}
                  </Text>
                </Popover>
              </S.Label>

              {!editMainCell && (
                <div>
                  <S.TextWrapper>
                    {loading ? (
                      <Shell borderRadius="kilo" height={24} width={120} />
                    ) : (
                      <Text noMargin>
                        {fieldMainCellValue &&
                          mask.maskedText('phone', fieldMainCellValue)}
                      </Text>
                    )}
                  </S.TextWrapper>
                </div>
              )}
            </S.Flex>

            {!editMainCell && loading ? (
              <Shell borderRadius="kilo" height={20} width={38} />
            ) : (
              <S.Flex>
                <button
                  onClick={handleEditMainCellAndSendCodeIdentity}
                  data-gtm-element-id="click-edit-main-phone-button">
                  <Text
                    noMargin
                    isBold
                    color={editable ? 'p700' : 'n300'}
                    size="kilo">
                    <span
                      style={{ cursor: editable ? 'pointer' : 'not-allowed' }}>
                      editar
                    </span>
                  </Text>
                </button>
              </S.Flex>
            )}

            {editMainCell && (
              <S.EditableData>
                <NumberField
                  name="mainCell"
                  rules={{
                    required: 'Insira seu Celular',
                    validate: (value: string) =>
                      (validator.isCellPhone(value) &&
                        validator.isCellPhoneDigit(
                          removeNonNumericCharacters(value)
                        )) ||
                      'Celular inválido'
                  }}
                  size="kilo"
                  control={control}
                  state={Boolean(errors.mainCell) && 'invalid'}
                  hint={errors.mainCell?.message}
                  format={handlePhoneFormat}
                  value={fieldMainCellValue}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setFieldMainCellValue(
                      removeNonNumericCharacters(event.target.value)
                    )
                  }}
                />

                <S.Flex>
                  <S.CancelWrapper onClick={onCancelChangePhone}>
                    <Text size="kilo" color="r600" noMargin isBold>
                      Cancelar
                    </Text>
                  </S.CancelWrapper>

                  <S.ButtonWrapper>
                    <Button
                      data-gtm-element-id="click-save-main-phone-button"
                      onClick={handleValidMainCellAndSendCode}
                      isDisabled={
                        !isValid ||
                        contextData.mainCellphone === fieldMainCellValue
                      }
                      type="button"
                      size="kilo">
                      Salvar e validar celular
                    </Button>
                  </S.ButtonWrapper>
                </S.Flex>
              </S.EditableData>
            )}
          </S.Wrapper>
        )}

        {contextData.phones.map((phone: Phone, key: number) => (
          <S.Wrapper key={`contacts-phone-${key}`}>
            <S.Flex>
              <S.Label>
                <Text size="mega" noMargin isBold>
                  {phone.type === 'landline' ? 'Telefone' : 'Celular'}
                </Text>
              </S.Label>

              <div>
                <S.TextWrapper>
                  <Text noMargin>{mask.maskedText('phone', phone.number)}</Text>
                </S.TextWrapper>
              </div>
            </S.Flex>

            <S.Flex>
              <S.ActionRemoveLink>
                <button
                  data-gtm-element-id="click-phone-delete-button"
                  onClick={() => {
                    if (editable) {
                      handleDeletePhoneConfirm(phone)
                    }
                  }}>
                  <Text
                    noMargin
                    isBold
                    color={editable ? 'r600' : 'n300'}
                    size="kilo">
                    <span
                      style={{ cursor: editable ? 'pointer' : 'not-allowed' }}>
                      excluir
                    </span>
                  </Text>
                </button>
              </S.ActionRemoveLink>

              <button
                onClick={() => {
                  if (editable) {
                    history.push({
                      pathname: '/editar-telefone',
                      state: {
                        origin: 'editPhone',
                        phone
                      }
                    })
                  }
                }}>
                <Text
                  noMargin
                  isBold
                  color={editable ? 'p700' : 'n300'}
                  size="kilo">
                  <span
                    style={{ cursor: editable ? 'pointer' : 'not-allowed' }}>
                    editar
                  </span>
                </Text>
              </button>
            </S.Flex>
          </S.Wrapper>
        ))}

        <S.Wrapper>
          <S.Flex>
            <S.Label>
              <Popover
                list={{
                  id: 'click-preferences-tooltip',
                  title: 'Preferências de contato:',
                  items: [
                    'Selecionar por quais meios você deseja receber contato',
                    'Receber comunicações e ofertas'
                  ]
                }}
                placement="bottom">
                <Text size="mega" noMargin isBold>
                  Preferências de contato
                </Text>
              </Popover>
            </S.Label>
          </S.Flex>

          <S.Flex>
            {loading ? (
              <Shell borderRadius="kilo" height={20} width={38} />
            ) : (
              <button
                data-gtm-element-id="click-contact-preferences-button"
                onClick={() => {
                  if (editable) {
                    history.push('/preferencias-de-contato')
                  }
                }}>
                <Text
                  noMargin
                  isBold
                  color={editable ? 'p700' : 'n300'}
                  size="kilo">
                  <span
                    style={{ cursor: editable ? 'pointer' : 'not-allowed' }}>
                    editar
                  </span>
                </Text>
              </button>
            )}
          </S.Flex>
        </S.Wrapper>

        <S.ActionButtonWrapper>
          <Link to="/adicionar-telefone">
            <Button
              variant="outline"
              size="kilo"
              data-gtm-element-id="click-add-new-telephone-button">
              Adicionar novo telefone
            </Button>
          </Link>
        </S.ActionButtonWrapper>
      </Layout>

      {contextData.mainCellphone && (
        <Modal
          isOpen={checkPhoneForEmail}
          isCentered
          onClose={() => setCheckPhoneForEmail(false)}>
          <Dialog
            hasScroll
            onClose={() => setCheckPhoneForEmail(false)}
            title="Editar e-mail">
            <VerifyLayout
              inModal
              onSuccess={handleEditEmailAndVerifyCodeSuccess}
              payload={{
                fiscalId: contextData.fiscalId,
                context: 'confirm_identity'
              }}
              infoText={
                <>
                  Para sua segurança, digite o código de 6 dígitos recebido em{' '}
                  <strong>seu telefone</strong>{' '}
                  {mask.maskedText('phone', contextData.mainCellphone)}
                </>
              }
              onRensendCode={handleEditEmailAndSendCodeIdentity}
            />
          </Dialog>
        </Modal>
      )}

      <Modal
        isOpen={checkEmailForEmail}
        isCentered
        onClose={onCancelChangeEmail}>
        <Dialog hasScroll onClose={onCancelChangeEmail} title="Validar e-mail">
          <VerifyLayout
            inModal
            onSuccess={handleValidEmailAndVerifyCodeSuccess}
            payload={{
              fiscalId: contextData.fiscalId,
              context: 'confirm_device'
            }}
            infoText={
              <>
                Digite o código de 6 dígitos recebido em seu
                <strong> novo e-mail </strong>
                que deseja cadastrar: {fieldEmailValue}{' '}
                <button
                  onClick={() => setCheckEmailForEmail(false)}
                  data-gtm-element-id="click-edit-email-validation-code-button">
                  <Text as="a" color="p700">
                    editar
                  </Text>
                </button>
              </>
            }
            onRensendCode={handleValidEmailAndSendCode}
          />
        </Dialog>
      </Modal>

      <Modal
        isOpen={checkEmailForPhone}
        isCentered
        onClose={() => setCheckEmailForPhone(false)}>
        <Dialog
          hasScroll
          onClose={() => setCheckEmailForPhone(false)}
          title="Editar celular principal">
          <VerifyLayout
            inModal
            onSuccess={handleEditMainCellAndVerifyCodeSuccess}
            payload={{
              fiscalId: contextData.fiscalId,
              context: 'confirm_identity'
            }}
            infoText={
              <>
                Para sua segurança, digite o código de 6 dígitos recebido em{' '}
                <strong>seu e-mail</strong>
                {` ${fieldEmailValue}`}
              </>
            }
            onRensendCode={handleEditMainCellAndSendCodeIdentity}
          />
        </Dialog>
      </Modal>

      <Modal
        isOpen={checkPhoneForPhone}
        isCentered
        onClose={onCancelChangePhone}>
        <Dialog
          hasScroll
          onClose={onCancelChangePhone}
          title="Validar celular principal">
          <VerifyLayout
            inModal
            onSuccess={handleValidMainCellAndVerifyCodeSuccess}
            payload={{
              fiscalId: contextData.fiscalId,
              context: 'confirm_device'
            }}
            infoText={
              <>
                Para sua segurança, digite o código de 6 dígitos recebido em{' '}
                <strong>seu telefone</strong>{' '}
                {fieldMainCellValue &&
                  mask.maskedText('phone', fieldMainCellValue)}{' '}
                <button
                  onClick={() => setCheckPhoneForPhone(false)}
                  data-gtm-element-id="click-edit-phone-validation-code-button">
                  <Text as="a" color="p700">
                    editar
                  </Text>
                </button>
              </>
            }
            onRensendCode={handleValidMainCellAndSendCode}
          />
        </Dialog>
      </Modal>

      <Modal
        isOpen={Boolean(deletePhone)}
        isCentered
        onClose={handleCancelDeleteNumber}
        size="mega">
        <Dialog
          hasScroll
          onClose={handleCancelDeleteNumber}
          title="Excluir telefone?">
          <Text>
            {`Tem certeza que deseja excluir o telefone ${deletePhone?.number ? mask.maskedText('phone', deletePhone?.number) : ''}?`}
          </Text>

          <div
            style={{
              marginTop: 48,
              marginBottom: 4,
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between'
            }}>
            <Button
              isDisabled={deleteLoading}
              size="mega"
              appearance="critical"
              variant="ghost"
              onClick={handleCancelDeleteNumber}>
              Cancelar e manter número
            </Button>

            <Button
              data-gtm-element-id="click-confirm-phone-delete-button"
              isLoading={deleteLoading}
              size="mega"
              onClick={() => handleDeletePhoneSubmit(true)}>
              Sim, excluir
            </Button>
          </div>
        </Dialog>
      </Modal>
    </>
  )
}

export default ContactsDesktop
