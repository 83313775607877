import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperStyles = ({ theme }) => css`
  label: postal-code__wrapper;

  margin-top: ${theme.spacings.mega};
`

const contentStyles = ({ theme }) => css`
  label: postal-code__content;

  display: flex;
  flex-direction: column;
  align-items: start;
  gap: ${theme.spacings.giga};

  ${theme.media.mega} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    order: 0;
  }
`

const infoStyles = () => css`
  label: postal-code__info;

  flex: 1;
`

const inputGroupStyles = ({ theme }) => css`
  label: postal-code__input-group;

  flex: 1;

  display: flex;
  align-items: end;
  gap: ${theme.spacings.byte};

  span[class*='validation-hint'] {
    position: absolute;
  }
  div[class*='input__field'] {
    width: 100%;

    input {
      border-radius: ${theme.borderRadius.mega};
      border: 1px solid ${theme.colors.n300};
    }
  }

  & > a {
    flex-shrink: 0;
    color: ${theme.colors.g700};
    &:hover {
      text-decoration: none;
    }
  }

  padding-bottom: ${theme.spacings.giga};
`

export const Wrapper = styled('section')(wrapperStyles)
export const Content = styled('section')(contentStyles)
export const Info = styled('div')(infoStyles)
export const InputGroup = styled('div')(inputGroupStyles)
