import {
  Drawer,
  Avatar as Image,
  Heading,
  Text,
  Spacer,
  Flex,
  Button,
  ScrollArea
} from '@backyard-ui/core'
import { Fragment, useCallback, useState } from 'react'

import { CustomError } from 'scripts/react-components/shared/components/ProductThumb/components/assisted-sale/StockContent/StockContent'
import emitter from 'scripts/utils/emitter'

import styles from './ModalStock.styles'
import {
  CardSimpleInfo,
  CardStoreStock,
  CardSummaryOthersStock,
  CardSummaryStock
} from './components'
import { getOtherBatches, GetOtherBatchesResponse } from './services'

export type StockProps = {
  storageCenter: string
  storageCenterName: string
  handledByBatch: boolean
  isMarketPlace: boolean
  total: number
  unit: string
  handleByBatch: boolean
  largestBatch: number
  details: {
    synthetic: {
      total: number
      reserved: number
      watermark: number
      available: number
    }
    analytic: {
      storageWarehouse: string
      storageWarehouseType: string
      total: number
      reserved: number
      available: number
    }[]
    others: {
      storageCenter: string
      storageCenterName: string
      totalStock: number
      unit: string
    }[]
  }
  batches: {
    batchId: string
    quantity: number
    unit: string
    description: string
  }[]
}

interface ModalStockProps {
  isOpen: boolean
  productDescription: string
  urlImage: string
  onClose: (value: boolean) => void
  stock: StockProps
  productId: number
}

const ModalStock = (props: ModalStockProps) => {
  const { isOpen, onClose, productDescription, urlImage, productId, stock } =
    props

  const classNames = styles()

  const [showStores, setShowStores] = useState(false)
  const [showOthersBatches, setShowOthersBatches] = useState(false)
  const [batches, setBatches] = useState<GetOtherBatchesResponse['batches']>([])
  const [loadingBatches, setLoadingBatches] = useState(false)

  const handleShowStores = () => {
    setShowStores(!showStores)
  }

  const handleCloseOtherStock = () => {
    setShowOthersBatches(false)
  }

  const handleClickOthersBatches = async () => {
    setShowOthersBatches(true)
    setShowStores(false)

    if (batches.length === 0) {
      await fetchOtherBatches()
    }
  }

  const fetchOtherBatches = useCallback(async () => {
    try {
      setLoadingBatches(true)

      const centers =
        stock?.details?.others?.map(item => item.storageCenter) ?? []

      const { batches } = await getOtherBatches({
        productId,
        centers: [...centers, stock.storageCenter]
      })

      setBatches(batches)
    } catch (error) {
      const typedError = error as CustomError

      emitter.emit('stickyFeedback:error', {
        title: 'Ops! Ocorreu o seguinte erro:',
        content:
          typedError?.data?.errors?.[0]?.message ||
          typedError?.data?.errors?.[0]
      })
    } finally {
      setLoadingBatches(false)
    }
  }, [productId, stock?.details?.others, stock.storageCenter])

  return (
    <Drawer.Root isOpen={isOpen} onOpenChange={onClose}>
      <Drawer.Content data-testid="modal-stock">
        <Drawer.CloseButton />
        <Spacer axis="vertical" size={10} />

        <ScrollArea UNSAFE_className="w-full pr-6">
          <Heading size="md">Estoque - {stock?.storageCenterName}</Heading>
          <Spacer axis="vertical" size={4} />
          <Text color="neutral-700">
            Verifica as informações de estoque do produto
          </Text>

          <Spacer axis="vertical" size={4} />

          <Flex justify="center">
            <Image.Root size="xl">
              <Image.Image src={urlImage} alt={stock?.storageCenterName} />
            </Image.Root>
          </Flex>

          <div className={classNames.divider()} />

          <Text color="neutral-700">{productDescription}</Text>

          <Spacer axis="vertical" size={4} />

          {showOthersBatches ? (
            <CardSummaryOthersStock
              batches={batches}
              loading={loadingBatches}
              onClose={handleCloseOtherStock}
            />
          ) : (
            <Fragment>
              {stock?.details?.analytic?.map(item => (
                <div key={item.storageWarehouse}>
                  <CardStoreStock
                    available={item.available}
                    reserved={item.reserved}
                    total={item.total}
                    storageWarehouseType={item.storageWarehouseType}
                  />
                  <Spacer axis="vertical" size={4} />
                </div>
              ))}

              <CardSummaryStock
                total={stock?.details.synthetic.total}
                reserved={stock?.details.synthetic.reserved}
                watermark={stock?.details.synthetic.watermark}
                available={stock?.details.synthetic.available}
                storeName={stock?.storageCenterName}
                storageCenterName={stock?.storageCenterName}
              />
              <Spacer axis="vertical" size={4} />
              <Flex justify="space-between">
                {stock?.details?.others && (
                  <Button
                    variant="ghost"
                    onClick={handleShowStores}
                    appearance="neutral"
                    UNSAFE_className="ml-2">
                    {showStores ? 'Ocultar lojas/CDs' : 'Ver lojas/CDs'}
                  </Button>
                )}

                {stock?.handledByBatch && stock?.details?.others?.length && (
                  <Button
                    variant="ghost"
                    onClick={handleClickOthersBatches}
                    appearance="neutral"
                    UNSAFE_className="mr-2">
                    Ver lotes totais
                  </Button>
                )}
              </Flex>
            </Fragment>
          )}

          <Spacer axis="vertical" size={4} />

          {!showOthersBatches &&
            showStores &&
            stock?.details?.others.map(item => (
              <CardSimpleInfo
                title={item.storageCenterName}
                description={`${item.totalStock} ${item.unit} na Loja`}
                key={item.storageCenter}
              />
            ))}
        </ScrollArea>
      </Drawer.Content>
    </Drawer.Root>
  )
}

export default ModalStock
