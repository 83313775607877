import { UserInterface } from 'frontendMyOrders/user-interface/typography'
import { Button } from '@backyard-ui/core'
import { Icon } from 'frontendMyOrders/user-interface/icons'

import * as S from './styled'

const PanicError = () => {
  const refreshPage = () => {
    window.location.reload()
  }

  return (
    <S.Wrapper>
      <S.IconWrapper>
        <Icon
          name="x-outline"
          color="icon-semantic-danger"
          height="32"
          width="32"
        />
      </S.IconWrapper>
      <S.TextWrapper>
        <UserInterface size="lg" appearance="neutral-strong" type="strong">
          Ocorreu um erro
        </UserInterface>
        <UserInterface size="xs" appearance="neutral-soft">
          Estamos trabalhando para realizar a correção.
        </UserInterface>
        <UserInterface size="xs" appearance="neutral-soft">
          Por favor, tente novamente.
        </UserInterface>
      </S.TextWrapper>
      <Button onClick={refreshPage}>Tentar novamente</Button>
    </S.Wrapper>
  )
}

export default PanicError
