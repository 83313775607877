import MyOrders from 'frontendMyOrders/MyOrders'
import { MarketplaceChat } from 'frontendMyOrders/plugins/Chat/MarketPlace'

import reactComponentLoader from 'scripts/react-component-loader'

import { NativeAppProps } from './plugins/Chat/MarketPlace/App'

reactComponentLoader(MyOrders, '[data-my-orders-page]')

const MarketplaceChatWrapper = (props: NativeAppProps) => {
  const orderId = props.orderId
  const marketplaceOrder = props.marketplaceOrder
  const shopName = props.shopName ?? ''
  const steps = JSON.parse(props.steps ?? '')
  const shippingAddress = JSON.parse(props.shippingAddress ?? '')
  const deliveryDate = JSON.parse(props.deliveryDate ?? '')
  const trackingCode = props.trackingCode
  const trackingUrl = props.trackingUrl
  const invoices = JSON.parse(props.invoices ?? '')
  const threads = JSON.parse(props.threads ?? '')
  const isOnMobileApp = !!props.isOnMobileApp
  const closeTimeInHours = props.closeTimeInHours || 0

  return (
    <MarketplaceChat
      orderId={orderId}
      marketplaceOrder={marketplaceOrder}
      shopName={shopName}
      steps={steps}
      shippingAddress={shippingAddress}
      deliveryDate={deliveryDate}
      trackingCode={trackingCode}
      trackingUrl={trackingUrl}
      invoices={invoices}
      threads={threads}
      isOnMobileApp={isOnMobileApp}
      closeTimeInHours={closeTimeInHours}
    />
  )
}

reactComponentLoader(MarketplaceChatWrapper, '[data-my-orders-app-chat]')
