import { useState } from 'react'
import { Button } from '@backyard-ui/core'
import { Plus } from '@backyard-ui/icons'

import { SimilarProduct } from 'frontendCheckout/models/SimilarProduct.class'

import { DialogFailedAddToCart } from './DialogFailedAddToCart'

type ProductProps = {
  product: SimilarProduct
  callback: (cart: { uniqueProductsCount: number }) => void
  closeDrawer: () => void
  setIsLoading: (value: boolean) => void
}

export const Product = ({
  product,
  callback,
  closeDrawer,
  setIsLoading
}: ProductProps) => {
  const [showFailedAddToCart, setShowFailedAddToCart] = useState(false)

  return (
    <>
      <div className="flex flex-row gap-4 border-b border-gray-200 py-4">
        <img
          className="border rounded-lg border-gray-300 w-20 h-20 min-w-20 min-h-20"
          src={product.imagePath}
          alt={product.name}
        />
        <div className="w-full">
          <div className="font-bold">{product.name}</div>
          <div className="flex flex-col md:flex-row md:justify-between">
            <div>
              <div className="text-gray-500">
                Vendido e entregue por {product.storeName}
              </div>
              {product.hasDiscount && (
                <div className="text-gray-300 line-through">
                  {product.priceFromText}
                </div>
              )}
              <div>
                <span className="font-bold">{product.priceText}</span>
                <span>{product.dfpMessage}</span>
              </div>
            </div>
            <div className="min-w-36 py-2 md:pl-2">
              <Button
                size="sm"
                iconLeft={<Plus />}
                variant="outline"
                onClick={async () => {
                  setIsLoading(true)
                  try {
                    const cart = await product.addToCart().response
                    if (cart.status === 200) {
                      callback(cart.value.data)
                      closeDrawer()
                    } else {
                      setShowFailedAddToCart(true)
                      setIsLoading(false)
                    }
                  } catch {
                    setShowFailedAddToCart(true)
                    setIsLoading(false)
                  }
                }}
              >
                {' '}
                Adicionar{' '}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <DialogFailedAddToCart
        setShowFailedAddToCart={setShowFailedAddToCart}
        showFailedAddToCart={showFailedAddToCart}
      />
    </>
  )
}
