import { ResponseType, request } from 'frontendCheckout/services/fetch'

import { endpoints } from './endpoints'

export type response =
  | ResponseType<200, { data: { uniqueProductsCount: number } }>
  | ResponseType<406, { data: {} }>

export type status = response extends ResponseType<infer Code, unknown>
  ? Code
  : never

export type props = {
  override?: boolean
  products: { id: number; quantity: number; sku?: string }[]
  type: 'delivery' | 'pickupInStore'
}

export const addProduct = ({ products, type, override = false }: props) => {
  return request<response>({
    url: endpoints.addProduct(),
    method: 'POST',
    data: {
      products,
      type,
      override
    },
    errors: [406]
  })
}
