import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgCaixaOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        d="m15.81 11.5-3.096-6H6.5l3.1 6zM12.036 18.5l-1.016-1.97 4.13-4.03 3.1 6z"
        fill={color ? Colors[color] : 'currentColor'}
      />
      <g fill={color ? Colors[color] : 'currentColor'} opacity={0.64}>
        <path d="m22 5.5-6.19 6-2.082-4.035L15.75 5.5zM15.15 12.5 9 18.5H2l6.19-6z" />
      </g>
    </svg>
  )
}

const ForwardRef = forwardRef(SvgCaixaOutline)
const Memo = memo(ForwardRef)
export default Memo
