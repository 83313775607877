import { FC, useState } from 'react'

import NewPassword from './pages/NewPassword/NewPassword'
import PasswordChangeConfirmation from './pages/PasswordChangeConfirmation/PasswordChangeConfirmation'
import SelectContact from './pages/SelectContact/SelectContact'
import VerifySecurityCode from './pages/VerifySecurityCode/VerifySecurityCode'

type Step = {
  component: FC<{ onNextStep: () => void }>
}

const steps: Step[] = [
  {
    component: PasswordChangeConfirmation
  },
  {
    component: SelectContact
  },
  {
    component: VerifySecurityCode
  },
  {
    component: NewPassword
  }
]

const AccessData: FC = () => {
  const [currentStep, setCurrentStep] = useState(0)

  const handleNextStep = () => {
    const lastStep = 3

    if (currentStep < lastStep) {
      setCurrentStep(prevStep => prevStep + 1)
    } else {
      setCurrentStep(0)
    }
  }

  const StepComponent = steps[currentStep].component

  return <StepComponent onNextStep={handleNextStep} />
}

export default AccessData
