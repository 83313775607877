import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgInstagramOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M19.494 4.517c.454.453.808.996 1.039 1.594.267.71.41 1.46.421 2.217.056.957.056 1.267.056 3.71s-.013 2.748-.056 3.71a6.6 6.6 0 0 1-.419 2.186 4.61 4.61 0 0 1-2.633 2.633c-.7.262-1.439.404-2.186.419-.955.055-1.268.055-3.71.055s-2.747-.013-3.71-.055a6.6 6.6 0 0 1-2.185-.45 4.6 4.6 0 0 1-2.634-2.632 6.6 6.6 0 0 1-.419-2.187c-.056-.957-.056-1.268-.056-3.71s.013-2.748.056-3.71a6.6 6.6 0 0 1 .419-2.186A4.6 4.6 0 0 1 6.11 3.479a6.6 6.6 0 0 1 2.184-.42c.955-.054 1.268-.054 3.71-.054s2.747.012 3.71.054a6.6 6.6 0 0 1 2.186.42c.598.231 1.14.585 1.594 1.038m-.49 12.814c.2-.54.304-1.112.311-1.688v.001h.011c.043-.956.043-1.218.043-3.654s-.011-2.704-.054-3.655a5.1 5.1 0 0 0-.311-1.669 3 3 0 0 0-1.712-1.712 5 5 0 0 0-1.67-.311c-.967-.055-1.217-.055-3.654-.055s-2.704.012-3.655.055a5 5 0 0 0-1.67.311 2.99 2.99 0 0 0-1.718 1.712c-.2.54-.305 1.111-.311 1.687-.053.969-.053 1.218-.053 3.655s.01 2.704.053 3.654c.01.57.114 1.134.311 1.669a2.99 2.99 0 0 0 1.719 1.711 5 5 0 0 0 1.669.311c.968.055 1.217.055 3.655.055 2.436 0 2.704-.011 3.654-.055a5 5 0 0 0 1.67-.311 2.99 2.99 0 0 0 1.712-1.711m-2.198-9.046a1.078 1.078 0 1 0 0-2.156 1.078 1.078 0 0 0 0 2.156m-4.807-.908a4.624 4.624 0 1 0 0 9.248 4.624 4.624 0 0 0 0-9.248m0 7.627a3.004 3.004 0 1 1 0-6.008 3.004 3.004 0 0 1 0 6.008"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgInstagramOutline)
const Memo = memo(ForwardRef)
export default Memo
