import { useCashbackContext } from '../../contexts/CashbackContext'
import { Form } from './components/Form'
import { StatusStepper } from './components/StatusStepper'

export default function ArchitectRegistration() {
  const { supplierStatus, supplier } = useCashbackContext()

  if (supplier?.isDraft || supplierStatus === 'PENDING_REGISTRATION') {
    return <Form />
  }

  return <StatusStepper />
}