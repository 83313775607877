import { Col, TextField } from '@leroy-merlin-br/backyard-react'
import NumberFormat from 'react-number-format'
import { useFormContext } from '../../../../../../contexts/FormContext'
import { useEffect } from 'react'
import { removeNonNumericCharacters } from '../../../../../../../../../../../../../user/utils'
import * as S from '../../../../styles/form-styled'
import { InputLoading } from '../../../../../../../../../../../../../user/components'

export default function General() {
  const {
    form: { register, watch, setValue },
    formState: { errors },
    legalEntity,
    loadingLegalEntity,
    getLegalEntity,
    companyFormIsDisabled,
    saveDraftWithDebounce
  } = useFormContext()

  const documentNumber = watch('companyDocumentNumber')

  useEffect(() => {
    const documentNumberOnlyNumbers = removeNonNumericCharacters(documentNumber)

    if (documentNumberOnlyNumbers?.length >= 14) {
      getLegalEntity()
    }
  }, [documentNumber])

  useEffect(() => {
    if (legalEntity) {
      setValue('companySocialName', legalEntity.company.socialName)
      setValue('companyFantasyName', legalEntity.company.fantasyName || '')

      setValue(
        'companyStateRegistration',
        legalEntity.stateRegistration?.number || ''
      )

      setValue('companyMainCnae', String(legalEntity.mainCNAE))
    }
  }, [legalEntity])

  const companyStateRegistrationIsDisabled =
    legalEntity?.stateRegistration?.exempt === true ||
    Boolean(legalEntity?.stateRegistration?.number?.length)

  const showForm = Boolean(legalEntity) || !companyFormIsDisabled

  return (
    <>
      <Col>
        <S.FieldWrapper>
          <InputLoading isLoading={loadingLegalEntity}>
            <NumberFormat
              customInput={TextField}
              label="CNPJ"
              placeholder="xx.xxx.xxx/xxxx-xx"
              hint={errors.companyDocumentNumber?.message}
              disabled={loadingLegalEntity}
              format="##.###.###/####-##"
              value={documentNumber}
              {...register('companyDocumentNumber', {
                onChange: event => {
                  setValue('companyDocumentNumber', event.target.value)
                }
              })}
            />
          </InputLoading>
        </S.FieldWrapper>
      </Col>

      {showForm && (
        <>
          <Col size={{ giga: 6 }}>
            <S.FieldWrapper>
              <TextField
                label="Razão Social"
                disabled={companyFormIsDisabled}
                {...register('companySocialName')}
                hint={errors.companySocialName?.message}
                onBlur={saveDraftWithDebounce}
              />
            </S.FieldWrapper>
          </Col>

          <Col size={{ giga: 6 }}>
            <S.FieldWrapper>
              <TextField
                label="Nome Fantasia"
                disabled={companyFormIsDisabled}
                {...register('companyFantasyName')}
                hint={errors.companyFantasyName?.message}
                onBlur={saveDraftWithDebounce}
              />
            </S.FieldWrapper>
          </Col>

          <Col size={{ giga: 6 }}>
            <S.FieldWrapper>
              <TextField
                label="Inscrição Estadual"
                disabled={
                  companyFormIsDisabled || companyStateRegistrationIsDisabled
                }
                {...register('companyStateRegistration')}
                hint={errors.companyStateRegistration?.message}
                onBlur={saveDraftWithDebounce}
              />
            </S.FieldWrapper>
          </Col>

          <Col size={{ giga: 6 }}>
            <S.FieldWrapper>
              <TextField
                label="CNAE"
                disabled={companyFormIsDisabled}
                {...register('companyMainCnae')}
                hint={errors.companyMainCnae?.message}
                onBlur={saveDraftWithDebounce}
              />
            </S.FieldWrapper>
          </Col>

          <Col>
            <S.FieldWrapper>
              <TextField
                label="Inscrição Municipal"
                placeholder="xxxxxxxx-x"
                {...register('companyCityRegistration')}
                hint={errors.companyCityRegistration?.message}
                onBlur={saveDraftWithDebounce}
              />
            </S.FieldWrapper>
          </Col>
        </>
      )}
    </>
  )
}
