import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperColumnstyles = ({ theme }) => css`
  label: wrapper-column;

  display: flex;
  flex-direction: column;
  margin-bottom: ${theme.spacings.giga};
  position: relative;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${theme.media.mega} {
    justify-content: flex-start;
  }
`
const anchorImagestyles = ({ theme }) => css`
  label: product__anchor__image;

  height: 60px;
  width: 60px;
  min-width: 60px;

  ${theme.media.mega} {
    font-size: ${theme.typography.headings.mega.fontSize};
    height: 90px;
    width: 90px;
    min-width: 90px;
  }
`

const columnstyles = () => css`
  label: product__column;

  display: flex;
  flex-direction: column;
  width: 100%;
`

const imageStyles = ({ theme }) => css`
  label: product__image-wrapper;

  border: 1px solid ${theme.colors.n400};
  border-radius: ${theme.borderRadius.mega};
  height: 60px;
  width: 60px;

  ${theme.media.mega} {
    height: 90px;
    width: 90px;
  }
`

const infoWrapperstyles = ({ theme }) => css`
  label: product__info;

  display: flex;
  flex-direction: row;
  width: 100%;
  gap: ${theme.spacings.byte};
`

const basicInfoWrapperstyles = () => css`
  label: product__basic-info;
  display: flex;
  flex-direction: column;
`

const namestyles = ({ theme }) => css`
  label: product__name;

  color: ${theme.colors.n900};
  font-size: ${theme.typography.text.mega.fontSize};
  max-width: 85%;

  ${theme.media.mega} {
    font-size: ${theme.typography.headings.mega.fontSize};
    max-width: 90%;
  }

  transition: color 0.3s ease;

  &:hover {
    color: ${theme.colors.p600};
  }
`

const smallInfostyles = ({ theme }) => css`
  label: product__small-info;

  color: ${theme.colors.n400};
  font-size: ${theme.typography.subHeadings.kilo.fontSize};
  line-height: ${theme.typography.subHeadings.kilo.lineHeight};
  height: 16px;

  ${theme.media.mega} {
    font-size: ${theme.typography.text.kilo.fontSize};
    height: 20px;
    line-height: ${theme.typography.text.kilo.lineHeight};
  }
`

const removeButtonstyles = ({ theme }) => css`
  label: product__remove-button;

  align-items: center;
  background: ${theme.colors.n100};
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  font-size: 10px;
  height: 25px;
  justify-content: center;
  position: absolute;
  right: 0;
  width: 25px;

  svg {
    width: 10px;
    height: 10px;
  }

  &:hover {
    background: ${theme.colors.n200};
  }
`

const alertWrapperstyles = () => css`
  label: product__alert;

  text-align: left;
`

const alertTitlestyles = ({ theme }) => css`
  label: product__alert__title;

  color: ${theme.colors.n600};
  font-size: ${theme.typography.text.mega.fontSize};
  font-weight: ${theme.font.weight.bold};
  margin-bottom: ${theme.spacings.giga};
`

const serviceImageStyles = ({ theme }) => css`
  label: product__service-image;

  border-radius: ${theme.borderRadius.mega};
  margin-right: ${theme.spacings.kilo};

  width: 60px;

  svg {
    width: 60px;
  }

  ${theme.media.mega} {
    width: 90px;

    svg {
      width: 90px;
    }
  }
`
export const ServiceImage = styled('div')(serviceImageStyles)
export const WrapperColumn = styled('div')(wrapperColumnstyles)
export const AnchorImage = styled('a')(anchorImagestyles)
export const Column = styled('div')(columnstyles)
export const Image = styled('img')(imageStyles)
export const InfoWrapper = styled('div')(infoWrapperstyles)
export const BasicInfoWrapper = styled('div')(basicInfoWrapperstyles)
export const Name = styled('a')(namestyles)
export const SmallInfo = styled('span')(smallInfostyles)
export const RemoveButton = styled('button')(removeButtonstyles)
export const AlertWrapper = styled('div')(alertWrapperstyles)
export const AlertTitle = styled('h1')(alertTitlestyles)
