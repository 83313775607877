import { Icon } from '@backyard-ui/core'
import styled from '@emotion/styled'
import {
  ArrowLeftOutline,
  ArrowRightOutline
} from 'frontendMyOrders/user-interface/icons/components'
import { UserInterface } from 'frontendMyOrders/user-interface/typography'
import React from 'react'

interface ArchivedButtonProps {
  text: string
  onClick: () => void
  type: 'forward' | 'back'
}

const ArchivedButtonWrapper = styled.a`
  position: relative;
  height: 50px;
  width: 100%;
  box-sizing: border-box;
`

const IconWrapper = styled.div<ArchivedButtonProps>`
  position: absolute;
  top: 44%;
  transform: translateY(-50%);
  transition: left 0.5s ease-in-out;
  left: ${({ type }: ArchivedButtonProps) => (type === 'back' ? '0' : '155px')};

  @media (min-width: 968px) {
    left: ${({ type }: ArchivedButtonProps) =>
      type === 'back' ? '0' : '155px'};
`

const TextWrapper = styled.div<ArchivedButtonProps>`
  position: absolute;
  top: 50%;
  white-space: nowrap;
  transition: left 0.5s ease-in-out;
  left: ${({ type }: ArchivedButtonProps) => (type === 'back' ? '36px' : '0')};
  transform: translateY(-50%);

  @media (min-width: 968px) {
    left: ${({ type }: ArchivedButtonProps) =>
      type === 'back' ? '25px' : '0'};
  }
`

const ArchivedButton: React.FC<ArchivedButtonProps> = ({
  text,
  onClick,
  type
}) => {
  return (
    <ArchivedButtonWrapper onClick={onClick}>
      <TextWrapper type={type}>
        <UserInterface appearance="neutral-default" type="strong" size="sm">
          {text}
        </UserInterface>
      </TextWrapper>
      <Icon>
        <IconWrapper type={type}>
          {type === 'back' ? (
            <ArrowLeftOutline
              data-testid="chat-ArrowLeftOutline"
              color="icon-neutral-default"
            />
          ) : (
            <ArrowRightOutline
              data-testid="chat-ArrowRightOutline"
              color="icon-neutral-default"
            />
          )}
        </IconWrapper>
      </Icon>
    </ArchivedButtonWrapper>
  )
}

export default ArchivedButton
