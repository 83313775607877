import { Shipping as ShippingApiType } from 'frontendCheckout/services/types'

import { Product } from './Product.class'

type props = {
  type: string
  storeId: string | null
  storeName: string
  shipments: Shipment[]
}

type Shipment = {
  modalities: Modalities[]
  products: Product[]
}

type Modalities = {
  cost: number
  deadline?: number
  deadlineMessage?: string
  deadlinePrefix: string
  type: string
}

export class Shipping implements props {
  static createFromShipmentApi = shipmentApi

  readonly type: string
  readonly storeId: string | null
  readonly storeName: string
  readonly shipments: Shipment[]
  private items: Product[] | null = null

  constructor(data: props) {
    this.type = data.type
    this.storeId = data.storeId
    this.storeName = data.storeName
    this.shipments = data.shipments
  }

  get products() {
    if (!this.items) {
      this.items = this.shipments.flatMap((shipment) => shipment.products)
    }
    return this.items
  }

  get productsWithError() {
    return this.products.filter((product) => product.errors)
  }

  fetchSimilarProductsForProductsWithError() {
    return Promise.all(
      this.productsWithError.map((product) => product.fetchSimilarProducts())
    )
  }
}

function shipmentApi(shipping: ShippingApiType.getShipping.Shipping) {
  const shipments = shipping.shipments.map((shipment) => ({
    modalities: shipment.modalities.map((modality) => ({
      cost: modality.cost,
      deadline: modality.deadline,
      deadlineMessage: modality.deadlineMessage,
      deadlinePrefix: modality.deadlinePrefix,
      type: modality.type
    })),
    products: shipment.items.map((item) => Product.createFromShipmentApi(item))
  }))
  return new Shipping({
    shipments: shipments,
    storeId: shipping.storeId,
    storeName: shipping.storeName,
    type: shipping.type
  })
}
