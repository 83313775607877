import { api } from 'frontendCheckout/services/api'
import { Shipping as ShippingApiType } from 'frontendCheckout/services/types'

import { SimilarProduct } from './SimilarProduct.class'

type Props = {
  id: string
  productId: number
  storeId: null | string
  externalId: number
  name: string
  skuid: string | null
  quantity: number
  discountByPaymentMethod: null | string
  priceTo: number
  priceFrom?: number
  discount?: number
  priceTotal: number
  thumbUrl: string
  productUrl: string
  packaging: number
  packagingCount: number
  packagingLabel: {
    singular: string
    plural: string
  }
  translatedUnit: string
  unit: string
  shopName: string
  brand: string
  categoryTree: string
  isExpressDelivery: boolean
  expressDeliveryDay: 'today' | 'tomorrow'
  isSoldOut: boolean
  errors?: { quotation?: string; stock?: string }
}

export class Product {
  static createFromShipmentApi = shipmentApi

  readonly id: string
  readonly productId: number
  readonly storeId: null | string
  readonly externalId: number
  readonly name: string
  readonly skuid: string | null
  readonly quantity: number
  readonly discountByPaymentMethod: null | string
  readonly priceTo: number
  readonly priceFrom: number | undefined
  readonly discount: number | undefined
  readonly priceTotal: number
  readonly thumbUrl: string
  readonly productUrl: string
  readonly packaging: number
  readonly packagingCount: number
  readonly packagingLabel: {
    singular: string
    plural: string
  }

  readonly translatedUnit: string
  readonly unit: string
  readonly shopName: string
  readonly brand: string
  readonly categoryTree: string
  readonly isExpressDelivery: boolean
  readonly expressDeliveryDay: 'today' | 'tomorrow'
  readonly isSoldOut: boolean
  readonly errors?: { quotation?: string; stock?: string }
  private similarProduct: SimilarProduct[] | undefined = undefined

  constructor(data: Props) {
    this.id = data.id
    this.productId = data.productId
    this.storeId = data.storeId
    this.externalId = data.externalId
    this.name = data.name
    this.skuid = data.skuid
    this.quantity = data.quantity
    this.discountByPaymentMethod = data.discountByPaymentMethod
    this.priceTo = data.priceTo
    this.priceFrom = data.priceFrom
    this.discount = data.discount
    this.priceTotal = data.priceTotal
    this.thumbUrl = data.thumbUrl
    this.productUrl = data.productUrl
    this.packaging = data.packaging
    this.packagingCount = data.packagingCount
    this.packagingLabel = data.packagingLabel
    this.translatedUnit = data.translatedUnit
    this.unit = data.unit
    this.shopName = data.shopName
    this.brand = data.brand
    this.categoryTree = data.categoryTree
    this.isExpressDelivery = data.isExpressDelivery
    this.expressDeliveryDay = data.expressDeliveryDay
    this.isSoldOut = data.isSoldOut
    this.errors = data.errors
  }

  async fetchSimilarProducts() {
    try {
      const similarProduct = await api.crosses.getSimilarProducts({
        productId: this.productId
      }).response
      if (similarProduct.value) {
        this.similarProduct = similarProduct.value.data?.[0].products
          .filter(product => !product.isSoldOut)
          .map(
            product =>
              new SimilarProduct({
                name: product.name,
                id: product.id,
                imagePath: product.picture,
                priceFrom:
                  product.price.from &&
                  parseFloat(
                    product.price.from.integers.replaceAll('.', '') +
                      '.' +
                      product.price.from.decimals
                  ),
                priceTo: parseFloat(
                  product.price.to.integers.replaceAll('.', '') +
                    '.' +
                    product.price.to.decimals
                ),
                dfpMessage: product.discount.paymentMethodsDiscount,
                storeName: product.seller || 'Leroy Merlin',
                unitName: product.translatedUnit,
                hasVariant: product.hasVariants,
                skuId: product.skuId
              })
          )
      }
    } catch {}
  }

  get similarProducts() {
    return this.similarProduct
  }

  get similarProductsWithoutVariants() {
    return this.similarProduct?.filter(
      similarProduct => !similarProduct.hasVariant
    )
  }
}

function shipmentApi(item: ShippingApiType.getShipping.Item) {
  return new Product({
    brand: item.brand,
    categoryTree: item.categoryTree,
    discount: item.price.discount,
    discountByPaymentMethod: item.discountByPaymentMethod,
    expressDeliveryDay: item.expressDeliveryDay,
    externalId: item.externalId,
    id: item.id,
    isExpressDelivery: item.isExpressDelivery,
    isSoldOut: item.isSoldOut,
    name: item.name,
    packaging: item.packaging,
    packagingCount: item.packagingCount,
    packagingLabel: {
      plural: item.packagingUnitLabels.plural,
      singular: item.packagingUnitLabels.singular
    },
    priceFrom: item.price.from,
    priceTo: item.price.to,
    priceTotal: item.price.total,
    productId: item.productId,
    productUrl: item.productUrl,
    quantity: item.quantity,
    shopName: item.shopName,
    skuid: item.skuId,
    storeId: item.storeId,
    thumbUrl: item.thumbUrl,
    translatedUnit: item.translatedUnit,
    unit: item.unit,
    errors: item.errors
  })
}
