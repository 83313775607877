import PropTypes from 'prop-types'
import { MinusCircleOutline, PlusCircleOutline } from '@backyard-ui/icons'
import { IconButton } from '@backyard-ui/core'
import { useEffect, useState } from 'react'

import { Input } from './components/Input'
import * as S from './styled'

const Quantity = ({
  packaging,
  packagingCount,
  productMaxStock,
  updateQuantity,
  useFormatQuantity,
  handleInputChange
}) => {
  const [disabledPlusButton, setDisabledPlusButton] = useState(false)
  const [disabledMinusButton, setDisabledMinusButton] = useState(false)

  useEffect(() => {
    setDisabledPlusButton(packagingCount >= productMaxStock)
  }, [packagingCount, productMaxStock])

  useEffect(() => {
    setDisabledMinusButton(useFormatQuantity <= packaging)
  }, [useFormatQuantity, packaging])

  return (
    <S.Wrapper>
      <IconButton
        data-cy="quantity-minus-action"
        data-testid="quantity-minus-action"
        disabled={disabledMinusButton}
        onClick={() =>
          updateQuantity({
            value: -1,
            isDecreasing: true
          })
        }
        icon={<MinusCircleOutline />}
        radius="md"
        size="sm"
        variant="ghost"
      />
      <Input
        quantity={useFormatQuantity}
        packaging={packaging}
        updateQuantity={handleInputChange}
      />
      <IconButton
        data-cy="quantity-plus-action"
        data-testid="quantity-plus-action"
        onClick={() => updateQuantity({ value: 1 })}
        disabled={disabledPlusButton}
        icon={<PlusCircleOutline />}
        radius="md"
        size="sm"
        variant="ghost"
      />
    </S.Wrapper>
  )
}

Quantity.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  packaging: PropTypes.number,
  packagingCount: PropTypes.number,
  productMaxStock: PropTypes.number,
  updateQuantity: PropTypes.func.isRequired,
  useFormatQuantity: PropTypes.number
}

export default Quantity
