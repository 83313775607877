import MyOrdersProvider from 'frontendMyOrders/context/MyOrdersContext'
import Refactor from 'frontendMyOrders/App'

import { MyOrders as Legacy } from 'checkout/components/MyOrders'

export type MyOrdersPropsType = {
  isRefactorEnabled: boolean
  isMarketplaceChatEnabled: boolean
  closeTimeInHours: number
  errors: string
  fiscalId: string
  saleOrderId: string
}

const MyOrders = (props: MyOrdersPropsType) => {
  const pageVersion = props.isRefactorEnabled ? 'v2' : 'v1'

  return (
    <MyOrdersProvider
      isMarketplaceChatEnabled={props.isMarketplaceChatEnabled}
      closeTimeInHours={props.closeTimeInHours}
      saleOrderId={props.saleOrderId}
      fiscalId={props.fiscalId}
      isRefactorEnabled={props.isRefactorEnabled}>
      {pageVersion === 'v1' && <Legacy {...props} />}
      {pageVersion === 'v2' && <Refactor />}
    </MyOrdersProvider>
  )
}

export default MyOrders
