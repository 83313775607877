import { Button, Heading, Icon, Text } from '@backyard-ui/core'

export interface BodyProps {
  TitleIcon: JSX.Element
  title: string
  descriptions: string[]
  buttonType: 'whatsapp' | 'chat'
  buttonText: string
  buttonUrl?: string
  onClick?: () => void
}

const STEP_NAME = {
  whatsapp: 'Click WhatsApp',
  chat: 'Click Concierge'
}

const Body = ({
  TitleIcon,
  title,
  descriptions,
  buttonType,
  buttonText,
  buttonUrl,
  onClick
}: BodyProps) => (
  <div className="flex flex-col gap-4">
    <div className="flex gap-2">
      <Icon appearance="primary" size="md">
        {TitleIcon}
      </Icon>
      <Heading size="sm" asChild>
        <strong>{title}</strong>
      </Heading>
    </div>

    <div className="flex flex-col gap-2">
      {descriptions &&
        descriptions.map((description, index) => (
          <Text key={index} size="md" color="gray-600" weight="normal">
            {description}
          </Text>
        ))}
    </div>

    <div className="w-80 mt-1">
      {onClick && !buttonUrl && (
        <Button
          data-gtm-flow-name="Concierge"
          data-gtm-step-name={STEP_NAME[buttonType]}
          size="sm"
          isFullWidth
          onClick={onClick}
          data-testid="body-button"
        >
          {buttonText}
        </Button>
      )}

      {!onClick && buttonUrl && (
        <Button
          data-gtm-flow-name="Concierge"
          data-gtm-step-name={STEP_NAME[buttonType]}
          size="sm"
          isFullWidth
          asChild
        >
          <a
            href={buttonUrl}
            rel="noopener noreferrer"
            target="_blank"
            data-testid="body-button"
          >
            {buttonText}
          </a>
        </Button>
      )}
    </div>
  </div>
)

export default Body
