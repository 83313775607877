import styled from '@emotion/styled'
import { css } from '@emotion/react'

const baseStyles = ({ theme }) => css`
  label: quantity;

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 130px;
  margin-right: ${theme.spacings.mega};

  border: 1px solid ${theme.colors.n300};
  border-radius: ${theme.borderRadius.mega};

  button {
    &:disabled {
      background-color: transparent;
      border: none;
    }

    &:focus,
    &:focus-visible {
      outline: none;
      box-shadow: none;
    }

    &:hover {
      background-color: transparent;
    }
  }
`

export const Wrapper = styled('div')(baseStyles)
