import { useEffect } from 'react'

export const useCreditCardObserver = (callback: () => void) => {
  useEffect(() => {
    const storedCard = document.querySelectorAll('.stored-card')
    const addCard = document.querySelectorAll('.add-card')
    const installments = document.querySelectorAll<HTMLInputElement>(
      "[name*='cards'][name$='[amount_installment]']"
    )

    storedCard.forEach(input => input.addEventListener('click', callback))
    addCard.forEach(input => input.addEventListener('click', callback))
    installments.forEach(input => input.addEventListener('change', callback))

    return () => {
      storedCard.forEach(input => input.removeEventListener('click', callback))
      addCard.forEach(input => input.removeEventListener('click', callback))
      installments.forEach(input =>
        input.removeEventListener('change', callback)
      )
    }
  }, [callback])
}
