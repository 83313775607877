import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

export const Card = styled('div')(
  ({ theme }: Theme) => css`
    display: flex;
    gap: ${theme.spacings.mega};
    padding: ${theme.spacings.mega};
    width: 100%;
    border-radius: ${theme.borderRadius.giga};
    background-color: ${theme.colors.r100};
  `
)

export const ContentInfo = styled('div')(
  ({ theme }: Theme) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacings.giga};

    p {
      line-height: 1;

      li {
        margin-left: ${theme.spacings.giga};
        list-style: disc;
        ${theme.typography.text.kilo}
      }
    }

    .bold {
      font-weight: ${theme.font.weight.bold};
    }
  `
)
