import { Shipping as ShippingApiType } from 'frontendCheckout/services/types'
import { api } from 'frontendCheckout/services/api'

import { Shipping } from './Shippings.class'
import { Product } from './Product.class'

type props = {
  shippings: Shipping[]
}

export class ShippingCart implements props {
  static createFromShipmentApi = shipmentApi

  readonly shippings: Shipping[]
  private allProducts: Product[] | null = null

  constructor(data: props) {
    this.shippings = data.shippings
  }

  get products() {
    if (!this.allProducts) {
      this.allProducts = this.shippings.flatMap(
        (shippings) => shippings.products
      )
    }
    return this.allProducts
  }

  get productsWithError() {
    return this.shippings.flatMap((shipping) => shipping.productsWithError)
  }

  findProduct(id: string) {
    return this.products.find((product) => product.id === id)
  }

  async fetchSimilarProductsForProductsWithError() {
    await Promise.all(
      this.shippings.map((shipping) =>
        shipping.fetchSimilarProductsForProductsWithError()
      )
    )
  }
}

function shipmentApi(cart: ShippingApiType.getShipping.Cart) {
  return new ShippingCart({
    shippings: cart.shippings.map((shipping) =>
      Shipping.createFromShipmentApi(shipping)
    )
  })
}
