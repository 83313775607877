import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { Badge, Dialog, Stack, Tabs, Text } from '@backyard-ui/core'
import { useMediaQuery } from '@backyard-ui/hooks'
import { datadogRum } from '@datadog/browser-rum'
import { type PaymentMethod, getPaymentMethods } from 'hpdp/services/product'

import emitter from 'scripts/utils/emitter'

import { BankslipContent, PaymentMethods, PixContent } from './components'
import { Props } from './types'

const PaymentMethodsModal: FunctionComponent<Props> = ({
  productId,
  regionId,
  sellerId
}) => {
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([])
  const [currentSeller, setCurrentSeller] = useState<PaymentMethod>({
    sellerId: sellerId
  })

  const { creditCard, branded, brandedPro, bankSlip, pix } = currentSeller

  const isDesktop = useMediaQuery('md')

  const handleSellerChange = useCallback(
    data => {
      const current = paymentMethods.find(item => item.sellerId === data.shopId)
      if (current) {
        setCurrentSeller(current)
      }
    },
    [paymentMethods]
  )

  useEffect(() => {
    const doAsync = async () => {
      try {
        const response = await getPaymentMethods(productId, regionId)
        setPaymentMethods(response)

        const current = response.find(item => item.sellerId === sellerId)
        if (current) {
          setCurrentSeller(current)
        }
      } catch (e) {
        datadogRum.addError(e)
      }
    }

    doAsync()
  }, [productId, regionId, sellerId])

  useEffect(() => {
    emitter.on('buybox:sellerChange', handleSellerChange)
  }, [handleSellerChange])

  return (
    <Dialog.Root size="3xl" isCentered closeOnOverlayClick hasScroll>
      <Dialog.Trigger data-cy="payment-methods-trigger">
        <Text size="sm" color="green-600" weight="bold">
          Formas de pagamento
        </Text>
      </Dialog.Trigger>

      <Dialog.Content>
        <Dialog.CloseButton />
        <Dialog.Title>
          <Text size="xl" color="black" weight="bold">
            Formas de pagamento
          </Text>
        </Dialog.Title>

        <Dialog.Description>
          <Tabs.Root defaultValue="credit-card">
            <Tabs.List aria-label="payment-methods">
              {creditCard && (
                <Tabs.Trigger value="credit-card">
                  <Text size={isDesktop ? 'lg' : 'md'}>
                    Cartão de Crédito{' '}
                    {creditCard.discountPercentage && (
                      <Badge appearance="primary">
                        - {creditCard.discountPercentage}%
                      </Badge>
                    )}
                  </Text>
                </Tabs.Trigger>
              )}
              {branded && (
                <Tabs.Trigger value="branded-card">
                  <Text size={isDesktop ? 'lg' : 'md'}>
                    Cartão Celebre{' '}
                    {branded.discountPercentage && (
                      <Badge appearance="primary">
                        - {branded.discountPercentage}%
                      </Badge>
                    )}
                  </Text>
                </Tabs.Trigger>
              )}
              {brandedPro && (
                <Tabs.Trigger value="brandedPro-card">
                  <Text size={isDesktop ? 'lg' : 'md'}>
                    Celebre Pro{' '}
                    {brandedPro.discountPercentage && (
                      <Badge appearance="primary">
                        - {brandedPro.discountPercentage}%
                      </Badge>
                    )}
                  </Text>
                </Tabs.Trigger>
              )}
              {bankSlip && (
                <Tabs.Trigger value="bankslip">
                  <Text size={isDesktop ? 'lg' : 'md'}>
                    Boleto{' '}
                    {bankSlip.discountPercentage && (
                      <Badge appearance="primary">
                        - {bankSlip.discountPercentage}%
                      </Badge>
                    )}
                  </Text>
                </Tabs.Trigger>
              )}
              {pix && (
                <Tabs.Trigger value="pix">
                  <Text size={isDesktop ? 'lg' : 'md'}>
                    Pix{' '}
                    {pix.discountPercentage && (
                      <Badge appearance="primary">
                        - {pix.discountPercentage}%
                      </Badge>
                    )}
                  </Text>
                </Tabs.Trigger>
              )}
            </Tabs.List>

            {creditCard && (
              <Tabs.Content value="credit-card">
                <PaymentMethods installments={creditCard.installments} />
              </Tabs.Content>
            )}

            {branded && (
              <Tabs.Content value="branded-card">
                <PaymentMethods installments={branded.installments} />
              </Tabs.Content>
            )}

            {brandedPro && (
              <Tabs.Content value="brandedPro-card">
                <PaymentMethods installments={brandedPro.installments} />
              </Tabs.Content>
            )}

            {bankSlip && (
              <Tabs.Content value="bankslip">
                <Stack direction="column" spacing="4">
                  <PaymentMethods
                    installments={[
                      {
                        price: bankSlip.price,
                        quantity: 1,
                        totalPrice: bankSlip.price
                      }
                    ]}
                  />
                  <BankslipContent />
                </Stack>
              </Tabs.Content>
            )}

            {pix && (
              <Tabs.Content value="pix">
                <Stack direction="column" spacing="4">
                  <PaymentMethods
                    installments={[
                      {
                        price: pix.price,
                        quantity: 1,
                        totalPrice: pix.price
                      }
                    ]}
                  />
                  <PixContent />
                </Stack>
              </Tabs.Content>
            )}
          </Tabs.Root>
        </Dialog.Description>
      </Dialog.Content>
    </Dialog.Root>
  )
}

export default PaymentMethodsModal
