import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgVolumeFullOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M21.499 12c0 4.091-2.472 7.453-5.999 9v-2c2.387-1.386 3.999-4.047 3.999-7S17.887 6.386 15.5 5V3c3.527 1.547 5.999 4.909 5.999 9M17.5 12c0 1.771-.775 3.9-2 5V7c1.225 1.1 2 3.229 2 5M6.197 17H3.5c-1.103 0-2-.897-2-2V9c0-1.103.897-2 2-2h2.697l5.749-3.832A1 1 0 0 1 13.5 4v16a1 1 0 0 1-1.555.832zM6.5 9h-3v6h3q.026.001.05.01a.2.2 0 0 0 .041.009 1 1 0 0 1 .382.116q.017.008.035.013.025.006.047.02l4.445 2.964V5.868L7.055 8.832q-.02.011-.042.017a.2.2 0 0 0-.04.016 1 1 0 0 1-.38.116q-.021.002-.041.01A.2.2 0 0 1 6.5 9"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgVolumeFullOutline)
const Memo = memo(ForwardRef)
export default Memo
