import { Inline } from '@leroy-merlin-br/backyard-react'

import IconLoyalty from 'lmcv/components/Icons/IconLoyalty'

export default function Header() {
  return (
    <Inline data-testid="cashback-registration-status-header">
      <IconLoyalty width={80} />
    </Inline>
  )
}
