import { formatDate } from 'frontendMyOrders/utils/formatters'

export const deliveryInformationMessage = ({
  slug,
  shippingAddressFormatted,
  date,
  deliveryDate,
  trackingCode,
  trackingUrl,
  description
}: {
  slug: string
  shippingAddressFormatted: string
  date: string
  deliveryDate: string
  trackingCode: string
  trackingUrl: string
  description: string
}) => {
  const deliveryDateFormatted = deliveryDate
    ? formatDate(deliveryDate, 'long_weekday_day_month')
    : ''

  switch (slug) {
    case 'in_transit':
      return `A entrega na ${shippingAddressFormatted} está na etapa: <strong>pedido em trânsito</strong>. E chegará até <strong>${deliveryDateFormatted}</strong><br/><u><a href="${trackingUrl}" target='_blank' rel='noopener noreferrer'>Acompanhe seu pedido</a></u> utilizando o código de rastreio ${trackingCode}.`
    case 'finished':
      return `A entrega na ${shippingAddressFormatted} chegou ${date}.`
    case 'canceled':
      return 'Notei que <strong>sua compra foi cancelada</strong>, e por isso as informações sobre o produto não estão mais disponíveis.'
    default:
      return `A entrega na ${shippingAddressFormatted} está na etapa: <strong>${description}</strong>. E chegará até <strong>${deliveryDateFormatted}</strong>.`
  }
}
