import { useCallback } from 'react'

import { GtmDataAttributesGenerator } from 'shared/tracker/data-attributes-generators'

import { requestRichRelevanceTracking } from 'catalog/services/track-rich-relevance'

import { formatPrice } from './NewProductThumb.utils'
import {
  ExpressDeliveryTag,
  PriceTag,
  ProductImage,
  ProductInfo
} from './components'
import AssistedSaleContentManager from '../ProductThumb/components/assisted-sale/AssistedSaleContentManager'
import * as S from './styled'
import { NewProductThumbProps } from './NewProductThumb.types'

const NewProductThumb = ({
  index = 0,
  partnerName,
  product,
  variableWidth = false,
  shouldShowNewReviewsRating = false,
  onClick = () => undefined,
  reviewRating = { avgRating: '0', totalReviewsCount: 0 },
  isLoadingReview,
  fullWidth = false,
  itemCategory = '',
  itemList = ''
}: NewProductThumbProps) => {
  const {
    id,
    shortName,
    picture,
    unit,
    inWishList,
    installments,
    isOutlet = false,
    tag,
    offer,
    price,
    discount,
    url,
    trackingUrl = null,
    assistedSale,
    isSoldOut,
    isExpressDelivery,
    expressDeliveryDay,
    name,
    services,
    brand,
    categoryTree
  } = product

  const isPromotional = Boolean(offer)
  const isDiscountByPaymentMethod = Boolean(
    discount && discount.discountType === 'payment-method'
  )

  const isDiscountByTwoPrice = Boolean(
    discount && discount.discountType === 'twoPrices'
  )

  const typeClass = offer ? `type-${offer.type}` : ''
  const soldOutClass = isSoldOut ? 'type-soldout' : ''

  const productThumbClass = `new-product-thumb ${typeClass} ${soldOutClass}`

  const handleWrapperClick = useCallback(
    event => {
      event.preventDefault()
      const anchorElement = event.target.closest('a')

      const trackClickAndGoToUrl = async () => {
        if (trackingUrl && anchorElement) {
          await requestRichRelevanceTracking(trackingUrl)
        }

        onClick()

        if (anchorElement) {
          window.location.href = url
        }
      }

      trackClickAndGoToUrl()
    },
    [onClick, trackingUrl, url]
  )

  return (
    <S.Wrapper
      fullWidth={fullWidth}
      variableWidth={variableWidth}
      className={productThumbClass}
      onClick={handleWrapperClick}
      {...GtmDataAttributesGenerator.productsList({
        partnerName,
        index,
        title: itemList,
        item: {
          id,
          name,
          brand,
          category: itemCategory,
          price: formatPrice(price),
          expressDeliveryDay
        }
      })}>
      <div>
        <ProductImage
          name={name}
          brand={brand}
          category={categoryTree}
          id={id}
          tag={tag}
          url={url}
          offer={offer}
          picture={picture}
          shortName={shortName}
          inWishList={inWishList}
          assistedSale={assistedSale}
          price={price}
          onHeartClick={onClick}
          featureInstallmentDiscount={discount.featureInstallmentDiscount}
        />
        <ProductInfo
          id={id}
          url={url}
          name={name}
          shortName={shortName}
          hasServiceBadge={services}
          reviewRating={reviewRating}
          isLoadingReview={isLoadingReview}
          shouldShowNewReviewsRating={shouldShowNewReviewsRating}
        />
      </div>
      <div>
        <AssistedSaleContentManager
          {...product}
          isMobileContext={false}
          price={price}
        />
        {isExpressDelivery && (
          <ExpressDeliveryTag
            isPromotional={isPromotional}
            isSoldOut={isSoldOut}
            deliveryDate={expressDeliveryDay}
          />
        )}
        <PriceTag
          price={price}
          unit={unit}
          installments={installments}
          url={url}
          isSoldOut={isSoldOut}
          isOutlet={isOutlet}
          isPromotional={isPromotional}
          isDiscountByTwoPrice={isDiscountByTwoPrice}
          isDiscountByPaymentMethod={isDiscountByPaymentMethod}
          paymentMethodsDiscount={discount.paymentMethodsDiscount}
          featureInstallmentDiscount={discount.featureInstallmentDiscount}
        />
      </div>
    </S.Wrapper>
  )
}

export default NewProductThumb
