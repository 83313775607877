import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgFloorOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M3 5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2zm4 0H5v6h2zm2 0v6h2V5zm4 0v2h6V5zm6 4h-6v2h6zm0 4h-2v6h2zm-4 6v-6h-2v6zm-4 0v-2H5v2zm-6-4h6v-2H5z"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgFloorOutline)
const Memo = memo(ForwardRef)
export default Memo
