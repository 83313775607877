import Cookies from 'js-cookie'

import instance from '../api'

export type ProductService = {
  acceptanceTerms: {
    message: string
    title: string
  }[]
  id: number
  name: string
  price: {
    from: number
    to: number
  }
  serviceBankName: string
  unit: string
}

export type ProductVariant = {
  name: string
  characteristicId: string
  types: {
    id: number
    label: string
    url: string
    hasStock: boolean
  }[]
}

export type GetProductVariantsData = ProductVariant[]

type GetProductVariantsResponse = {
  data: GetProductVariantsData
}

export type PaymentMethodInstallment = {
  quantity: number
  price: number
  totalPrice: number
}

export type PaymentMethodItem = {
  price: number
  discountPercentage?: number | null
  priceWithoutDiscounts?: number | null
  installments?: PaymentMethodInstallment[]
}

export type PaymentMethod = {
  sellerId: string
  bankSlip?: PaymentMethodItem
  creditCard?: PaymentMethodItem
  branded?: PaymentMethodItem
  brandedPro?: PaymentMethodItem
  pix?: PaymentMethodItem
}

export type GetPaymentMethodsResponse = {
  data: PaymentMethod[]
}

export type loyaltyCashback = {
  name: string
  picture: null | string
  points: {
    actual: null | number
    season: {
      balance: number
      toExpire: number
      expireAt: string
    }
  }

  occupation: null | string
  professional: never[]
  status: number
  hasPreferences: boolean
  hasProfessionalRelationship: boolean
  seasonEndsAt: string
  hasArchitectCashback: boolean
}

export type GetloyaltyCashbackData = loyaltyCashback

type GetloyaltyCashbackResponse = {
  data: GetloyaltyCashbackData
}

export const getPaymentMethods = async (productId: number, regionId: string) =>
  await instance
    .get<GetPaymentMethodsResponse>(
      `/api/v3/products/${productId}/payment-methods`,
      {
        params: {
          region: regionId
        }
      }
    )
    .then(({ data }) => data.data)
    .catch(err => {
      throw err.response
    })

export const getProductServices = async (
  productId: number,
  regionId: string
) => {
  const response = await instance
    .get<{ data: ProductService[] }>(
      `/api/v3/crosses/${productId}/services?region=${regionId}`
    )
    .then(({ data }) => data.data)
    .catch(err => {
      throw err.response
    })

  return response
}

export const getloyaltyCashback = async () => {
  const API_TOKEN = Cookies.get('api_token') || ''
  const response = await instance
    .get<GetloyaltyCashbackResponse>('/api/v2/loyaltyProgram/profile', {
      headers: {
        Authorization: `Bearer ${API_TOKEN}`
      }
    })
    .then(({ data }) => data.data)
    .catch(err => {
      throw err.response
    })

  return response
}

export const getProductVariants = async (productId: number) =>
  await instance
    .get<GetProductVariantsResponse>(`/api/v3/products/${productId}/variants`)
    .then(({ data }) => data.data)
    .catch(err => {
      throw err.response
    })
