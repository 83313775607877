import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgMicrophoneSolid (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M16 12c0 2.206-1.794 4-4 4s-4-1.794-4-4V6a4.006 4.006 0 0 1 3.812-3.996 1 1 0 0 1 .209-.025C14.215 1.979 16 3.783 16 6zm-5 10v-2.069C7.061 19.436 4 16.072 4 12h2c0 3.309 2.691 6 6 6s6-2.691 6-6h2c0 4.073-3.061 7.436-7 7.931V22z"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgMicrophoneSolid)
const Memo = memo(ForwardRef)
export default Memo
