import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgBedOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        d="M20 9.557V3h-2v2H6V3H4v6.557C2.81 10.25 2 11.525 2 13v4a1 1 0 0 0 1 1h1v4h2v-4h12v4h2v-4h1a1 1 0 0 0 1-1v-4c0-1.475-.811-2.75-2-3.443M18 7v2h-5V7zM6 7h5v2H6zm14 9H4v-3c0-1.103.897-2 2-2h12c1.103 0 2 .897 2 2z"
        fill={color ? Colors[color] : 'currentColor'}
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgBedOutline)
const Memo = memo(ForwardRef)
export default Memo
