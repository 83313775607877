export function getAttribute(region: string) {
  const ATTRIBUTE_TYPES = {
    range: {
      [`regionalAttributes.${region}.promotionalPrice`]: {
        label: 'Preço',
        filterName: 'preco',
        filterValue: ''
      }
    },
    toggle: {
      'relevanceFields.isOurLowestPrice': {
        label: 'Nosso Preço + Baixo',
        filterName: 'nosso-preco-mais-baixo',
        filterValue: true
      },
      'relevanceFields.novelty': {
        label: 'Novidades',
        filterName: 'novidades',
        filterValue: true
      },
      'relevanceFields.exclusiveMarkeplace': {
        label: 'Exclusivo Leroy Merlin',
        filterName: 'exclusivo-leroy-merlin',
        filterValue: true
      },
      'relevanceFields.exclusiveBrand': {
        label: 'Marca Própria',
        filterName: 'marca-propria',
        filterValue: true
      },
      'relevanceFields.hasStock': {
        label: 'Em estoque',
        filterName: 'em-estoque',
        filterValue: true
      },
      [`regionalAttributes.${region}.offer.enabled`]: {
        label: 'Somente Ofertas',
        filterName: 'ofertas',
        filterValue: 'Em_Oferta'
      },
      [`regionalAttributes.${region}.facets.freeShipping`]: {
        label: 'Frete Grátis',
        filterName: 'frete-gratis',
        filterValue: 'Com_Frete_Grátis'
      },
      [`regionalAttributes.${region}.facets.expressShipping`]: {
        label: 'Entrega Rápida',
        filterName: 'entrega-rapida',
        filterValue: 'Com_Entrega_Rápida'
      },
      [`regionalAttributes.${region}.facets.blackFriday`]: {
        label: 'Black Friday',
        filterName: 'black-friday',
        filterValue: 'Em_Black_Friday'
      }
    },
    checkbox: {
      'boitataFacets.sellers': {
        label: 'Vendido Por',
        filterName: '',
        filterValue: ''
      }
    }
  }

  return ATTRIBUTE_TYPES
}

export type AttributesTypes = ReturnType<typeof getAttribute>
