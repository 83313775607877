import { useEffect, useState } from 'react'
import { Text, Container, CloseButton } from '@leroy-merlin-br/backyard-react'
import Cookies from 'js-cookie'

import { useUserResources } from '../../hooks'
import * as S from './styled'
import {
  EMPLOYEE_NOTIFICATION_COOKIE_NAME,
  TWELVE_HOURS_IN_SECONDS
} from './constants'

const EmployeeNotification = () => {
  const [showNotification, setShowNotification] = useState(false)
  const { userResources } = useUserResources()

  useEffect(() => {
    const hasCookie = Cookies.get(EMPLOYEE_NOTIFICATION_COOKIE_NAME) === 'true'

    if (hasCookie || !userResources?.companyId) {
      return
    }

    setShowNotification(true)

    const now = new Date()
    const expires = new Date(now.getTime() + TWELVE_HOURS_IN_SECONDS * 1000)
    const secure = true
    const domain = undefined
    const sameSite = 'strict'

    Cookies.set(EMPLOYEE_NOTIFICATION_COOKIE_NAME, 'true', {
      expires,
      secure,
      domain,
      sameSite
    })
  }, [userResources])

  if (!showNotification) {
    return null
  }

  return (
    <S.Wrapper>
      <Container>
        <S.ButtonWrapper>
          <CloseButton
            appearance="primary"
            size="kilo"
            onClick={() => setShowNotification(false)}
          />
        </S.ButtonWrapper>

        <Text as="p">Olá, {userResources?.name}!</Text>

        <Text as="p" size="kilo" noMargin>
          Vimos que trabalha com a gente, por isso você tem direito a descontos
          em nosso site! Esses descontos funcionam da seguinte forma: 20% em
          produtos MDH, 10% nos outros produtos. O desconto de colaborador não é
          aplicado em produtos que já estão em oferta ou que não sejam vendidos
          pela Leroy Merlin.
        </Text>
      </Container>
    </S.Wrapper>
  )
}

export default EmployeeNotification
