export const generateDeliveryInformationMessage = ({
  message,
  createdAt
}: {
  message: string
  createdAt: string
}) => {
  return {
    text: message,
    from: 'bot',
    time: createdAt || new Date().toISOString()
  }
}
