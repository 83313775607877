/* istanbul ignore file */

import styled from '@emotion/styled'
import { css } from '@emotion/react'

const headerWrapperStyle = () => css`
  display: flex;
  flex-direction: column;
  padding: -var(--desktop-space-inset-xl) var(--desktop-space-inset-xl);
  margin-top: 40px;
  gap: var(--desktop-space-inset-md);
`

export const HeaderWrapper = styled('div')(headerWrapperStyle)

const headerSpacingWrapperStyle = () => css`
  display: flex;
  flex-direction: column;
  gap: var(--desktop-space-inset-md);
  padding: 0 var(--desktop-space-inset-xs);

  @media (min-width: 968px) {
    padding: 0 var(--desktop-space-inset-xl);
  }
`

export const HeaderSpacingWrapper = styled('div')(headerSpacingWrapperStyle)

const descriptionWrapperStyle = () => css`
  display: flex;
  flex-direction: column;
  gap: var(--desktop-space-inset-md);
  padding: 0 var(--desktop-space-inset-xs);

  @media (min-width: 968px) {
    padding: 0 var(--desktop-space-inset-xl);
  }
`

export const DescriptionWrapper = styled('div')(descriptionWrapperStyle)

const buttonsWrapperStyle = () => css`
  background-color: var(--color-surface-neutral-strong);
  display: flex;
  flex-wrap: wrap;
  gap: var(--desktop-space-inset-md);
  padding: var(--desktop-space-inset-md) var(--desktop-space-inset-xs);

  @media (min-width: 968px) {
    padding: var(--desktop-space-inset-md) var(--desktop-space-inset-xl);
  }
`

export const ButtonsWrapper = styled('div')(buttonsWrapperStyle)

const appWrapperStyles = () => css`
  background-color: var(--color-surface-neutral-strong);
  border-radius: var(--border-radius-lg) 0 0 0;
  display: flex;
  flex-direction: column;
`

export const AppWrapper = styled('div')(appWrapperStyles)

const appFooterStyles = () => css`
  display: block;
  padding-top: 1rem;
`
export const AppFooter = styled('div')(appFooterStyles)
