import { ChangeEvent, FC } from 'react'
import { Select, Text } from '@leroy-merlin-br/backyard-react'

import * as S from '../styled'
import { SelectData } from '../types'

interface FavoriteStoreFieldProps {
  favoriteStores: SelectData[]
  loading: boolean
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void
  value: string
  isError: boolean
}

const FavoriteStoreField: FC<FavoriteStoreFieldProps> = ({
  favoriteStores,
  loading,
  onChange,
  value,
  isError
}) => {
  return (
    <S.Field>
      <S.SelectWrapper>
        <Select
          disabled={loading}
          label={
            <S.Label>
              <Text color="n800" size="mega" isBold>
                Qual sua loja favorita?
              </Text>
            </S.Label>
          }
          options={favoriteStores}
          onChange={onChange}
          placeholder="Selecione sua loja favorita"
          value={value}
          state={isError ? 'invalid' : undefined}
          hint={isError ? 'Escolha uma opção de loja favorita' : undefined}
        />
      </S.SelectWrapper>
    </S.Field>
  )
}

export default FavoriteStoreField
