import { ChangeEvent, FC, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Stack, Text, toast } from '@leroy-merlin-br/backyard-react'
import { useForm } from 'react-hook-form'
import { isServerError, removeNonNumericCharacters } from 'user/utils'
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3'

import * as dataLayer from 'scripts/utils/data-layer'

import { NumberField } from 'shared/components'

import * as validator from 'utils/validators/validators'

import { getContact } from '../../services'
import { useRecoveryContext } from '../../context/recovery-context'
import * as S from './styled'
import { Layout } from '../Layout'

const showGenericError = () =>
  toast.critical('Não foi possível listar os contatos deste CPF/CNPJ!', {
    variant: 'light',
    content: 'Tente novamente mais tarde.'
  })

type FiscalIdValidateStepProps = {
  onNextStep: () => void
}

type FormValues = {
  fiscalId: string
}

export type ServerErrorType = {
  message: string
  status: number
  data: {
    errors: {
      'g-recaptcha': string[]
      password: string[]
    }
  }
}

const FiscalIdValidateStep: FC<FiscalIdValidateStepProps> = ({
  onNextStep
}) => {
  const { captchaKey, isCaptchaEnterpriseEnabled } = useRecoveryContext()

  return (
    <GoogleReCaptchaProvider
      useEnterprise={isCaptchaEnterpriseEnabled}
      reCaptchaKey={captchaKey}>
      <WithRecaptchaComponent onNextStep={onNextStep} />
    </GoogleReCaptchaProvider>
  )
}

const WithRecaptchaComponent: FC<FiscalIdValidateStepProps> = ({
  onNextStep
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const [isLoading, setIsLoading] = useState(false)
  const [apiError, setApiError] = useState('')
  const [fiscalId, setFiscalId] = useState('')

  const history = useHistory()

  const {
    control,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      fiscalId: ''
    }
  })

  const { contextData, updateData, isCaptchaEnabled } = useRecoveryContext()

  const buildFiscalIdMask = () => {
    const value = removeNonNumericCharacters(fiscalId)

    const CPFMask = validator.isCpf(value)
      ? '###.###.###-##'
      : '###.###.###-###'

    if (!value.length) {
      return '##.###.###/####-##'
    }

    return value?.length <= 11 ? CPFMask : '##.###.###/####-##'
  }

  const validateCPForCNPJ = (value: string): string | boolean => {
    return value.length <= 11
      ? validator.isCpf(value) || 'CPF inválido'
      : validator.isCnpj(value) || 'CNPJ inválido'
  }

  const onSubmit = async (formData: { fiscalId: string }) => {
    setIsLoading(true)

    dataLayer.recoveryFiscalIdSendButtonClick()

    if (!executeRecaptcha) {
      setIsLoading(false)

      showGenericError()

      return
    }

    const token = isCaptchaEnabled
      ? await executeRecaptcha('contactsRecoveryPassword')
      : null

    try {
      const { data } = await getContact(formData.fiscalId, token)

      if (data.length === 0) {
        setApiError('CPF ou CNPJ não encontrado')

        return
      }

      setApiError('')
      onNextStep()
      updateData({
        ...contextData,
        fiscalId: formData.fiscalId,
        optionsToRecovery: data
      })
    } catch (error) {
      const { status, data } = error as ServerErrorType

      if (data.errors) {
        const [recaptchaError] = data.errors['g-recaptcha']

        if (recaptchaError) {
          showGenericError()

          return
        }
      }

      const tooManyAttemptsError = status === 429

      if (tooManyAttemptsError) {
        toast.critical('Você atingiu o limite de tentativas!', {
          variant: 'light',
          content: 'Tente novamente mais tarde.'
        })

        return
      }

      const hasServerError = status && isServerError(status)

      if (hasServerError) {
        history.push('/erro-interno')
      }

      return
    } finally {
      setIsLoading(false)
    }
  }

  const hasError = Boolean(errors.fiscalId) || Boolean(apiError)

  const handleInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setFiscalId(target.value)

    setApiError('')
  }

  return (
    <Layout description="Digite seu CPF ou CNPJ abaixo para redefinir sua senha">
      <form onSubmit={handleSubmit(onSubmit)} data-cy="fiscal-id-form">
        <NumberField
          name="fiscalId"
          label="CPF/CNPJ"
          placeholder="Digite seu  CPF ou CNPJ"
          rules={{
            required: 'Insira seu CPF ou CNPJ',
            validate: (value: string) => validateCPForCNPJ(value)
          }}
          control={control}
          state={hasError && 'invalid'}
          format={buildFiscalIdMask()}
          hint={errors.fiscalId?.message || apiError}
          onChange={handleInputChange}
          // Esse id está sendo utilizado para o tagueamento
          id="fiscal-id-input"
        />
        <S.ButtonWrapper>
          <Button
            type="submit"
            isDisabled={!isValid}
            isLoading={isLoading}
            isStretch
            // Esse id está sendo utilizado para o tagueamento
            id="fiscal-id-form-submit-button">
            Enviar
          </Button>
        </S.ButtonWrapper>
      </form>

      <Stack alignX="center">
        <a
          href="/cadastre-se"
          target="_blank"
          // Esse id está sendo utilizado para o tagueamento
          id="fiscal-id-form-signup-link"
          onClick={dataLayer.recoveryLinkToSignupPageClick}>
          <Text size="mega" color="p600" noMargin>
            Novo Cadastro
          </Text>
        </a>
      </Stack>
    </Layout>
  )
}

export default FiscalIdValidateStep
