import { tv } from 'tailwind-variants'

export default tv({
  base: '',
  slots: {
    container: `
      px-4
      py-5
      mb-4

      border
      border-gray-200
      rounded-md
    `
  }
})
