import {
  OptionsType,
  SubOptionsType,
  TernarySelectorOptionsType
} from '../MarketPlace/types'

export const generateCancellationMessage = (
  createdAt: string,
  subOptions: SubOptionsType = []
) => {
  return {
    text: 'Agora, escolha o motivo do cancelamento.',
    from: 'bot',
    time: createdAt || new Date().toISOString(),
    subOptions
  }
}

export const generateSubOptionOthersCancellationMessage = ({
  createdAt
}: {
  createdAt: string
}) => {
  return {
    text: 'Agora, escreva o motivo do cancelamento.',
    from: 'bot',
    time: createdAt || new Date().toISOString()
  }
}

export const generateSubOptionConfirmationCancellationMessage = ({
  createdAt,
  subOption,
  option,
  options
}: {
  createdAt: string
  subOption: string
  option: string
  options: OptionsType
}) => {
  const ternaryOptions: TernarySelectorOptionsType = [
    {
      label: 'Sim',
      key: 'SIM'
    },
    {
      label: 'Não',
      key: 'NAO'
    }
  ]

  const subOptionToDisplay = options
    .find(indexOption => indexOption.id === option)
    ?.subOptions.find(indexSubOption => indexSubOption.id === subOption)?.name

  return {
    text: `Você confirma a solicitação de cancelamento pelo motivo <strong>${subOptionToDisplay}</strong>?`,
    from: 'bot',
    time: createdAt || new Date().toISOString(),
    ternaryOptions
  }
}

export const generateConfirmOtherOptionCancellationMessage = ({
  createdAt,
  placeholder
}: {
  createdAt: string
  placeholder: string
}) => {
  const ternaryOptions: TernarySelectorOptionsType = [
    {
      label: 'Sim',
      key: 'SIM'
    },
    {
      label: 'Não',
      key: 'NAO'
    }
  ]

  return {
    text: `Você confirma o cancelamento pelo motivo <strong>${placeholder}</strong>?`,
    from: 'bot',
    time: createdAt || new Date().toISOString(),
    ternaryOptions
  }
}
