import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgAppleOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        d="M19.665 16.811a10.3 10.3 0 0 1-1.021 1.837q-.807 1.15-1.316 1.592-.787.723-1.692.744-.649.001-1.562-.373-.914-.372-1.683-.371-.805-.001-1.73.371-.924.375-1.495.393-.866.038-1.729-.764-.55-.48-1.377-1.648-.885-1.245-1.455-2.891-.61-1.78-.611-3.447 0-1.91.826-3.292a4.86 4.86 0 0 1 1.73-1.751 4.65 4.65 0 0 1 2.34-.662q.69.001 1.81.422t1.436.422q.237 0 1.593-.498 1.279-.46 2.163-.384 2.4.192 3.6 1.895-2.145 1.301-2.123 3.637.02 1.82 1.317 3.023c.382.365.828.658 1.315.863q-.159.46-.336.882M15.998 2.38q-.001 1.426-1.039 2.659c-.836.976-1.846 1.541-2.941 1.452a3 3 0 0 1-.021-.36c0-.913.396-1.889 1.103-2.688q.528-.606 1.343-1.009.813-.397 1.536-.435.02.192.019.381"
        fill={color ? Colors[color] : 'currentColor'}
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgAppleOutline)
const Memo = memo(ForwardRef)
export default Memo
