import { Fragment } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ModalProvider, ToastContainer } from '@leroy-merlin-br/backyard-react'
import { Minimal } from '@leroy-merlin-br/backpack-header'

import reactComponentLoader from 'scripts/react-component-loader'
import { AlgoliaConfigProps } from 'scripts/react-components/entry-key/routes/routes'
import { HAS_SIMPLEX_PAGE_META_TAG } from 'scripts/react-components/entry-key/utils/constants'

import { InstantSearch } from '../../entry-key/InstantSearch'
import CartDrawer from '../Header/components/Cart/components/CartDrawer'
import { NewHeader } from '.'
import { CartDrawerProvider } from '../Header/contexts/CartDrawerContext'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

export interface HeaderProps {
  /**
   * Feature flip to black-friday
   */
  isBlackWeekend?: boolean

  /**
   * Feature flip to enable/disable social provider
   */
  socialLoginProviders?: Array<'google' | 'facebook' | 'apple'>

  /**
   * Force header to route given input. Defaults to false
   */
  forceRouting?: boolean

  /**
   * Algolia config data
   */
  algolia?: AlgoliaConfigProps
}

export function Header(props: HeaderProps) {
  const {
    isBlackWeekend = false,
    socialLoginProviders = [],
    forceRouting,
    algolia
  } = props

  const IS_ENTRY_KEY = document.querySelector('[data-page]')
  const ALGOLIA_DATA = IS_ENTRY_KEY ? algolia : JSON.parse(String(algolia))

  const Component = (
    <NewHeader
      element={IS_ENTRY_KEY ? Fragment : InstantSearch}
      routing={
        !IS_ENTRY_KEY || Boolean(forceRouting) || HAS_SIMPLEX_PAGE_META_TAG
      }
      socialLoginProviders={socialLoginProviders}
      isBlackWeekend={isBlackWeekend}
      algolia={ALGOLIA_DATA}
    />
  )

  return (
    <QueryClientProvider client={queryClient}>
      <CartDrawerProvider>
        <ModalProvider>
          <ToastContainer position="top-right" />
          {Component}
          <CartDrawer />
        </ModalProvider>
      </CartDrawerProvider>
    </QueryClientProvider>
  )
}

reactComponentLoader(Header, '[data-backpack-header]')
reactComponentLoader(Minimal, '[data-backpack-header-clean]')
