export const generateIncorrectProductMessage = ({
  shopName,
  createdAt
}: {
  shopName: string
  createdAt: string
}) => {
  return {
    text: `Conte um pouco mais sobre seu problema. Assim fica mais fácil para o vendedor <strong>${shopName}</strong> ajudar você. <br/><br/> <strong>Adicione fotos</strong> do produto para agilizar a solução.`,
    from: 'bot',
    time: createdAt || new Date().toISOString()
  }
}
