import { Text, Spacer, SimpleGrid } from '@backyard-ui/core'

import styles from './CardStoreStock.styles'
import { CardBase } from '../CardBase'

interface CardStoreStockProps {
  available: number
  reserved: number
  total: number
  storageWarehouseType: string
}

const CardStoreStock = ({
  available,
  reserved,
  total,
  storageWarehouseType
}: CardStoreStockProps) => {
  const classNames = styles()

  return (
    <CardBase title={storageWarehouseType}>
      <Spacer axis="vertical" size={2} />
      <div className={classNames.titles()}>
        <SimpleGrid columns={3} gapX="4">
          <Text weight="bold" color="green-700" align="center">
            Físico
          </Text>

          <Text weight="bold" color="green-700" align="center">
            Reservado
          </Text>

          <Text weight="bold" color="green-700" align="center">
            Disponível
          </Text>
        </SimpleGrid>
      </div>
      <Spacer axis="vertical" size={2} />

      <SimpleGrid columns={3} gapX="4">
        <Text align="center" color="neutral-700">
          {total}
        </Text>

        <Text align="center" color="neutral-700">
          {reserved}
        </Text>

        <Text align="center" color="neutral-700">
          {available}
        </Text>
      </SimpleGrid>
      <Spacer axis="vertical" size={2} />
    </CardBase>
  )
}

export default CardStoreStock
