import { Dispatch } from '@reduxjs/toolkit'
import { toast } from '@backyard-ui/core'
import { AnyAction } from 'redux'

import { PanicErrorType } from '../types'

export const handleErrors = (
  panicError: PanicErrorType,
  dispatch: Dispatch,
  setPanicError: (param: boolean) => AnyAction
) => {
  if (!panicError) return false

  if (panicError.status !== 422 || Array.isArray(panicError.data.errors)) {
    return true
  }

  Object.keys(panicError?.data.errors).forEach(key => {
    if (key.includes('file')) {
      if (Array.isArray(panicError.data.errors)) {
        return
      }

      panicError?.data.errors[key].forEach(message => {
        toast({
          appearance: 'critical',
          description: message,
          duration: 4000,
          variant: 'solid'
        })
      })
    }
  })

  dispatch(setPanicError(false))

  return false
}
