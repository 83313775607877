import { Col, TextField } from '@leroy-merlin-br/backyard-react'
import { useCallback, useEffect, useState, FocusEvent } from 'react'
import NumberFormat from 'react-number-format'

import { InputLoading } from 'scripts/react-components/user/components'
import { removeNonNumericCharacters } from 'scripts/react-components/user/utils'

import * as S from '../../../../styles/form-styled'
import { getAddressByZipcode } from '../../../../../../../../../../services/address.service'
import { Address } from '../../../../../../../../../../types/address'
import { useFormContext } from '../../../../../../contexts/FormContext'

const VALID_CEP_LENGTH = 8

export function AddressForm() {
  const [loadingCep, setLoadingCep] = useState(false)
  const [cepNotFound, setCepNotFound] = useState(false)
  const [companyHasAddress, setCompanyHasAddress] = useState(true)

  const {
    form: { register, watch, setValue, clearErrors, getValues, setError },
    formState: { errors },
    legalEntity,
    companyFormIsDisabled,
    saveDraftWithDebounce
  } = useFormContext()

  const cep = watch('addressCep')

  const fillAddressForm = useCallback(
    async (state = '', city = '', street = '', district = '') => {
      setValue('addressState', state, { shouldValidate: true })
      setValue('addressCity', city, { shouldValidate: true })
      setValue('addressStreet', street, { shouldValidate: true })
      setValue('addressDistrict', district, { shouldValidate: true })
    },
    [setValue]
  )

  useEffect(() => {
    const addressFound =
      Boolean(legalEntity) && Boolean(legalEntity?.address?.street)
    setCompanyHasAddress(addressFound)
    if (addressFound && legalEntity?.address) {
      fillAddressForm(
        legalEntity.address.state,
        legalEntity.address.city,
        legalEntity.address.street,
        legalEntity.address.district
      )
      setValue(
        'addressCep',
        removeNonNumericCharacters(legalEntity.address.postalCode.number),
        { shouldValidate: true }
      )
      setValue('addressNumber', legalEntity.address.number || '', {
        shouldValidate: true
      })
      setValue('addressComplement', legalEntity.address.complement || '', {
        shouldValidate: true
      })
    }
  }, [legalEntity, setValue, fillAddressForm])

  const searchCep = useCallback(async () => {
    setLoadingCep(true)
    try {
      const { state, city, street, district }: Address =
        await getAddressByZipcode(cep)
      fillAddressForm(state, city, street, district)
      clearErrors('addressCep')
      setCepNotFound(false)
    } catch (error) {
      setCepNotFound(true)
      fillAddressForm()
    } finally {
      setLoadingCep(false)
      saveDraftWithDebounce()
    }
  }, [cep, clearErrors, fillAddressForm])

  useEffect(() => {
    const isValid =
      Boolean(cep) &&
      removeNonNumericCharacters(cep).length === VALID_CEP_LENGTH
    if (isValid && !companyHasAddress) {
      searchCep()
    }
  }, [cep, companyHasAddress, searchCep])

  function validateCepField(value: string) {
    const sanitizedCep = removeNonNumericCharacters(value)

    if (sanitizedCep.length !== 8) {
      setError('addressCep', {
        type: 'manual',
        message: 'CEP inválido'
      })
      fillAddressForm()
    } else {
      clearErrors('addressCep')
    }
  }

  const showForm = Boolean(legalEntity) || !companyFormIsDisabled

  return (
    <>
      {showForm && (
        <>
          <Col size={{ giga: 4 }}>
            <S.FieldWrapper>
              <InputLoading isLoading={loadingCep}>
                <NumberFormat
                  customInput={TextField}
                  label="CEP"
                  disabled={companyHasAddress}
                  format="#####-###"
                  value={getValues('addressCep')}
                  {...register('addressCep', {
                    onChange: event => {
                      setValue('addressCep', event.target.value)
                    }
                  })}
                  hint={
                    errors.addressCep
                      ? errors.addressCep.message
                      : cepNotFound
                        ? 'CEP não encontrado'
                        : ''
                  }
                  onBlur={(event: FocusEvent<HTMLInputElement>) =>
                    validateCepField(event.target.value)
                  }
                />
              </InputLoading>
            </S.FieldWrapper>
          </Col>

          <Col size={{ giga: 4 }}>
            <S.FieldWrapper>
              <TextField
                label="Estado"
                disabled={true}
                {...register('addressState')}
                hint={errors.addressState?.message}
              />
            </S.FieldWrapper>
          </Col>

          <Col size={{ giga: 4 }}>
            <S.FieldWrapper>
              <TextField
                label="Cidade"
                disabled={true}
                {...register('addressCity')}
                hint={errors.addressCity?.message}
              />
            </S.FieldWrapper>
          </Col>

          <Col size={{ giga: 6 }}>
            <S.FieldWrapper>
              <TextField
                label="Endereço"
                disabled={true}
                {...register('addressStreet')}
                hint={errors.addressStreet?.message}
              />
            </S.FieldWrapper>
          </Col>

          <Col size={{ giga: 6 }}>
            <S.FieldWrapper>
              <TextField
                label="Número"
                disabled={companyHasAddress}
                {...register('addressNumber')}
                hint={errors.addressNumber?.message}
              />
            </S.FieldWrapper>
          </Col>

          <Col size={{ giga: 6 }}>
            <S.FieldWrapper>
              <TextField
                label="Complemento"
                disabled={companyHasAddress}
                {...register('addressComplement')}
              />
            </S.FieldWrapper>
          </Col>

          <Col size={{ giga: 6 }}>
            <S.FieldWrapper>
              <TextField
                label="Bairro"
                disabled={true}
                {...register('addressDistrict')}
                hint={errors.addressDistrict?.message}
              />
            </S.FieldWrapper>
          </Col>
        </>
      )}
    </>
  )
}
