import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef, memo } from "react";

import { Colors, type Color } from "../types/icon-colors";

interface SVGRProps {
  color?: Color;
}

function SvgMinusOutline({ color, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}>
      <path d="M5 11h14v2H5z" fill={color ? Colors[color] : "currentColor"} />
    </svg>
  );
}

const ForwardRef = forwardRef(SvgMinusOutline);
const Memo = memo(ForwardRef);
export default Memo;
