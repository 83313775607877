import React from 'react'
import { useDynamicWidgets } from 'react-instantsearch'

import { useUserResources } from 'scripts/react-components/shared/hooks'
import { IS_CATEGORY } from 'scripts/react-components/entry-key/utils/constants'

import { useServerContext } from 'entry-key/routes/routes.context'

import { getAttribute, AttributesTypes } from './Filters.utils'
import { Checkboxes } from './Checkboxes'
import { Switches } from './Switches'
import { PriceRange } from './PriceRange'

export const FILTER_PREFIX = 'attributes.'

function Filters() {
  const { userResources } = useUserResources()
  const { attributesToRender } = useDynamicWidgets()
  const { algolia } = useServerContext()

  if (!userResources?.region) {
    return null
  }

  const ATTRIBUTES = getAttribute(userResources.region)

  const getCheckboxAttributes = (item: string) => {
    return ATTRIBUTES.checkbox[item as keyof AttributesTypes['checkbox']]
  }

  function filterAttributes(attribute: string[]) {
    const filtered = attribute.filter(
      item => item.includes(FILTER_PREFIX) || getCheckboxAttributes(item)
    )

    const limited = filtered.slice(0, algolia?.maxFacets)

    return limited
  }

  const checkboxes = () => {
    const algoliaFacetsKey = Object.keys(algolia?.facets ?? {})

    if (IS_CATEGORY && algoliaFacetsKey.length) {
      const facets = Object.keys(ATTRIBUTES.checkbox).concat(algoliaFacetsKey)

      return facets.map((item, index) => {
        const categoryAttribute = getCheckboxAttributes(item)
          ? item
          : `attributes.${item}`

        return (
          <Checkboxes
            attribute={categoryAttribute}
            key={`${categoryAttribute}-${index}`}
            label={
              getCheckboxAttributes(item)?.label ??
              categoryAttribute.slice(FILTER_PREFIX.length)
            }
          />
        )
      })
    }

    return filterAttributes(attributesToRender).map(
      (attribute: string, index: number) => {
        if (
          getCheckboxAttributes(attribute) ||
          attribute.includes(FILTER_PREFIX)
        ) {
          return (
            <Checkboxes
              attribute={attribute}
              key={`${attribute}-${index}`}
              label={
                getCheckboxAttributes(attribute)?.label ??
                attribute.slice(FILTER_PREFIX.length)
              }
            />
          )
        }
      }
    )
  }

  return (
    <React.Fragment>
      {attributesToRender.map((attribute, index) => {
        if (ATTRIBUTES.toggle[attribute]) {
          return (
            <Switches
              attribute={attribute}
              index={index}
              key={`${attribute}-${index}`}
              label={ATTRIBUTES.toggle[attribute]?.label}
            />
          )
        }

        if (ATTRIBUTES.range[attribute]) {
          return (
            <PriceRange
              attribute={attribute}
              index={index}
              key={`${attribute}-${index}`}
              label={ATTRIBUTES.range[attribute]?.label}
            />
          )
        }

        return null
      })}

      {checkboxes()}
    </React.Fragment>
  )
}

export default Filters
