import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from '@leroy-merlin-br/backyard-react'

import { ProfileResponse } from '../../types'
import { getProfile } from '../../services'
import { getOptions, updateInterests } from '../../services/preferences'
import { SelectData } from './types'

export type LoyaltyData = {
  optIn: boolean
  favoriteStore: string
  optInPro: boolean
  occupation: string
  status?: number
}

export type InterestOptionData = {
  id: string
  name: string
}

const ARCHITECT_PROFESSION_CODE = 7
const ARCHITECT_PROFESSION_CODE_PENDING = 8

export const usePreferences = () => {
  const [loyalty, setLoyalty] = useState<LoyaltyData>({
    optIn: true,
    favoriteStore: '',
    optInPro: false,
    occupation: ''
  })
  const [interests, setInterests] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  const [occupations, setOccupations] = useState<SelectData[]>([])
  const [favoriteStores, setFavoriteStores] = useState<SelectData[]>([])
  const [favoriteStoreError, setFavoriteStoreError] = useState(false)
  const [interestsOptions, setInterestsOptions] = useState<
    InterestOptionData[]
  >([])
  const [disableProfessionField, setDisableProfessionField] = useState(false)
  const [isModalOptOutOpen, setModalOptOutOpen] = useState(false)
  const [isLoyalty, setIsLoyalty] = useState(false)

  const history = useHistory()

  const interestsShellOptions = Array.from(
    { length: 27 },
    (_, index) => index + 1
  )

  useEffect(() => {
    ;(async () => {
      setLoading(true)

      try {
        const { data: options } = await getOptions()

        setOccupations(
          options.occupations.map(
            (occupation: { name: string; id: string }) => ({
              label: occupation.name,
              value: occupation.id
            })
          )
        )

        setFavoriteStores(
          options.favoriteStores.map(
            (favoriteStore: { name: string; id: string }) => ({
              label: favoriteStore.name,
              value: favoriteStore.id
            })
          )
        )

        setInterestsOptions(options.interests)
      } catch {
        history.push('/erro-interno')
      }

      try {
        const profile: ProfileResponse = await getProfile()

        if (profile?.data?.customer?.loyalty) {
          const { active, favoriteStore, isProfessional, profession, status } =
            profile.data.customer.loyalty

          setLoyalty({
            favoriteStore,
            occupation: profession,
            optIn: !!active,
            optInPro: active && isProfessional,
            status
          })

          const isArchitect =
            status === ARCHITECT_PROFESSION_CODE ||
            status === ARCHITECT_PROFESSION_CODE_PENDING

          setDisableProfessionField(isArchitect)

          setIsLoyalty(active)
        }

        if (profile?.data?.interests?.interestAreaName?.length) {
          setInterests(profile.data.interests.interestAreaName)
        }

        setLoading(false)
      } catch {
        history.push('/erro-interno')
      }
    })()
  }, [history])

  const handleUpdateInterests = async () => {
    if (!loyalty.favoriteStore) {
      setFavoriteStoreError(true)

      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

      return
    }

    setLoading(true)

    try {
      await updateInterests({
        loyalty,
        interests: {
          interestAreaName: interests
        }
      })

      toast.primary('Preferências atualizadas com sucesso!', {
        variant: 'solid'
      })
    } catch (error) {
      if (error?.data?.errors?.length) {
        const [{ code }] = error.data.errors

        if (code === 'loyalty.favoriteStore') {
          return toast.critical('O campo loja favorita é obrigatório!', {
            variant: 'solid'
          })
        }

        if (code === 'loyalty.occupation') {
          return toast.critical(
            'O campo profissão é obrigatório para entrar no Programa de Fidelidade!',
            {
              variant: 'solid'
            }
          )
        }
      }

      toast.critical('Não foi possível atualizar as preferências!', {
        variant: 'solid'
      })
    } finally {
      setLoading(false)

      setModalOptOutOpen(false)
    }
  }

  const checkIsSelected = (item: string) =>
    interests?.filter(interest => interest === item).length

  const handleSelect = (item: string) => {
    if (loading) {
      return
    }

    if (interests?.filter(interest => interest === item).length) {
      setInterests(interests?.filter(interest => interest !== item))
    } else {
      setInterests([...(interests || []), item])
    }
  }

  const toggleModalOptOut = () => setModalOptOutOpen(!isModalOptOutOpen)

  return {
    loyalty,
    setLoyalty,
    loading,
    handleUpdateInterests,
    checkIsSelected,
    handleSelect,
    occupations,
    favoriteStores,
    interestsOptions,
    interestsShellOptions,
    disableProfessionField,
    isModalOptOutOpen,
    toggleModalOptOut,
    isLoyalty,
    favoriteStoreError,
    setFavoriteStoreError
  }
}
