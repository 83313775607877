import PropTypes from 'prop-types'
import { Text, Card } from '@leroy-merlin-br/backyard-react'
import { flattenDeep } from 'lodash'
import { Button } from '@backyard-ui/core'

import { SummaryPricesCart } from 'shared/components/Summary'
import { CouponAlertMessage } from 'shared/components'

import formatPriceToBRL from 'utils/formatPriceToBRL'

import * as dataLayer from 'checkout/services/data-layer'

import { useStandaloneService } from 'frontendCheckout/hooks'
import * as tracker from 'frontendCheckout/tracker'

import TotalPaymentMethods from './TotalPaymentMethods'
import * as S from './styled'

const Summary = ({
  cart,
  services,
  context,
  discount = 0,
  cheapestPayMethodTotal = 0,
  canBePurchased,
  subtotal,
  servicesSubTotal = 0,
  total,
  freight,
  regionId,
  paymentMethodsSummary,
  paymentMethods,
  totalInstallments,
  totalDiscountsByType,
  firstCheapestPaymentMethod,
  showDiscountsModal,
  zipCode
}) => {
  const hasFreight = typeof freight !== 'undefined' && freight !== null
  const shouldRenderSummaryPricesCart = paymentMethods !== undefined

  const { isStandaloneServiceCart } = useStandaloneService({
    services,
    isCartWithoutProducts: !cart?.shippings?.length
  })

  const onClickPayment = ({ shippings }, regionId, services = []) => {
    tracker.pushCart({
      eventName: 'begin_checkout',
      shippings,
      services,
      zipCode,
      freightCost: freight,
      cart: {
        value: subtotal + servicesSubTotal,
        discount
      }
    })

    const findDeadLineMessage = shipment =>
      shipment.modalities.find(
        modality => modality.type === 'standard' || modality.type === 'express'
      )?.deadlineMessage || ''

    const datalayerProducts = flattenDeep(
      shippings.map(shipping =>
        shipping.shipments.map(shipment => {
          const deadlineMessage = findDeadLineMessage(shipment)
          return shipment.items.map(product => ({
            name: product.name,
            id: product.productId,
            brand: product.brand || '',
            category: product.categoryTree || '',
            quantity: product.packagingCount,
            packaging: product.packaging,
            price: product.price.to,
            shipmentType:
              shipping.type === 'pickupInStore'
                ? 'pickup-in-store'
                : 'delivery',
            store: shipping.type === 'pickupInStore' ? shipping.storeName : '',
            storeId: product.storeId || '',
            marketPlace: shipping.type === 'delivery' ? product.shopName : '',
            withdrawalDate: product.deliveryForecast || '',
            shipmentDate: deadlineMessage,
            hasDiscount:
              product.price.discount > 0 ? 'com-desconto' : 'sem-desconto',
            discountValue:
              product.price.discount > 0 ? product.price.discount : ''
          }))
        })
      )
    )

    const datalayerServices = services.map(service => {
      const isStandaloneService = !service.relatedProduct.id

      return {
        name: service.name,
        id: service.serviceId,
        brand: 'Serviço',
        category: service.serviceBankName,
        quantity: service.quantity,
        price: service.price.to,
        shipmentType: 'delivery',
        store: 'Leroy Merlin',
        marketPlace: 'Leroy Merlin',
        withdrawalDate: '',
        shipmentDate:
          '3 dias úteis' +
          (isStandaloneService ? '' : ' após a entrega ou retirada do produto'),
        hasDiscount: 'sem-desconto',
        discountValue: '',
        relatedProduct: service.relatedProduct.name || ''
      }
    })

    dataLayer.checkoutStep1({
      regionId,
      products: [...datalayerProducts, ...datalayerServices]
    })

    window.location.href = '/pedido/entrega'
  }

  return (
    <S.Wrapper context={context}>
      {!shouldRenderSummaryPricesCart && (
        <Card data-cy="cart-info">
          <S.CartItem>
            <Text size="mega" as="span" noMargin>
              Subtotal
            </Text>
            <Text
              size="mega"
              as="span"
              noMargin
              isBold
              data-cy="summary-subtotal">
              {formatPriceToBRL(subtotal)}
            </Text>
          </S.CartItem>

          {hasFreight && (
            <S.CartItem>
              <Text size="mega" as="span" noMargin>
                Frete estimado
              </Text>
              {isStandaloneServiceCart || freight === 0 ? (
                <Text color="p500" size="mega" as="span" noMargin isBold>
                  Grátis
                </Text>
              ) : (
                <Text size="mega" as="span" noMargin isBold>
                  {formatPriceToBRL(freight)}
                </Text>
              )}
            </S.CartItem>
          )}

          {discount > 0 && (
            <S.CartItem>
              <Text size="mega" as="span" noMargin>
                Descontos
              </Text>
              <Text color="p500" size="mega" as="span" noMargin isBold>
                - {formatPriceToBRL(discount)}
              </Text>
            </S.CartItem>
          )}

          {servicesSubTotal > 0 && (
            <S.CartItem>
              <Text size="mega" as="span" noMargin>
                Serviço de Instalação
              </Text>
              <Text size="mega" as="span" noMargin isBold>
                {formatPriceToBRL(servicesSubTotal)}
              </Text>
            </S.CartItem>
          )}

          <S.Footer>
            <Text size="giga" as="span" noMargin isBold>
              Total estimado
            </Text>
            <Text size="giga" as="span" noMargin isBold>
              {formatPriceToBRL(total)}
            </Text>
          </S.Footer>
        </Card>
      )}
      {paymentMethodsSummary && !shouldRenderSummaryPricesCart && (
        <S.PaymentMethodSummaryWrapper>
          <TotalPaymentMethods paymentMethodsSummary={paymentMethodsSummary} />
        </S.PaymentMethodSummaryWrapper>
      )}
      {shouldRenderSummaryPricesCart && (
        <SummaryPricesCart
          discount={discount}
          subTotal={subtotal}
          total={cheapestPayMethodTotal}
          servicesSubTotal={servicesSubTotal}
          paymentMethods={paymentMethods}
          freight={freight}
          totalInstallments={totalInstallments}
          totalDiscountsByType={totalDiscountsByType}
          firstCheapestPaymentMethod={firstCheapestPaymentMethod}
          showDiscountsModal={showDiscountsModal}
        />
      )}
      <S.ButtonWrapper>
        <Button
          isFullWidth
          size="md"
          data-cy="proceed-to-address-button"
          onClick={() => onClickPayment(cart, regionId, services)}
          isDisabled={!canBePurchased}>
          Continuar para pagamento
        </Button>
      </S.ButtonWrapper>

      {!isStandaloneServiceCart && <CouponAlertMessage />}
    </S.Wrapper>
  )
}

Summary.propTypes = {
  cart: PropTypes.shape({
    shippings: PropTypes.array,
    subTotal: PropTypes.number,
    discountTotal: PropTypes.number,
    total: PropTypes.number
  }),
  services: PropTypes.array,
  context: PropTypes.array.isRequired,
  discount: PropTypes.number,
  cheapestPayMethodTotal: PropTypes.number,
  canBePurchased: PropTypes.bool,
  subtotal: PropTypes.number.isRequired,
  servicesSubTotal: PropTypes.number,
  total: PropTypes.number.isRequired,
  freight: PropTypes.number,
  regionId: PropTypes.string.isRequired,
  paymentMethods: PropTypes.string,
  totalInstallments: PropTypes.shape({
    amount: PropTypes.number,
    value: PropTypes.string,
    totalPrice: PropTypes.string
  }),
  totalDiscountsByType: PropTypes.shape({
    stampedPrice: PropTypes.number,
    promo: PropTypes.number,
    employee: PropTypes.number,
    'payment-method': PropTypes.number,
    pix: PropTypes.number,
    bank_slip: PropTypes.number,
    credit_card: PropTypes.number
  }),
  firstCheapestPaymentMethod: PropTypes.string,
  showDiscountsModal: PropTypes.bool
}

export default Summary
