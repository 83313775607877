import styled from '@emotion/styled'
import { css } from '@emotion/react'

const dividerContainerStyle = () => css`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  gap: var(--desktop-space-inset-xs);
`
export const DividerContainer = styled('div')(dividerContainerStyle)

const lineStyle = () => css`
  flex: 1;
  height: 1px;
  background-color: var(--color-surface-neutral-stronger);
`
export const Line = styled('div')(lineStyle)

const onlyLineStyle = () => css`
  width: 100%;
  height: 1px;
  background-color: var(--color-surface-neutral-stronger);
`
export const OnlyLine = styled('div')(onlyLineStyle)
