import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgStoreOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        d="M19.148 2.971A2.01 2.01 0 0 0 17.434 2H6.566c-.698 0-1.355.372-1.714.971L2.143 7.485A1 1 0 0 0 2 8a3.97 3.97 0 0 0 1 2.618V19c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2v-8.382A3.97 3.97 0 0 0 22 8c0-.181-.05-.36-.143-.515zm.836 5.28A2 2 0 0 1 18 10c-1.103 0-2-.897-2-2 0-.068-.025-.128-.039-.192l.02-.004L15.22 4h2.214zM10.819 4h2.361l.813 4.065A2 2 0 0 1 12 10a2 2 0 0 1-1.993-1.935zM6.566 4H8.78l-.76 3.804.02.004C8.025 7.872 8 7.932 8 8c0 1.103-.897 2-2 2a2 2 0 0 1-1.984-1.749zM10 19v-3h4v3zm6 0v-3c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v3H5v-7.142c.321.083.652.142 1 .142a4 4 0 0 0 3-1.357A4 4 0 0 0 12 12a4 4 0 0 0 3-1.357A4 4 0 0 0 18 12c.348 0 .679-.059 1-.142V19z"
        fill={color ? Colors[color] : 'currentColor'}
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgStoreOutline)
const Memo = memo(ForwardRef)
export default Memo
