import { Stack } from '@leroy-merlin-br/backyard-react'

import { useLoyaltyProfile } from 'shared/hooks'

import { useResize } from 'utils/hooks/use-resize'

import IconLoyalty from 'lmcv/components/Icons/IconLoyalty'
import { Header as LoyaltyHeader } from 'lmcv/components'

import * as S from './styled'

export default function Header() {
  const { loyaltyProfile } = useLoyaltyProfile()
  const [isMobile] = useResize()

  return (
    <>
      {!isMobile && loyaltyProfile && (
        <LoyaltyHeader loyaltyProfile={loyaltyProfile} />
      )}

      <S.Wrapper>
        <Stack space="kilo">
          <S.Title size="giga" isBold>
            Solicitação de cashback
          </S.Title>
          <S.Sub color="n500">
            Troque seus pontos por cashback na sua conta corrente emitindo nota
            fiscal.
          </S.Sub>
        </Stack>
        <IconLoyalty width={80} />
      </S.Wrapper>
    </>
  )
}
