import { createSlice } from '@reduxjs/toolkit'
import { InvoicesType } from 'frontendMyOrders/types'

import {
  DeliveryDateType,
  ShippingAddressType,
  StepsType,
  ApiChatItemType,
  ThreadsType,
  PanicErrorType
} from '../types'

export interface PropertiesStateType {
  data: {
    orderId: string
    marketplaceOrder: string
    createdAt: string
    chatStatus: string
    sellerResponseTimeInHours: string
    threadId: string
    ticket: {
      contact: string
      isOriginal: boolean
      type: string
      protocol: string
    }
    option?: string
    subOption?: string
    userName: string
    shopName: string
    canSendMessage: boolean
    isLoading: boolean
    panicError?: PanicErrorType
    userResourcesEmail: string
    userResourcesPhone: string
    steps?: StepsType
    threads?: ThreadsType
    shippingAddressFormatted?: string
    shippingAddress?: ShippingAddressType
    deliveryDate?: DeliveryDateType
    trackingCode?: string
    trackingUrl?: string
    invoices?: InvoicesType
    contactType?: 'email' | 'phone' | 'whatsapp'
    contactSelected?: string
    contactIsOriginal?: boolean
    apiChat: ApiChatItemType[]
    isOnMobileApp?: boolean
    type: 'chat' | 'ticket'
  }
}

const initialState: PropertiesStateType = {
  data: {
    orderId: '',
    marketplaceOrder: '',
    createdAt: '',
    chatStatus: 'idle',
    sellerResponseTimeInHours: '',
    threadId: '',
    ticket: {
      contact: '',
      isOriginal: false,
      type: '',
      protocol: ''
    },
    option: undefined,
    subOption: undefined,
    userName: '',
    shopName: '',
    canSendMessage: false,
    isLoading: false,
    userResourcesEmail: '',
    userResourcesPhone: '',
    apiChat: [] as ApiChatItemType[],
    threads: [] as ThreadsType,
    type: 'chat'
  }
}

export const propertiesSlice = createSlice({
  name: 'Properties',
  initialState,
  reducers: {
    setProperties: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload
      }
    },
    setOption: (state, action) => {
      state.data.option = action.payload
    },
    setSubOption: (state, action) => {
      state.data.subOption = action.payload
    },
    setCanSendMessage: (state, action) => {
      state.data.canSendMessage = action.payload
    },
    setIsLoading: (state, action) => {
      state.data.isLoading = action.payload
    },
    setPanicError: (state, action) => {
      state.data.panicError = action.payload
    },
    resetProperties: state => {
      state.data = initialState.data
    }
  }
})

export const {
  setProperties,
  setSubOption,
  setOption,
  setCanSendMessage,
  setIsLoading,
  setPanicError,
  resetProperties
} = propertiesSlice.actions

export default propertiesSlice.reducer
