import React, { createContext, useContext, useMemo } from 'react'
import { MarketplaceChat } from 'frontendMyOrders/plugins/Chat/MarketPlace'
import { AppProps } from 'frontendMyOrders/plugins/Chat/MarketPlace/App'

type ContextType = {
  isRefactorEnabled: boolean
  isMarketplaceChatEnabled: boolean
  closeTimeInHours: number
  errors: string
  fiscalId: string
  saleOrderId: string
  plugins: {
    MarketplaceChat: React.FC<AppProps> | null
  }
}

const MyOrdersContext = createContext<ContextType>({
  isRefactorEnabled: false,
  isMarketplaceChatEnabled: false,
  closeTimeInHours: 0,
  errors: '',
  fiscalId: '',
  saleOrderId: '',
  plugins: {
    MarketplaceChat: null
  }
})

type MyOrdersProviderProps = {
  isMarketplaceChatEnabled: boolean
  closeTimeInHours: number
  children: React.ReactNode
  saleOrderId: string
  fiscalId: string
  isRefactorEnabled: boolean
}

const MyOrdersProvider = ({
  isMarketplaceChatEnabled,
  closeTimeInHours,
  saleOrderId,
  fiscalId,
  isRefactorEnabled,
  children
}: MyOrdersProviderProps) => {
  const plugins = useMemo(() => {
    return {
      MarketplaceChat: isMarketplaceChatEnabled ? MarketplaceChat : null
    }
  }, [isMarketplaceChatEnabled])

  const store = useMemo(() => {
    return {
      isRefactorEnabled,
      isMarketplaceChatEnabled,
      closeTimeInHours,
      errors: '',
      fiscalId,
      saleOrderId,
      plugins
    }
  }, [
    isRefactorEnabled,
    isMarketplaceChatEnabled,
    closeTimeInHours,
    fiscalId,
    saleOrderId,
    plugins
  ])

  return (
    <MyOrdersContext.Provider value={store}>
      {children}
    </MyOrdersContext.Provider>
  )
}

export const useMyOrdersContext = () => {
  return useContext(MyOrdersContext)
}

export default MyOrdersProvider
