import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgBroomOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M12 4a1 1 0 0 0-1 1v5a2 2 0 0 1-2 2H7a1 1 0 0 0-1 1v1h12v-1a1 1 0 0 0-1-1h-2a2 2 0 0 1-2-2V5a1 1 0 0 0-1-1m6.183 12H5.817l-.813 4h13.992zM4 14.9V13a3 3 0 0 1 3-3h2V5a3 3 0 0 1 6 0v5h2a3 3 0 0 1 3 3v1.9l.956 4.702A2 2 0 0 1 18.996 22H5.004a2 2 0 0 1-1.96-2.398z"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgBroomOutline)
const Memo = memo(ForwardRef)
export default Memo
