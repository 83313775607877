import { useState, useLayoutEffect, useEffect, useCallback } from 'react'
import {
  Stack,
  Container,
  Grid,
  Spacer,
  Hidden,
  Button,
  Flex
} from '@backyard-ui/core'
import { FilterOutline } from '@backyard-ui/icons'
import axios from 'axios'
import { useStats, useInstantSearch } from 'react-instantsearch'

import { useUserResources } from 'shared/hooks'

import WishlistProviderMethods from 'site/Wishlist/provider'

import CategoryCustomHelmet from './helmet'
import { TopContent } from './ui/TopContent'
import { MiddleContent } from './ui/MiddleContent'
import { Breadcrumb } from './ui/Breadcrumb'
import { Header } from '../../ui/Header'
import { Content } from '../../ui/Content'
import { Aside } from '../../ui/Aside'
import { useEntryStore } from '../../Entry.store'
import { useServerContext } from '../../routes/routes.context'
import styles from './page.styles'
import { transformContentUrl } from '../../lib/transformContentUrl.utils'
import { IS_ON_MOBILE_APP } from '../../constants'

interface MetaTag {
  type: string
  value: string
}

type MetaTagsArray = MetaTag[][]

export interface VideoTagSEO {
  id: string
  url: string
  title: string
  picture: string
}

export interface ContentTagSEO {
  id: string
  url: string
  title: string
  picture: string
}

interface TagsSEO {
  canonical?: string
  prev?: string
  next?: string
  metaTags?: MetaTagsArray
  gaTrack?: string
  topDescription?: string
  middleDescription?: string
  footerDescription?: string
  title?: string
  video?: VideoTagSEO[]
  contents?: ContentTagSEO[]
}

export const getTags = async (hitsPerPage: number, nbHits: number) => {
  try {
    const data = {
      hitsPerPage: hitsPerPage,
      nbHits: nbHits,
      url: encodeURI(transformContentUrl(document.URL))
    }
    const segments = data.url.split('/')
    const apiDomain = segments.slice(0, 3).join('/')

    const response = await axios.get(`${apiDomain}/api/v3/category/content`, {
      params: data
    })
    return response.data
  } catch (error) {
    throw axios.isAxiosError(error) ? error.response : error
  }
}

function CategoryPage() {
  const { meta } = useServerContext()
  const { userResources } = useUserResources()
  const { results, indexUiState } = useInstantSearch()
  const { nbHits } = useStats()

  const [scroll, setScroll] = useState(false)
  const [tagsSEO, setTags] = useState<TagsSEO>()

  const store = useEntryStore(state => state.ui)

  const handleScroll = () => {
    if (window.scrollY > 215) {
      setScroll(true)
    } else {
      setScroll(false)
    }
  }

  const fetchData = useCallback(async () => {
    if (indexUiState) {
      const data = await getTags(results.hitsPerPage, nbHits)
      setTags(data.data)
    }
  }, [nbHits, results.hitsPerPage, indexUiState])

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchData()
    }, 500)

    return () => clearTimeout(timeout)
  }, [fetchData])

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  if (!userResources?.region) {
    return null
  }

  return (
    <div data-testid="category-page">
      {tagsSEO && <CategoryCustomHelmet {...tagsSEO} />}
      <Stack direction="column">
        <Container size={{ initial: '3xl', lg: '6xl' }} className="z-20 w-full">
          <Breadcrumb />

          {!IS_ON_MOBILE_APP && (
            <TopContent
              topDescription={tagsSEO?.topDescription || meta.description.top}
              categoryTitle={tagsSEO?.title || null}
              videos={tagsSEO?.video}
              contents={tagsSEO?.contents}
            />
          )}
        </Container>

        <Container size={{ initial: '3xl', lg: '6xl' }} className="w-full">
          <Grid.Root isGrow>
            <Grid.Item
              size={{
                initial: 12,
                md: 0,
                lg: 3
              }}>
              <Aside />
            </Grid.Item>
            <Grid.Item
              size={{
                initial: 12,
                md: 9
              }}>
              <Stack direction="column">
                <Hidden above="lg">
                  <Header categoryTitle={tagsSEO?.title || null} />
                </Hidden>

                <WishlistProviderMethods>
                  <Content region={userResources.region} />
                </WishlistProviderMethods>
              </Stack>
            </Grid.Item>
          </Grid.Root>
          <Spacer />

          {tagsSEO?.middleDescription && (
            <MiddleContent middleDescription={tagsSEO?.middleDescription} />
          )}
        </Container>

        {!store.isFiltersOpened && (
          <Hidden above="lg">
            <Flex
              justify="center"
              className={styles().button({
                animation: scroll ? 'up' : 'down'
              })}>
              <Button
                iconLeft={<FilterOutline />}
                onClick={store.toggleFilters}>
                Filtros
              </Button>
            </Flex>
          </Hidden>
        )}
      </Stack>
    </div>
  )
}

export default CategoryPage
