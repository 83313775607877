import { Button, Card, Text, Hidden, Flex } from '@backyard-ui/core'
import { useInstantSearch, useStats } from 'react-instantsearch'
import { FilterOutline } from '@backyard-ui/icons'

import { useEntryStore } from '../../Entry.store'
import { IS_ON_MOBILE_APP } from '../../constants'

interface HeaderProps {
  categoryTitle?: string | null
}

function Header({ categoryTitle }: HeaderProps) {
  const { results } = useInstantSearch()
  const { nbHits } = useStats()

  const title = !results?._state.query?.trim().length
    ? 'Produtos Recomendados'
    : results?._state.query

  const totalHits = nbHits.toLocaleString()
  const store = useEntryStore(state => state.ui)

  return (
    <Card.Root>
      <Card.Body>
        <Flex justify="space-between" align="center">
          {IS_ON_MOBILE_APP ? (
            <div>
              <Text color="neutral-700">
                <span className="font-bold">{categoryTitle || title}</span>
              </Text>
              <Text size="sm" color="neutral-700">
                {`${totalHits} ${
                  totalHits === '1' ? 'Resultado' : 'Resultados'
                }`}
              </Text>
            </div>
          ) : (
            <Text color="neutral-700">
              {`${totalHits} ${totalHits === '1' ? 'Resultado' : 'Resultados'}`}{' '}
              <Hidden below="lg">
                para{' '}
                <span className="capitalize font-bold">
                  &quot;{categoryTitle || title}&quot;
                </span>
              </Hidden>
            </Text>
          )}

          <Hidden above="lg">
            <Button
              size="xs"
              iconLeft={<FilterOutline />}
              onClick={store.toggleFilters}>
              Filtros
            </Button>
          </Hidden>
        </Flex>
      </Card.Body>
    </Card.Root>
  )
}

export default Header
