import { RecoveryContextProvider } from './context/recovery-context'
import { Routes } from './routes'

type AppProps = {
  isCaptchaEnabled: boolean | string
  isCaptchaEnterpriseEnabled: boolean
  captchaKey: string
}

const App = ({
  isCaptchaEnabled,
  isCaptchaEnterpriseEnabled,
  captchaKey
}: AppProps) => (
  <RecoveryContextProvider
    isCaptchaEnabled={isCaptchaEnabled === 'true'}
    isCaptchaEnterpriseEnabled={isCaptchaEnterpriseEnabled}
    captchaKey={captchaKey}>
    <Routes />
  </RecoveryContextProvider>
)

export default App
