import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgHelpOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M2 12C2 6.486 6.486 2 12 2s10 4.486 10 10-4.486 10-10 10S2 17.514 2 12m2 0c0 4.411 3.589 8 8 8s8-3.589 8-8-3.589-8-8-8-8 3.589-8 8m5.22-4.846A3.94 3.94 0 0 1 12 6a3.937 3.937 0 0 1 3.933 3.934c0 1.596-1.176 2.549-1.955 3.18l-.003.002-.041.033a6 6 0 0 0-.494.425c-.408.407-.44.777-.441.793L13 15h-2v-.667c0-.118.029-1.177 1.027-2.174q.33-.316.691-.599c.735-.594 1.216-1.028 1.216-1.626A1.936 1.936 0 0 0 12 8a1.936 1.936 0 0 0-1.934 1.934h-2a3.94 3.94 0 0 1 1.154-2.78M13 18v-2h-2v2z"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgHelpOutline)
const Memo = memo(ForwardRef)
export default Memo
