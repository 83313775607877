import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgMastercardOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        d="M11.454 17.021q.073.062.151.122a6.17 6.17 0 0 1-3.42 1.03A6.172 6.172 0 0 1 5.267 6.558a6.18 6.18 0 0 1 6.335.298c-.05.043-.1.082-.138.126A6.63 6.63 0 0 0 9.166 12c0 1.925.833 3.755 2.288 5.021m4.361-11.195a6.14 6.14 0 0 0-3.416 1.03c.049.043.099.082.137.126A6.63 6.63 0 0 1 14.835 12a6.67 6.67 0 0 1-2.288 5.021q-.075.062-.151.122a6.16 6.16 0 0 0 3.418 1.03 6.174 6.174 0 1 0 .001-12.347M12 7.15A6.15 6.15 0 0 0 9.644 12 6.15 6.15 0 0 0 12 16.853 6.16 6.16 0 0 0 14.357 12 6.15 6.15 0 0 0 12 7.15"
        fill={color ? Colors[color] : 'currentColor'}
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgMastercardOutline)
const Memo = memo(ForwardRef)
export default Memo
