import emitter from 'scripts/utils/emitter'
import BaseComponent from 'scripts/components/base-component'

class RecaptchaEnterprise extends BaseComponent {
  static componentName = 'recaptcha-enterprise'

  static emitterEvents = {
    'recaptcha:execute': 'execute',
    'recaptcha:submit-error': 'onSubmitError'
  }

  constructor(element) {
    super(element)

    this.options = {
      apiUrl: `https://www.google.com/recaptcha/enterprise.js?render=${this.$element.data('recaptcha-key')}`
    }
  }

  init() {
    this.captchaCompleted = false

    this.createDivNode()
    this.createScriptNode()

    window.recaptchaCallback = token => {
      this.captchaCompleted = true

      document.querySelector('#g-recaptcha-response').value = token

      emitter.emit('recaptcha:success')
    }
  }

  createDivNode() {
    const div = document.createElement('div')

    div.id = 'recaptcha'
    div.classList.add('g-recaptcha')
    div.dataset.sitekey = this.$element.data('recaptcha-key')
    div.dataset.callback = 'recaptchaCallback'
    div.dataset.size = 'invisible'

    this.$element.append(div)
  }

  createScriptNode() {
    const script = document.createElement('script')
    script.src = this.options.apiUrl

    document.querySelector('head').appendChild(script)
  }

  execute(action = '') {
    if (!this.captchaCompleted) {
      window.grecaptcha.enterprise
        .execute(this.$element.data('recaptcha-key'), { action })
        .then(token => {
          window.recaptchaCallback(token)
        })
    } else {
      emitter.emit('recaptcha:success')
    }
  }

  onSubmitError() {
    window.grecaptcha.enterprise.reset()
    this.captchaCompleted = false
  }
}

export default $el => new RecaptchaEnterprise($el).init()

export { RecaptchaEnterprise as Component }
