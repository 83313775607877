'use client'

import { useEffect, useState } from 'react'

import * as CouponUI from 'frontendCheckout/ui/v1/CouponAndVoucher'
import * as TabUI from 'frontendCheckout/ui/v1/Tabs'
import { Brithness } from 'frontendCheckout/assets/icons'
import { apiTypes } from 'frontendCheckout/services/api'
import { useStandaloneService } from 'frontendCheckout/hooks'

import { useSummary } from '../../hooks/useSummary'
import { useTwig } from '../../hooks/useTwig'
import { tab } from './types'
import { useAvailableCouponsAndVouchers } from '../../hooks/useAvailableCouponsAndVouchers'
import { Coupon } from './Coupons'
import { VoucherModalContent } from '../VoucherModalContent'
import { UnexpectedErrorModal } from './UnexpectedErrorModal'

type CouponsAndVoucherProps = {
  cartId: string
  isListPromoCouponsEnabled: boolean
  services: string
  hasShippingOptions: string
}

export const CouponsAndVoucher = ({
  cartId,
  isListPromoCouponsEnabled,
  services,
  hasShippingOptions
}: CouponsAndVoucherProps) => {
  const [showModal, setShowModal] = useState(false)
  const [currentTab, setCurrentTab] = useState<tab>('coupon')
  const [couponInput, setCouponInput] = useState('')
  const [showModalUnexpectedError, setShowModalUnexpectedError] =
    useState(false)

  const {
    getSummary,
    summary,
    isLoading: isLoadingSummary,
    status: getSummaryStatusCode
  } = useSummary()

  const { getSelectedPaymentMethod, isCashPayment } = useTwig({
    cartId,
    summary,
    getSummary,
    isLoadingSummary,
    getSummaryStatusCode
  })

  const dictionaryErrorMessages = {
    UNUSED_VOUCHER: 'Os seguintes vouchers não foram utilizados',
    UNUSED_MONEY_VOUCHER: 'Os seguintes cashbacks não foram utilizados',
    UNUSED_COUPON: 'Os seguintes cupons não foram utilizados',
    INVALID_VALUE_FOR_MONEY_VOUCHERS:
      'Valor inválido para utilizar os cashbacks',
    PROMO_9105:
      'Este cupom expirou, verifique as regras de aplicação para ter certeza de como utilizá-lo.',
    PROMO_9106:
      'Este cupom está expirado, verifique as regras de aplicação para ter certeza de como utilizá-lo.',
    PROMO_2002:
      'Infelizmente este cupom não pode ser utilizado pois os itens em seu carrinho não se encaixam nas regras de aplicação. Verifique novamente como utilizá-lo.',
    PROMO_99999:
      'Este cupom não pode ser aplicado pois os itens em seu carrinho já possuem desconto.'
  } as const

  const {
    availableCouponsAndVoucher,
    getAvailableCouponsAndVouchers,
    isLoading: isLoadingAvailableCouponsAndVoucher
  } = useAvailableCouponsAndVouchers()

  const isLoading = isLoadingSummary || isLoadingAvailableCouponsAndVoucher

  const numberOfAppliedCouponsAndVouchers =
    summary.appliedMoneyVouchers.length +
    summary.appliedVouchers.length +
    (summary.appliedCoupon ? 1 : 0)

  const appliedCoupon = availableCouponsAndVoucher.coupons.find(
    coupon => coupon.code === summary.appliedCoupon
  )

  const textIncludesInput = (
    coupon: apiTypes.Cart.getAvailableVouchersAndCoupons.Coupon
  ) => coupon.code.includes(couponInput) || coupon.title.includes(couponInput)

  const { isStandaloneServiceCart } = useStandaloneService({
    services: JSON.parse(services),
    isCartWithoutProducts: !JSON.parse(hasShippingOptions)
  })

  const getCouponsToShow = () => {
    if (!summary.appliedCoupon) {
      return availableCouponsAndVoucher.coupons.filter(textIncludesInput)
    }

    if (appliedCoupon) {
      return [
        appliedCoupon,
        ...availableCouponsAndVoucher.coupons.filter(
          coupon => coupon !== appliedCoupon
        )
      ].filter(textIncludesInput)
    }

    return [
      {
        code: summary.appliedCoupon,
        title: summary.appliedCoupon,
        id: summary.appliedCoupon
      },
      ...availableCouponsAndVoucher.coupons
    ].filter(textIncludesInput)
  }

  const applyCouponClick = async (code: string) => {
    await getSummary({
      cartId,
      params: {
        payment_method: getSelectedPaymentMethod(),
        coupon: code,
        money_vouchers: summary.appliedMoneyVouchers,
        vouchers: summary.appliedVouchers,
        simulate: true,
        cash_payment: isCashPayment()
      }
    })

    setCouponInput('')
  }

  const couponClick = async (code: string) => {
    getSummary({
      cartId,
      params: {
        payment_method: getSelectedPaymentMethod(),
        money_vouchers: summary.appliedMoneyVouchers,
        vouchers: summary.appliedVouchers,
        coupon: code === summary.appliedCoupon ? undefined : code,
        simulate: true,
        cash_payment: isCashPayment()
      }
    })
  }

  const TriggerButtonVisual = () => {
    if (numberOfAppliedCouponsAndVouchers) {
      return (
        <CouponUI.ButtonTextAddedCupon
          quantity={numberOfAppliedCouponsAndVouchers}
        />
      )
    }

    if (availableCouponsAndVoucher.coupons.length > 0) {
      return (
        <CouponUI.ButtonTextEmpty
          quantity={availableCouponsAndVoucher.coupons.length}
        />
      )
    }

    return <CouponUI.ButtonTextDefault />
  }

  const buildListErrorMessages = (
    errors: apiTypes.Cart.getSummary.ErrorsByCoupon[]
  ) =>
    errors?.map(({ code, vouchers }) => {
      const allErrorVouchersTitles =
        vouchers
          ?.map(
            voucher =>
              availableCouponsAndVoucher.vouchers.money.find(
                money => money.id === voucher
              )?.title ||
              availableCouponsAndVoucher.vouchers.percentage.find(
                percentage => percentage.id === voucher
              )?.title
          )
          .filter(couponTitle => !!couponTitle)
          .join(', ') ?? []

      const voucherText =
        allErrorVouchersTitles.length > 0 ? `: (${allErrorVouchersTitles})` : ''

      const errorMessage =
        dictionaryErrorMessages[code as keyof typeof dictionaryErrorMessages]
      if (errorMessage) return `${errorMessage}${voucherText}`
      return 'Este cupom não é válido'
    })

  useEffect(() => {
    if (getSummaryStatusCode === -1 || getSummaryStatusCode === 0) return
    if (getSummaryStatusCode >= 200 && getSummaryStatusCode < 300) return

    setShowModalUnexpectedError(true)
  }, [getSummaryStatusCode])

  useEffect(() => {
    getSummary({
      cartId,
      params: {
        payment_method: getSelectedPaymentMethod(),
        coupon: summary.appliedCoupon ?? undefined,
        money_vouchers: summary.appliedMoneyVouchers,
        vouchers: summary.appliedVouchers,
        simulate: true,
        cash_payment: isCashPayment()
      }
    })

    getAvailableCouponsAndVouchers({ cartId })
  }, [cartId])

  useEffect(() => {
    if (summary.errors.length > 0) {
      setShowModal(true)
    }
  }, [summary.errors.length])

  const percentageVoucherClick = (id: string) => {
    const appliedPercentageVoucher = summary.appliedVouchers.includes(id)
      ? summary.appliedVouchers.filter(voucher => voucher !== id)
      : [...summary.appliedVouchers, id]

    getSummary({
      cartId,
      params: {
        payment_method: getSelectedPaymentMethod(),
        money_vouchers: summary.appliedMoneyVouchers,
        vouchers: appliedPercentageVoucher,
        coupon: summary.appliedCoupon ?? undefined,
        simulate: true,
        cash_payment: isCashPayment()
      }
    })
  }

  const usedPoints = summary.appliedMoneyVouchers.reduce((points, voucher) => {
    const voucherPoints =
      availableCouponsAndVoucher.vouchers.money.find(
        val => val.id === voucher && val.active !== true
      )?.points || 0
    return points + voucherPoints
  }, 0)

  const moneyVoucherClick = (id: string, active: boolean, points = 0) => {
    const availablePoints =
      availableCouponsAndVoucher.points.points - usedPoints
    if (!active && availablePoints < points) return

    const getNewSelectedVouchersIds = (id: string, active: boolean) => {
      if (!active) return [...summary.appliedMoneyVouchers, id]

      const indexToRemove = summary.appliedMoneyVouchers.findIndex(
        appliedMoneyVoucher => appliedMoneyVoucher === id
      )
      if (indexToRemove === -1) return [...summary.appliedMoneyVouchers, id]
      const appliedMoneyVouchers = [...summary.appliedMoneyVouchers]
      appliedMoneyVouchers.splice(indexToRemove, indexToRemove + 1)
      return appliedMoneyVouchers
    }

    getSummary({
      cartId,
      params: {
        payment_method: getSelectedPaymentMethod(),
        money_vouchers: getNewSelectedVouchersIds(id, active),
        vouchers: summary.appliedVouchers,
        coupon: summary.appliedCoupon ?? undefined,
        simulate: true,
        cash_payment: isCashPayment()
      }
    })
  }

  if (isStandaloneServiceCart || !isListPromoCouponsEnabled) return <></>

  return (
    <>
      <UnexpectedErrorModal showModal={showModalUnexpectedError} />
      <CouponUI.Root isOpen={showModal}>
        <CouponUI.Trigger
          color={numberOfAppliedCouponsAndVouchers ? 'neutral' : 'primary'}
          onClick={() => {
            setShowModal(true)
          }}>
          {TriggerButtonVisual()}
        </CouponUI.Trigger>
        <CouponUI.Content>
          <CouponUI.Title>Cupons</CouponUI.Title>
          <CouponUI.Description>
            <TabUI.Root>
              <TabUI.Button
                onClick={() => setCurrentTab('coupon')}
                colorOnActive="green"
                isActive={currentTab === 'coupon'}>
                <span>Cupons</span>
              </TabUI.Button>
              <TabUI.Button
                onClick={() => setCurrentTab('voucher')}
                colorOnActive="purple"
                isActive={currentTab === 'voucher'}>
                <span className="flex items-center gap-2">
                  <Brithness />
                  Fidelidade
                </span>
              </TabUI.Button>
            </TabUI.Root>
            {currentTab === 'coupon' && (
              <Coupon
                appliedCouponCode={summary.appliedCoupon}
                applyCouponClick={applyCouponClick}
                couponClick={couponClick}
                applyCouponErrorMessage=""
                concludeButtonClick={() => {
                  setShowModal(false)
                }}
                couponInput={couponInput}
                couponList={getCouponsToShow()}
                setCouponInput={setCouponInput}
                isLoading={isLoading}
              />
            )}
            {currentTab === 'voucher' && (
              <VoucherModalContent
                points={availableCouponsAndVoucher.points.points - usedPoints}
                pointsExpiration={availableCouponsAndVoucher.points.expiration}
                moneyVoucherList={availableCouponsAndVoucher.vouchers.money}
                percentageVoucherList={
                  availableCouponsAndVoucher.vouchers.percentage
                }
                showLoad={isLoading}
                appliedMoneyVouchers={summary.appliedMoneyVouchers}
                appliedPercentageVouchers={summary.appliedVouchers}
                concludeButtonClick={() => {
                  setShowModal(false)
                }}
                moneyVoucherClick={moneyVoucherClick}
                percentageVoucherClick={percentageVoucherClick}
              />
            )}
            {!!summary?.errors?.length &&
              buildListErrorMessages(summary.errors).map((message, index) => (
                <div key={index} className="mt-4 mb-2">
                  <CouponUI.Error>{message}</CouponUI.Error>
                </div>
              ))}
          </CouponUI.Description>
          <CouponUI.Footer>
            {currentTab === 'coupon'
              ? 'Você só pode aplicar um cupom por vez.'
              : 'Cada produto pode ter apenas um cupom aplicado.'}
          </CouponUI.Footer>
        </CouponUI.Content>
      </CouponUI.Root>
    </>
  )
}
