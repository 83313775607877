import { useCallback, useEffect, useState } from 'react'
import {
  Text,
  Tabs,
  Stack,
  Row,
  Col,
  Card,
  Visible,
  Button,
  Hidden
} from '@leroy-merlin-br/backyard-react'
import { BuildingOutline, UserOutline } from '@backyard-ui/icons'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { LegalText } from 'user/shared/components'

import * as dataLayer from 'scripts/utils/data-layer'

import { Content } from '../../components'
import { FormPF } from './components/FormPF'
import { FormPJ } from './components/FormPJ'
import * as S from './styled'
import { useSignupContext } from '../../context/signup-context'

type SignUpProps = {
  verifyCodeStepOnSignup: boolean
  onNextStep?: () => void
}

export const SignUp = (props: SignUpProps) => {
  const { captchaKey, isCaptchaEnterpriseEnabled } = useSignupContext()

  return (
    <GoogleReCaptchaProvider
      useEnterprise={isCaptchaEnterpriseEnabled}
      reCaptchaKey={captchaKey}>
      <WithRecaptchaComponent {...props} />
    </GoogleReCaptchaProvider>
  )
}

const WithRecaptchaComponent = (props: SignUpProps) => {
  const [selectedTabId, setSelectedTabId] = useState('person')

  const assetHost = window && window.env.assetHost

  const tabs = [
    {
      id: 'person',
      label: 'Para você',
      panel: <FormPF {...props} />,
      icon: UserOutline
    },
    {
      id: 'company',
      label: 'Para sua empresa',
      panel: <FormPJ />,
      icon: BuildingOutline
    }
  ]

  const pushDatalayerOnSelectedTab = useCallback(() => {
    if (selectedTabId === 'person') {
      dataLayer.signupFormPageRender('pf')
    }

    if (selectedTabId === 'company') {
      dataLayer.signupFormPageRender('pj')
    }
  }, [selectedTabId])

  useEffect(() => {
    pushDatalayerOnSelectedTab()
  }, [pushDatalayerOnSelectedTab, selectedTabId])

  return (
    <Content>
      <Row>
        <Col
          size={{
            giga: 5
          }}>
          <Stack space="mega">
            <Card title="Criar conta">
              <Stack space="kilo">
                <Tabs
                  items={tabs}
                  onTabClick={(_, tabId) => {
                    setSelectedTabId(tabId)
                  }}
                  selectedId={selectedTabId}
                />
                <LegalText noMargin size="kilo" />
              </Stack>
            </Card>
            <Hidden when={['kilo', 'untilKilo']}>
              <Text noMargin>
                Já tem uma conta?{' '}
                <S.Link
                  href="/login"
                  onClick={dataLayer.clickOnSignupPageLoginLink}>
                  Faça o login
                </S.Link>
                .
              </Text>
            </Hidden>
            <Visible when={['kilo', 'untilKilo']}>
              <S.ButtonWrapper>
                <Button
                  as="a"
                  href="/login"
                  variant="outline"
                  color="p600"
                  isStretch
                  onClick={dataLayer.clickOnSignupPageLoginLink}>
                  Já tem uma conta? Faça o login
                </Button>
              </S.ButtonWrapper>
            </Visible>
          </Stack>
        </Col>
        <Visible when={['tera', 'giga']}>
          <Col size={{ giga: 6 }} offset={{ giga: 1 }}>
            {selectedTabId === 'person' && (
              <S.TitleWrapper>
                <Text isBold>
                  Faça seu cadastro e transforme as suas compras em{' '}
                  <Text as="span" isBold color="v500">
                    pontos
                  </Text>{' '}
                  e benefícios.
                </Text>
              </S.TitleWrapper>
            )}
            <S.ImageWrapper>
              <img
                src={`${assetHost}assets/images/signup/background.svg`}
                alt="Uma mulher sentada ao lado de seu cachorro dentro de uma casa"
              />
            </S.ImageWrapper>
          </Col>
        </Visible>
      </Row>
    </Content>
  )
}
