import { Button, Dialog } from '@backyard-ui/core'

type props = {
  showFailedAddToCart: boolean
  setShowFailedAddToCart: (status: boolean) => void
}

export const DialogFailedAddToCart = ({
  setShowFailedAddToCart,
  showFailedAddToCart
}: props) => {
  const handleDialog = (prev: boolean) => setShowFailedAddToCart(prev)

  return (
    <Dialog.Root isOpen={showFailedAddToCart} onOpenChange={handleDialog}>
      <Dialog.Content>
        <Dialog.CloseButton />
        <Dialog.Title>Parece que houve um problema inesperado</Dialog.Title>
        <Dialog.Description>
          Tente novamente ou entre em contato com a Central de Atendimento
        </Dialog.Description>

        <Dialog.Footer>
          <Dialog.Close>
            <Button>Entendi</Button>
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  )
}
