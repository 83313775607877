import { removeEmptyValues } from '../../utils'

export type BannerAttributesParams = {
  id?: string
  elementType?: 'content' | 'promotion'
  type: string
  name: string
  index: number
  listName?: string
}

type ContentBannerGTMDataAttributes = {
  'data-gtm-content-name': string
  'data-gtm-content-type': string
}

type PromotionBannerGTMDataAttributes = {
  'data-gtm-promotion-department-id'?: string
  'data-gtm-promotion-name': string
  'data-gtm-promotion-position': number
  'data-gtm-promotion-type': string
  'data-gtm-promotion-list-name'?: string
}

const generateContentBanner = (
  params: BannerAttributesParams
): ContentBannerGTMDataAttributes => ({
  'data-gtm-content-name': params.name,
  'data-gtm-content-type': params.type
})

const generatePromotionBanner = (
  params: BannerAttributesParams
): PromotionBannerGTMDataAttributes => {
  const dataAttributes = {
    'data-gtm-promotion-department-id': params.id,
    'data-gtm-promotion-name': params.name,
    'data-gtm-promotion-position': params.index + 1,
    'data-gtm-promotion-type': params.type,
    'data-gtm-promotion-list-name': params.listName
  }

  return removeEmptyValues(dataAttributes)
}

const generateByElementType = {
  content: generateContentBanner,
  promotion: generatePromotionBanner
}

export const bannerGenerator = (params: BannerAttributesParams) => {
  const elementType = params.elementType || 'promotion'

  return generateByElementType[elementType](params)
}
