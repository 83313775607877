import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import SalesImpression from 'site/utils/dataLayer/Sales/SalesImpression'

import { useDataLayerPush } from 'utils/hooks/use-data-layer-push'

import { Widget } from '../components/Widget'
import Banner from '../components/Banner'
import bannerPropTypes from '../prop-types/banner'

const StandardBanners = ({
  title,
  banners,
  bannersCount,
  type,
  full = false,
  noTopSpacing = false,
  noBottomSpacing = false,
  hiddenMobile = false
}) => {
  const [sideColors, setSideColors] = useState({ left: '', right: '' })

  const dataLayerOptions = {
    callback: () =>
      new SalesImpression(banners, type).sendImpressionToDataLayer()
  }

  useDataLayerPush(dataLayerOptions)

  useEffect(() => {
    if (banners.length && !isEmpty(banners[0]?.pictures)) {
      setSideColors(banners[0].pictures[type].sideBackgroundColors)
    }
  }, [banners, type])

  if (!banners.length || isEmpty(banners[0]?.pictures)) {
    return null
  }

  return (
    <Widget
      title={full ? '' : title}
      noTopSpacing={noTopSpacing}
      noBottomSpacing={noBottomSpacing}
      full={full}
      sideBackgroundColors={full ? sideColors : null}
      hiddenMobile={hiddenMobile}
    >
      <div className="offers-widget-banners">
        {banners.slice(0, bannersCount).map((banner, index) => (
          <Banner
            index={index}
            position={index + 1}
            key={`banner-${banner.id}`}
            parentWidgetType={type}
            widgetTitle={title}
            {...banner}
          />
        ))}
      </div>
    </Widget>
  )
}

StandardBanners.propTypes = {
  full: PropTypes.bool,
  title: PropTypes.string,
  banners: PropTypes.arrayOf(PropTypes.shape(bannerPropTypes)).isRequired,
  bannersCount: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  noTopSpacing: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  noBottomSpacing: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  hiddenMobile: PropTypes.bool
}

export default StandardBanners
