import { Fragment } from 'react'

import Tags from './Tags'
import { StockContent } from './StockContent'
import QuantityControls from './QuantityControls'
import { StockProps } from '../../../NewProductThumb/NewProductThumb.types'

interface AssistedSaleContentManagerProps {
  isMobileContext?: boolean
  unit: string
  id: number
  assistedSale?: {
    tags: string[]
    stock: StockProps
  }
  price?: {
    to: {
      integers: string
      decimals: string
    }
  }
  shortName: string
  picture: string
}

const AssistedSaleContentManager = ({
  isMobileContext,
  assistedSale,
  unit,
  id,
  price,
  shortName,
  picture
}: AssistedSaleContentManagerProps) => {
  if (!assistedSale) {
    return null
  }

  const { tags, stock } = assistedSale

  const stockProps = {
    stock,
    unit,
    productDescription: shortName,
    urlImage: picture
  }

  const quantityProps = { lm: id, price }

  const renderDesktopContext = () => (
    <div className="assisted-sale-content">
      <Tags tags={tags} />
      <StockContent {...stockProps} id={id} />
      <QuantityControls {...quantityProps} />
    </div>
  )

  const renderMobileContext = () => (
    <Fragment>
      <StockContent {...stockProps} id={id} />
      <QuantityControls {...quantityProps} />
    </Fragment>
  )

  return (
    <Fragment>
      {isMobileContext ? renderMobileContext() : renderDesktopContext()}
    </Fragment>
  )
}

export default AssistedSaleContentManager
