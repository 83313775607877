import type { PRODUCT_DISCOUNT_TYPE } from 'newCheckout/dataLayer/transaction'
import type {
  DatalayerProduct,
  DeliveryAddress,
  Service,
  Shippings
} from 'newCheckout/pages/Success/type'

import { getItemDiscount } from '../../utils'
import { DATA_LAYER_DISCOUNT_TYPE, PurchaseItem } from './types'

const getDiscountType = (type: PRODUCT_DISCOUNT_TYPE) => {
  const dataLayerDiscountType: Record<string, DATA_LAYER_DISCOUNT_TYPE> = {
    stampedPrice: 'stamped',
    employee: 'employee',
    loyaltyProgram: 'loyalty',
    'payment-method': 'by-payment-method',
    promo: 'coupon',
    '': undefined
  }

  return dataLayerDiscountType[type]
}

const getPurchaseProducts = (
  address: DeliveryAddress,
  dataLayerProducts: DatalayerProduct[],
  shippings: Shippings
): PurchaseItem[] => {
  const purchaseProducts: PurchaseItem[] = []

  shippings.map(shipping => {
    const isDelivery = shipping.type === 'delivery'

    shipping.items.map(item => {
      const dataLayerItem = dataLayerProducts.find(
        product => product.id === item.product_id
      )

      const category = dataLayerItem?.category
        ? dataLayerItem.category.split('/')
        : []

      const isBox = item.packaging !== 1

      purchaseProducts.push({
        id: item.product_id,
        bind_id: undefined,
        name: item.product.name,
        type: 'product',
        price: item.toPrice,
        price_box: isBox ? item.toPrice * item.packaging : null,
        quantity_box: isBox ? Math.round(item.quantity / item.packaging) : null,
        box_unit: isBox ? item.packaging : null,
        discount: item.discountPrice ? item.discountPrice : undefined,
        discount_type: item.discountType
          ? getDiscountType(item.discountType)
          : undefined,
        brand: dataLayerItem ? dataLayerItem.brand : undefined,
        category: category.length ? category[0] : undefined,
        category2: category.length ? category[1] : undefined,
        category3: category.length ? category[2] : undefined,
        category4: category.length ? category[3] : undefined,
        category5: category.length ? category[4] : undefined,
        variant: undefined,
        seller: dataLayerItem ? dataLayerItem.shopName : undefined,
        delivery_type: isDelivery ? 'delivery' : 'pickup-in-store',
        delivery_time: isDelivery ? shipping.time : undefined,
        delivery_city: isDelivery ? address.city : undefined,
        delivery_state_code: isDelivery ? address.state : undefined,
        delivery_cep: isDelivery ? address.cep : undefined,
        pickup_store: isDelivery ? undefined : shipping.storeName,
        pickup_available_time: isDelivery ? undefined : shipping.time,
        quantity: item.quantity
      })
    })
  })

  return purchaseProducts
}

const getPurchaseServices = (
  address: DeliveryAddress,
  services: Service[]
): PurchaseItem[] => {
  const purchaseServices: PurchaseItem[] = []

  services.map(service => {
    const hasDiscount = service.price.from > service.price.to

    purchaseServices.push({
      id: service.serviceId,
      bind_id: service.relatedProduct.productId,
      name: service.name,
      type: 'service',
      price: service.price.to,
      price_box: null,
      quantity_box: null,
      box_unit: null,
      discount: hasDiscount
        ? getItemDiscount(service.price.to, service.price.from)
        : undefined,
      discount_type: undefined,
      brand: 'Leroy Merlin',
      category: 'serviço',
      category2: undefined,
      category3: undefined,
      category4: undefined,
      category5: undefined,
      variant: undefined,
      seller: 'Leroy Merlin',
      delivery_type: undefined,
      delivery_time: undefined,
      delivery_city: address.city,
      delivery_state_code: address.state,
      delivery_cep: address.cep,
      pickup_store: undefined,
      pickup_available_time: undefined,
      quantity: service.quantity
    })
  })

  return purchaseServices
}

export type GetPurchaseItemsProps = {
  address: DeliveryAddress
  dataLayerProducts: DatalayerProduct[]
  shippings: Shippings
  services: Service[]
}

export const getPurchaseItems = ({
  address,
  dataLayerProducts,
  shippings,
  services
}: GetPurchaseItemsProps) => {
  const purchaseProducts = getPurchaseProducts(
    address,
    dataLayerProducts,
    shippings
  )
  const purchaseServices = getPurchaseServices(address, services)

  return [...purchaseProducts, ...purchaseServices]
}
