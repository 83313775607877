import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgPurchaseTagOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M11.383 2.076q.183.076.324.217l10 10a1 1 0 0 1 0 1.414l-8 8a1 1 0 0 1-1.414 0l-10-10A1 1 0 0 1 2 11V6a1 1 0 0 1 .293-.707l3-3A1 1 0 0 1 6 2h5q.198 0 .383.076M4 10.586l9 9L19.586 13l-9-9H6.414L4 6.414zm6-2.233a1.647 1.647 0 1 1-3.294 0 1.647 1.647 0 0 1 3.294 0"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgPurchaseTagOutline)
const Memo = memo(ForwardRef)
export default Memo
