import { createSlice } from '@reduxjs/toolkit'

import { ReduxMessagesType } from '../types'

const initialState: ReduxMessagesType = {
  data: {
    list: []
  }
}

export const messagesSlice = createSlice({
  name: 'Messages',
  initialState,
  reducers: {
    setMessages: (state, action) => {
      state.data.list.push(action.payload)
    },
    updateTernarySelected: (state, action) => {
      state.data.list[state.data.list.length - 1] = {
        ...state.data.list[state.data.list.length - 1],
        ternarySelected: action.payload
      }
    },
    resetMessages: state => {
      state.data.list = []
    }
  }
})

export const { setMessages, resetMessages, updateTernarySelected } =
  messagesSlice.actions

export default messagesSlice.reducer
