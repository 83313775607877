import { TimesCircleOutline } from '@backyard-ui/icons'
import { Icon } from '@leroy-merlin-br/backyard-react'

import * as S from './styled'

interface ErrorMessageProps {
  message: string
}

export default function ErrorMessage({ message }: ErrorMessageProps) {
  return (
    <S.Card>
      <Icon size="mega" color="critical" as={TimesCircleOutline} />

      <S.ContentInfo>
        <p className="bold">
          A Nota Fiscal enviada não foi aprovada, entenda os motivos:
        </p>

        <p>
          <ul>
            <li>{message}</li>
          </ul>
        </p>

        <p>
          Retorne aqui quando a nota for emitida corretamente para anexá-la.
        </p>
      </S.ContentInfo>
    </S.Card>
  )
}
