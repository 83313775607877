import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgStoreLeroyOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M11.445 3.168a1 1 0 0 1 1.11 0l9 6A1 1 0 0 1 22 10v9a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-9a1 1 0 0 1 .445-.832zM4 11h16v8h-3v-3a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v3H4zm5 8h6v-3H9zm8.697-10H6.303L12 5.202z"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgStoreLeroyOutline)
const Memo = memo(ForwardRef)
export default Memo
