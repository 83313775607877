import styled from '@emotion/styled'
import { css } from '@emotion/react'

const DescriptionContainerStyles = () => css`
  background-color: #f4f1eb;
  padding: 0 var(--desktop-space-inset-xs);
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media (min-width: 968px) {
    padding: 0 var(--desktop-space-inset-xl);
  }
`

export const SkeletonDescriptionContainer = styled('div')(
  DescriptionContainerStyles
)

const messageContainerStyles = () => css`
  width: 100%;
  display: flex;
  gap: 12px;

  & > div:first-of-type > div {
    background-color: #dedad2;
  }
`

export const MessageContainer = styled('div')(messageContainerStyles)

const messageStyles = ({ alignRight }: { alignRight: boolean }) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
  align-items: ${alignRight ? 'end' : 'start'};

  & > div {
    background-color: #dedad2;
  }
`

export const Message = styled('div')(messageStyles)

const titleContainerStyles = () => css`
  background-color: #f4f1eb;
  padding: var(--desktop-space-inset-xl) var(--desktop-space-inset-xs) 0;
  width: 100%;
  height: 100%;

  @media (min-width: 968px) {
    padding: var(--desktop-space-inset-xl) var(--desktop-space-inset-xl) 0;
  }
`

export const SkeletonTitleContainer = styled('div')(titleContainerStyles)

const titleTextContainerStyles = () => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > div {
    background-color: #dedad2;
  }
`

export const TitleTextContainer = styled('div')(titleTextContainerStyles)

const footerContainerStyles = () => css`
  background-color: #f4f1eb;
  padding: 16px;
  width: 100%;
  height: 100%;
`

export const SkeletonFooterContainer = styled('div')(footerContainerStyles)

const footerTypeContainerStyles = () => css`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;

  & > div {
    background-color: #dedad2;
  }
`

export const FooterTypeContainer = styled('div')(footerTypeContainerStyles)
