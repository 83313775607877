import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgPackageOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        d="M22 8q.015-.105 0-.21v-.08a.8.8 0 0 0-.07-.16.4.4 0 0 0-.05-.08l-.1-.13-.08-.06-.12-.09-9-5a1 1 0 0 0-1 0l-9 5-.09.07-.11.08a.4.4 0 0 0-.07.11.4.4 0 0 0-.08.1.6.6 0 0 0-.06.14.3.3 0 0 0 0 .1A.8.8 0 0 0 2 8v8a1 1 0 0 0 .52.87l9 5a1 1 0 0 0 .13.06h.1c.164.04.336.04.5 0h.1l.14-.06 9-5A1 1 0 0 0 22 16zm-10 3.87L5.06 8l2.76-1.52 6.83 3.9zm0-7.72L18.94 8 16.7 9.25 9.87 5.34zM4 9.7l7 3.92v5.68l-7-3.89zm9 9.6v-5.68l3-1.68V15l2-1v-3.18l2-1.11v5.7z"
        fill={color ? Colors[color] : 'currentColor'}
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgPackageOutline)
const Memo = memo(ForwardRef)
export default Memo
