import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgWindOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M9.5 2C11.43 2 13 3.57 13 5.5S11.43 9 9.5 9H2V7h7.5c.827 0 1.5-.673 1.5-1.5S10.327 4 9.5 4c-.862 0-1.25.585-1.25 1h-2c0-1.475 1.216-3 3.25-3M8 15h7.5c1.93 0 3.5 1.57 3.5 3.5S17.43 22 15.5 22c-2.034 0-3.25-1.525-3.25-3h2c0 .415.388 1 1.25 1 .827 0 1.5-.673 1.5-1.5s-.673-1.5-1.5-1.5H8zm6-6c0-2.206 1.794-4 4-4s4 1.794 4 4-1.794 4-4 4H2v-2h16c1.103 0 2-.897 2-2s-.897-2-2-2-2 .897-2 2zm-8 6H2v2h4z"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgWindOutline)
const Memo = memo(ForwardRef)
export default Memo
