import { Fragment, useState } from 'react'
import { Flex, Spacer, Spinner } from '@backyard-ui/core'

import { useUserResources } from 'scripts/react-components/shared/hooks'
import emitter from 'scripts/utils/emitter'

import { getStockInfo } from 'shared/components/NewProductThumb/components/assisted-sale/ModalStock/services'
import { ModalStock } from 'shared/components/NewProductThumb/components/assisted-sale/ModalStock'
import { CustomError } from 'shared/components/ProductThumb/components/assisted-sale/StockContent/StockContent'

import { Button as TrackedClickButton } from 'site/Analytics'

interface StockOmsProps {
  productName: string
  productPicture: string
  lm: number
  isLocateOnStoreEnabled: boolean
}

const StockOms = (props: StockOmsProps) => {
  const { productName, productPicture, lm, isLocateOnStoreEnabled } = props
  const [isLoading, setIsLoading] = useState(false)

  const [modalStockInfo, setModalStockInfo] = useState(null)
  const [modalStockOpen, setModalStockOpen] = useState(false)

  const { userResources } = useUserResources()

  const showDrawerStockInfo = async () => {
    try {
      setIsLoading(true)

      const response = await getStockInfo({ productId: lm })

      setModalStockInfo(response)
    } catch (error) {
      const typedError = error as CustomError

      emitter.emit('stickyFeedback:error', {
        title: 'Ops! Ocorreu o seguinte erro:',
        content:
          typedError?.data?.errors?.[0]?.message ||
          typedError?.data?.errors?.[0]
      })
    } finally {
      setIsLoading(false)
    }

    setModalStockOpen(!modalStockOpen)
  }

  return (
    <Fragment>
      <TrackedClickButton
        className="button-hollow button-hollow-primary"
        data-stock-info
        onClick={showDrawerStockInfo}
        eventCategory="PagProduto"
        eventAction={`viu_estoque_${userResources?.region}`}
        eventLabel="estoque"
        eventValue={lm}
        data-testid="stock-info-button">
        <Flex align="center" justify="center">
          {isLocateOnStoreEnabled
            ? 'Estoque e Localização na Loja'
            : 'Estoque na Loja física'}
          <Spacer size={2} />
          {isLoading && <Spinner size="sm" data-testid="loading-modal" />}
        </Flex>
      </TrackedClickButton>

      {modalStockOpen && modalStockInfo && (
        <ModalStock
          isOpen={modalStockOpen}
          onClose={setModalStockOpen}
          productDescription={productName}
          urlImage={productPicture}
          stock={modalStockInfo}
          data-testid="modal-stock"
          productId={lm}
        />
      )}
    </Fragment>
  )
}

export default StockOms
