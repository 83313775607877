import { FunctionComponent, useState, useEffect } from 'react'
import { Button, Text, Tooltip, Stack } from '@backyard-ui/core'

import emitter from 'scripts/utils/emitter'
import tryAddToCart from 'scripts/utils/cart/addToCart'
import addServiceToCart from 'scripts/utils/cart/addServiceToCart'

import { VoltageDialog } from './components'
import { DeliveryTypes, Props, RenderButtonProps } from './types'
import styles from './PurchaseButtonsSection.styles'

const PurchaseButtonsSection: FunctionComponent<Props> = ({
  purchaseButtons,
  productVoltage,
  productId,
  regionId,
  isServiceOnEcommerce,
  defaultShopOfferId,
  voltageModalBlacklist
}) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedOption, setSelectedOption] =
    useState<DeliveryTypes>('delivery')
  const [shopOfferId, setShopOfferId] = useState(defaultShopOfferId)
  const [isUnavailable, setIsUnavailable] = useState(false)

  const { pickupInStore, ecommerce } = purchaseButtons

  const [
    purchaseButtonPickupInStoreDisabled,
    setPurchaseButtonPickupInStoreDisabled
  ] = useState(!pickupInStore?.enabled)
  const [purchaseButtonEcommerceDisabled, setPurchaseButtonEcommerceDisabled] =
    useState(!ecommerce?.enabled)

  const shouldShowEcommerceButton = ecommerce?.visible
  const shouldShowPickupInStoreButton =
    pickupInStore?.visible && !isServiceOnEcommerce
  const shouldShowOrSpan =
    ecommerce?.visible && shouldShowPickupInStoreButton && !isServiceOnEcommerce

  useEffect(() => {
    emitter.on(
      'buybox:sellerChange',
      ({
        shopOfferId,
        purchaseButtonPickupInStoreDisabled,
        purchaseButtonEcommerceDisabled
      }) => {
        setShopOfferId(shopOfferId)

        setPurchaseButtonPickupInStoreDisabled(
          purchaseButtonPickupInStoreDisabled === 'true'
        )
        setPurchaseButtonEcommerceDisabled(
          purchaseButtonEcommerceDisabled === 'true'
        )
      }
    )
  }, [])

  useEffect(() => {
    emitter.on('pdp:seller:unavailable', setIsUnavailable)

    return () => {
      emitter.off('pdp:seller:unavailable', setIsUnavailable)
    }
  }, [])

  const addDeliveryProductToCart = () => {
    if (isServiceOnEcommerce) {
      const serviceData = {
        override: true,
        services: [
          {
            id: productId,
            skuId: null,
            quantity: 1
          }
        ]
      }

      addServiceToCart(serviceData)
    } else {
      const productData = {
        regionId: regionId,
        type: 'delivery',
        products: [
          {
            id: productId,
            quantity: 1,
            skuId: shopOfferId || undefined
          }
        ],
        override: false
      }

      tryAddToCart(productData)
    }
  }

  const showStockInfo = () => {
    emitter.emit('showStock')
  }

  const submit = (option: DeliveryTypes) => {
    if (option === 'delivery') {
      return addDeliveryProductToCart()
    }

    if (option === 'pickup') {
      return showStockInfo()
    }
  }

  const handleButtonClick = (option: DeliveryTypes) => {
    const shouldShowVoltageModal =
      !voltageModalBlacklist?.some(
        (element) =>
          element?.trim().toLowerCase() === productVoltage?.trim().toLowerCase()
      ) && productVoltage

    if (shouldShowVoltageModal) {
      setSelectedOption(option)
      setDialogOpen(true)
    } else {
      submit(option)
    }
  }

  const renderButton = ({ render, tooltip }: RenderButtonProps) => {
    if (!tooltip) {
      return <div className="w-full">{render()}</div>
    }

    return (
      <div className="w-full">
        <Tooltip.Provider>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>{render()}</Tooltip.Trigger>
            <Tooltip.Content>{tooltip}</Tooltip.Content>
          </Tooltip.Root>
        </Tooltip.Provider>
      </div>
    )
  }

  return (
    <>
      <VoltageDialog
        dialogOpen={dialogOpen}
        productVoltage={productVoltage}
        selectedOption={selectedOption}
        setDialogOpen={setDialogOpen}
        submit={submit}
      />

      <Stack align="center" spacing={2}>
        {shouldShowEcommerceButton &&
          renderButton({
            render: () => (
              <Button
                size="md"
                isFullWidth
                data-cy="ecommerce-button"
                isDisabled={purchaseButtonEcommerceDisabled || isUnavailable}
                onClick={() => handleButtonClick('delivery')}
              >
                <Text size="md" color="white" weight="bold">
                  {isServiceOnEcommerce ? 'Contratar' : 'Comprar e receber'}
                </Text>
              </Button>
            ),
            tooltip: ecommerce.tooltip
          })}

        {shouldShowOrSpan && (
          <div className={styles()}>
            <Text size="md" color="gray-600" weight="bold" asChild>
              <span>ou</span>
            </Text>
          </div>
        )}

        {shouldShowPickupInStoreButton &&
          renderButton({
            render: () => (
              <Button
                size="md"
                appearance="info"
                isFullWidth
                data-cy="pickup-button"
                isDisabled={
                  purchaseButtonPickupInStoreDisabled || isUnavailable
                }
                onClick={() => handleButtonClick('pickup')}
              >
                <Text size="md" color="white" weight="bold">
                  Comprar e retirar
                </Text>
              </Button>
            ),
            tooltip: pickupInStore.tooltip
          })}
      </Stack>
    </>
  )
}

export default PurchaseButtonsSection
