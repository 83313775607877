import { Icon } from '@backyard-ui/core'
import styled from '@emotion/styled'
import {
  MessageOutline,
  ChevronLeftOutline
} from 'frontendMyOrders/user-interface/icons/components'
import {
  Heading,
  UserInterface
} from 'frontendMyOrders/user-interface/typography'
import { memo } from 'react'

type HeaderProps = {
  shopName: string
  isOnMobileApp?: boolean
  isMobile: boolean
}

const headerWrapperStyled = styled.div`
  height: 48px;
  display: flex;
  flex-direction: column;
  gap: var(--desktop-space-inset-2xs);
`
const HeaderWrapper = headerWrapperStyled.withComponent('div')

const textWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const TextWrapper = textWrapperStyled.withComponent('div')

const iconWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  gap: var(--desktop-space-inset-xs);
`
const IconWrapper = iconWrapperStyled.withComponent('div')

const Header = memo(({ shopName, isOnMobileApp, isMobile }: HeaderProps) => {
  const redirectToMyOrders = () => {
    window.location.href = '/meus-pedidos'
  }

  return (
    <HeaderWrapper data-testid="chat-header">
      <TextWrapper>
        <IconWrapper>
          {(isOnMobileApp || isMobile) && (
            <Icon size="lg" data-testid="redirect-chat-icon">
              <ChevronLeftOutline onClick={redirectToMyOrders} />
            </Icon>
          )}

          <Icon size="lg" data-testid="message-chat-icon">
            <MessageOutline color="icon-semantic-accent" />
          </Icon>

          <Heading size="xs" appearance="neutral-strong">
            Conversa com vendedor
          </Heading>
        </IconWrapper>
      </TextWrapper>

      <UserInterface appearance="neutral-soft" size="sm">
        Vendido e entregue por <b>{shopName}</b>
      </UserInterface>
    </HeaderWrapper>
  )
})

Header.displayName = 'header'

export default Header
