import { ChangeEvent, FC } from 'react'
import { Select, Text } from '@leroy-merlin-br/backyard-react'
import { Popover } from 'user/components'

import * as S from '../styled'
import { SelectData } from '../types'

interface OccupationFieldProps {
  disabled: boolean
  occupations: SelectData[]
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void
  value: string
}

const OccupationField: FC<OccupationFieldProps> = ({
  disabled,
  occupations,
  onChange,
  value
}) => {
  return (
    <S.Field>
      <S.SelectWrapper>
        <Select
          disabled={disabled}
          label={
            <S.Label>
              <Popover
                list={{
                  title: 'Vantagens',
                  items: [
                    'Profissionais da construção possuem vantagens exclusivas no Programa de Fidelidade'
                  ]
                }}
                placement="bottom">
                <Text color="n800" size="mega" isBold noMargin>
                  Qual sua profissão?
                </Text>
              </Popover>
            </S.Label>
          }
          options={occupations}
          onChange={onChange}
          placeholder="Selecione sua profissão"
          value={value}
        />
      </S.SelectWrapper>
    </S.Field>
  )
}

export default OccupationField
