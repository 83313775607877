import { tv } from 'tailwind-variants'

export default tv({
  base: `
    h-8
    px-6
    
    flex
    justify-between
    items-center
    
    border-b
    border-gray-200
      
    last:border-0
  `,
  variants: {
    color: {
      warning100: 'bg-orange-100',
      success100: 'bg-green-100',
      default: 'bg-white'
    }
  },
  defaultVariants: {
    color: 'default'
  }
})
