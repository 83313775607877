import { SoldOutProductMessage } from './components/SoldOutProductMessage'
import * as S from './styled'

interface PriceTagProps {
  price: {
    to: {
      integers: string
      decimals: string
    }
    from: {
      integers: string
      decimals: string
    }
    suggested?: {
      integers: string
      decimals: string
    }
  }
  unit: string
  installments: {
    amount: number
    value: string
    totalPrice?: string
  }
  url: string
  name?: string
  paymentMethodsDiscount: string
  isSoldOut: boolean
  isOutlet: boolean
  isPromotional: boolean
  isDiscountByPaymentMethod: boolean
  featureInstallmentDiscount?: boolean
  isDiscountByTwoPrice: boolean
}

const PriceTag = ({
  isSoldOut,
  isOutlet,
  isPromotional,
  isDiscountByPaymentMethod,
  price,
  unit,
  paymentMethodsDiscount,
  installments,
  url,
  name,
  featureInstallmentDiscount,
  isDiscountByTwoPrice
}: PriceTagProps) => {
  const { to, from, suggested } = price
  const hasPrice = !!to
  const hasInstallments = Boolean(installments)
  const hasSuggestedPrice = Boolean(
    (isDiscountByPaymentMethod || isDiscountByTwoPrice) && suggested
  )

  const renderPromotional = () => {
    if (!isPromotional || featureInstallmentDiscount) {
      return null
    }
    let priceText = `R$ ${from.integers},${from.decimals} ${unit}`
    if (hasSuggestedPrice) {
      priceText = `R$ ${suggested?.integers},${suggested?.decimals} ${unit}`
    }

    return (
      <S.AdditionalPriceInfo
        isPromotional={isPromotional}
        data-testid="promotional-price-info"
        isOutlet={isOutlet}>
        {priceText}
      </S.AdditionalPriceInfo>
    )
  }

  const renderPaymentMethods = () => {
    if (!isDiscountByPaymentMethod && !isDiscountByTwoPrice) {
      return null
    }

    return (
      <S.AdditionalPaymentDiscountPriceInfo
        isOutlet={isOutlet}
        data-testid="payment-method-discount">
        {paymentMethodsDiscount}
      </S.AdditionalPaymentDiscountPriceInfo>
    )
  }

  const renderInstallments = () => {
    if (!hasInstallments) {
      return null
    }

    const totalPriceInstallments =
      isDiscountByPaymentMethod || isDiscountByTwoPrice
        ? `R$ ${installments.totalPrice} em até`
        : ''

    return (
      <S.AdditionalPriceInfo data-testid="additional-price-info">
        {totalPriceInstallments} {installments.amount}x de R${' '}
        {installments.value} s/juros
      </S.AdditionalPriceInfo>
    )
  }

  if (isSoldOut) {
    return (
      <div>
        <SoldOutProductMessage />
      </div>
    )
  }

  if (hasPrice) {
    return (
      <a href={url} title={name}>
        <S.PriceTag
          isDiscountByPaymentMethod={
            isDiscountByPaymentMethod || isDiscountByTwoPrice
          }
          isPromotional={isPromotional}
          isOutlet={isOutlet}
          featureInstallmentDiscount={featureInstallmentDiscount}>
          {renderPromotional()}
          <S.PriceContent
            isPromotional={isPromotional}
            hasInstallments={hasInstallments}>
            <S.Price>
              R$ {to.integers},{to.decimals}
            </S.Price>
            <S.PriceUnit>{unit}</S.PriceUnit>
          </S.PriceContent>
          {renderPaymentMethods()}
          {renderInstallments()}
        </S.PriceTag>
      </a>
    )
  }

  return (
    <div>
      <SoldOutProductMessage />
    </div>
  )
}

export default PriceTag
