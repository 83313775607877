import { useCallback, useEffect } from 'react'

import { useGTMShipping } from 'shared/tracker/hooks'
import type { Shipping } from 'shared/tracker/types'

import { pushAddShippingInfo } from '../../events/add-shipping-info'
import { getOnlyProducts } from '../utils'
import type { ServiceObject } from '../types'

export type GTMShippingPageProps = {
  services: ServiceObject[]
  totalDiscount: number
}

type ZipCodeChangeData = {
  shippings: Shipping[]
  zipCode: string
}

const GTMShippingPage = ({ services, totalDiscount }: GTMShippingPageProps) => {
  const { handleShippingsChange } = useGTMShipping()

  const handleZipCodeChange = useCallback(
    ({ detail }) => {
      const { shippings, zipCode }: ZipCodeChangeData = detail

      handleShippingsChange({
        pageType: 'shipping',
        shippings,
        zipCode
      })
    },
    [handleShippingsChange]
  )

  const handleSubmit = useCallback(
    ({ detail }) => {
      const { items, cartValue, freightCost } = detail

      const onlyProducts = getOnlyProducts(items, services)
      const subTotal = parseFloat(cartValue.toFixed(2))

      pushAddShippingInfo({
        products: onlyProducts,
        services,
        cart: {
          value: subTotal,
          discount: totalDiscount
        },
        freightCost: parseFloat(freightCost)
      })
    },
    [services, totalDiscount]
  )

  useEffect(() => {
    document.addEventListener('shipping:zipcode:change', handleZipCodeChange)

    return () => {
      document.removeEventListener(
        'shipping:zipcode:change',
        handleZipCodeChange
      )
    }
  }, [handleZipCodeChange])

  useEffect(() => {
    document.addEventListener('shipping:submit', handleSubmit)

    return () => {
      document.removeEventListener('shipping:submit', handleSubmit)
    }
  }, [handleSubmit])

  return null
}

export default GTMShippingPage
