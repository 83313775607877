import React from 'react'
import styled from '@emotion/styled'
import { UserInterface } from 'frontendMyOrders/user-interface/typography'

interface SelectOptionProps {
  label: string
  isSelected: boolean
  onSelect: () => void
  isFirst?: boolean
  isLast?: boolean
  isDisabled?: boolean
}

const OptionWrapper = styled.div<{ isSelected: boolean }>`
  padding: var(--desktop-space-inset-sm) var(--desktop-space-inset-md);
  background-color: ${({ isSelected }: SelectOptionProps) =>
    isSelected
      ? 'var(--color-surface-accent-soft)'
      : 'var(--color-surface-neutral-default)'};
  border: 1px solid var(--color-border-neutral-interactive);
  border-top-left-radius: ${({ isFirst }: SelectOptionProps) =>
    isFirst ? 'var(--border-radius-lg)' : '0'};
  border-top-right-radius: ${({ isFirst }: SelectOptionProps) =>
    isFirst ? 'var(--border-radius-lg)' : '0'};
  border-bottom-left-radius: ${({ isLast }: SelectOptionProps) =>
    isLast ? 'var(--border-radius-lg)' : '0'};
  border-bottom-right-radius: ${({ isLast }: SelectOptionProps) =>
    isLast ? 'var(--border-radius-lg)' : '0'};
  border-bottom-width: ${({ isLast }: SelectOptionProps) =>
    isLast ? '1px' : '0'};
  color: ${({ isSelected }: SelectOptionProps) =>
    isSelected
      ? 'var(--color-surface-accent-strong)'
      : 'var(--color-text-neutral-default)'};
  cursor: pointer;
  pointer-events: ${({ isDisabled }: SelectOptionProps) =>
    isDisabled ? 'none' : 'auto'};

  transition:
    background-color 0.3s ease,
    color 0.3s ease;

  &:hover {
    background-color: ${({ isSelected }: SelectOptionProps) =>
      isSelected
        ? 'var(--color-surface-accent-soft)'
        : 'var(--color-surface-neutral-stronger)'};
  }
`

const SelectOption: React.FC<SelectOptionProps> = ({
  label,
  isSelected,
  onSelect,
  isFirst,
  isLast,
  isDisabled
}) => {
  return (
    <OptionWrapper
      isSelected={isSelected}
      onClick={!isDisabled ? onSelect : undefined}
      isFirst={isFirst}
      isLast={isLast}
      isDisabled={isDisabled}>
      <UserInterface
        appearance={isSelected ? 'accent-strong' : 'neutral-default'}
        type={isSelected ? 'strong' : 'default'}
        size="sm">
        {label}
      </UserInterface>
    </OptionWrapper>
  )
}

export default SelectOption
