import { InvoicesType } from 'frontendMyOrders/types'
import { formatFullAddress } from 'frontendMyOrders/utils/formatters'

import { resetMessages, setMessages } from '../redux/messages'
import { setOptions } from '../redux/options'
import { setCanSendMessage, setProperties } from '../redux/properties'
import { useAppDispatch, useAppSelector } from '../redux/store'
import {
  ApiThreadResponseType,
  ShippingAddressType,
  StepsType,
  ThreadsType
} from '../types'
import useMarketplaceChatService from './useMarketplaceChatService'

export type CreateLayoutProps = {
  threadId?: string
  orderId: string
  marketplaceOrder: string
  shopName: string
  steps?: StepsType
  threads?: ThreadsType
  shippingAddress?: ShippingAddressType
  deliveryDate?: {
    date: string
    message: string | null
  }
  trackingCode?: string
  trackingUrl?: string
  invoices?: InvoicesType
  userName?: string
  userEmail?: string
  userPhone?: string
  isOnMobileApp?: boolean
}

function useCreateLayout() {
  const marketplaceChatService = useMarketplaceChatService()
  const dispatch = useAppDispatch()
  const { properties, options } = useAppSelector(state => state)

  const initializeChatProperties = async ({
    orderId,
    marketplaceOrder,
    shopName,
    steps = [],
    shippingAddress,
    deliveryDate,
    trackingCode,
    trackingUrl,
    invoices,
    userName,
    userEmail,
    userPhone,
    threads,
    threadId,
    isOnMobileApp
  }: CreateLayoutProps) => {
    const data = threadId
      ? await marketplaceChatService.fetchByThreadId({
          orderId,
          marketplaceOrder,
          threadId
        })
      : await marketplaceChatService.get({
          orderId,
          marketplaceOrder
        })

    const shippingAddressFormatted = formatFullAddress(shippingAddress)

    if (data) {
      dispatch(setOptions(data.options))
      dispatch(
        setProperties({
          orderId,
          marketplaceOrder,
          createdAt: data.createdAt,
          chatStatus: data.status,
          sellerResponseTimeInHours: data.sellerResponseTimeInHours,
          threadId: data.threadId,
          ticket: data.ticket,
          option: data?.option,
          subOption: data?.subOption,
          userName,
          shopName,
          steps,
          threads,
          shippingAddressFormatted,
          shippingAddress,
          deliveryDate,
          trackingCode,
          trackingUrl,
          invoices,
          userResourcesEmail: userEmail,
          userResourcesPhone: userPhone,
          apiChat: data.chat,
          isOnMobileApp,
          type: data.type
        })
      )
    }

    return data
  }

  const initializeChatMessages = (
    restart = false,
    response?: ApiThreadResponseType['data'],
    appNativeParams?: { userName: string; shopName: string; createdAt: string }
  ) => {
    let { userName, shopName, createdAt } = properties.data

    if (!userName && appNativeParams) {
      userName = appNativeParams.userName
    }
    if (!shopName && appNativeParams) {
      shopName = appNativeParams.shopName
    }
    if (!createdAt && appNativeParams) {
      createdAt = appNativeParams.createdAt
    }

    if (restart) {
      dispatch(
        setProperties({
          option: undefined,
          subOption: undefined,
          contactType: '',
          contactSelected: ''
        })
      )
      dispatch(setCanSendMessage(false))
      dispatch(resetMessages())
    }

    dispatch(
      setMessages({
        text: `Olá${userName ? ', ' + userName : ''}! Aqui você consegue suporte com problemas relacionados aos produtos <strong>vendidos e entregues por ${shopName}.</strong>`,
        from: 'bot',
        time: !restart && createdAt ? createdAt : new Date().toISOString()
      })
    )
    dispatch(
      setMessages({
        text: 'Mas antes, escolha com qual dos assuntos você precisa de ajuda.',
        from: 'bot',
        time: !restart && createdAt ? createdAt : new Date().toISOString(),
        options: response?.options ? response.options : options.data
      })
    )
  }

  return { initializeChatMessages, initializeChatProperties }
}

export default useCreateLayout
