import { css } from '@emotion/react'
import styled from '@emotion/styled'

const contentStyles = ({ theme }) => css`
  label: content;

  border-bottom: 1px solid ${theme.colors.n100};
  border-radius: 0 0 ${theme.borderRadius.mega} ${theme.borderRadius.mega};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: ${theme.spacings.byte};
  margin-top: 16px;
`

const badgeStyles = ({ theme }) => css`
  label: content__badge;

  border-radius: ${theme.borderRadius.mega};
  margin-right: ${theme.spacings.kilo};

  width: 60px;

  svg {
    width: 60px;
  }

  ${theme.media.mega} {
    width: 90px;

    svg {
      width: 90px;
    }
  }
`

const containerStyles = () => css`
  label: content__container;

  border-radius: 15px;
  display: flex;
`

const columnStyles = () => css`
  label: custom-column;

  display: flex;
  flex-direction: column;
  width: 100%;
`

const serviceTitleWrapperStyles = ({ theme }) => css`
  label: content__service-title;

  background-color: ${theme.colors.y400};
  border-radius: ${theme.borderRadius.tera};
  width: 136px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const productWrapperStyles = ({ theme }) => css`
  label: content__product-wrapper;

  ${theme.media.mega} {
    display: none;
  }
`

const rowStyles = ({ theme }) => css`
  label: custom-row;

  display: flex;

  button {
    display: none;

    ${theme.media.mega} {
      display: unset;
    }
  }

  ${theme.media.mega} {
    padding-top: unset;
  }
`

const unityFromSpStyles = ({ theme }) => css`
  label: services__unity-from;

  ${theme.media.mega} {
    display: none;
  }
`

const sellerNameStyles = ({ theme }) => css`
  label: service__seller-name;

  text-align: left;
  margin-bottom: ${theme.spacings.bit};
`

const footerStyles = ({ theme, isAdded }) => css`
  label: footer__description;

  display: flex;
  justify-content: space-between;
  flex-direction: ${isAdded ? 'column' : 'row'};
  align-items: flex-start;

  span {
    text-align: left;
  }

  [data-add-services] {
    ${theme.media.mega} {
      display: none;
    }
  }

  ${theme.media.mega} {
    flex-direction: row;
    justify-content: ${isAdded ? 'unset' : 'space-between'};
  }
`

const takeTogetherStyles = ({ theme }) => css`
  label: services__take-together;

  display: none;
  margin-bottom: ${theme.spacings.byte};

  ${theme.media.mega} {
    display: unset;
  }
`

const quantityWrapperStyles = ({ theme }) => css`
  label: content__quantity;

  margin-top: ${theme.spacings.kilo};

  div {
    margin-top: 0;
  }

  ${theme.media.mega} {
    margin-top: unset;
  }
`

const forProductStyles = ({ theme }) => css`
  label: services__for-product;

  display: none;

  ${theme.media.mega} {
    display: unset;
  }
`

const productDescriptionStyles = ({ theme }) => css`
  label: services__product-description;

  margin-bottom: ${theme.borderRadius.giga};
`

const descriptionStyles = ({ theme }) => css`
  label: service__description;

  display: none;

  ${theme.media.mega} {
    display: unset;
  }
`

const notificationWrapperStyles = ({ theme }) => css`
  label: notification;

  margin-top: ${theme.spacings.mega};
`

const removeServiceStyles = ({ theme }) => css`
  label: services__remove;

  align-items: center;
  background: ${theme.colors.n100};
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  font-size: 10px;
  height: 25px;
  justify-content: center;
  position: absolute;
  right: 0;
  width: 25px;
  margin-left: auto;

  & i {
    margin-bottom: 1px;
  }

  &:hover {
    background: ${theme.colors.n200};
  }
`

const opacityEnabledStyles = ({ errors }) =>
  Object.keys(errors).length &&
  css`
    label: services--enabled;

    opacity: 0.4;
  `

const serviceNameStyles = ({ theme }) => css`
  label: service__name;

  color: ${theme.colors.n900};
  font-size: ${theme.typography.text.mega.fontSize};
  max-width: 85%;

  ${theme.media.mega} {
    font-size: ${theme.typography.headings.mega.fontSize};
    max-width: 90%;
  }
`

export const Content = styled('div')(contentStyles)
export const Badge = styled('div')(badgeStyles)
export const Container = styled('div')(containerStyles, opacityEnabledStyles)
export const Column = styled('div')(columnStyles)
export const ServiceTitleWrapper = styled('div')(serviceTitleWrapperStyles)
export const ProductWrapper = styled('div')(productWrapperStyles)
export const TakeTogether = styled('div')(takeTogetherStyles)
export const Row = styled('div')(rowStyles)
export const UnityFromSP = styled('div')(unityFromSpStyles)
export const SellerName = styled('div')(sellerNameStyles)
export const Footer = styled('div')(footerStyles)
export const QuantityWrapper = styled('div')(quantityWrapperStyles)
export const ForProduct = styled('div')(forProductStyles)
export const ProductDescription = styled('div')(productDescriptionStyles)
export const Description = styled('div')(descriptionStyles)
export const NotificationWrapper = styled('div')(notificationWrapperStyles)
export const RemoveService = styled('div')(removeServiceStyles)
export const ServiceName = styled('span')(serviceNameStyles)
