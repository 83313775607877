import {
  Button,
  Checkbox,
  Dialog,
  Heading,
  Hidden,
  Modal,
  Stack,
  Text,
  Visible
} from '@leroy-merlin-br/backyard-react'
import {
  Bathtub,
  Bedding,
  AirConditioner,
  Kitchen,
  Frame,
  Fridge,
  DoorHinge,
  Drill,
  Pendant,
  Fence,
  Broom,
  Shovel,
  Plug,
  Faucet,
  Wood,
  Hanger,
  Paw,
  PaintBucket,
  Floor,
  Door,
  CameraSecurity,
  Mixer,
  Carpet
} from '@leroy-merlin-br/backyard-icons'
import { ChangeEvent } from 'react'

import { Shell } from 'shared/components'

import { Layout } from '../../components/Layout'
import { MobileLayout } from '../../components/MobileLayout'
import * as S from './styled'
import { InterestOptionData, usePreferences } from './usePreferences'
import * as Icons from '../../icons'
import FavoriteStoreField from './components/FavoriteStoreField'
import OptInProField from './components/OptInProField'
import OccupationField from './components/OccupationField'

const interestIcons: Record<string, Element> = {
  bathroom: <Bathtub width={30} height={30} />,
  ironmongery: <DoorHinge width={30} height={30} />,
  tools: <Drill width={30} height={30} />,
  wood: <Wood width={30} height={30} />,
  construction: <Shovel width={30} height={30} />,
  electrical: <Plug width={30} height={30} />,
  hydraulic: <Faucet width={30} height={30} />,
  paints: <PaintBucket width={30} height={30} />,
  floor: <Floor width={30} height={30} />,
  door: <Door width={30} height={30} />,
  kitchen: <Kitchen width={30} height={30} />,
  decoration: <Frame width={30} height={30} />,
  lighting: <Pendant width={30} height={30} />,
  garden: <Fence width={30} height={30} />,
  organization: <Hanger width={30} height={30} />,
  maintenance: <Broom width={30} height={30} />,
  petshop: <Paw width={30} height={30} />,
  ventilation: <AirConditioner width={30} height={30} />,
  security: <CameraSecurity width={30} height={30} />,
  diy: <Icons.BricolageIcon />,
  pool: <Icons.PoolIcon />,
  bed_table_bath: <Bedding width={30} height={30} />,
  home_appliances: <Fridge width={30} height={30} />,
  small_appliances: <Mixer width={30} height={30} />,
  informatics: <Icons.ComputingIcon />,
  audio_video_tv: <Icons.AudioVideoTvIcon />,
  carpet: <Carpet width={30} height={30} />
}

const Preferences = () => {
  const {
    loyalty,
    setLoyalty,
    loading,
    handleUpdateInterests,
    checkIsSelected,
    handleSelect,
    occupations,
    favoriteStores,
    interestsOptions,
    interestsShellOptions,
    disableProfessionField,
    isModalOptOutOpen,
    toggleModalOptOut,
    isLoyalty,
    favoriteStoreError,
    setFavoriteStoreError
  } = usePreferences()

  const handleOptIOrOut = () => {
    if (isLoyalty && loyalty.optIn) {
      setLoyalty({ ...loyalty, optIn: false })

      toggleModalOptOut()

      return
    }

    setLoyalty({ ...loyalty, optIn: true })
  }

  const renderComponent = () => (
    <S.Wrapper>
      <S.TitleWrapper>
        <Heading noMargin>Preferências</Heading>
      </S.TitleWrapper>

      <Text color="n900">
        Conhecendo seus interesses, podemos sugerir ofertas e benefícios que se
        encaixam no que você deseja.
      </Text>

      <S.Heading>
        <Heading color="n900" size="mega" noMargin>
          Programa de Fidelidade
        </Heading>
      </S.Heading>

      <S.SubWrapper>
        <S.Field>
          {loading ? (
            <Stack space="byte">
              <Shell borderRadius="kilo" height={25} width={200} />
              <Shell borderRadius="kilo" height={45} />
            </Stack>
          ) : (
            <>
              <Text color="n900" size="mega" isBold>
                {'Faça parte do "Leroy Merlin Com Você”'}
              </Text>

              <S.CheckboxWrapper>
                <Checkbox
                  disabled={loading}
                  defaultChecked={loyalty.optIn}
                  onClick={handleOptIOrOut}
                  color="n900">
                  {`Aceito participar do programa de fidelidade "Leroy Merlin Com
                  Você” para receber benefícios e vantagens exclusivas por
                  e-mail e SMS.`}
                </Checkbox>
              </S.CheckboxWrapper>
            </>
          )}
        </S.Field>

        {!loading && loyalty.optIn && (
          <FavoriteStoreField
            isError={favoriteStoreError}
            favoriteStores={favoriteStores}
            loading={loading}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              setLoyalty({ ...loyalty, favoriteStore: e.target.value })

              setFavoriteStoreError(false)
            }}
            value={loyalty.favoriteStore}
          />
        )}

        {!loading && loyalty.optIn && (
          <OptInProField
            defaultChecked={loyalty.optInPro}
            disabled={loading || disableProfessionField}
            onClick={() =>
              setLoyalty({ ...loyalty, optInPro: !loyalty.optInPro })
            }
          />
        )}

        {!loading && loyalty.optIn && loyalty.optInPro && (
          <OccupationField
            disabled={loading || disableProfessionField}
            occupations={occupations}
            onChange={(e: ChangeEvent<HTMLSelectElement>) =>
              setLoyalty({ ...loyalty, occupation: e.target.value })
            }
            value={loyalty.occupation}
          />
        )}
      </S.SubWrapper>

      <S.Heading>
        <Heading color="n900" size="mega">
          Selecione outros temas do seu interesse:
        </Heading>
      </S.Heading>

      <S.SubWrapper style={{ marginBottom: 30 }}>
        <S.RadioSquareWrapper>
          {loading ? (
            <ul>
              {interestsShellOptions.map((interestShell: number) => (
                <S.CustomShell key={`preferences-interest-${interestShell}`}>
                  <Shell borderRadius="kilo" height={144} />
                </S.CustomShell>
              ))}
            </ul>
          ) : (
            <ul>
              {interestsOptions?.map((interest: InterestOptionData) => (
                <S.Item
                  key={`preferences-interest-${interest.id}`}
                  isLoading={loading}
                  selected={checkIsSelected(interest.id)}
                  onClick={() => handleSelect(interest.id)}>
                  {interestIcons[interest.id]}
                  {interest.name}
                </S.Item>
              ))}
            </ul>
          )}
        </S.RadioSquareWrapper>
      </S.SubWrapper>

      <S.ButtonWrapper>
        <Button isLoading={loading} onClick={handleUpdateInterests}>
          Salvar
        </Button>
      </S.ButtonWrapper>
    </S.Wrapper>
  )

  return (
    <>
      <Visible when={['untilKilo', 'kilo']}>
        <MobileLayout>{renderComponent()}</MobileLayout>
      </Visible>
      <Hidden when={['untilKilo', 'kilo']}>
        <Layout backLink="/">{renderComponent()}</Layout>
      </Hidden>

      <Modal
        isOpen={isModalOptOutOpen}
        isCentered
        onClose={toggleModalOptOut}
        size="mega">
        <Dialog
          onClose={toggleModalOptOut}
          title="Gostaria de sair do Programa de Fidelidade?">
          <Text>
            Ao sair do programa de fidelidade você perderá todos os benefícios
            acumulados.
          </Text>

          <div
            style={{
              marginTop: 48,
              marginBottom: 4,
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between'
            }}>
            <Button
              isDisabled={loading}
              size="mega"
              appearance="critical"
              variant="ghost"
              onClick={toggleModalOptOut}>
              Cancelar
            </Button>

            <Button
              isLoading={loading}
              size="mega"
              onClick={handleUpdateInterests}>
              Sim, sair
            </Button>
          </div>
        </Dialog>
      </Modal>
    </>
  )
}

export default Preferences
