import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgWrenchOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        d="M5.122 21c.378.378.88.586 1.414.586S7.572 21.378 7.95 21l4.336-4.336a7.5 7.5 0 0 0 2.217.333 7.45 7.45 0 0 0 5.302-2.195 7.48 7.48 0 0 0 1.632-8.158l-.57-1.388-4.244 4.243-2.121-2.122 4.243-4.243-1.389-.571A7.5 7.5 0 0 0 14.499 2c-2.003 0-3.886.78-5.301 2.196a7.48 7.48 0 0 0-1.862 7.518L3 16.05a2 2 0 0 0 0 2.828zm4.548-8.791-.254-.616a5.49 5.49 0 0 1 1.196-5.983 5.46 5.46 0 0 1 4.413-1.585l-3.353 3.353 4.949 4.95 3.355-3.355a5.49 5.49 0 0 1-1.587 4.416c-1.55 1.55-3.964 2.027-5.984 1.196l-.615-.255-5.254 5.256h.001l-.001 1v-1l-2.122-2.122z"
        fill={color ? Colors[color] : 'currentColor'}
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgWrenchOutline)
const Memo = memo(ForwardRef)
export default Memo
