import { isValid as isValidCpf } from '@fnando/cpf'
import { AxiosResponse } from 'axios'
import { ParsedQs } from 'qs'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useSignupContext } from 'user/signup/context/signup-context'
import { signupService } from 'user/signup/services'
import { hasUnknownError } from 'user/signup/utils'
import { loginPostMessage } from 'user/signup/utils/appPostMessage'
import { handleErrorsMessage, isServerError } from 'user/utils'
import * as dataLayer from 'user/signup/utils/data-layer'
import {
  SendCodePayload,
  sendCodeAuth
} from 'user/signup/services/verify-contact'
import { ShowToastParams, showToast } from 'user/my-account/utils/toast'
import { useQuery, useStores } from 'user/shared/hooks'
import { checkFiscalId } from 'user/social-sing-up/services'

import { useUserResources } from 'shared/hooks'

import { tracker } from 'utils/app'

import { FORM_DEFAULT_VALUES, FORM_FIELDS_CODES } from './constants'
import useNewsLetterOptin, { UserInfo } from './useNewsLetterOptin'

type RequestError = {
  data: {
    errors: {
      code:
        | 'fiscalId'
        | 'name'
        | 'email'
        | 'emailConfirm'
        | 'phone'
        | 'password'
        | 'loyalty'
        | 'loyalty.optInPro'
        | 'loyalty.favoriteStore'
        | 'loyalty.birthdate'
        | 'loyalty.occupation'
      message: string
      messages: {
        code: string
        message: string
      }[]
    }[]
  }
  status: number
}

type DataLayerErrors = {
  [key: string]: string
}

type SignupResponse = {
  redirect: undefined
  token: {
    accessToken: string
    tokenType: string
    expiresIn: string
  }
  needDocumentation?: boolean
}

export type SignUpFormRequest = {
  data: UserInfo
  redirectTo: string | ParsedQs | string[] | ParsedQs[]
  recaptchaToken: string | null
  csrfToken: string
}

export function useFormPF(
  verifyCodeStepOnSignup: boolean,
  onNextStep?: () => void
) {
  const {
    clearErrors,
    control,
    register,
    formState,
    setError,
    setValue,
    getValues,
    handleSubmit,
    watch,
    trigger
  } = useForm({
    mode: 'onBlur',
    defaultValues: FORM_DEFAULT_VALUES
  })

  const { executeRecaptcha } = useGoogleReCaptcha()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [classOccupation, setClassOccupation] = useState('')

  const [isCheckingFiscalId, setCheckFiscalId] = useState(false)
  const [validFiscalId, setValidFiscalId] = useState('')

  const history = useHistory()
  const redirectTo = useRef()
  const { setIsLoggedIn, isCaptchaEnabled, updateData } = useSignupContext()
  const { userControls } = useUserResources()

  const { generalOptin, handleOptinsToggle, handleGeneralOptinToggle } =
    useNewsLetterOptin(setValue, getValues)

  const isProfessionalWatch = watch('loyalty.optInPro')
  const passwordWatch = watch('password')

  const { queryParams, stores } = useStores({
    onHasStore: (store: string) => {
      setValue('loyalty.favoriteStore', store)
    }
  })

  const query = useQuery()

  const getSignupRecaptchaParams = async (
    userInfo: UserInfo
  ): Promise<SignUpFormRequest> => {
    const recaptchaToken =
      isCaptchaEnabled && executeRecaptcha
        ? await executeRecaptcha('signUpFormPF')
        : null

    const csrfToken = (
      document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement
    ).content

    return {
      data: userInfo,
      redirectTo: queryParams.redirect || '/',
      recaptchaToken,
      csrfToken
    }
  }

  const signUpLegacy = async (signupParams: SignUpFormRequest) => {
    try {
      const { data } = await signupService.signup(signupParams)

      const shouldNeedDocumentation = handleResponseSignUp(data, signupParams)

      if (shouldNeedDocumentation) {
        return
      }

      onSignupCompleted()
    } catch (error) {
      handleRequestErrors(error as RequestError)
    } finally {
      setIsSubmitting(false)
    }
  }

  const signupWithVerifyCodeStep = async (
    userInfo: UserInfo,
    onNextStep: () => void
  ) => {
    try {
      await signupService.signupCheckForm(userInfo)

      await validateAccount(userInfo)

      onNextStep()
    } catch (error) {
      handleRequestErrors(error as RequestError)
    } finally {
      setIsSubmitting(false)
    }
  }

  const onFormSubmit = async (userInfo: UserInfo) => {
    if (!isValidCpf(userInfo.fiscalId)) {
      setError('fiscalId', {
        type: 'manual',
        message: 'CPF inválido'
      })

      return
    }

    setIsSubmitting(true)

    if (verifyCodeStepOnSignup && onNextStep) {
      signupWithVerifyCodeStep(userInfo, onNextStep)
    } else {
      const signupWithRecaptchaParams = await getSignupRecaptchaParams(userInfo)

      signUpLegacy(signupWithRecaptchaParams)
    }
  }

  const validateAccount = async (userInfo: UserInfo): Promise<undefined> => {
    const otpParams: SendCodePayload = {
      fiscalId: userInfo.fiscalId,
      type: 'email',
      fullName: userInfo.name,
      contact: userInfo.email,
      context: 'confirm_contact'
    }

    const showToastParams: ShowToastParams = {
      type: 'critical',
      title: 'Não foi possível enviar o código.',
      content: 'Tente novamente mais tarde.'
    }

    try {
      await sendCodeAuth(otpParams)

      showToastParams.type = 'primary'
      showToastParams.title = 'Código enviado!'
      showToastParams.content = ''

      showToast(showToastParams)

      updateData({ data: userInfo })
    } catch (error) {
      const { status } = error as AxiosResponse

      const hasServerError = status && isServerError(status)

      if (hasServerError) {
        showToastParams.title = 'Não foi possível enviar o código.'
      } else if (status === 429) {
        showToastParams.title = 'Você atingiu o limite de tentativas!'
      }

      showToast(showToastParams)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleResponseSignUp = (
    data: SignupResponse,
    signupParams: SignUpFormRequest
  ): boolean => {
    if (data !== undefined) {
      redirectTo.current = data.redirect

      if (userControls?.isOnMobileApp) {
        loginPostMessage(data.token)
      }

      if (data.needDocumentation) {
        setClassOccupation(signupParams.data.loyalty?.occupation)
        return true
      }
    }

    dataLayerSubmitFormSuccess()

    return false
  }

  const handleRequestErrors = (error: RequestError) => {
    const { data, status } = error

    const hasServerError = status && isServerError(status)
    const hasSomeUnknowError =
      errors && hasUnknownError(data.errors, FORM_FIELDS_CODES)

    if (hasServerError || hasSomeUnknowError) {
      history.push('/erro-interno')
    }

    const dataLayerErrors: DataLayerErrors = {}
    if (data.errors && Array.isArray(data.errors)) {
      for (const { code, message, messages } of data.errors) {
        setError(code, {
          type: 'manual',
          message: handleErrorsMessage(message, messages)
        })
        dataLayerErrors[code] = ''
      }
    }

    onFormInvalid(dataLayerErrors)
  }

  const dataLayerSubmitFormSuccess = () => {
    const dataLayerObj = {
      signupType: 'pf',
      optinsSelected: dataLayer.optinsSelectedFormatter(getValues())
    }

    dataLayer.onSubmitFormSuccess(dataLayerObj)
  }

  const onFormInvalid = async (errors: DataLayerErrors) => {
    const dataLayerObj = {
      signupType: 'pf',
      fieldsStatus: dataLayer.fieldsStatusFormatter(errors),
      optinsSelected: dataLayer.optinsSelectedFormatter(getValues())
    }
    dataLayer.onSubmitFormWithFieldsErrors(dataLayerObj)
  }

  const onSignupCompleted = async () => {
    tracker.signUp('site-pf')

    setIsLoggedIn(true)

    history.push({
      pathname: '/sucesso',
      state: {
        redirect: redirectTo.current,
        signupType: 'pf'
      }
    })
  }

  async function handleCheckDocument(document: string) {
    const documentRaw = document.replace(/\D/g, '')

    if (validFiscalId === documentRaw) {
      return
    }

    if (documentRaw.length === 11) {
      if (isValidCpf(documentRaw)) {
        setCheckFiscalId(true)

        const redirect = query.get('redirect')

        try {
          await checkFiscalId(documentRaw, redirect)

          setValidFiscalId(documentRaw)
        } catch ({ data }) {
          if (data?.errors?.length) {
            const [error] = data.errors

            setError('fiscalId', {
              type: 'manual',
              message: error.message
            })

            return error.message
          } else {
            setError('fiscalId', {
              type: 'manual',
              message: data.message
            })

            return data.message
          }
        } finally {
          setCheckFiscalId(false)
        }
      } else {
        setError('fiscalId', {
          type: 'manual',
          message: 'CPF inválido'
        })
      }
    } else {
      clearErrors('fiscalId')
    }
  }

  useEffect(() => {
    passwordWatch && trigger('password')
  }, [passwordWatch, trigger])

  const { errors } = formState

  return {
    clearErrors,
    control,
    register,
    setError,
    getValues,
    handleSubmit,
    watch,
    isSubmitting,
    classOccupation,
    stores,
    generalOptin,
    handleOptinsToggle,
    handleGeneralOptinToggle,
    isProfessionalWatch,
    onFormSubmit,
    onFormInvalid,
    onSignupCompleted,
    errors,
    getSignupRecaptchaParams,
    handleResponseSignUp,
    handleCheckDocument,
    isCheckingFiscalId,
    validFiscalId,
    setValidFiscalId,
    dataLayerSubmitFormSuccess,
    handleRequestErrors
  }
}
