import { Button, Inline, Text, Visible } from '@leroy-merlin-br/backyard-react'
import { Icon } from '@backyard-ui/core'
import {
  ClockOutline,
  DollarSignOutline,
  MoneyOutline
} from '@backyard-ui/icons'
import { useState } from 'react'

import { InvoiceUpload } from 'scripts/react-components/lmcv-pro/pages/CashbackPage/components/CashbackRedeem/components/InvoiceSender/components/InvoiceUpload'
import { useRedeemContext } from 'scripts/react-components/lmcv-pro/pages/CashbackPage/components/CashbackRedeem/contexts/RedeemContext'
import { BackyardCoreIconWrapper } from 'scripts/react-components/shared/components/BackyardCoreIconWrapper'

import * as S from './styled'

export default function InvoiceSender() {
  const { cashback } = useRedeemContext()

  const [uploadInvoiceIsVisible, setUploadInvoiceIsVisible] = useState(false)

  return (
    <>
      <S.Content data-testid="invoice-sender-content">
        <S.ContentLine>
          <S.FlexResponsive>
            <Inline>
              <S.Circle>
                <Icon size="2xl" appearance="tertiary">
                  <BackyardCoreIconWrapper Icon={MoneyOutline} />
                </Icon>
                <S.CircleIcon>
                  <Icon size="xs">
                    <BackyardCoreIconWrapper Icon={DollarSignOutline} />
                  </Icon>
                </S.CircleIcon>
              </S.Circle>
            </Inline>
            <S.ResponsiveAlignment>
              <Text isBold noMargin size="giga" color="n400">
                {cashback?.cashback} reais
              </Text>
              <Text noMargin size="mega" color="n400">
                por {cashback?.balance} pts
              </Text>
            </S.ResponsiveAlignment>
          </S.FlexResponsive>
          <Button
            type="button"
            appearance="secondary"
            disabled={!cashback?.enabled}
            onClick={() => setUploadInvoiceIsVisible(true)}>
            <span>Anexar nota fiscal</span>
          </Button>
        </S.ContentLine>
        <S.ContentLine>
          <Inline alignY="baseline" space="bit">
            <Icon size="xs">
              <BackyardCoreIconWrapper Icon={ClockOutline} />
            </Icon>
            <Text color="n400" size="mega" noMargin>
              Expira em {cashback?.daysUntilExpiration} dias
            </Text>
          </Inline>
          {cashback?.enabled && (
            <Visible when={['mega', 'giga', 'tera']}>
              <Text color="f500" size="mega" noMargin>
                A Nota Fiscal deve ser emitida e enviada até o dia 20 de cada
                mês
              </Text>
            </Visible>
          )}
        </S.ContentLine>
      </S.Content>

      <InvoiceUpload
        uploadInvoiceIsVisible={uploadInvoiceIsVisible}
        handleClose={() => setUploadInvoiceIsVisible(false)}
      />
    </>
  )
}
