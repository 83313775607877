import { useCallback, useState } from 'react'
import { Button } from '@leroy-merlin-br/backyard-react'

import { SuggestionOption } from '../../types'
import * as S from './styled'

const Option = ({
  command,
  label,
  isDisabled,
  isSelected: isCurrentSelected
}: SuggestionOption) => {
  const [isSelected, setIsSelected] = useState(isCurrentSelected)
  const commandClick = useCallback(() => {
    command()
    setIsSelected(true)
  }, [setIsSelected, command])

  return (
    <S.OptionContainer isSelected={isSelected}>
      <Button
        data-gtm-flow-name="Concierge"
        data-gtm-step-name="Click interaction button"
        isDisabled={!!isSelected || isDisabled}
        isStretch
        size="kilo"
        as="button"
        type="button"
        onClick={commandClick}
      >
        {label}
      </Button>
    </S.OptionContainer>
  )
}

export default Option
