import { useEffect, useRef, useState } from 'react'
import * as S from './styled'
import { useFormContext } from 'scripts/react-components/lmcv-pro/pages/CashbackPage/components/ArchitectRegistration/components/Form/contexts/FormContext'
import { Heading, Spinner } from '@leroy-merlin-br/backyard-react'
import classNames from 'classnames'

export default function ContentHeader() {
  const { isSavingDraft } = useFormContext()

  const headerRef = useRef<HTMLDivElement>(null)

  const [headerIsSticky, setHeaderIsSticky] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (headerRef.current) {
        const { top } = headerRef.current.getBoundingClientRect()
        setHeaderIsSticky(top <= 0)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <S.Header
      ref={headerRef}
      className={classNames({ 'sticky-active': headerIsSticky })}>
      <Heading size="tera" as="h1" noMargin>
        Cadastro
      </Heading>

      {isSavingDraft && (
        <div className="saving">
          <Spinner size="mega" />
          <span>Salvando dados...</span>
        </div>
      )}
    </S.Header>
  )
}
