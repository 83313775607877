import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'

type VariantProps =
  | 'heading-xs'
  | 'heading-sm'
  | 'heading-md'
  | 'heading-lg'
  | 'heading-xl'
  | 'subtitle-md'
  | 'subtitle-lg'
  | 'overline-md'
  | 'overline-lg'
  | 'body-xs'
  | 'body-xs'
  | 'body-sm'
  | 'body-sm'
  | 'body-md'
  | 'body-md'
  | 'body-lg'
  | 'body-lg'
  | 'longform-md'
  | 'longform-md'
  | 'longform-lg'
  | 'longform-lg'
  | 'numerals-xs'
  | 'numerals-md'
  | 'numerals-lg'
  | 'numerals-xl'
  | 'ui-xs'
  | 'ui-sm'
  | 'ui-md'
  | 'ui-lg'

type FontProps =
  | 'heading-xs-default'
  | 'heading-sm-default'
  | 'heading-md-default'
  | 'heading-lg-default'
  | 'heading-xl-default'
  | 'subtitle-md-default'
  | 'subtitle-lg-default'
  | 'overline-md-default'
  | 'overline-lg-default'
  | 'body-xs-default'
  | 'body-xs-strong'
  | 'body-xs-emphasis'
  | 'body-sm-default'
  | 'body-sm-strong'
  | 'body-sm-emphasis'
  | 'body-md-default'
  | 'body-md-strong'
  | 'body-md-emphasis'
  | 'body-lg-default'
  | 'body-lg-strong'
  | 'body-lg-emphasis'
  | 'longform-md-default'
  | 'longform-md-strong'
  | 'longform-md-emphasis'
  | 'longform-lg-default'
  | 'longform-lg-strong'
  | 'longform-lg-emphasis'
  | 'numerals-xs-default'
  | 'numerals-xs-strong'
  | 'numerals-md-default'
  | 'numerals-md-strong'
  | 'numerals-lg-default'
  | 'numerals-lg-strong'
  | 'numerals-xl-default'
  | 'numerals-xl-strong'
  | 'ui-xs-default'
  | 'ui-xs-strong'
  | 'ui-sm-default'
  | 'ui-sm-strong'
  | 'ui-md-default'
  | 'ui-md-strong'
  | 'ui-lg-default'
  | 'ui-lg-strong'

export type AppearanceProps =
  | 'neutral-soft'
  | 'neutral-default'
  | 'neutral-strong'
  | 'neutral-inverse'
  | 'accent-base'
  | 'accent-strong'
  | 'loyalty-base'
  | 'loyalty-strong'
  | 'info-default'
  | 'info-base'
  | 'info-strong'
  | 'success-base'
  | 'success-strong'
  | 'success-default'
  | 'warning-base'
  | 'warning-strong'
  | 'warning-default'
  | 'danger-base'
  | 'danger-strong'
  | 'danger-default'

type TransformProps = 'uppercase' | 'lowercase' | 'capitalize' | 'normal-case'

type TypeProps = 'default' | 'strong' | 'emphasis'

export type TypographyProps = {
  variant?: VariantProps
  appearance?: AppearanceProps
  transform?: TransformProps
  children?: React.ReactNode
  tag?: 'span' | 'strong' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  type?: TypeProps
  strike?: boolean
  innerText?: string
}

const fontStyles = {
  'heading-xs-default': css`
    label: type-heading-xs;
    font: var(--type-mobile-heading-xs);

    @media (min-width: 1024px) {
      font: var(--type-desktop-heading-xs);
    }
  `,
  'heading-sm-default': css`
    label: type-heading-sm;
    font: var(--type-mobile-heading-sm);

    @media (min-width: 1024px) {
      font: var(--type-desktop-heading-sm);
    }
  `,
  'heading-md-default': css`
    label: type-heading-md;
    font: var(--type-mobile-heading-md);

    @media (min-width: 1024px) {
      font: var(--type-desktop-heading-md);
    }
  `,
  'heading-lg-default': css`
    label: type-heading-lg;
    font: var(--type-mobile-heading-lg);

    @media (min-width: 1024px) {
      font: var(--type-desktop-heading-lg);
    }
  `,
  'heading-xl-default': css`
    label: type-heading-xl;
    font: var(--type-mobile-heading-xl);

    @media (min-width: 1024px) {
      font: var(--type-desktop-heading-xl);
    }
  `,
  'subtitle-md-default': css`
    label: type-subtitle-md;
    font: var(--type-mobile-subtitle-md);

    @media (min-width: 1024px) {
      font: var(--type-desktop-subtitle-md);
    }
  `,
  'subtitle-lg-default': css`
    label: type-subtitle-lg;
    font: var(--type-mobile-subtitle-lg);

    @media (min-width: 1024px) {
      font: var(--type-desktop-subtitle-lg);
    }
  `,
  'overline-md-default': css`
    label: type-overline-md;
    font: var(--type-mobile-overline-md);

    @media (min-width: 1024px) {
      font: var(--type-desktop-overline-md);
    }
  `,
  'overline-lg-default': css`
    label: type-overline-lg;
    font: var(--type-mobile-overline-lg);

    @media (min-width: 1024px) {
      font: var(--type-desktop-overline-lg);
    }
  `,
  'body-xs-default': css`
    label: type-body-xs-default;
    font: var(--type-mobile-body-xs-default);

    @media (min-width: 1024px) {
      font: var(--type-desktop-body-xs-default);
    }
  `,
  'body-xs-strong': css`
    label: type-body-xs-strong;
    font: var(--type-mobile-body-xs-strong);

    @media (min-width: 1024px) {
      font: var(--type-desktop-body-xs-strong);
    }
  `,
  'body-xs-emphasis': css`
    label: type-body-xs-emphasis;
    font: var(--type-mobile-body-xs-emphasis);

    @media (min-width: 1024px) {
      font: var(--type-desktop-body-xs-emphasis);
    }
  `,
  'body-sm-default': css`
    label: type-body-sm-default;
    font: var(--type-mobile-body-sm-default);

    @media (min-width: 1024px) {
      font: var(--type-desktop-body-sm-default);
    }
  `,
  'body-sm-strong': css`
    label: type-body-sm-strong;
    font: var(--type-mobile-body-sm-strong);

    @media (min-width: 1024px) {
      font: var(--type-desktop-body-sm-strong);
    }
  `,
  'body-sm-emphasis': css`
    label: type-body-sm-emphasis;
    font: var(--type-mobile-body-sm-emphasis);

    @media (min-width: 1024px) {
      font: var(--type-desktop-body-sm-emphasis);
    }
  `,
  'body-md-default': css`
    label: type-body-md-default;
    font: var(--type-mobile-body-md-default);

    @media (min-width: 1024px) {
      font: var(--type-desktop-body-md-default);
    }
  `,
  'body-md-strong': css`
    label: type-body-md-strong;
    font: var(--type-mobile-body-md-strong);

    @media (min-width: 1024px) {
      font: var(--type-desktop-body-md-strong);
    }
  `,
  'body-md-emphasis': css`
    label: type-body-md-emphasis;
    font: var(--type-mobile-body-md-emphasis);

    @media (min-width: 1024px) {
      font: var(--type-desktop-body-md-emphasis);
    }
  `,
  'body-lg-default': css`
    label: type-body-lg-default;
    font: var(--type-mobile-body-lg-default);

    @media (min-width: 1024px) {
      font: var(--type-desktop-body-lg-default);
    }
  `,
  'body-lg-strong': css`
    label: type-body-lg-strong;
    font: var(--type-mobile-body-lg-strong);

    @media (min-width: 1024px) {
      font: var(--type-desktop-body-lg-strong);
    }
  `,
  'body-lg-emphasis': css`
    label: type-body-lg-emphasis;
    font: var(--type-mobile-body-lg-emphasis);

    @media (min-width: 1024px) {
      font: var(--type-desktop-body-lg-emphasis);
    }
  `,
  'longform-md-default': css`
    label: type-longform-md-default;
    font: var(--type-mobile-longform-md-default);

    @media (min-width: 1024px) {
      font: var(--type-desktop-longform-md-default);
    }
  `,
  'longform-md-strong': css`
    label: type-longform-md-strong;
    font: var(--type-mobile-longform-md-strong);

    @media (min-width: 1024px) {
      font: var(--type-desktop-longform-md-strong);
    }
  `,
  'longform-md-emphasis': css`
    label: type-longform-md-emphasis;
    font: var(--type-mobile-longform-md-emphasis);

    @media (min-width: 1024px) {
      font: var(--type-desktop-longform-md-emphasis);
    }
  `,
  'longform-lg-default': css`
    label: type-longform-lg-default;
    font: var(--type-mobile-longform-lg-default);

    @media (min-width: 1024px) {
      font: var(--type-desktop-longform-lg-default);
    }
  `,
  'longform-lg-strong': css`
    label: type-longform-lg-strong;
    font: var(--type-mobile-longform-lg-strong);

    @media (min-width: 1024px) {
      font: var(--type-desktop-longform-lg-strong);
    }
  `,
  'longform-lg-emphasis': css`
    label: type-longform-lg-emphasis;
    font: var(--type-mobile-longform-lg-emphasis);

    @media (min-width: 1024px) {
      font: var(--type-desktop-longform-lg-emphasis);
    }
  `,
  'numerals-xs-default': css`
    label: type-numerals-xs-default;
    font: var(--type-mobile-numerals-xs-default);

    @media (min-width: 1024px) {
      font: var(--type-desktop-numerals-xs-default);
    }
  `,
  'numerals-xs-strong': css`
    label: type-numerals-xs-strong;
    font: var(--type-mobile-numerals-xs-strong);

    @media (min-width: 1024px) {
      font: var(--type-desktop-numerals-xs-strong);
    }
  `,
  'numerals-md-default': css`
    label: type-numerals-md-default;
    font: var(--type-mobile-numerals-md-default);

    @media (min-width: 1024px) {
      font: var(--type-desktop-numerals-md-default);
    }
  `,
  'numerals-md-strong': css`
    label: type-numerals-md-strong;
    font: var(--type-mobile-numerals-md-strong);

    @media (min-width: 1024px) {
      font: var(--type-desktop-numerals-md-strong);
    }
  `,
  'numerals-lg-default': css`
    label: type-numerals-lg-default;
    font: var(--type-mobile-numerals-lg-default);

    @media (min-width: 1024px) {
      font: var(--type-desktop-numerals-lg-default);
    }
  `,
  'numerals-lg-strong': css`
    label: type-numerals-lg-strong;
    font: var(--type-mobile-numerals-lg-strong);

    @media (min-width: 1024px) {
      font: var(--type-desktop-numerals-lg-strong);
    }
  `,
  'numerals-xl-default': css`
    label: type-numerals-xl-default;
    font: var(--type-mobile-numerals-xl-default);

    @media (min-width: 1024px) {
      font: var(--type-desktop-numerals-xl-default);
    }
  `,
  'numerals-xl-strong': css`
    label: type-numerals-xl-strong;
    font: var(--type-mobile-numerals-xl-strong);

    @media (min-width: 1024px) {
      font: var(--type-desktop-numerals-xl-strong);
    }
  `,
  'ui-xs-default': css`
    label: type-ui-xs-default;
    font: var(--type-mobile-ui-xs-default);

    @media (min-width: 1024px) {
      font: var(--type-desktop-ui-xs-default);
    }
  `,
  'ui-xs-strong': css`
    label: type-ui-xs-strong;
    font: var(--type-mobile-ui-xs-strong);

    @media (min-width: 1024px) {
      font: var(--type-desktop-ui-xs-strong);
    }
  `,
  'ui-sm-default': css`
    label: type-ui-sm-default;
    font: var(--type-mobile-ui-sm-default);

    @media (min-width: 1024px) {
      font: var(--type-desktop-ui-sm-default);
    }
  `,
  'ui-sm-strong': css`
    label: type-ui-sm-strong;
    font: var(--type-mobile-ui-sm-strong);

    @media (min-width: 1024px) {
      font: var(--type-desktop-ui-sm-strong);
    }
  `,
  'ui-md-default': css`
    label: type-ui-md-default;
    font: var(--type-mobile-ui-md-default);

    @media (min-width: 1024px) {
      font: var(--type-desktop-ui-md-default);
    }
  `,
  'ui-md-strong': css`
    label: type-ui-md-strong;
    font: var(--type-mobile-ui-md-strong);

    @media (min-width: 1024px) {
      font: var(--type-desktop-ui-md-strong);
    }
  `,
  'ui-lg-default': css`
    label: type-ui-lg-default;
    font: var(--type-mobile-ui-lg-default);

    @media (min-width: 1024px) {
      font: var(--type-desktop-ui-lg-default);
    }
  `,
  'ui-lg-strong': css`
    label: type-ui-lg-strong;
    font: var(--type-mobile-ui-lg-strong);

    @media (min-width: 1024px) {
      font: var(--type-desktop-ui-lg-strong);
    }
  `
}

const appearanceStyles = {
  'neutral-soft': css`
    label: color-text-neutral-soft;
    color: var(--color-text-neutral-soft);
  `,
  'neutral-default': css`
    label: color-text-neutral-default;
    color: var(--color-text-neutral-default);
  `,
  'neutral-strong': css`
    label: color-text-neutral-strong;
    color: var(--color-text-neutral-strong);
  `,
  'neutral-inverse': css`
    label: color-text-neutral-inverse;
    color: var(--color-text-neutral-inverse);
  `,
  'accent-base': css`
    label: color-text-accent-base;
    color: var(--color-text-accent-base);
  `,
  'accent-strong': css`
    label: color-text-accent-strong;
    color: var(--color-text-accent-strong);
  `,
  'loyalty-base': css`
    label: color-text-loyalty-base;
    color: var(--color-text-loyalty-base);
  `,
  'loyalty-strong': css`
    label: color-text-loyalty-strong;
    color: var(--color-text-loyalty-strong);
  `,
  'info-default': css`
    label: color-text-info-default;
    color: var(--color-text-info-default);
  `,
  'info-base': css`
    label: color-text-info-base;
    color: var(--color-text-info-base);
  `,
  'info-strong': css`
    label: color-text-info-strong;
    color: var(--color-text-info-strong);
  `,
  'success-base': css`
    label: color-text-success-base;
    color: var(--color-text-success-base);
  `,
  'success-strong': css`
    label: color-text-success-strong;
    color: var(--color-text-success-strong);
  `,
  'success-default': css`
    label: color-text-success-default;
    color: var(--color-text-success-default);
  `,
  'warning-base': css`
    label: color-text-warning-base;
    color: var(--color-text-warning-base);
  `,
  'warning-strong': css`
    label: color-text-warning-strong;
    color: var(--color-text-warning-strong);
  `,
  'warning-default': css`
    label: color-text-warning-default;
    color: var(--color-text-warning-default);
  `,
  'danger-base': css`
    label: color-text-danger-base;
    color: var(--color-text-danger-base);
  `,
  'danger-strong': css`
    label: color-text-danger-strong;
    color: var(--color-text-danger-strong);
  `,
  'danger-default': css`
    label: color-text-danger-default;
    color: var(--color-text-danger-default);
  `
}

const transformStyles = {
  uppercase: css`
    label: uppercase;
    text-transform: uppercase;
  `,
  lowercase: css`
    label: lowercase;
    text-transform: lowercase;
  `,
  capitalize: css`
    label: capitalize;
    text-transform: capitalize;
  `,
  'normal-case': css`
    label: normal-case;
    text-transform: none;
  `
}

const TypographyWrapper = styled.span<TypographyProps>`
  ${({ variant, type }: { variant: VariantProps; type: TypeProps }) =>
    fontStyles[`${variant}-${type}` as FontProps]};
  ${({ appearance }: { appearance: AppearanceProps }) =>
    appearanceStyles[appearance]};
  ${({ transform }: { transform: TransformProps }) =>
    transformStyles[transform]};
  ${({ strike }: { strike: boolean }) =>
    `text-decoration: ${strike ? 'line-through' : 'none'}`};
`

const Typography: React.FC<TypographyProps> = ({
  variant = 'body-xs',
  appearance = 'neutral-default',
  transform = 'normal-case',
  tag = 'p',
  children,
  type = 'default',
  strike = false,
  innerText = ''
}) => {
  const Tag = TypographyWrapper.withComponent(tag)

  const props = {
    variant: variant,
    type: type,
    appearance: appearance,
    transform: transform,
    strike: strike
  }

  if (innerText) {
    return <Tag {...props} dangerouslySetInnerHTML={{ __html: innerText }} />
  }

  return <Tag {...props}>{children ?? ''}</Tag>
}

export default Typography
