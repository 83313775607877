export const beginMiraklFlow = ({
  shopName,
  createdAt
}: {
  shopName: string
  createdAt: string
}) => {
  return {
    text: `Conte um pouco mais sobre seu problema. Assim fica mais fácil para o vendedor <strong>${shopName}</strong> ajudar você.`,
    from: 'bot',
    time: createdAt || new Date().toISOString()
  }
}

export const sellerTimeToResponse = ({
  shopName,
  createdAt,
  sellerResponseTimeInHours
}: {
  shopName: string
  createdAt?: string
  sellerResponseTimeInHours: string
}) => {
  return {
    text: `Que pena! Sentimos muito pelo ocorrido. O vendedor <strong>${shopName}</strong> já foi notificado e entrará em contato com você em até ${sellerResponseTimeInHours} horas.`,
    from: 'bot',
    time: createdAt || new Date().toISOString()
  }
}

export const disclaimerToUseOnlyChat = ({
  createdAt
}: {
  createdAt?: string
}) => {
  return {
    text: '<strong>Fique atento:</strong> Acompanhe este chat, pois as respostas à sua solicitação serão recebidas exclusivamente por aqui.',
    from: 'bot',
    time: createdAt || new Date().toISOString()
  }
}
