import { ResponseType, request } from 'frontendCheckout/services/fetch'

import { endpoints } from './endpoints'

export type SimilarProducts = {
  title: string
  slug: string
  originProductId: number
  products: Products[]
}

export type Products = {
  id: number
  name: string
  shortName: string
  picture: string
  unit: string
  url: string
  inWishList: boolean
  isOutlet: boolean
  tag: {
    type: string
    label: string
  }
  installments: {
    amount: number
    value: string
  }
  price: {
    to: {
      integers: string
      decimals: string
    }
    from: {
      integers: string
      decimals: string
    } | null
    regionPrice: string
  }
  offer: {
    type: string
    label: string
    value: number
  }
  brand: string
  category: string
  categoryTree: string
  soldBy: string
  seller: string | null
  skuId: string
  translatedUnit: string
  hasVariants: boolean
  discount: {
    discountType: string
    paymentMethodsDiscount: string
  }
  isFreeShipping: boolean
  isSoldOut: boolean
}

export type response =
  | ResponseType<200, { data: SimilarProducts[] }>
  | ResponseType<204, null>

export type status = response extends ResponseType<infer Code, unknown>
  ? Code
  : never

export type props = {
  productId: number,
}

export const getSimilarProducts = ({ productId }: props) => {
  return request<response>({
    url: endpoints.products(productId),
    params:{type: ['similarProducts']},
    method: 'GET',
    errors: [204]
  })
}
