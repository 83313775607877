import { MouseEvent, KeyboardEvent } from 'react'
import { Button, Icon } from '@leroy-merlin-br/backyard-react'
import { CrossOutline } from '@backyard-ui/icons'

import DiscountsInfo from './components/DiscountInfo'
import * as S from './styled'
import * as DocumentHandle from './utils/DocumentHandle'

type TotalDiscountsByType = {
  [key: string]: number
}

type Props = {
  onClose: (event: MouseEvent | KeyboardEvent) => void
  discount: number
  totalDiscountsByType: TotalDiscountsByType
  firstCheapestPaymentMethod?: string
  paymentMethods?: string
  isPaymentPage?: boolean
}

const upperFirstLetter = (phrase: string) => {
  return phrase.charAt(0).toUpperCase() + phrase.slice(1)
}

const DiscountsModal = ({
  onClose,
  discount,
  totalDiscountsByType,
  paymentMethods,
  firstCheapestPaymentMethod,
  isPaymentPage = false
}: Props) => {
  const renderStampedPrice = (
    <DiscountsInfo
      discountValue={totalDiscountsByType.stampedPrice}
      discountName="Oferta"
      discountDescription="Ofertas LM."
    />
  )

  const renderDiscountByPaymentMethod = () => {
    let paymentSelected = firstCheapestPaymentMethod
    let paymentDescription = paymentMethods

    if (isPaymentPage) {
      const selectedPaymentMethod =
        DocumentHandle.getSelectedPaymentMethodFromDocument()
      const paymentMethodValues = mountPaymentMethodValues(
        selectedPaymentMethod
      )
      paymentSelected = paymentMethodValues.paymentMethodSelected
      paymentDescription = paymentMethodValues.paymentDescription
    }

    if (
      !paymentDescription ||
      !paymentSelected ||
      !totalDiscountsByType[paymentSelected]
    ) {
      return null
    }

    return (
      <DiscountsInfo
        discountValue={totalDiscountsByType[paymentSelected]}
        discountName={upperFirstLetter(paymentDescription)}
        discountDescription="Descontos por forma de pagamento"
      />
    )
  }

  const renderTwoPriceDiscount = () => {
    return (
      <DiscountsInfo
        discountValue={totalDiscountsByType.twoPrices}
        discountName="À vista"
        discountDescription="Desconto à vista"
      />
    )
  }

  const renderCoupon = (
    <DiscountsInfo
      discountValue={totalDiscountsByType.promo}
      discountName="Cupom"
      discountDescription="Descontos cupom resgatado"
    />
  )

  const renderEmployeeDiscount = (
    <DiscountsInfo
      discountValue={totalDiscountsByType.employee}
      discountName="Desconto Funcionário"
      discountDescription="Desconto de funcionário"
    />
  )

  const renderLoyaltyProgramDiscount = (
    <DiscountsInfo
      discountValue={totalDiscountsByType.loyaltyProgram}
      discountName="Fidelidade"
      discountDescription="Voucher de desconto"
    />
  )

  const renderTotal = () => {
    let discountValue = discount
    if (isPaymentPage) {
      const totalDiscountContent = DocumentHandle.getTotalDiscountFromDocument()
      discountValue = mountTotalDiscount(totalDiscountContent)
    }
    return (
      <DiscountsInfo
        discountValue={discountValue}
        discountName="Total de descontos"
        isLast={true}
      />
    )
  }

  const mountTotalDiscount = (totalDiscountContent: string) => {
    let discountValue = totalDiscountContent
    discountValue = discountValue.replace(/[^\d.,]/g, '')
    discountValue = discountValue.replace(',', '.')

    return parseFloat(discountValue)
  }

  const mountPaymentMethodValues = (paymentMethod: string) => {
    let paymentMethodToShow = paymentMethod
    let paymentDescription = ''

    if (paymentMethod === 'credit_card' || paymentMethod === 'virtual_card') {
      paymentMethodToShow = 'credit_card'
      paymentDescription = 'à vista no cartão'
    }

    if (paymentMethod === 'bank_slip') {
      paymentDescription = 'à vista no boleto'
    }

    if (paymentMethod === 'pix') {
      paymentDescription = 'à vista no pix'
    }

    const paymentMethodValues = {
      paymentMethodSelected: paymentMethodToShow,
      paymentDescription: paymentDescription
    }

    return paymentMethodValues
  }

  return (
    <S.WrapperModal data-testid="discounts-modal">
      <S.WrapperHeader>
        <S.Title>Descontos</S.Title>
        <span
          onClick={(e: MouseEvent) => {
            onClose(e)
          }}>
          <S.CloseButton
            data-testid="close-button"
            data-cy="discounts-modal-close-button">
            <Icon as={CrossOutline} />
          </S.CloseButton>
        </span>
      </S.WrapperHeader>
      <S.WrapperContents data-testid="discounts-modal-contents">
        {totalDiscountsByType.stampedPrice && renderStampedPrice}
        {totalDiscountsByType['payment-method'] &&
          renderDiscountByPaymentMethod()}
        {totalDiscountsByType.twoPrices && renderTwoPriceDiscount()}
        {totalDiscountsByType.promo && renderCoupon}
        {totalDiscountsByType.employee && renderEmployeeDiscount}
        {totalDiscountsByType.loyaltyProgram && renderLoyaltyProgramDiscount}
        {renderTotal()}
      </S.WrapperContents>
      <Button
        isStretch
        onClick={(e: MouseEvent) => {
          onClose(e)
        }}>
        Fechar
      </Button>
    </S.WrapperModal>
  )
}

export default DiscountsModal
