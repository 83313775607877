import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgTrashOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M5.586 21.414A2 2 0 0 1 5 20V8H3V6h4V4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2h4v2h-2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-1.414-.586M15 4H9v2h6zm2 4H7v12h10zm-6 2H9v8h2zm4 0h-2v8h2z"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgTrashOutline)
const Memo = memo(ForwardRef)
export default Memo
