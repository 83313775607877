import { tv } from 'tailwind-variants'

export default tv({
  base: '',
  slots: {
    item: `
      px-10
      py-2
      
      border-b
      border-gray-200
      
      last:border-0
    `
  }
})
