import { useContext } from 'react'
import classnames from 'classnames'
import { QuickSearch } from 'search/containers'
import { Icon } from '@leroy-merlin-br/backyard-react'
import { CrossOutline } from '@backyard-ui/icons'

import { LocationInfo } from 'site/Location'
import { HeaderContext } from 'site/Header/contexts/HeaderContext'

import { ShareButtons } from './ShareButtons'
import { Cart } from '../../../Cart'
import MobileMenu from '../../MobileMenu'
import { HeaderLogo } from '../../common/HeaderLogo'
import HamburguerMenu from '../../../HamburguerMenu'
import * as S from './styled'

const RenderMobile = ({
  isAssistedSale,
  debounceTimer,
  minSearchTermLimit
}) => {
  const { isExpanded, isOpened, toggleMenu } = useContext(HeaderContext)

  const overlayClasses = classnames('new-header-mobile-overlay', {
    'is-opened': isOpened
  })

  const layerClasses = classnames('new-header-mobile-layer', {
    'is-opened': isOpened,
    'is-expanded': isExpanded
  })

  return (
    <div className="new-header-mobile">
      <S.WrapperIconCross onClick={toggleMenu}>
        <span className={overlayClasses} />
        {isOpened && <Icon as={CrossOutline} size="mega" fill="white" />}
      </S.WrapperIconCross>
      <div className="new-header-mobile-top">
        <div className="new-header-mobile-divisor left">
          <HamburguerMenu trigger={toggleMenu} />
          <HeaderLogo />
        </div>
        <div className="new-header-mobile-divisor right">
          {!isAssistedSale && (
            <a
              href="/minhas-listas-de-desejos"
              title="Minhas listas de Favoritos"
              className="wishlist">
              <i className="glyph glyph-heart"></i>
            </a>
          )}
          <ShareButtons />
          <Cart />

          {isAssistedSale && (
            <a href="/logout" title="Sair da minha conta">
              <i className="glyph glyph-signout" />
            </a>
          )}
        </div>
      </div>
      <div className="new-header-mobile-bottom">
        <QuickSearch
          debounceTimer={debounceTimer}
          minSearchTermLimit={minSearchTermLimit}
        />
        {<LocationInfo />}
      </div>
      <S.WrapperMobileMenu>
        <div className={layerClasses}>
          <nav className="menu">
            <MobileMenu />
          </nav>
        </div>
      </S.WrapperMobileMenu>
    </div>
  )
}

export default RenderMobile
