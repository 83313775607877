import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgLinkOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M5.844 20.862a5 5 0 0 1-1.622-1.084 5.006 5.006 0 0 1 0-7.071l2.829-2.828 1.414 1.414-2.829 2.828a3.005 3.005 0 0 0 0 4.243 3.007 3.007 0 0 0 4.243 0l2.828-2.829 1.414 1.414-2.828 2.829a4.99 4.99 0 0 1-3.536 1.462 5 5 0 0 1-1.913-.378M21.239 7.757a5 5 0 0 1-1.461 3.536l-2.829 2.828-1.414-1.414 2.829-2.828a3.005 3.005 0 0 0 0-4.243 3.007 3.007 0 0 0-4.243 0l-2.828 2.829-1.414-1.414 2.828-2.829a5.006 5.006 0 0 1 7.071 0 5 5 0 0 1 1.461 3.535M7.05 15.536l1.415 1.414 8.486-8.485-1.414-1.415z"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgLinkOutline)
const Memo = memo(ForwardRef)
export default Memo
