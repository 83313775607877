export const transformContentUrl = (urlString: string): string => {
  try {
    const url = new URL(urlString)
    const regex = /\/page\/(\d+)/

    const match = regex.exec(url.pathname)

    if (match) {
      const pageNumber = match[1]

      const newPath = url.pathname.replace(regex, '')
      url.pathname = newPath || '/'

      url.searchParams.set('page', pageNumber)

      return url.toString()
    } else {
      return urlString
    }
  } catch (error) {
    return urlString
  }
}
