import { SelectContactOptionsGroup } from 'frontendMyOrders/plugins/Chat/components'

import { useAppSelector } from '../../redux/store'
import { ContactSelectorOptionsType, ContactSelectorType } from '../../types'

const Options = ({
  contactOptions,
  onContactOptionClick
}: {
  contactOptions: ContactSelectorOptionsType
  onContactOptionClick: (contactOption: ContactSelectorType) => void
}) => {
  const properties = useAppSelector(state => state.properties)

  return (
    <SelectContactOptionsGroup
      options={contactOptions}
      onClick={onContactOptionClick}
      selected={properties?.data.contactType || ''}
    />
  )
}

export default Options
