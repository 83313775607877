import { generateSimpleId } from 'frontendMyOrders/utils'
import { formatDate } from 'frontendMyOrders/utils/formatters'

import { Divider, MessageBox } from '../../components'
import {
  endChatFlow,
  generateCancellationMessage,
  generateContactEmailMessage,
  generateContactMessage,
  generateContactNumberMessage,
  generateDefectiveProductMessage,
  generateDeliveryInformationMessage,
  generateEndContactMessage,
  generateGetContactMessage,
  generateIncorrectProductMessage,
  generateOtherSubjectsMessage,
  generateProductInformationMessage,
  generateReversalMessage,
  generateShiftReturnMessage,
  generateInvoiceMessage,
  generateInvoiceEmptyMessage,
  generateSubOptionOthersCancellationMessage,
  generateSubOptionConfirmationCancellationMessage
} from '../../dictionary'
import { generateSubOptionShiftReturnMessage } from '../../dictionary/shift-return'
import { setMessages, updateTernarySelected } from '../redux/messages'
import {
  setOption,
  setSubOption,
  setCanSendMessage,
  setProperties,
  PropertiesStateType
} from '../redux/properties'
import { useAppDispatch, useAppSelector } from '../redux/store'
import type {
  ApiPostNotify,
  ApiPostResponse,
  ContactSelectorType,
  ContactType,
  MessageType,
  SelectOptionType,
  StepType,
  TernarySelectorType,
  TransferChatPostResponse
} from '../types'
import { generateMissingProductMessage } from '../../dictionary/missing-product'
import { deliveryInformationMessage } from '../utils/delivery-information-message'
import {
  generateTernaryMessage,
  generateTernaryThruthyMessage
} from '../../dictionary/ternary'
import { beginMiraklFlow } from '../../dictionary/mirakl'
import { useMarketplaceChatService } from '../hooks'
import { isSameDate } from '../utils/isSameDate'

const Chat = () => {
  const dispatch = useAppDispatch()

  const messages = useAppSelector(state => state.messages)
  const options = useAppSelector(state => state.options)
  const properties = useAppSelector(state => state.properties)

  const marketplaceChatService = useMarketplaceChatService()

  const sendTicket = async ({
    currentContact,
    currentContactType,
    contactIsOriginal,
    option,
    subOption,
    threadId
  }: {
    currentContact: string
    currentContactType: ContactType
    contactIsOriginal: boolean
    option: string
    subOption: string
    threadId: string
  }): Promise<ApiPostResponse['data'] | undefined> => {
    const { orderId, marketplaceOrder } = properties.data

    if (currentContactType) {
      const response = await marketplaceChatService.post(
        threadId,
        {
          type: 'ticket',
          option,
          subOption,
          contact: currentContact,
          contactIsOriginal: contactIsOriginal ? 1 : 0,
          contactType: currentContactType
        },
        orderId,
        marketplaceOrder
      )

      if (response) {
        return response
      }
    }
  }

  const transferChat = async ({
    currentContact,
    currentContactType,
    contactIsOriginal,
    threadId
  }: {
    currentContact: string
    currentContactType: ContactType
    contactIsOriginal: boolean
    threadId: string
  }) => {
    const { orderId, marketplaceOrder } = properties.data

    if (currentContactType) {
      const response = await marketplaceChatService.transfer({
        threadId,
        data: {
          contact: currentContact,
          contactIsOriginal: contactIsOriginal ? 1 : 0,
          contactType: currentContactType
        },
        orderId,
        marketplaceOrder
      })

      if (response) {
        dispatch(setProperties({ chatStatus: 'transfer' }))
        return response
      }
    }
  }

  const notify = async ({
    option,
    subOption
  }: ApiPostNotify): Promise<ApiPostResponse['data'] | undefined> => {
    const { orderId, marketplaceOrder } = properties.data

    const response = await marketplaceChatService.notify(
      {
        option,
        subOption
      },
      orderId,
      marketplaceOrder
    )

    if (response) {
      dispatch(setProperties({ chatStatus: 'notify' }))
      return response
    }
  }

  const onOptionClick = (option: SelectOptionType | string) => {
    dispatch(setOption(option))

    if (!properties.data.option) {
      const subOptions = options.data.find(
        currentOption => currentOption.id === option
      )?.subOptions

      if (option === 'reversal') {
        dispatch(
          setMessages(
            generateReversalMessage({
              createdAt: new Date().toISOString()
            })
          )
        )
      }

      if (option === 'invoice') {
        if (properties.data.invoices) {
          const accessKey = properties.data.invoices[0]?.accessKey ?? ''
          dispatch(
            setMessages(
              generateInvoiceMessage({
                createdAt: new Date().toISOString(),
                accessKey
              })
            )
          )
          dispatch(
            setMessages(
              generateTernaryMessage({
                createdAt: new Date().toISOString()
              })
            )
          )
        } else {
          dispatch(
            setMessages(
              generateInvoiceEmptyMessage({
                createdAt: new Date().toISOString()
              })
            )
          )
          dispatch(
            setMessages(
              generateTernaryMessage({
                createdAt: new Date().toISOString()
              })
            )
          )
        }
      }

      if (option === 'deliveryInformation') {
        const currentStep = properties.data?.steps?.find(
          (step: StepType) => step.current
        )

        const message = deliveryInformationMessage({
          slug: currentStep?.slug ?? '',
          shippingAddressFormatted:
            properties.data.shippingAddressFormatted ?? '',
          date: currentStep?.date ?? '',
          deliveryDate: properties.data.deliveryDate?.date ?? '',
          trackingCode: properties.data.trackingCode ?? '',
          trackingUrl: properties.data.trackingUrl ?? '',
          description: currentStep?.description ?? ''
        })

        dispatch(
          setMessages(
            generateDeliveryInformationMessage({
              message,
              createdAt: new Date().toISOString()
            })
          )
        )

        dispatch(
          setMessages(
            generateTernaryMessage({
              createdAt: new Date().toISOString()
            })
          )
        )
      }

      if (option === 'cancellation') {
        dispatch(
          setMessages(
            generateCancellationMessage(new Date().toISOString(), subOptions)
          )
        )
      }

      if (option === 'defectiveProduct') {
        dispatch(setMessages(generateDefectiveProductMessage(properties.data)))
      }

      if (option === 'incorrectProduct') {
        dispatch(setMessages(generateIncorrectProductMessage(properties.data)))
      }

      if (option === 'shiftReturn') {
        dispatch(
          setMessages(
            generateShiftReturnMessage(new Date().toISOString(), subOptions)
          )
        )
      }

      if (option === 'productInformation') {
        dispatch(
          setMessages(generateProductInformationMessage(properties.data))
        )
      }

      if (option === 'otherSubject') {
        dispatch(setMessages(generateOtherSubjectsMessage(properties.data)))
      }

      if (option === 'missingProduct') {
        dispatch(setMessages(generateMissingProductMessage(properties.data)))
      }

      const beginChat = options.data.find(
        current => current.id === option
      )?.beginChat

      if (beginChat) {
        dispatch(setCanSendMessage(true))
      }
    }
  }

  const onSubOptionClick = (subOption: string) => {
    dispatch(setSubOption(subOption))

    const type = options.data.find(
      current => current.id === properties.data.option
    )?.type

    if (!properties.data.subOption) {
      if (properties.data.option === 'cancellation') {
        if (type === 'ticket') {
          if (subOption === 'other') {
            dispatch(
              setMessages(
                generateSubOptionOthersCancellationMessage({
                  createdAt: new Date().toISOString()
                })
              )
            )
          } else {
            dispatch(
              setMessages(
                generateSubOptionConfirmationCancellationMessage({
                  createdAt: new Date().toISOString(),
                  option: properties.data.option,
                  subOption: subOption,
                  options: options.data
                })
              )
            )
          }
        } else if (type === 'chat') {
          dispatch(
            setMessages(
              beginMiraklFlow({
                createdAt: new Date().toISOString(),
                shopName: properties.data.shopName
              })
            )
          )
        }
      }

      if (properties.data.option === 'shiftReturn') {
        dispatch(
          setMessages(generateSubOptionShiftReturnMessage(properties.data))
        )
      }
    }

    const beginChatWithSubOption = options.data.find(
      current => current.id === properties.data.option
    )?.beginChatWithSubOption

    if (beginChatWithSubOption) {
      dispatch(setCanSendMessage(true))
    }

    const beginChatWhenConfirmContact = options.data.find(
      current => current.id === properties.data.option
    )?.beginChatWhenConfirmContact

    if (beginChatWhenConfirmContact && subOption === 'other') {
      dispatch(setCanSendMessage(true))
    }
  }

  const generateContactFlow = async ({
    ternaryOption,
    data
  }: {
    ternaryOption: TernarySelectorType
    data: PropertiesStateType['data']
  }): Promise<
    ApiPostResponse['data'] | TransferChatPostResponse['data'] | undefined
  > => {
    if (data.contactType) {
      if (ternaryOption.key === 'SIM') {
        const currentContact =
          data.contactType === 'email'
            ? data.userResourcesEmail
            : data.userResourcesPhone

        dispatch(
          setProperties({
            contactSelected: currentContact,
            contactIsOriginal: true
          })
        )

        const type = options.data.find(
          current => current.id === properties.data.option
        )?.type

        const response =
          type === 'ticket'
            ? await sendTicket({
                currentContact,
                currentContactType: data.contactType,
                contactIsOriginal: true,
                option: data.option ?? '',
                subOption: data.subOption ?? '',
                threadId: data.threadId
              })
            : await transferChat({
                currentContact,
                currentContactType: data.contactType,
                contactIsOriginal: true,
                threadId: data.threadId
              })

        dispatch(
          setMessages(
            generateEndContactMessage({
              protocol: response?.ticket.protocol ?? '',
              createdAt: new Date().toISOString(),
              contactType: data.contactType,
              contact:
                data.contactType === 'email'
                  ? data.userResourcesEmail
                  : data.userResourcesPhone
            })
          )
        )

        dispatch(
          setProperties({
            chatStatus: type === 'ticket' ? 'ticket' : 'transfer'
          })
        )

        if (response) {
          return response
        }
      }

      if (ternaryOption.key === 'NAO') {
        dispatch(
          setMessages(
            generateGetContactMessage({
              createdAt: new Date().toISOString(),
              contactType: data.contactType
            })
          )
        )
      }
    } else {
      if (ternaryOption.key === 'SIM') {
        dispatch(
          setMessages(
            generateContactMessage({
              createdAt: new Date().toISOString()
            })
          )
        )
      }

      if (ternaryOption.key === 'NAO') {
        dispatch(
          setMessages(
            endChatFlow({
              createdAt: new Date().toISOString()
            })
          )
        )
      }
    }
  }

  const onTernaryOptionClick = async (ternaryOption: TernarySelectorType) => {
    let label: 'SIM' | 'NAO' | '' = ''
    const { option } = properties.data
    const flowsWithConfirmation = ['reversal', 'cancellation']

    switch (ternaryOption.key) {
      case 'SIM':
        label = 'SIM'
        break
      case 'NAO':
        label = 'NAO'
        break
      default:
        return
    }

    if (option && flowsWithConfirmation.includes(option)) {
      dispatch(updateTernarySelected(label))

      const response = await generateContactFlow({
        data: properties.data,
        ternaryOption: ternaryOption
      })

      if (response?.id) {
        dispatch(
          setProperties({
            threadId: response?.id
          })
        )
      }
    } else {
      dispatch(updateTernarySelected(label))

      if (properties.data.contactType) {
        const response = await generateContactFlow({
          data: properties.data,
          ternaryOption
        })

        if (response?.id) {
          dispatch(
            setProperties({
              threadId: response?.id
            })
          )

          return
        }

        return
      }

      if (ternaryOption.key === 'SIM') {
        dispatch(
          setMessages(
            generateTernaryThruthyMessage({
              createdAt: new Date().toISOString()
            })
          )
        )
        if (properties.data.option) {
          notify({
            option: properties.data.option,
            subOption: properties.data.subOption || ''
          })
        }
      } else {
        dispatch(
          setMessages(
            beginMiraklFlow({
              shopName: properties.data.shopName,
              createdAt: new Date().toISOString()
            })
          )
        )
      }
    }
    const beginChatWithConfirmation = options.data.find(
      current => current.id === properties.data.option
    )?.beginChatWithConfirmation

    const beginChatWhenConfirmContact = options.data.find(
      current => current.id === properties.data.option
    )?.beginChatWhenConfirmContact

    if (beginChatWhenConfirmContact && ternaryOption.key === 'NAO') {
      dispatch(setCanSendMessage(true))
    }

    if (beginChatWithConfirmation && ternaryOption.key === 'NAO') {
      dispatch(setCanSendMessage(true))
    }

    const hasTernarySelected = messages.data.list.find(
      message => message.ternarySelected
    )

    if (
      option &&
      flowsWithConfirmation.includes(option) &&
      ternaryOption.key === 'NAO' &&
      !hasTernarySelected
    ) {
      dispatch(setCanSendMessage(false))
    }
  }

  const onContactOptionClick = (contact: ContactSelectorType) => {
    dispatch(
      setProperties({
        contactType: contact.key
      })
    )

    const type = options.data.find(
      current => current.id === properties.data.option
    )?.type

    if (
      properties.data.option === 'reversal' ||
      properties.data.option === 'cancellation' ||
      type === 'chat'
    ) {
      if (contact.key === 'email') {
        dispatch(
          setMessages(
            generateContactEmailMessage({
              createdAt: new Date().toISOString(),
              email: properties.data.userResourcesEmail
            })
          )
        )
      } else if (contact.key === 'phone') {
        dispatch(
          setMessages(
            generateContactNumberMessage({
              createdAt: new Date().toISOString(),
              phoneNumber: properties.data.userResourcesPhone,
              type: 'phone'
            })
          )
        )
      } else if (contact.key === 'whatsapp') {
        dispatch(
          setMessages(
            generateContactNumberMessage({
              createdAt: new Date().toISOString(),
              phoneNumber: properties.data.userResourcesPhone,
              type: 'whatsapp'
            })
          )
        )
      }
    }
  }

  const nameForMessageBox = (from: string) => {
    switch (from) {
      case 'bot':
        return 'Leroy Merlin Automático'
      case 'user':
        return properties.data.userName
      case 'seller':
        return properties.data.shopName
      default:
        return 'Leroy Merlin Automático'
    }
  }

  const renderMessages = (messages: MessageType[]) => {
    return messages.map((message, index) => {
      const currentMessageDate = new Date(message.time)
      let showDivider = false

      if (index === 0) {
        showDivider = true
      } else {
        const previousMessageDate = new Date(messages[index - 1].time)
        showDivider = !isSameDate(currentMessageDate, previousMessageDate)
      }

      return (
        <div key={generateSimpleId()}>
          {showDivider && (
            <Divider
              text={formatDate(message.time, 'long_weekday_day_month')}
            />
          )}
          <MessageBox
            timestamp={formatDate(message.time, 'short_hour')}
            type={message.from}
            message={message.text}
            name={nameForMessageBox(message.from)}
            attachments={message.attachments}
            options={message.options}
            onOptionClick={onOptionClick}
            subOptions={message.subOptions}
            onSubOptionClick={onSubOptionClick}
            ternaryOptions={message?.ternaryOptions}
            ternarySelected={message.ternarySelected ?? ''}
            onTernaryOptionClick={onTernaryOptionClick}
            contactOptions={message.contactOptions}
            onContactOptionClick={onContactOptionClick}
            isOnMobileApp={properties.data.isOnMobileApp}
          />
        </div>
      )
    })
  }

  const shouldRenderMessages = messages?.data.list && properties

  if (!shouldRenderMessages) {
    return null
  }

  return <>{renderMessages(messages.data.list)}</>
}

export default Chat
