import { useState, useEffect } from 'react'
import { Badge, Text } from '@leroy-merlin-br/backyard-react'

import emitter from 'scripts/utils/emitter'

import { useUserResources } from 'shared/hooks'
import { OfferSchema } from 'shared/schemas/OfferSchema'

import {
  FromPrice,
  Installments,
  PriceTagLabel,
  UnavailableProductMessage
} from './components'
import * as S from './styled'

const withPropsFromEvent = (Component, event) => {
  const WrapperComponent = props => {
    const [propsFromEvents, setPropsFromEvents] = useState()

    useEffect(() => {
      emitter.on(event, setPropsFromEvents)

      return () => emitter.removeListener(event, setPropsFromEvents)
    }, [])

    const isNotFirstLoad = Boolean(propsFromEvents)

    const {
      productPriceTag,
      isBlackWeekend,
      isAssistedSale,
      hidePrice,
      isSoldOut,
      isServicesEnabled,
      hasCrossServices,
      storeName
    } = props

    const newProps = isNotFirstLoad
      ? {
          productPriceTag,
          isBlackWeekend,
          isAssistedSale,
          hidePrice,
          isSoldOut,
          isServicesEnabled,
          hasCrossServices,
          storeName
        }
      : props

    return <Component {...newProps} {...propsFromEvents} />
  }

  WrapperComponent.displayName = `withPropsFromEvent(${
    Component.displayName || 'Component'
  })`

  return WrapperComponent
}

const PriceTag = ({
  purchaseButtons: propsButtons,
  isAssistedSale,
  isOnDemand,
  isOfferSchemaEnabled,
  unit,
  isOutlet: propsIsOutlet,
  isSoldOut,
  hidePrice,
  toPriceIntegers,
  toPriceDecimals,
  regionPriceIntegers,
  regionPriceDecimals,
  fromPriceIntegers,
  fromPriceDecimals,
  suggestedPriceIntegers,
  suggestedPriceDecimals,
  discountPercentage,
  packagingPackaging,
  packagingPriceIntegers,
  packagingPriceDecimals,
  packagingLabel,
  packagingUnit,
  installmentsAmount,
  installmentsValue,
  installmentsTotalValue,
  brandedInstallmentsAmount,
  shopName,
  storeName,
  shouldRenderOutletBadge,
  shouldRenderFreeShippingBadge,
  paymentMethodsDiscount,
  discountType,
  featureInstallmentDiscount: propsFeatureInstallmentDiscount
}) => {
  const [isUnavailable, setIsUnavailable] = useState(false)

  const isFeatureInstallmentDiscount =
    propsFeatureInstallmentDiscount &&
    JSON.parse(propsFeatureInstallmentDiscount)
  const isOutlet = propsIsOutlet && JSON.parse(propsIsOutlet)
  const hasSuggestedPrice = Boolean(suggestedPriceIntegers)
  const hasFromPrice = Boolean(fromPriceIntegers)
  const discount = parseFloat(discountPercentage, 10)

  if (hasSuggestedPrice) {
    fromPriceIntegers = suggestedPriceIntegers
    fromPriceDecimals = suggestedPriceDecimals
  }

  const isDiscountByPaymentMethod = discountType === 'payment-method'
  const shouldShowPaymentMethodDiscoutLabel =
    discountType === 'payment-method' || discountType === 'twoPrices'
  const isPromotional = Boolean(hasFromPrice) && !isFeatureInstallmentDiscount

  const shouldShowInstallments = parseInt(installmentsAmount) > 1

  const urlParams = new URLSearchParams(window.location.search)
  const hasStoreCode =
    shopName === 'Leroy Merlin' && urlParams.has('store_code')
  const hasRegion = urlParams.has('region')

  const { userResources } = useUserResources()

  useEffect(() => {
    emitter.on('pdp:seller:unavailable', setIsUnavailable)

    return () => {
      emitter.off('pdp:seller:unavailable', setIsUnavailable)
    }
  }, [])

  const getProductAvailability = () => {
    if (isOnDemand) {
      return 'LimitedAvailability'
    }

    const purchaseButtons = propsButtons ? JSON.parse(propsButtons) : {}
    const isEcommerceButtonEnabled = purchaseButtons?.ecommerce?.enabled
    const isPickupButtonEnabled = purchaseButtons?.pickupInStore?.enabled

    if (!isEcommerceButtonEnabled && !isPickupButtonEnabled) {
      return 'OutOfStock'
    }

    return 'InStock'
  }

  const renderRegionName = () => {
    const hasRegionId = urlParams.has('region_id')
    const isOtherRegion = urlParams.get('region') === 'outros'

    const hasGoogleMerchantParam =
      urlParams.has('gclid') ||
      urlParams.has('gad_source') ||
      urlParams.has('srsltid')

    if (hasGoogleMerchantParam && isOtherRegion && !hasRegionId) {
      return ' - demais regiões'
    }

    const regionName = userResources?.regionName

    if (!hasRegion || !regionName) {
      return null
    }

    return ` - ${regionName}`
  }

  if (hidePrice || isSoldOut || isUnavailable) {
    return (
      <S.PriceTag isUnavailable data-testid="unavailable">
        {isOfferSchemaEnabled && (
          <OfferSchema
            availability="OutOfStock"
            productUrl={window.location.href}
            productPrice={{
              integers: toPriceIntegers,
              decimals: toPriceDecimals
            }}
          />
        )}

        <UnavailableProductMessage
          isAssistedSale={isAssistedSale}
          hidePrice={hidePrice}
        />
      </S.PriceTag>
    )
  }

  return (
    <S.PriceTag isOutlet={isOutlet} isPromotional={isPromotional}>
      {isOfferSchemaEnabled && (
        <OfferSchema
          availability={getProductAvailability()}
          productUrl={window.location.href}
          productPrice={{
            integers: toPriceIntegers,
            decimals: toPriceDecimals
          }}
        />
      )}

      <PriceTagLabel
        discount={discount}
        isOutlet={isOutlet}
        isDiscountByPaymentMethod={isDiscountByPaymentMethod}
        hasFromPrice={hasFromPrice}
        featureInstallmentDiscount={isFeatureInstallmentDiscount}
      />

      <S.ToPriceContainer>
        <S.Container>
          <div>
            <FromPrice
              isOutlet={isOutlet}
              price={{
                integers: regionPriceIntegers,
                decimals: regionPriceDecimals
              }}
              unit={unit}
              hidePrice={isFeatureInstallmentDiscount}
            />
            <FromPrice
              isOutlet={isOutlet}
              price={{
                integers: fromPriceIntegers,
                decimals: fromPriceDecimals
              }}
              unit={unit}
              hidePrice={isFeatureInstallmentDiscount}
            />
          </div>
        </S.Container>

        <S.Container>
          <div>
            <S.ToPriceInteger data-cy="product-price-integer">
              R$ {toPriceIntegers},{toPriceDecimals}
            </S.ToPriceInteger>
            <span data-cy="product-price-decimal"> /{unit}</span>
          </div>
        </S.Container>
        {shouldShowPaymentMethodDiscoutLabel && (
          <S.Container>
            <Text
              as="span"
              color={isOutlet ? 'white' : 'black'}
              size="kilo"
              noMargin
              data-cy="info-payment-method"
              data-testid="info-payment-method">
              {paymentMethodsDiscount}
            </Text>
          </S.Container>
        )}
      </S.ToPriceContainer>

      {packagingPackaging && (
        <S.Container>
          <S.PackagingPrice>
            <Text as="strong" isBold noMargin>
              R${packagingPriceIntegers},{packagingPriceDecimals} /{' '}
              {packagingLabel}{' '}
            </Text>

            <S.Dot />

            <Text as="span" noMargin>
              {' '}
              cada {packagingLabel} possui{' '}
              {packagingPackaging.replace('.', ',')}
              {packagingUnit}
            </Text>
          </S.PackagingPrice>
        </S.Container>
      )}

      <S.Container>
        <div>
          {shouldShowInstallments && (
            <>
              <Installments
                installments={{
                  amount: parseInt(installmentsAmount, 10),
                  value: installmentsValue,
                  totalValue: installmentsTotalValue
                }}
              />
              <Installments
                installments={{
                  amount: parseInt(brandedInstallmentsAmount, 10)
                }}
                isBranded
              />
            </>
          )}

          <S.ShopName>
            <Text color="n400" size="kilo" noMargin>
              Vendido e entregue por{' '}
              <Text
                as="strong"
                color="black"
                size="kilo"
                isBold
                data-seller-selected
                data-cy="seller-name"
                data-testid="seller-name-with-region">
                {shopName}
                {hasStoreCode && ` - ${storeName}`}
                {renderRegionName()}
              </Text>
            </Text>
          </S.ShopName>
        </div>
      </S.Container>

      <S.Container>
        {shouldRenderFreeShippingBadge && (
          <Badge appearance="critical">Frete Grátis</Badge>
        )}

        {shouldRenderOutletBadge && (
          <S.OutletBadgeWrapper>
            <Badge>Black Friday</Badge>
          </S.OutletBadgeWrapper>
        )}
      </S.Container>
    </S.PriceTag>
  )
}

PriceTag.displyName = 'PriceTag'

export default withPropsFromEvent(PriceTag, 'buybox:sellerChange')
