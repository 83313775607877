import { AppProps } from './entry'
import { HomeProps } from './pages/Home/Home'

export const maskCEP = (value: string) => {
  const numericCep = value.replace(/\D/g, '')

  return numericCep.replace(/^(\d{5})(\d{3})$/, '$1-$2')
}

export const parsedItemsNewMyAccount = (value: AppProps['itemsMyAccount']) => {
  return JSON.parse(value as unknown as string) as HomeProps['itemsMyAccount']
}
