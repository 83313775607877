import { memo } from 'react'
import { UserInterface } from 'frontendMyOrders/user-interface/typography'

import * as S from './styled'

interface DividerProps {
  text?: string
}

const Divider = ({ text }: DividerProps) => {
  if (!text) {
    return <S.OnlyLine />
  }

  return (
    <S.DividerContainer>
      <S.Line />
      <UserInterface size="xs" appearance="neutral-soft">
        {text}
      </UserInterface>
      <S.Line />
    </S.DividerContainer>
  )
}

export default memo(Divider)
