import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgArrowCircleUpOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M2 11.994c0-5.514 4.486-10 10.001-10.001 5.514.001 10 4.487 10 10.001s-4.486 10-10.001 10c-5.513 0-9.999-4.486-10-10m2 0c.001 4.411 3.59 8 8 8 4.412 0 8.001-3.589 8.001-8s-3.589-8-8-8.001C7.589 3.994 4 7.583 4 11.994m3.996.012 4.005-4.005 4.004 4.005h-3.004V16h-2v-3.994z"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgArrowCircleUpOutline)
const Memo = memo(ForwardRef)
export default Memo
