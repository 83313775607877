import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgPinOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M12 22a1 1 0 0 1-.58-.186C11.116 21.599 3.971 16.44 4 9.995 4 5.589 7.59 2 12 2s8 3.589 8 8c.03 6.44-7.116 11.599-7.42 11.814A1 1 0 0 1 12 22m6-11.995A6.007 6.007 0 0 0 12 4c-3.309 0-6 2.691-6 6-.02 4.441 4.39 8.427 6 9.735 1.612-1.307 6.021-5.292 6-9.73M16 10c0 2.206-1.794 4-4 4s-4-1.794-4-4 1.794-4 4-4 4 1.794 4 4m-2 0c0-1.103-.897-2-2-2s-2 .897-2 2 .897 2 2 2 2-.897 2-2"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgPinOutline)
const Memo = memo(ForwardRef)
export default Memo
