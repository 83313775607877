import { useEffect } from 'react'

export const useKeyupCardObserver = (callback: () => void) => {
  useEffect(() => {
    const cardNumberInputEl = document.querySelector('[data-card="number"]')

    if (cardNumberInputEl) {
      cardNumberInputEl.addEventListener('keyup', callback)
    }

    return () => {
      if (cardNumberInputEl) {
        cardNumberInputEl.removeEventListener('keyup', callback)
      }
    }
  }, [callback])
}
