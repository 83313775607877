import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgReceiptOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M18 11h3a1 1 0 0 1 1 1v6c0 1.654-1.346 3-3 3H5c-1.654 0-3-1.346-3-3V4a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1zM4.293 18.707A1 1 0 0 0 5 19h11.171A3 3 0 0 1 16 18V5H4v13a1 1 0 0 0 .293.707m15.414 0A1 1 0 0 0 20 18v-5h-2v5a1 1 0 0 0 1.707.707M14 7H6v2h8zm0 4H6v2h8zm0 4h-3v2h3z"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgReceiptOutline)
const Memo = memo(ForwardRef)
export default Memo
