import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

export const ContentInfo = styled('div')(
  ({ theme }: Theme) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${theme.spacings.peta};
  `
)

export const Message = styled('div')(
  ({ theme }: Theme) => css`
    display: flex;
    justify-content: center;

    p {
      ${theme.typography.text.mega};
      color: ${theme.colors.n500};
      text-align: center;

      ${theme.media.mega} {
        max-width: 350px;
      }
    }
  `
)

export const DeniedCard = styled('div')(
  ({ theme }: Theme) => css`
    border: 1px solid ${theme.colors.n100};
    border-radius: ${theme.borderRadius.giga};
    padding: ${theme.spacings.tera};
    width: 100%;
  `
)
