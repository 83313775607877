import { createUrlInvoice } from 'scripts/react-components/checkout/components/MyOrders/utils/createUrlInvoice'

export const generateInvoiceMessage = ({
  createdAt,
  accessKey
}: {
  createdAt: string
  accessKey: string
}) => {
  const url = createUrlInvoice(accessKey)

  return {
    text: `Acesse sua nota fiscal clicando em <a href="${url}" target="_blank"><u>Portal da Nota Fiscal Eletrônica</u></a> consultando pela chave de acesso ${accessKey}.`,
    from: 'bot',
    time: createdAt || new Date().toISOString()
  }
}

export const generateInvoiceEmptyMessage = ({
  createdAt
}: {
  createdAt: string
}) => {
  return {
    text: 'Sua nota fiscal estará disponível depois da confirmação do pagamento.',
    from: 'bot',
    time: createdAt || new Date().toISOString()
  }
}
