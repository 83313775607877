const CATEGORY_PAGE_REFERRER = 'category-page'
const SIMPLEX_PAGE_REFERRER = 'simplex-page'

type ElementWithDataset = {
  dataset: {
    page: string
  }
}

type ElementWithContent = {
  content?: string
}

const HAS_SIMPLEX_PAGE_META_TAG = Boolean(
  (
    document.querySelector(
      'meta[name="simplex-page"]'
    ) as unknown as ElementWithContent
  )?.content
)

const IS_CATEGORY =
  (document.querySelector('[data-page]') as unknown as ElementWithDataset)
    ?.dataset.page === 'category'

const IS_SIMPLEX = document.location.href.includes('indexa_id')

export {
  CATEGORY_PAGE_REFERRER,
  IS_CATEGORY,
  IS_SIMPLEX,
  HAS_SIMPLEX_PAGE_META_TAG,
  SIMPLEX_PAGE_REFERRER
}
