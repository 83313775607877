import reactComponentLoader from 'scripts/react-component-loader'

import App from './App'
import { parsedItemsNewMyAccount } from './utils'

export type AppProps = {
  itemsMyAccount: string
  isCaptchaEnabled: boolean
  isCaptchaEnterpriseEnabled: boolean
  captchaKey: string
}

const AppWithProps = ({
  itemsMyAccount,
  isCaptchaEnabled,
  isCaptchaEnterpriseEnabled,
  captchaKey
}: AppProps) => {
  const itemsMenu = parsedItemsNewMyAccount(itemsMyAccount)

  return (
    <App
      itemsMyAccount={itemsMenu}
      isCaptchaEnabled={isCaptchaEnabled}
      isCaptchaEnterpriseEnabled={isCaptchaEnterpriseEnabled}
      captchaKey={captchaKey}
    />
  )
}

reactComponentLoader(AppWithProps, '[data-page-my-account]')

export default AppWithProps
