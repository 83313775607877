import { TernarySelectorOptionsType } from '../MarketPlace/types'

export const generateReversalMessage = ({
  createdAt
}: {
  createdAt: string
}) => {
  const ternaryOptions: TernarySelectorOptionsType = [
    {
      label: 'Sim',
      key: 'SIM'
    },
    {
      label: 'Não',
      key: 'NAO'
    }
  ]

  return {
    text: 'Você confirma o assunto <strong>Estorno não realizado</strong>?',
    from: 'bot',
    time: createdAt || new Date().toISOString(),
    ternaryOptions
  }
}
