import {
  AppleOutline,
  ArrowCircleDownOutline,
  ArrowCircleDownSolid,
  ArrowCircleLeftOutline,
  ArrowCircleLeftSolid,
  ArrowCircleRightOutline,
  ArrowCircleRightSolid,
  ArrowCircleUpOutline,
  ArrowCircleUpSolid,
  ArrowDownOutline,
  ArrowLeftOutline,
  ArrowRightOutline,
  ArrowUpOutline,
  AwardOutline,
  AwardSolid,
  BadgeDollarSolid,
  BadgeOfferSolid,
  BarcodeOutline,
  BathOutline,
  BedOutline,
  BellOutline,
  BellSolid,
  BlenderOutline,
  BlockOutline,
  BoneOutline,
  BrickOutline,
  BroomOutline,
  BulbOutline,
  CaixaOutline,
  CalendarEventOutline,
  CalendarOutline,
  CartAddOutline,
  CartOutline,
  CartSolid,
  CctvOutline,
  ChairOutline,
  CheckCircleOutline,
  CheckOutline,
  ChevronDownOutline,
  ChevronLeftOutline,
  ChevronRightOutline,
  ChevronUpOutline,
  ClockOutline,
  ClosetOutline,
  CogOutline,
  CollapseOutline,
  CopyOutline,
  CreditCardOutline,
  DangerOutline,
  DangerSolid,
  DesktopOutline,
  DiamondOutline,
  DislikeOutline,
  DislikeSolid,
  DollarCircleOutline,
  DollarOutline,
  DonateOutline,
  DonateSolid,
  DoorOpenOutline,
  DotsHorizontalOutline,
  DownloadOutline,
  EditOutline,
  EmojiDisappointedOutline,
  EmojiDisappointedSolid,
  EmojiHappyOutline,
  EmojiHappySolid,
  EmojiMehOutline,
  EmojiMehSolid,
  EmojiSadOutline,
  EmojiSadSolid,
  EmojiSatisfiedOutline,
  EmojiSatisfiedSolid,
  EnvelopeOutline,
  EnvelopeSolid,
  ExpandOutline,
  FacebookOutline,
  FileOutline,
  FilterOutline,
  FloorOutline,
  FridgeOutline,
  GiftOutline,
  GiftSolid,
  GithubOutline,
  GoogleOutline,
  HeartOutline,
  HeartSolid,
  HelpOutline,
  HelpSolid,
  HideOutline,
  HomeOutline,
  HomeSolid,
  ImageOutline,
  InfoOutline,
  InfoSolid,
  InstagramOutline,
  LeafOutline,
  LeafSolid,
  LikeOutline,
  LikeSolid,
  LinkOutline,
  LmvcOutline,
  LockedOutline,
  MastercardOutline,
  MenuOutline,
  MessageOutline,
  MessageSolid,
  MicrophoneOutline,
  MicrophoneSolid,
  MinusOutline,
  MoonOutline,
  MoonSolid,
  NailOutline,
  PackageOutline,
  PackageSolid,
  PaintRollOutline,
  PhoneOutline,
  PhoneSolid,
  PinOutline,
  PinterestOutline,
  PipeOutline,
  PixOutline,
  PlantOutline,
  PlayOutline,
  PlugOutline,
  PlusOutline,
  PurchaseTagOutline,
  PurchaseTagSolid,
  QrOutline,
  QrScanOutline,
  ReceiptOutline,
  ReceiptSolid,
  RefreshOutline,
  RestaurantOutline,
  RulerOutline,
  SearchOutline,
  ShareOutline,
  ShareSolid,
  ShowOutline,
  SliderOutline,
  SortDownOutline,
  SortUpOutline,
  StarHalfOutline,
  StarOutline,
  StarSolid,
  StoreLeroyOutline,
  StoreOutline,
  SuccessSolid,
  SunOutline,
  SunSolid,
  SupportOutline,
  TiktokOutline,
  TransferOutline,
  TrashOutline,
  TruckFastOutline,
  TruckOutline,
  TwitterOutline,
  UndoOutline,
  UnlockedOutline,
  UploadOutline,
  UserOutline,
  UserSolid,
  VisaOutline,
  VolumeFullOutline,
  WarningOutline,
  WarningSolid,
  WasherOutline,
  WhatsappOutline,
  WindOutline,
  WoodOutline,
  WrenchOutline,
  XOutline,
  YoutubeOutline,
  ZoomInOutline,
  ZoomOutOutline
} from '../components'

export const IconNames = {
  'apple-outline': AppleOutline,
  'arrow-circle-down-outline': ArrowCircleDownOutline,
  'arrow-circle-down-solid': ArrowCircleDownSolid,
  'arrow-circle-left-outline': ArrowCircleLeftOutline,
  'arrow-circle-left-solid': ArrowCircleLeftSolid,
  'arrow-circle-right-outline': ArrowCircleRightOutline,
  'arrow-circle-right-solid': ArrowCircleRightSolid,
  'arrow-circle-up-outline': ArrowCircleUpOutline,
  'arrow-circle-up-solid': ArrowCircleUpSolid,
  'arrow-down-outline': ArrowDownOutline,
  'arrow-left-outline': ArrowLeftOutline,
  'arrow-right-outline': ArrowRightOutline,
  'arrow-up-outline': ArrowUpOutline,
  'award-outline': AwardOutline,
  'award-solid': AwardSolid,
  'badge-dollar-solid': BadgeDollarSolid,
  'badge-offer-solid': BadgeOfferSolid,
  'barcode-outline': BarcodeOutline,
  'bath-outline': BathOutline,
  'bed-outline': BedOutline,
  'bell-outline': BellOutline,
  'bell-solid': BellSolid,
  'blender-outline': BlenderOutline,
  'block-outline': BlockOutline,
  'bone-outline': BoneOutline,
  'brick-outline': BrickOutline,
  'broom-outline': BroomOutline,
  'bulb-outline': BulbOutline,
  'caixa-outline': CaixaOutline,
  'calendar-event-outline': CalendarEventOutline,
  'calendar-outline': CalendarOutline,
  'cart-add-outline': CartAddOutline,
  'cart-outline': CartOutline,
  'cart-solid': CartSolid,
  'cctv-outline': CctvOutline,
  'chair-outline': ChairOutline,
  'check-circle-outline': CheckCircleOutline,
  'check-outline': CheckOutline,
  'chevron-down-outline': ChevronDownOutline,
  'chevron-left-outline': ChevronLeftOutline,
  'chevron-right-outline': ChevronRightOutline,
  'chevron-up-outline': ChevronUpOutline,
  'clock-outline': ClockOutline,
  'closet-outline': ClosetOutline,
  'cog-outline': CogOutline,
  'collapse-outline': CollapseOutline,
  'copy-outline': CopyOutline,
  'credit-card-outline': CreditCardOutline,
  'danger-outline': DangerOutline,
  'danger-solid': DangerSolid,
  'desktop-outline': DesktopOutline,
  'diamond-outline': DiamondOutline,
  'dislike-outline': DislikeOutline,
  'dislike-solid': DislikeSolid,
  'dollar-circle-outline': DollarCircleOutline,
  'dollar-outline': DollarOutline,
  'donate-outline': DonateOutline,
  'donate-solid': DonateSolid,
  'door-open-outline': DoorOpenOutline,
  'dots-horizontal-outline': DotsHorizontalOutline,
  'download-outline': DownloadOutline,
  'edit-outline': EditOutline,
  'emoji-disappointed-outline': EmojiDisappointedOutline,
  'emoji-disappointed-solid': EmojiDisappointedSolid,
  'emoji-happy-outline': EmojiHappyOutline,
  'emoji-happy-solid': EmojiHappySolid,
  'emoji-meh-outline': EmojiMehOutline,
  'emoji-meh-solid': EmojiMehSolid,
  'emoji-sad-outline': EmojiSadOutline,
  'emoji-sad-solid': EmojiSadSolid,
  'emoji-satisfied-outline': EmojiSatisfiedOutline,
  'emoji-satisfied-solid': EmojiSatisfiedSolid,
  'envelope-outline': EnvelopeOutline,
  'envelope-solid': EnvelopeSolid,
  'expand-outline': ExpandOutline,
  'facebook-outline': FacebookOutline,
  'file-outline': FileOutline,
  'filter-outline': FilterOutline,
  'floor-outline': FloorOutline,
  'fridge-outline': FridgeOutline,
  'gift-outline': GiftOutline,
  'gift-solid': GiftSolid,
  'github-outline': GithubOutline,
  'google-outline': GoogleOutline,
  'heart-outline': HeartOutline,
  'heart-solid': HeartSolid,
  'help-outline': HelpOutline,
  'help-solid': HelpSolid,
  'hide-outline': HideOutline,
  'home-outline': HomeOutline,
  'home-solid': HomeSolid,
  'image-outline': ImageOutline,
  'info-outline': InfoOutline,
  'info-solid': InfoSolid,
  'instagram-outline': InstagramOutline,
  'leaf-outline': LeafOutline,
  'leaf-solid': LeafSolid,
  'like-outline': LikeOutline,
  'like-solid': LikeSolid,
  'link-outline': LinkOutline,
  'lmvc-outline': LmvcOutline,
  'locked-outline': LockedOutline,
  'mastercard-outline': MastercardOutline,
  'menu-outline': MenuOutline,
  'message-outline': MessageOutline,
  'message-solid': MessageSolid,
  'microphone-outline': MicrophoneOutline,
  'microphone-solid': MicrophoneSolid,
  'minus-outline': MinusOutline,
  'moon-outline': MoonOutline,
  'moon-solid': MoonSolid,
  'nail-outline': NailOutline,
  'package-outline': PackageOutline,
  'package-solid': PackageSolid,
  'paint-roll-outline': PaintRollOutline,
  'phone-outline': PhoneOutline,
  'phone-solid': PhoneSolid,
  'pin-outline': PinOutline,
  'pinterest-outline': PinterestOutline,
  'pipe-outline': PipeOutline,
  'pix-outline': PixOutline,
  'plant-outline': PlantOutline,
  'play-outline': PlayOutline,
  'plug-outline': PlugOutline,
  'plus-outline': PlusOutline,
  'purchase-tag-outline': PurchaseTagOutline,
  'purchase-tag-solid': PurchaseTagSolid,
  'qr-outline': QrOutline,
  'qr-scan-outline': QrScanOutline,
  'receipt-outline': ReceiptOutline,
  'receipt-solid': ReceiptSolid,
  'refresh-outline': RefreshOutline,
  'restaurant-outline': RestaurantOutline,
  'ruler-outline': RulerOutline,
  'search-outline': SearchOutline,
  'share-outline': ShareOutline,
  'share-solid': ShareSolid,
  'show-outline': ShowOutline,
  'slider-outline': SliderOutline,
  'sort-down-outline': SortDownOutline,
  'sort-up-outline': SortUpOutline,
  'star-half-outline': StarHalfOutline,
  'star-outline': StarOutline,
  'star-solid': StarSolid,
  'store-leroy-outline': StoreLeroyOutline,
  'store-outline': StoreOutline,
  'success-solid': SuccessSolid,
  'sun-outline': SunOutline,
  'sun-solid': SunSolid,
  'support-outline': SupportOutline,
  'tiktok-outline': TiktokOutline,
  'transfer-outline': TransferOutline,
  'trash-outline': TrashOutline,
  'truck-fast-outline': TruckFastOutline,
  'truck-outline': TruckOutline,
  'twitter-outline': TwitterOutline,
  'undo-outline': UndoOutline,
  'unlocked-outline': UnlockedOutline,
  'upload-outline': UploadOutline,
  'user-outline': UserOutline,
  'user-solid': UserSolid,
  'visa-outline': VisaOutline,
  'volume-full-outline': VolumeFullOutline,
  'warning-outline': WarningOutline,
  'warning-solid': WarningSolid,
  'washer-outline': WasherOutline,
  'whatsapp-outline': WhatsappOutline,
  'wind-outline': WindOutline,
  'wood-outline': WoodOutline,
  'wrench-outline': WrenchOutline,
  'x-outline': XOutline,
  'youtube-outline': YoutubeOutline,
  'zoom-in-outline': ZoomInOutline,
  'zoom-out-outline': ZoomOutOutline
} as const
