export type InvoiceStatus =
  | 'NOT_SENT'
  | 'SENT_WAITING_ANALYSIS'
  | 'PAYMENT_SUCCESS'
  | 'PAYMENT_DENIED'
  | 'UNKNOWN'

export const invoiceStatusMap = new Map<string, InvoiceStatus>([
  ['APPROVED', 'PAYMENT_SUCCESS'],
  ['CREATED', 'SENT_WAITING_ANALYSIS'],
  ['PURCHASE_ORDER_CREATED', 'SENT_WAITING_ANALYSIS'],
  ['VALIDATING_NFE', 'SENT_WAITING_ANALYSIS'],
  ['NFE_VALIDATED', 'SENT_WAITING_ANALYSIS'],
  ['REVERSAL', 'PAYMENT_DENIED'],
  ['REJECTED', 'PAYMENT_DENIED']
])

export const invoiceStatusGetFirstLabelByStatus = (
  status: InvoiceStatus
): string | undefined => {
  for (const [key, value] of Array.from(invoiceStatusMap.entries())) {
    if (value === status) {
      return key
    }
  }

  return undefined
}

export interface InvoiceStatusData {
  skipStatusStep: boolean
  status: {
    label: string
    description: string
    message: string | null
  }
}
