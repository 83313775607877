import { useEffect, useCallback, useState } from 'react'

import { apiTypes } from 'frontendCheckout/services/api'
import { formatters } from 'frontendCheckout/utils'

import {
  useCashbackObserver,
  useCreditCardObserver,
  usePaymentMethodObserver,
  useKeyupCardObserver
} from './'

type SummaryType = apiTypes.Cart.getSummary.Summary
type InstallmentsByBrandDefault =
  SummaryType['creditCardInstallmentsByBrand']['default']

interface SelectOptionParams {
  element: HTMLSelectElement
  optionLabel: string
  value: string
  selected?: boolean
}

const getElementById = (id: string) => document.getElementById(id)

const toggleSummaryCashback = (show: boolean) => {
  const summaryItem = document.getElementById('summary-item-cashback')
  if (summaryItem) {
    summaryItem.style.display = show ? 'block' : 'none'
  }
}

const toggleDiscountModalInfo = (show: boolean) => {
  const discountModalInfo = document.getElementById('discount-modal-info')
  if (discountModalInfo) {
    discountModalInfo.style.display = show ? 'block' : 'none'
  }
}

const toggleDiscountWrapper = (show: boolean) => {
  const discountWrapper = document.getElementById('summary-discount-wrapper')
  if (discountWrapper) {
    discountWrapper.classList[show ? 'remove' : 'add']('is-hidden')
    discountWrapper.style.display = show ? 'flex' : 'none'
  }
}

const toggleOverlaySpinner = (show: boolean) => {
  const overlayForSpinner = document.querySelector('[data-overlay-react]')

  if (overlayForSpinner) {
    overlayForSpinner.classList[show ? 'add' : 'remove']('active')
  }
}

const setInnerText = (element: HTMLElement | null, value: string) => {
  if (element) {
    element.innerText = value
  }
}

const removeClass = (element: HTMLElement | null, classToBeRemoved: string) => {
  if (element) {
    element.classList.remove(classToBeRemoved)
  }
}

const getCurrentInstallment = (installments: InstallmentsByBrandDefault) => {
  return installments[installments.length - 1]
}

const buildInstallmentsData = (
  value: number,
  installmentsByBrandDefault: InstallmentsByBrandDefault = []
) => {
  const formattedValue = formatters.numberToPrice(value)

  if (installmentsByBrandDefault.length === 0) {
    return `${formattedValue} à vista`
  }

  const currentInstallment = getCurrentInstallment(installmentsByBrandDefault)

  return `${formattedValue} em até ${
    currentInstallment?.quantity
  }x de ${formatters.numberToPrice(currentInstallment?.value)}`
}

const updateTwigWithSummary = (summary: SummaryType) => {
  const el = {
    pickupForecast: getElementById('summary-pickup-forecast'),
    deliveryForecast: getElementById('summary-delivery-forecast'),
    subtotal: getElementById('summary-subtotal'),
    installationService: getElementById('summary-installation-service'),
    moneyVoucher: getElementById('summary-money-voucher'),
    freightSubtotal: getElementById('freight_cost'),
    total: getElementById('summary-total'),
    totalDiscount: getElementById('totalDiscount')
  }

  toggleSummaryCashback(summary.moneyVouchersTotal > 0)
  toggleDiscountModalInfo(false)
  toggleDiscountWrapper(summary.discount > 0)

  setInnerText(
    el.totalDiscount,
    `- ${formatters.numberToPrice(summary.discount)}`
  )
  setInnerText(el.pickupForecast, 'Retirada em loja')
  setInnerText(el.deliveryForecast, 'Entrega em casa')
  setInnerText(el.subtotal, formatters.numberToPrice(summary.subTotal))
  setInnerText(
    el.installationService,
    formatters.numberToPrice(summary.serviceTotal)
  )
  setInnerText(
    el.moneyVoucher,
    formatters.numberToPrice(summary.moneyVouchersTotal)
  )
  setInnerText(el.freightSubtotal, formatters.numberToPrice(summary.freight))
  setInnerText(el.total, formatters.numberToPrice(summary.total))
}

type InstallmentsByBrandType =
  Array<apiTypes.Cart.getSummary.InstallmentsByBrand>

const createSelectOption = ({
  element,
  optionLabel,
  value,
  selected = false
}: SelectOptionParams) => {
  const option = document.createElement('option')
  option.textContent = optionLabel
  option.value = value
  option.selected = selected
  element.appendChild(option)
}

const installmentLabel = ({
  installment,
  value
}: {
  installment: number
  value: number
}) => `${installment}x de ${formatters.numberToPrice(value)}`

const shouldRepopulateSelect = (
  el: HTMLSelectElement,
  data: InstallmentsByBrandType
) => {
  if (el.options.length !== data.length + 1) return true

  for (let i = 1; i < el.options.length; i++) {
    const info = data[i - 1]
    if (el.options[i].value !== String(info.quantity)) return true
    if (
      el.options[i].textContent !==
      installmentLabel({ installment: info.quantity, value: info.value })
    ) {
      return true
    }
  }

  return false
}

const populateSelect = (
  el: HTMLSelectElement,
  data: InstallmentsByBrandType,
  addSelectedOption = false
) => {
  if (!shouldRepopulateSelect(el, data)) return

  el.options.length = 0

  createSelectOption({
    element: el,
    optionLabel: 'Quantidade de Parcelas',
    value: ''
  })

  data.forEach(item => {
    const { quantity: installment, value } = item
    const label = `${installment}x de ${formatters.numberToPrice(value)}`
    const isSingleInstallment =
      addSelectedOption && data.length === 1 && installment === 1

    createSelectOption({
      element: el,
      optionLabel: installmentLabel({ installment, value }),
      value: String(installment),
      selected: isSingleInstallment
    })
  })
}

const populateVouchers = (summary: SummaryType) => {
  const voucherList = document.getElementById('form-voucher-list')
  if (!voucherList) return

  voucherList.innerHTML = ''

  summary.appliedMoneyVouchers.forEach(appliedMoneyVoucherId => {
    const checkbox = document.createElement('input')
    checkbox.type = 'hidden'
    checkbox.name = 'vouchers[]'
    checkbox.value = appliedMoneyVoucherId
    voucherList.appendChild(checkbox)
  })

  summary.appliedVouchers.forEach(appliedVouchersId => {
    const checkbox = document.createElement('input')
    checkbox.type = 'hidden'
    checkbox.name = 'vouchers[]'
    checkbox.value = appliedVouchersId
    voucherList.appendChild(checkbox)
  })
}

const getInstallmentInfo = (summary: SummaryType, flag?: string) => {
  if (flag === 'celebre-pro') {
    return summary.creditCardInstallmentsByBrand.brandedPro
  }

  if (flag?.includes('celebre')) {
    return summary.creditCardInstallmentsByBrand.branded
  }

  return summary.creditCardInstallmentsByBrand.default
}

const updateNewCardInstallmentWithSummary = (summary: SummaryType) => {
  const newCreditCard = document.getElementById('amount-installment')
  const flagEl = document.getElementById('new-card-flag')

  if (
    newCreditCard instanceof HTMLSelectElement &&
    flagEl instanceof HTMLElement
  ) {
    const flag = flagEl.dataset.cardFlag

    const installmentInfo = getInstallmentInfo(summary, flag)
    populateSelect(newCreditCard, installmentInfo, true)

    const hasInstallments = installmentInfo.length > 1

    if (hasInstallments) {
      newCreditCard.removeAttribute('disabled')
    }
  }
}

const updateStoredInstallmentWithSummary = (summary: SummaryType) => {
  const storedCardsInstallments =
    document.querySelectorAll('[data-installment-card-brand]') ?? []

  storedCardsInstallments.forEach(elem => {
    if (elem instanceof HTMLSelectElement) {
      const flag = elem.dataset.installmentCardBrand
      const info = getInstallmentInfo(summary, flag)
      populateSelect(elem, info)
    }
  })
}

const setElementValue = (
  element: Element | null,
  value: number | string | undefined | null
) => {
  if (element && value != null) {
    element.setAttribute('value', String(value))
  }
}

const updateInputsFormSubmitWithSummary = (summary: SummaryType) => {
  const el = {
    firstCardValue: document.querySelector('[data-react-first-card-value]'),
    virtualCardValue: document.querySelector('[data-react-virtual-card]'),
    bankSlipValue: document.querySelector('[data-react-bank-slip]'),
    pixValue: document.querySelector('[data-react-pix]'),
    bankSlipInstallmentsValue: document.querySelector(
      '[data-react-bank-slip-installments]'
    )
  }
  const coupon = document.getElementById('form-coupon')

  const creditCardTotalPrice =
    summary?.discountsByPaymentMethods?.credit_card?.totalPrice ??
    summary?.total
  const bankSlipTotalPrice =
    summary?.discountsByPaymentMethods?.bank_slip?.totalPrice ?? summary?.total
  const pixTotalPrice =
    summary?.discountsByPaymentMethods?.pix?.totalPrice ?? summary?.total

  setElementValue(el.firstCardValue, creditCardTotalPrice)
  setElementValue(el.virtualCardValue, creditCardTotalPrice)
  setElementValue(el.bankSlipValue, bankSlipTotalPrice)
  setElementValue(el.pixValue, pixTotalPrice)
  setElementValue(el.bankSlipInstallmentsValue, bankSlipTotalPrice)
  setElementValue(coupon, summary.appliedCoupon)
  populateVouchers(summary)
}

const updatePaymentMethodWithSummary = (summary: SummaryType) => {
  const el = {
    creditCard: document.getElementById('data-net-price-credit-card'),
    virtualCard: document.getElementById('data-net-price-virtual-card'),
    bankSlip: document.getElementById('data-net-price-bank-slip'),
    pix: document.getElementById('data-net-price-pix')
  }

  const creditCardTotalPrice =
    summary?.discountsByPaymentMethods?.credit_card?.totalPrice ??
    summary?.total
  const bankSlipTotalPrice =
    summary?.discountsByPaymentMethods?.bank_slip?.totalPrice ?? summary?.total
  const pixTotalPrice =
    summary?.discountsByPaymentMethods?.pix?.totalPrice ?? summary?.total

  const creditCardInnerText = buildInstallmentsData(
    creditCardTotalPrice,
    summary.creditCardInstallmentsByBrand.default
  )
  const virtualCardInnerText = buildInstallmentsData(creditCardTotalPrice)
  const bankSlipInnerText = buildInstallmentsData(bankSlipTotalPrice)
  const pixInnerText = buildInstallmentsData(pixTotalPrice)

  setInnerText(el.creditCard, creditCardInnerText)
  setInnerText(el.virtualCard, virtualCardInnerText)
  setInnerText(el.bankSlip, bankSlipInnerText)
  setInnerText(el.pix, pixInnerText)
}

const cleanTwig = () => {
  const el = {
    creditCard: document.getElementById('data-net-price-credit-card'),
    virtualCard: document.getElementById('data-net-price-virtual-card'),
    bankSlip: document.getElementById('data-net-price-bank-slip'),
    pix: document.getElementById('data-net-price-pix')
  }

  removeClass(el.creditCard, 'blur')
  removeClass(el.virtualCard, 'blur')
  removeClass(el.bankSlip, 'blur')
  removeClass(el.pix, 'blur')
}

export const useTwig = (
  summaryInfo: apiTypes.Cart.getSummary.SummaryInfoProps
) => {
  const {
    cartId,
    summary,
    getSummary,
    isLoadingSummary,
    getSummaryStatusCode
  } = summaryInfo

  const [isNowCashPayment, setIsNowCashPayment] = useState(true)

  const updatesWithSummary = useCallback(() => {
    const isFetching = [-1, 0].includes(getSummaryStatusCode)

    if (isLoadingSummary || isFetching) {
      cleanTwig()
      return
    }

    updateTwigWithSummary(summary)
    updateStoredInstallmentWithSummary(summary)
    updateNewCardInstallmentWithSummary(summary)
    updateInputsFormSubmitWithSummary(summary)
    updatePaymentMethodWithSummary(summary)
  }, [isLoadingSummary, summary, getSummaryStatusCode])

  const paymentMethods = ['pix', 'token', 'bank_slip', 'credit_card'] as const
  type GetSelectedPaymentMethod = () => (typeof paymentMethods)[number]

  const getSelectedPaymentMethod: GetSelectedPaymentMethod = useCallback(() => {
    const paymentMethodRadio: NodeListOf<HTMLInputElement> =
      document.querySelectorAll('input[name="payment-method"][type="radio"]')
    for (let i = 0; i < paymentMethodRadio.length; i++) {
      if (paymentMethodRadio[i].checked) {
        if (paymentMethodRadio[i].value === 'virtual_card') return 'credit_card'
        return paymentMethodRadio[i].value as (typeof paymentMethods)[number]
      }
    }
    return 'credit_card'
  }, [])

  const isCashPayment = useCallback(() => {
    if (getSelectedPaymentMethod() !== 'credit_card') return true

    const selectedCardName = document.querySelector<HTMLInputElement>(
      "[name*='cards'][name$='[enabled]'][value=true]"
    )?.name
    const cardId = /cards\[(.*?)\]/.exec(selectedCardName as string)?.[1]
    const selectedInstallments = document.querySelector<HTMLInputElement>(
      `[name="cards[${cardId}][amount_installment]"]`
    )?.value
    const installmentsNumber = Number(selectedInstallments)

    if (Number.isNaN(installmentsNumber)) return true
    return installmentsNumber <= 1
  }, [getSelectedPaymentMethod])

  useEffect(() => {
    toggleOverlaySpinner(isLoadingSummary)

    updatesWithSummary()
  }, [isLoadingSummary, updatesWithSummary])

  useCashbackObserver(async (moneyVoucher: string) => {
    await getSummary({
      cartId,
      params: {
        payment_method: getSelectedPaymentMethod(),
        money_vouchers: moneyVoucher ? [moneyVoucher] : [],
        vouchers: summary.appliedVouchers,
        coupon: summary.appliedCoupon ?? undefined,
        simulate: true,
        cash_payment: isCashPayment()
      }
    })

    updatesWithSummary()
  })

  useCreditCardObserver(async () => {
    await getSummary({
      cartId,
      params: {
        payment_method: getSelectedPaymentMethod(),
        money_vouchers: summary.appliedMoneyVouchers,
        vouchers: summary.appliedVouchers,
        coupon: summary.appliedCoupon ?? undefined,
        simulate: true,
        cash_payment: isCashPayment()
      }
    })

    updatesWithSummary()
  })

  usePaymentMethodObserver(async () => {
    await getSummary({
      cartId,
      params: {
        payment_method: getSelectedPaymentMethod(),
        money_vouchers: summary.appliedMoneyVouchers,
        vouchers: summary.appliedVouchers,
        coupon: summary.appliedCoupon ?? undefined,
        simulate: true,
        cash_payment: isCashPayment()
      }
    })

    updatesWithSummary()
  })

  useKeyupCardObserver(() => updateNewCardInstallmentWithSummary(summary))

  return { getSelectedPaymentMethod, isCashPayment }
}
