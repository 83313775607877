import { useEffect } from 'react'

export const useCashbackObserver = (
  callback: (selectedCode: string) => void
) => {
  useEffect(() => {
    const elements = document.querySelectorAll(
      '[data-voucher]'
    ) as NodeListOf<HTMLInputElement>

    if (elements?.length === 0) return

    const handleChange = (event: Event) => {
      const target = event.target as HTMLInputElement
      if (target.checked) {
        callback(target.value)
      } else {
        callback('')
      }
    }

    elements.forEach(element => {
      element.addEventListener('change', handleChange)
    })

    return () => {
      elements.forEach(element => {
        element.removeEventListener('change', handleChange)
      })
    }
  }, [callback])
}
