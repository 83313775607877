import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgEmojiSatisfiedOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M2 12C2 6.486 6.486 2 12 2s10 4.486 10 10-4.486 10-10 10S2 17.514 2 12m2 0c0 4.411 3.589 8 8 8s8-3.589 8-8-3.589-8-8-8-8 3.589-8 8m9.557 3.686a4.06 4.06 0 0 0 1.761-1.45l1.658 1.119a6 6 0 0 1-.734.889 5.972 5.972 0 0 1-3.034 1.634 6 6 0 0 1-2.415 0 6 6 0 0 1-2.148-.903 6.1 6.1 0 0 1-1.621-1.62l1.658-1.119a4 4 0 0 0 4.875 1.45M8.5 12a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3m8.486-1.507a1.493 1.493 0 1 1-2.986 0 1.493 1.493 0 0 1 2.986 0"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgEmojiSatisfiedOutline)
const Memo = memo(ForwardRef)
export default Memo
