import { instance } from 'scripts/react-components/api'

import {
  ApiPostNotify,
  ApiPostPayload,
  ApiPostResponse,
  ApiThreadResponseType,
  URLParamsType,
  TransferChatPostPayload,
  TransferChatPostResponse
} from '../types'

export const getMarketplaceChat = async ({
  orderId,
  marketplaceOrder
}: URLParamsType): Promise<ApiThreadResponseType['data']> => {
  const response = await instance
    .get<ApiThreadResponseType>(
      `/api/v1/sale-order/${orderId}/shipment/${marketplaceOrder}/thread`
    )
    .then(({ data }) => data.data)
    .catch(err => {
      throw err.response
    })

  return response
}

export const getMarketplaceChatByThreadId = async ({
  orderId,
  marketplaceOrder,
  threadId
}: URLParamsType): Promise<ApiThreadResponseType['data']> => {
  const response = await instance
    .get<ApiThreadResponseType>(
      `/api/v1/sale-order/${orderId}/shipment/${marketplaceOrder}/thread/${threadId}`
    )
    .then(({ data }) => data.data)
    .catch(err => {
      throw err.response
    })

  return response
}

export const genericPostService = async ({
  urlParams,
  data
}: {
  urlParams: URLParamsType
  data: ApiPostPayload
}): Promise<ApiPostResponse['data']> => {
  const form = new FormData()

  form.append('type', data.type)
  form.append('option', data.option)
  if (data.subOption) {
    form.append('subOption', data.subOption)
  }
  if (data.files) {
    data.files.forEach(file => {
      form.append('files[]', file, file.name)
    })
  }

  if (data.type === 'chat') {
    form.append('message', data.message)
  } else if (data.type === 'ticket') {
    form.append('contact', data.contact)
    form.append('contactType', data.contactType)
    form.append('contactIsOriginal', data.contactIsOriginal)
  }

  const path = urlParams.threadId
    ? `/api/v1/sale-order/${urlParams.orderId}/shipment/${urlParams.marketplaceOrder}/thread/${urlParams.threadId}/add-message`
    : `/api/v1/sale-order/${urlParams.orderId}/shipment/${urlParams.marketplaceOrder}/thread`

  const response = await instance
    .post<ApiPostResponse>(path, form)
    .then(({ data }) => data.data)
    .catch(err => {
      throw err.response
    })

  return response
}

export const transferChatPost = async ({
  threadId,
  data,
  orderId,
  marketplaceOrder
}: {
  threadId: string
  orderId: string
  marketplaceOrder: string
  data: TransferChatPostPayload
}): Promise<TransferChatPostResponse['data']> => {
  const form = new FormData()

  form.append('contact', data.contact)
  form.append('contactType', data.contactType)
  form.append('contactIsOriginal', data.contactIsOriginal)

  const path = `/api/v1/sale-order/${orderId}/shipment/${marketplaceOrder}/thread/${threadId}/transfer`

  const response = await instance
    .post<TransferChatPostResponse>(path, form)
    .then(({ data }) => data.data)
    .catch(err => {
      throw err.response
    })

  return response
}

export const notifySalesForce = async ({
  urlParams,
  notifyData
}: {
  urlParams: URLParamsType
  notifyData: ApiPostNotify
}): Promise<ApiPostResponse['data']> => {
  const response = await instance
    .post<ApiPostResponse>(
      `/api/v1/sale-order/${urlParams.orderId}/shipment/${urlParams.marketplaceOrder}/thread/notify`,
      notifyData
    )
    .then(({ data }) => data.data)
    .catch(err => {
      throw err.response
    })

  return response
}

export const closeMarketplaceChat = async ({
  urlParams
}: {
  urlParams: URLParamsType
}): Promise<ApiPostResponse['data']> => {
  const response = await instance
    .post<ApiPostResponse>(
      `/api/v1/sale-order/${urlParams.orderId}/shipment/${urlParams.marketplaceOrder}/thread/${urlParams.threadId}/close`,
      {}
    )
    .then(({ data }) => data.data)
    .catch(err => {
      throw err.response
    })

  return response
}
