import { useEffect } from 'react'

export const usePaymentMethodObserver = (callback: () => void) => {
  useEffect(() => {
    const paymentMethodsEl = document.querySelectorAll(
      '[name="payment-method"]'
    )

    paymentMethodsEl.forEach(method => {
      method.addEventListener('change', callback)
    })

    return () => {
      paymentMethodsEl.forEach(method => {
        method.removeEventListener('change', callback)
      })
    }
  }, [callback])
}
