import styled from '@emotion/styled'
import { AlertDialog, Button as BackyardButton } from '@backyard-ui/core'
import { UserInterface } from 'frontendMyOrders/user-interface/typography'
import { memo } from 'react'

type Option = {
  key: string | number
  label: string
}

type ButtonProps = {
  data: Option
  onClick: (id: string | number) => void
  disabled?: boolean
  confirmOnActionMessage?: string
}

const ButtonWrapper = styled.button`
  background: var(--color-surface-accent-softer);
  border-radius: var(--border-radius-lg);
  border: none;
  cursor: pointer;
  padding: var(--desktop-space-inset-sm) var(--desktop-space-inset-xs);
  margin: 0;
  box-shadow: var(--desktop-space-inset-2xs) var(--desktop-space-inset-2xs)
    var(--desktop-space-inset-md) 0 var(--color-surface-neutral-stronger);
  transition: background-color 0.3s ease;

  &:hover {
    background: var(--color-surface-accent-soft);
  }
`

const CustomButtonWrapper = styled.div`
  background: var(--color-surface-accent-softer);
  border-radius: var(--border-radius-lg);
  border: none;
  cursor: pointer;
  padding: var(--desktop-space-inset-sm) var(--desktop-space-inset-xs);
  margin: 0;
  box-shadow: var(--desktop-space-inset-2xs) var(--desktop-space-inset-2xs)
    var(--desktop-space-inset-md) 0 var(--color-surface-neutral-stronger);
  transition: background-color 0.3s ease;

  &:hover {
    background: var(--color-surface-accent-soft);
  }
`

const Button = memo(
  ({
    data,
    onClick,
    disabled = false,
    confirmOnActionMessage
  }: ButtonProps) => {
    if (confirmOnActionMessage) {
      return (
        <AlertDialog.Root size="2xl">
          <AlertDialog.Trigger>
            <CustomButtonWrapper>
              <UserInterface appearance="accent-strong" size="sm" type="strong">
                {data.label}
              </UserInterface>
            </CustomButtonWrapper>
          </AlertDialog.Trigger>

          <AlertDialog.Content>
            <AlertDialog.Title>{data.label}</AlertDialog.Title>

            <AlertDialog.Description>
              <UserInterface size="md" innerText={confirmOnActionMessage} />
            </AlertDialog.Description>

            <AlertDialog.Footer>
              <AlertDialog.Cancel>
                <BackyardButton asChild appearance="neutral">
                  <div>Cancelar</div>
                </BackyardButton>
              </AlertDialog.Cancel>

              <AlertDialog.Action>
                <BackyardButton asChild onClick={() => onClick(data.key)}>
                  <div>{data.label}</div>
                </BackyardButton>
              </AlertDialog.Action>
            </AlertDialog.Footer>
          </AlertDialog.Content>
        </AlertDialog.Root>
      )
    }

    return (
      <ButtonWrapper
        disabled={disabled}
        data-testid="chat-button"
        onClick={() => onClick(data.key)}>
        <UserInterface appearance="accent-strong" size="sm" type="strong">
          {data.label}
        </UserInterface>
      </ButtonWrapper>
    )
  }
)

Button.displayName = 'Button'

export default Button
