import React from 'react'

import { useResize } from 'utils/hooks/use-resize'

import PriceWrapperDesktop from './PriceWrapperDesktop'
import PriceWrapperMobile from './PriceWrapperMobile'

const PriceWrapper = ({
  discountByPaymentMethod,
  packaging,
  packagingCount,
  translatedUnit,
  quantity,
  price,
  brand,
  name,
  categoryTree,
  id,
  productId,
  regionId,
  shippingType,
  shopName,
  skuId,
  storeId,
  updateProduct,
  onSuccess,
  onError,
  isHybridEnabled,
  cartId,
  changeItemTypeCallback,
  itemAvailableStores,
  installments,
  isStandaloneService,
  fetchPriceShipping,
  zipCode
}) => {
  const [isMobile] = useResize()
  const Component = isMobile ? PriceWrapperMobile : PriceWrapperDesktop

  const newShipmentTypeDatalayer =
    shippingType === 'delivery' ? 'pickup-in-store' : 'delivery'

  const shiftButtonDatalayerInfo = {
    regionId,
    productName: name,
    productId,
    productPrice: packaging * price.to,
    productBrand: brand,
    productCategory: categoryTree,
    shipmentType: newShipmentTypeDatalayer,
    store: newShipmentTypeDatalayer === 'pickup-in-store' ? storeId : '',
    marketPlace: newShipmentTypeDatalayer === 'delivery' ? shopName : '',
    productQuantity: packagingCount,
    relatedProduct: ''
  }

  return (
    <React.Fragment>
      <Component
        discountByPaymentMethod={discountByPaymentMethod}
        packaging={packaging}
        packagingCount={packagingCount}
        translatedUnit={translatedUnit}
        quantity={quantity}
        price={price}
        brand={brand}
        name={name}
        categoryTree={categoryTree}
        id={id}
        productId={productId}
        regionId={regionId}
        shippingType={shippingType}
        shopName={shopName}
        skuId={skuId}
        storeId={storeId}
        updateProduct={updateProduct}
        onSuccess={onSuccess}
        onError={onError}
        isHybridEnabled={isHybridEnabled}
        changeItemTypeCallback={changeItemTypeCallback}
        cartId={cartId}
        itemAvailableStores={itemAvailableStores}
        dataLayerInfo={shiftButtonDatalayerInfo}
        installments={installments}
        isStandaloneService={isStandaloneService}
        fetchPriceShipping={fetchPriceShipping}
        zipCode={zipCode}
      />
    </React.Fragment>
  )
}

export default PriceWrapper
