import { Stack } from '@leroy-merlin-br/backyard-react'

import Header from './components/Header'
import Content from './components/Content'
import * as S from './styled'

export default function StatusStepper() {
  return (
    <S.Wrapper data-testid="redeem-status-stepper">
      <Stack space="giga">
        <Header />
        <Content />
      </Stack>
    </S.Wrapper>
  )
}
