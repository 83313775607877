import { ChangeEvent, useState } from 'react'
import axios from 'axios'
import { Button } from '@leroy-merlin-br/backyard-react'
import { ShoppingCartOutline } from '@backyard-ui/icons'

import emitter from 'scripts/utils/emitter'

import { hideSpinner, showSpinner } from 'utils/Spinner'

import FeedbackErrorsTemplate, {
  FeedbackErrorsTemplateProps
} from './FeedbackErrorsTemplate'

interface QuantityControlsProps {
  lm: number
  price?: {
    to: {
      integers: string
      decimals: string
    }
  }
}

const QuantityControls = ({ lm, price }: QuantityControlsProps) => {
  const [quantity, setQuantity] = useState(1)

  const [isDisabledDecrementButton, setIsDisabledDecrementButton] =
    useState(true)

  const [isDisabledAddToCartButton, setIsDisabledAddToCartButton] = useState(
    !price?.to
  )

  const incrementItem = () => {
    setQuantity(prevQuantity => prevQuantity + 1)
    setIsDisabledDecrementButton(false)
  }

  const decrementItem = () => {
    setQuantity(prevQuantity => {
      if (prevQuantity <= 1) {
        setIsDisabledDecrementButton(true)
        return 1
      } else {
        setIsDisabledDecrementButton(false)
        return prevQuantity - 1
      }
    })
  }

  const sendToHybrisCart = async () => {
    const endpoint = `/hybris/carts/current?lm=${lm}&quantity=${quantity}`

    toggleCartButton()
    showSpinner()

    try {
      const {
        data: { redirect }
      } = await axios.get(endpoint)

      redirectTo(redirect)
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        onAddProductFailure(error.response.data)
      }
    } finally {
      toggleCartButton()

      hideSpinner()
    }
  }

  const redirectTo = (url: string) => {
    window.location.href = url
  }

  const toggleCartButton = () => {
    setIsDisabledAddToCartButton(prevState => !prevState)
  }

  const onAddProductFailure = (value: FeedbackErrorsTemplateProps) => {
    emitter.emit('stickyFeedback:error', {
      title: 'Ops! Ocorreram os seguintes erros:',
      content: FeedbackErrorsTemplate(value)
    })
  }

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10)
    if (!isNaN(value)) {
      setQuantity(value)
      setIsDisabledDecrementButton(value <= 1)
    }
  }

  return (
    <div className="assisted-sale-controls">
      <div className="quantity-controls">
        <button
          className="decrement-button"
          onClick={decrementItem}
          type="button"
          disabled={isDisabledDecrementButton}
          data-testid="decrement-button">
          <i className="glyph glyph-minus-btn" />
        </button>
        <input
          className="quantity-input"
          type="number"
          value={quantity}
          onChange={onChangeHandler}
        />
        <button
          className="increment-button"
          onClick={incrementItem}
          type="button"
          data-testid="increment-button">
          <i className="glyph glyph-plus-btn" />
        </button>
      </div>
      <div className="quantity-add">
        <Button
          onClick={sendToHybrisCart}
          isDisabled={isDisabledAddToCartButton}
          iconRight={ShoppingCartOutline}
          label="Adicionar ao Carrinho"
          appearance="primary"
          size="kilo"
          isStretch
          data-testid="add-to-cart-button">
          Adicionar
        </Button>
      </div>
    </div>
  )
}

export default QuantityControls
