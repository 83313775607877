import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { styleHelpers } from '@leroy-merlin-br/backyard-react'

import { Theme } from 'scripts/react-components/themes/Theme'

type StyleProps = {
  isOutlet: boolean
  isPromotional: boolean
  isDiscountByPaymentMethod: boolean
  featureInstallmentDiscount: boolean
  hasInstallments: boolean
}

const priceTagStyles = ({ theme }: Theme) => css`
  label: price-tag;

  box-sizing: border-box;
  border-bottom: 4px solid;
  border-color: ${theme.colors.p600};

  display: flex;
  flex-flow: column;
  justify-content: flex-end;

  margin: 0;
  padding: ${theme.spacings.byte} ${theme.spacings.byte} ${theme.spacings.kilo};

  text-align: left;

  ${theme.media.giga} {
    padding: ${theme.spacings.byte};
  }
`

const isPromotionalPriceTagStyles = ({
  theme,
  isPromotional,
  isDiscountByPaymentMethod,
  featureInstallmentDiscount
}: Theme & StyleProps) =>
  !featureInstallmentDiscount &&
  (isPromotional || isDiscountByPaymentMethod) &&
  css`
    label: price-tag--promotional;

    background-color: ${theme.colors.y500};
    border-color: ${theme.colors.r600};

    ${Price} {
      color: ${theme.colors.r600};
    }

    ${PriceUnit} {
      color: ${theme.colors.r600};
    }

    ${AdditionalPriceInfo} {
      color: ${theme.colors.n900};
    }
  `

const isOutletPriceTagStyles = ({ theme, isOutlet }: Theme & StyleProps) =>
  isOutlet &&
  css`
    label: price-tag--outlet;

    background-color: ${theme.colors.black};
    border-color: ${theme.colors.p600};

    ${Price} {
      color: ${theme.colors.p500};
    }

    ${PriceUnit} {
      color: ${theme.colors.p500};
    }

    ${AdditionalPriceInfo} {
      color: ${theme.colors.white};
    }
  `

const priceStyles = ({ theme }: Theme) => css`
  label: price-tag__price;

  display: inline-block;

  color: ${theme.colors.n900};

  ${styleHelpers.textGiga(theme)};
  font-weight: ${theme.font.weight.bold};

  padding-right: ${theme.spacings.bit};
`

const priceUnitStyles = ({ theme }: Theme) => css`
  label: price-tag__unity;

  display: inline-block;

  color: ${theme.colors.n900};
  font-weight: ${theme.font.weight.bold};
  font-size: 16px;
  line-height: 16px;
`

const priceContentStyles = ({ theme }: Theme) => css`
  label: price-tag__content;

  padding: ${theme.spacings.mega} 0;
`
const priceContentPromotionalStyles = ({
  theme,
  isPromotional
}: Theme & StyleProps) =>
  isPromotional &&
  css`
    label: price-tag__content--promotional;

    padding-top: ${theme.spacings.bit};
  `

const priceContentWithInstallmentsStyles = ({
  theme,
  hasInstallments
}: Theme & StyleProps) =>
  hasInstallments &&
  css`
    label: price-tag__content--with-installments;

    padding-bottom: ${theme.spacings.bit};
  `

const additionalPriceInfoStyles = ({
  theme,
  isPromotional,
  isOutlet
}: Theme & StyleProps) => css`
  label: price-tag__additional-price;

  color: ${isOutlet ? theme.colors.white : theme.colors.n700};
  font-weight: ${isPromotional
    ? theme.font.weight.regular
    : theme.font.weight.bold};
  font-size: 12px;
  line-height: 12px;
  text-decoration: ${isPromotional ? 'line-through' : 'none'};
`

const additionalPaymentDiscountPriceInfo = ({
  theme,
  isOutlet
}: Theme & StyleProps) => css`
  label: price-tag__additional-payment-discount-price;

  color: ${isOutlet ? theme.colors.white : theme.colors.n900};
  font-weight: ${theme.font.weight.regular};
  font-size: 12px;
  line-height: 12px;
  margin-top: -${theme.spacings.byte};
  padding-bottom: ${theme.spacings.bit};
`

export const PriceTag = styled('div')(
  priceTagStyles,
  isPromotionalPriceTagStyles,
  isOutletPriceTagStyles
)
export const Price = styled('span')(priceStyles)
export const PriceUnit = styled('span')(priceUnitStyles)
export const PriceContent = styled('div')(
  priceContentStyles,
  priceContentPromotionalStyles,
  priceContentWithInstallmentsStyles
)
export const AdditionalPriceInfo = styled('span')(additionalPriceInfoStyles)
export const AdditionalPaymentDiscountPriceInfo = styled('span')(
  additionalPaymentDiscountPriceInfo
)
