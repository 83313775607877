import {
  Button,
  Checkbox,
  Hidden,
  Text,
  Visible,
  toast
} from '@leroy-merlin-br/backyard-react'
import { ChangeEvent, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Layout } from '../../components/Layout'
import { MobileLayout } from '../../components/MobileLayout'
import { getProfile, updateProfile } from '../../services'
import { Optins } from '../../types'
import * as S from './styled'

export type Optin = {
  label: string
  name: string
  checked: boolean
}

const Preferences = () => {
  const { state } = useLocation()

  const history = useHistory()

  const [loading, setLoading] = useState(false)

  const [optins, setOptins] = useState<Optins>()

  const [items, setItems] = useState<Optin[]>([])

  useEffect(() => {
    ;(async () => {
      setLoading(true)

      const { data } = await getProfile()

      setOptins(data.optins)

      setItems([
        {
          label: 'e-mail',
          name: 'email',
          checked: data.optins?.email
        },
        {
          label: 'SMS',
          name: 'sms',
          checked: data.optins?.sms
        },
        {
          label: 'Whatsapp',
          name: 'whats_app',
          checked: data.optins?.whats_app
        },
        {
          label: 'Contato telefônico',
          name: 'phone_number',
          checked: data.optins?.phone_number
        },
        {
          label: 'Mala direta',
          name: 'direct_mail',
          checked: data.optins?.direct_mail
        },
        {
          label: 'Notificações',
          name: 'push_notification',
          checked: data.optins?.push_notification
        }
      ])

      setLoading(false)
    })()
  }, [state])

  const renderComponent = () => (
    <>
      <Text>
        Marque abaixo as opções onde você aceita receber novidades e promoções
        Leroy Merlin
      </Text>

      {items.map((item: Optin) => (
        <S.CheckboxWrapper key={`preferences-${item.name}-${item.checked}`}>
          <Checkbox
            data-gtm-element-id="options-preferences-checkbox"
            name={item.name}
            defaultChecked={item.checked}
            isDisabled={loading}
            value={optins[item.name]}
            onChange={({ target }: ChangeEvent<HTMLInputElement>) => {
              setOptins({ ...optins, [item.name]: !JSON.parse(target.value) })
            }}>
            <Text as="span" size="mega" color="n900">
              {item.label}
            </Text>
          </Checkbox>
        </S.CheckboxWrapper>
      ))}
    </>
  )

  const handleSubmit = async () => {
    setLoading(true)

    try {
      await updateProfile({ optins })

      toast.primary('Preferências atualizadas com sucesso!', {
        variant: 'solid'
      })
    } catch (error) {
      toast.critical('Não foi possível atualizar as suas preferências!', {
        variant: 'solid'
      })
    } finally {
      setLoading(false)

      history.push('/meus-contatos')
    }
  }

  return (
    <>
      <Visible when={['untilKilo', 'kilo']}>
        <MobileLayout description="Contatos">
          {renderComponent()}

          <S.ButtonWrapper>
            <Button
              data-gtm-element-id="click-save-prefenrences-button"
              isStretch
              isLoading={loading}
              onClick={handleSubmit}>
              Salvar
            </Button>
          </S.ButtonWrapper>

          <Button
            data-gtm-element-id="click-cancel-prefenrences-button"
            isStretch
            appearance="critical"
            variant="ghost"
            onClick={() => history.push('/meus-contatos')}>
            Cancelar
          </Button>
        </MobileLayout>
      </Visible>
      <Hidden when={['untilKilo', 'kilo']}>
        <Layout description="Contatos" backLink="/meus-contatos" maxWidth={376}>
          {renderComponent()}

          <div
            style={{
              marginTop: 24,
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between'
            }}>
            <Button
              data-gtm-element-id="click-cancel-prefenrences-button"
              isDisabled={loading}
              appearance="critical"
              variant="outline"
              size="kilo"
              onClick={() => history.push('/meus-contatos')}>
              Cancelar
            </Button>

            <Button
              data-gtm-element-id="click-save-prefenrences-button"
              isLoading={loading}
              size="kilo"
              data-testid="save-button"
              onClick={handleSubmit}>
              Salvar
            </Button>
          </div>
        </Layout>
      </Hidden>
    </>
  )
}

export default Preferences
