import { Button, Hidden } from '@leroy-merlin-br/backyard-react'

import { useLoyaltyProfile } from 'shared/hooks'

import IconLoyalty from 'lmcv/components/Icons/IconLoyalty'
import { Header as LoyaltyHeader } from 'lmcv/components'
import ImageBanner from 'lmcv/pages/Customers/components/Icons/ImageBanner'

import * as S from './styled'

interface WelcomeProps {
  onClickAdd: () => void
}

export default function Welcome({ onClickAdd }: WelcomeProps) {
  const { loyaltyProfile } = useLoyaltyProfile()

  return (
    <>
      {loyaltyProfile && <LoyaltyHeader loyaltyProfile={loyaltyProfile} />}

      <S.Wrapper data-testid="form-header-component">
        <S.ContainerContent>
          <IconLoyalty width={80} />

          <S.ContainerContent.Title>
            Iniciou um projeto?
          </S.ContainerContent.Title>

          <S.ContainerContent.Sub>
            <p>
              Convide seu cliente para se vincular a você na Leroy Merlin! A
              cada compra, você ganha 10% dos pontos dele, se for Engenheiro ou
              Decorador, e 100%, se for Arquiteto.
            </p>
            <p>Depois, é só trocar por cashback nas suas compras!</p>
          </S.ContainerContent.Sub>

          <Button appearance="primary" onClick={onClickAdd}>
            Vincular clientes
          </Button>

          <S.ContainerContent.BottomText>
            Você terá direito aos pontos apenas após seu cliente aceitar o
            convite para vínculo.
          </S.ContainerContent.BottomText>
        </S.ContainerContent>

        <Hidden when={['untilKilo', 'kilo', 'mega']}>
          <S.ContainerImage>
            <ImageBanner />
          </S.ContainerImage>
        </Hidden>
      </S.Wrapper>
    </>
  )
}
