import { FC } from 'react'
import { Icon } from '@backyard-ui/core'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  ImageOutline,
  StoreLeroyOutline,
  StoreOutline,
  DownloadOutline
} from 'frontendMyOrders/user-interface/icons/components'
import { Text } from 'frontendMyOrders/user-interface/typography'
import { generateSimpleId } from 'frontendMyOrders/utils'
import { byteToMegaByte } from 'frontendMyOrders/utils/formatters'

import { useResize } from 'scripts/react-components/utils/hooks/use-resize'

import {
  AttachmentsType,
  ContactSelectorOptionsType,
  ContactSelectorType,
  OptionsType,
  SelectOptionType,
  SubOptionsType,
  TernarySelectorOptionsType,
  TernarySelectorType
} from '../../MarketPlace/types'
import Options from '../../MarketPlace/Chat/components/Options'
import TernaryOptions from '../../MarketPlace/Chat/components/TernaryOptions'
import ContactOptions from '../../MarketPlace/Chat/components/ContactOptions'

const USER_TYPE = {
  BOT: 'bot',
  SELLER: 'seller',
  USER: 'user'
} as const

type UserTypeProps = (typeof USER_TYPE)[keyof typeof USER_TYPE]

const userTypeStyles = {
  bot: css`
    background-color: var(--color-surface-accent-soft);
  `,
  seller: css`
    background-color: var(--color-surface-neutral-stronger);
  `,
  user: css`
    background-color: var(--color-surface-accent-strong);
  `
}

type MessageBoxProps = {
  message: string
  type: UserTypeProps
  name: string
  timestamp: string
  attachments?: AttachmentsType
  options?: OptionsType
  subOptions?: SubOptionsType
  onOptionClick?: (option: SelectOptionType | string) => void
  onSubOptionClick?: (option: string) => void
  ternarySelected: 'SIM' | 'NAO' | ''
  ternaryOptions?: TernarySelectorOptionsType
  onTernaryOptionClick?: (ternaryOption: TernarySelectorType) => void
  contactOptions?: ContactSelectorOptionsType
  onContactOptionClick?: (contactOption: ContactSelectorType) => void
  isOnMobileApp?: boolean
}

interface MessageWrapperProps {
  type: UserTypeProps
}

const MessageWrapper = styled.div<MessageWrapperProps>`
  display: flex;
  align-items: flex-start;
  padding-top: 12px;
  gap: var(--desktop-space-inset-md);
  ${({ type }: MessageWrapperProps) =>
    type === 'user'
      ? `
        margin-left: var(--desktop-space-stack-4xl);
        justify-content: flex-end;
      `
      : `
        margin-right: var(--desktop-space-stack-2xl);
      `}
`

const MessageContent = styled.div<MessageWrapperProps>`
  ${({ type }: { type: UserTypeProps }) => userTypeStyles[type]};
  padding: var(--desktop-space-inset-xs);
  border-radius: 0 var(--border-radius-lg) var(--border-radius-lg)
    var(--border-radius-lg);
  display: inline-block;
  word-break: break-word;
  min-width: var(--desktop-space-inset-md);
  & > p {
    white-space: pre-wrap;
  }
`

const ImageWrapper = styled.div<MessageWrapperProps>`
  width: var(--desktop-space-inset-xl);
  height: var(--desktop-space-inset-xl);
  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-pill);
  ${({ type }: MessageWrapperProps) =>
    type === 'seller'
      ? `
        background-color: var(--color-surface-accent-default);
      `
      : `
        background-color: var(--color-surface-accent-soft);
      `}
`

const IconWrapper = styled.div`
  width: var(--desktop-space-inset-xl);
  height: var(--desktop-space-inset-xl);
  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const AvatarWrapper = styled.div<MessageWrapperProps>`
  width: 40px;
  height: 40px;
  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-pill);
  ${({ type }: MessageWrapperProps) =>
    type === 'seller'
      ? `
        background-color: var(--color-surface-info-softer);
        border: 1px solid var(--color-border-semantic-info);
      `
      : `
        background-color: var(--color-surface-accent-softer);
        border: 1px solid var(--color-border-semantic-accent);
      `}
`

const Attachment = styled.div`
  display: flex;
  align-items: center;
  gap: var(--desktop-space-inset-sm);
`

const AttachmentInfo = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
  gap: var(--desktop-space-inset-2xs);
`

const MessageInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--desktop-space-inset-2xs);
  width: 100%;
  margin-bottom: var(--desktop-space-inset-2xs);
`

const MessageContainer = styled.div<MessageWrapperProps>`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  gap: var(--desktop-space-inset-md);

  ${({ type }: MessageWrapperProps) =>
    type === 'user'
      ? `
      align-items: flex-end;
      `
      : `
      align-items: flex-start;
      `}
`

const MessageLabelWrapper = styled.div<MessageWrapperProps>`
  display: flex;
  flex-direction: column;

  ${({ type }: MessageWrapperProps) =>
    type === 'user'
      ? `
      align-items: flex-end;
      `
      : `
      align-items: flex-start;
      `}
`

const MessageBox: FC<MessageBoxProps> = ({
  message,
  type,
  name,
  timestamp,
  attachments,
  options,
  subOptions,
  onOptionClick,
  onSubOptionClick,
  ternarySelected,
  ternaryOptions,
  onTernaryOptionClick,
  contactOptions,
  onContactOptionClick,
  isOnMobileApp
}) => {
  const [isMobile] = useResize()

  return (
    <MessageWrapper type={type}>
      {type === USER_TYPE.SELLER && (
        <AvatarWrapper alt={`${name} avatar`} type={USER_TYPE.SELLER}>
          <Icon size="lg">
            <StoreOutline color="icon-semantic-info" fontSize="3xl" />
          </Icon>
        </AvatarWrapper>
      )}
      {type === USER_TYPE.BOT && (
        <AvatarWrapper alt={`${name} avatar`} type={USER_TYPE.BOT}>
          <Icon size="lg">
            <StoreLeroyOutline color="icon-semantic-accent" />
          </Icon>
        </AvatarWrapper>
      )}
      <MessageContainer type={type}>
        <MessageLabelWrapper type={type}>
          <MessageInfo>
            <Text appearance="neutral-strong" type="strong" size="sm">
              {name}
            </Text>
            <Text appearance="neutral-default" size="sm">
              {timestamp}
            </Text>
          </MessageInfo>
          <MessageContent type={type}>
            <Text
              size={isMobile ? 'md' : 'sm'}
              innerText={message}
              appearance={`${
                type === USER_TYPE.USER ? 'neutral-inverse' : 'neutral-strong'
              }`}
            />
          </MessageContent>
        </MessageLabelWrapper>
        {attachments &&
          attachments?.length > 0 &&
          attachments.map(attachment => (
            <MessageContent key={generateSimpleId()} type={type}>
              <Attachment>
                <ImageWrapper type={type}>
                  <Icon size="md">
                    <ImageOutline
                      color={`${
                        type === USER_TYPE.USER
                          ? 'icon-semantic-accent'
                          : 'icon-neutral-inverse'
                      }`}
                    />
                  </Icon>
                </ImageWrapper>
                <AttachmentInfo>
                  <Text
                    innerText={attachment.name}
                    appearance={`${
                      type === USER_TYPE.USER
                        ? 'neutral-inverse'
                        : 'neutral-strong'
                    }`}
                    size="sm"
                  />
                  <Text
                    appearance={`${
                      type === USER_TYPE.USER
                        ? 'neutral-inverse'
                        : 'neutral-strong'
                    }`}
                    size="sm">
                    {byteToMegaByte(attachment.size)}
                  </Text>
                </AttachmentInfo>
                <a
                  href={attachment.url}
                  target={isOnMobileApp ? '_blank' : '_self'}
                  rel="noreferrer">
                  <IconWrapper>
                    <Icon size="md">
                      <DownloadOutline
                        color={`${
                          type === USER_TYPE.USER
                            ? 'icon-neutral-inverse'
                            : 'icon-neutral-default'
                        }`}
                      />
                    </Icon>
                  </IconWrapper>
                </a>
              </Attachment>
            </MessageContent>
          ))}

        {options?.length && onOptionClick && (
          <Options options={options} onOptionClick={onOptionClick} />
        )}

        {subOptions?.length && onSubOptionClick && (
          <Options
            options={subOptions}
            onOptionClick={onSubOptionClick}
            isSubOption
          />
        )}

        {ternaryOptions?.length && onTernaryOptionClick && (
          <TernaryOptions
            ternaryOptions={ternaryOptions}
            onTernaryOptionClick={onTernaryOptionClick}
            ternarySelected={ternarySelected}
          />
        )}

        {contactOptions?.length && onContactOptionClick && (
          <ContactOptions
            contactOptions={contactOptions}
            onContactOptionClick={onContactOptionClick}
          />
        )}
      </MessageContainer>
    </MessageWrapper>
  )
}

export default MessageBox
