import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgQrScanOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M8.01 4H4v4H2V2h6.01zM2 16h2v4h4.01v2H2zm14 4h4v-4h2v6h-6zm4-16h-4V2h6v6h-2zm-9 7H5V5h6zM9 7H7v2h2zm2 12H5v-6h6zm-2-4H7v2h2zm4-10h6v6h-6zm2.01 8h-2v2h2v2h2v2h2v-2h-2v-2h2v-2h-2v2h-2zM15 9h2V7h-2z"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgQrScanOutline)
const Memo = memo(ForwardRef)
export default Memo
