import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import optionsReducer from './options'
import propertiesReducer from './properties'
import messagesReducer from './messages'

export const store = configureStore({
  reducer: {
    options: optionsReducer,
    properties: propertiesReducer,
    messages: messagesReducer
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
