import type { Modality, WidgetType } from '../types'

export const getItemsJoinedByPipe = (items: (string | number)[]) => {
  return items.length > 0 ? items.join('|') : undefined
}

export const getItemTotalPrice = (price: number, quantity: number) => {
  return parseFloat((price * quantity).toFixed(2))
}

export const getItemDiscount = (toPrice: number, fromPrice?: number) => {
  if (!fromPrice) return undefined

  return parseFloat((fromPrice - toPrice).toFixed(2))
}

export const getGTMWidgetType = (widgetType: WidgetType) => {
  const dictionary = {
    single_informative: 'informative',
    three_informative: 'informative',
    carousel_full_banner: 'slide',
    full_banner: 'banner',
    two_banners: 'banner',
    two_banners_wide: 'banner',
    three_banners: 'banner'
  }

  return dictionary[widgetType]
}

export const getCheapestModalityWithDeadline = (modalities: Modality[]) => {
  if (!modalities || !modalities.length) return null

  return modalities
    .filter((modality) => modality.deadline)
    .reduce((previous, current) => {
      return previous.cost < current.cost ? previous : current
    })
}

export const removeEmptyValues = <T extends object>(object: T): T =>
  Object.fromEntries(
    Object.entries(object).filter(([_, value]) => value !== '' && value != null)
  ) as T
