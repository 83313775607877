import { tv } from 'tailwind-variants'

export default tv({
  base: `
    border
    border-gray-200
  `,
  slots: {
    header: `
      bg-gray-100
      
      flex
      justify-center
      
      items-center
      
      h-8
      
      border-b
      border-gray-200
    `
  }
})
