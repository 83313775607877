import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperStyles = () => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--desktop-space-stack-md);
  width: 100%;
  height: 100%;
`

export const Wrapper = styled('div')(wrapperStyles)

const textWrapperStyles = () => css`
  text-align: center;
`

export const TextWrapper = styled('div')(textWrapperStyles)

const iconWrapperStyles = () => css`
  border: solid 3px var(--color-border-semantic-danger);
  border-radius: 100%;
`

export const IconWrapper = styled('div')(iconWrapperStyles)
