type FormatType =
  | 'long_day_month_year_hour'
  | 'long_month_hour'
  | 'long_weekday_day_month_year'
  | 'long_weekday_day_month'
  | 'long_day_month_hour'
  | 'short_hour'
  | 'short_day_month_year'

type OptionType = 'year' | 'month' | 'day' | 'hour' | 'minute' | 'weekday'

const dateFactory = (
  date: Date,
  option: OptionType,
  optionFormat: Intl.DateTimeFormatOptions[typeof option]
) => {
  const options: Intl.DateTimeFormatOptions = {
    [option]: optionFormat
  }

  const formatted = Intl.DateTimeFormat('pt-BR', {
    ...options,
    timeZone: 'America/Sao_Paulo'
  }).format(date)

  return option === 'minute' ? formatted.padStart(2, '0') : formatted
}

const isCustomDateFormat = (value: string) => {
  return /^\d{2}\/\d{2}\/\d{4}$/.test(value)
}

export const formatDate = (
  isoValue: string,
  format: FormatType = 'short_day_month_year'
): string => {
  if (!isoValue) {
    return ''
  }

  let date

  if (isCustomDateFormat(isoValue)) {
    const [day, month, year] = isoValue.split('/').map(Number)
    date = new Date(year, month - 1, day)
  } else {
    date = new Date(isoValue)

    if (isNaN(date.getTime())) {
      return ''
    }
  }

  switch (format) {
    case 'long_day_month_year_hour':
      return `${dateFactory(date, 'day', '2-digit')} de ${dateFactory(
        date,
        'month',
        'long'
      )} de ${dateFactory(date, 'year', 'numeric')} às ${dateFactory(
        date,
        'hour',
        '2-digit'
      )}:${dateFactory(date, 'minute', '2-digit')}`
    case 'long_month_hour':
      return `${dateFactory(date, 'month', 'long')} às ${dateFactory(
        date,
        'hour',
        '2-digit'
      )}:${dateFactory(date, 'minute', '2-digit')}`
    case 'long_weekday_day_month_year':
      return `${dateFactory(date, 'weekday', 'long')}, ${dateFactory(
        date,
        'day',
        '2-digit'
      )} de ${dateFactory(date, 'month', 'long')} de ${dateFactory(
        date,
        'year',
        'numeric'
      )}`
    case 'long_weekday_day_month':
      return `${dateFactory(date, 'weekday', 'long')}, ${dateFactory(
        date,
        'day',
        '2-digit'
      )} de ${dateFactory(date, 'month', 'long')}`
    case 'long_day_month_hour':
      return `${dateFactory(date, 'day', '2-digit')} de ${dateFactory(
        date,
        'month',
        'long'
      )} - ${dateFactory(date, 'hour', '2-digit')}:${dateFactory(
        date,
        'minute',
        '2-digit'
      )}`
    case 'short_hour':
      return `${dateFactory(date, 'hour', '2-digit')}:${dateFactory(
        date,
        'minute',
        '2-digit'
      )}`
    case 'short_day_month_year':
      return `${dateFactory(date, 'day', '2-digit')}/${dateFactory(
        date,
        'month',
        '2-digit'
      )}/${dateFactory(date, 'year', 'numeric')}`
  }
}
