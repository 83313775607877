import api from '../../api'

type HeaderItem = {
  name: string
  id?: string
  priority: number
  type: string
  url?: string
  hasChildren: boolean
}

export type HeaderMenu = HeaderItem & {
  items?: HeaderItem[]
}

type HeaderMenuResponse = {
  data: HeaderMenu[]
}

type SubCategoryChild = {
  id: string
  name: string
  url: string
}

type SubCategoryChildrenResponse = {
  data: {
    results: SubCategoryChild[]
  }
}

export const getHeaderMenu = async (): Promise<HeaderMenuResponse> => {
  const { data } = await api.get<HeaderMenuResponse>(
    '/api/v3/header-menu'
  )

  return data
}

export const getSubcategoryChildren = async (
  subcategoryId: string
): Promise<SubCategoryChildrenResponse> => {
  const { data } = await api.get<SubCategoryChildrenResponse>(
    `/api/v3/categories/${subcategoryId}/children`
  )

  return data
}
