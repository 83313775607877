import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { MyAccountContactContextProvider } from './context/my-account-contact-context'
import { MyAccountPersonalDataContextProvider } from './context/my-account-personal-data-context'
import {
  AddPhoneNumber,
  AddressNew,
  Addresses,
  CheckEmail,
  CheckPhone,
  ContactPreferences,
  Contacts,
  EditBirthdate,
  EditEmail,
  EditMainCell,
  EditName,
  EditPhoneNumber,
  Home,
  PageShell,
  PersonalData,
  Preferences
} from './pages'
import AccessData from './pages/AccessData/AccessData'
import { HomeProps } from './pages/Home/Home'
import { AccessDataProvider } from './context/my-account-access-data-context'

const Routes = (props: HomeProps) => (
  <BrowserRouter basename="/minha-conta">
    <Switch>
      <Route exact path="/" render={() => <Home {...props} />} />
      <Route exact path="/enderecos" component={Addresses} />
      <Route exact path="/adicionar-endereco" component={AddressNew} />
      <Route exact path="/editar-endereco" component={AddressNew} />
    </Switch>

    <Switch>
      <MyAccountPersonalDataContextProvider>
        <Route exact path="/dados-pessoais" component={PersonalData} />
        <Route exact path="/editar-nome" component={EditName} />
        <Route exact path="/editar-data-nascimento" component={EditBirthdate} />
      </MyAccountPersonalDataContextProvider>
    </Switch>

    <Switch>
      <AccessDataProvider {...props}>
        <Route exact path="/alterar-senha" component={AccessData} />
      </AccessDataProvider>
    </Switch>

    <Switch>
      <MyAccountContactContextProvider>
        <Route exact path="/adicionar-telefone" component={AddPhoneNumber} />
        <Route
          exact
          path="/editar-celular-principal"
          component={EditMainCell}
        />
        <Route exact path="/editar-email" component={EditEmail} />
        <Route exact path="/editar-telefone" component={EditPhoneNumber} />
        <Route exact path="/meus-contatos" component={Contacts} />
        <Route
          exact
          path="/preferencias-de-contato"
          component={ContactPreferences}
        />
        <Route exact path="/validar-celular" component={CheckPhone} />
        <Route exact path="/validar-email" component={CheckEmail} />
      </MyAccountContactContextProvider>
    </Switch>

    <Switch>
      <Route exact path="/dados-complementares" component={PageShell} />
      <Route exact path="/preferencias" component={Preferences} />
    </Switch>
  </BrowserRouter>
)

export default Routes
