import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgBlenderOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        d="M12.5 17.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"
        fill={color ? Colors[color] : 'currentColor'}
      />
      <path
        clipRule="evenodd"
        d="M6.006 2C4.766 2 4.062 3.418 4.81 4.406l1.493 1.97.602 6.626A2 2 0 0 0 5 15v5a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-5a2 2 0 0 0-1.905-1.998l.545-5.996A1.5 1.5 0 0 1 17 8.5V11h2V8.5a3.5 3.5 0 0 0-3.179-3.485l.076-.834A2 2 0 0 0 13.905 2zm7.08 11 .546-6H8.368l.182 2H11v2H8.731l.182 2zm.819-9-.09 1H7.77l-.758-1zM7 15v5h8v-5z"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgBlenderOutline)
const Memo = memo(ForwardRef)
export default Memo
