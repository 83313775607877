import { Hidden } from '@leroy-merlin-br/backyard-react'

import IconLoyalty from 'lmcv/components/Icons/IconLoyalty'

import * as S from './styled'
import ImageBanner from '../../../../../Icons/ImageBanner'

export default function Header() {
  return (
    <S.Wrapper data-testid="form-header-component">
      <S.ContainerContent>
        <IconLoyalty width={80} />

        <S.ContainerContent.Title>
          Cadastre-se e transforme seus pontos em <mark>cashback</mark>
        </S.ContainerContent.Title>

        <div>
          O novo benefício para Arquitetos do programa Leroy Merlin Com Você vai
          nos aproximar ainda mais e trazer benefícios incríveis. Para fazer
          parte do programa, preencha o cadastro abaixo e logo você estará apto
          a trocar seus pontos por reembolso direto na sua conta.
        </div>

        <S.ContainerContent.Checklist>
          <h3>Para participar é necessário:</h3>

          <ul>
            <li>CNPJ</li>
            <li>Inscrição Municipal</li>
            <li>Dados Bancários</li>
            <li>Comprovante Bancário</li>
          </ul>
        </S.ContainerContent.Checklist>
      </S.ContainerContent>

      <Hidden when={['untilKilo', 'kilo', 'mega']}>
        <S.ContainerImage>
          <ImageBanner />
        </S.ContainerImage>
      </Hidden>
    </S.Wrapper>
  )
}
