import { ReactNode } from 'react'
import { Flex, Icon, Spacer, Text } from '@backyard-ui/core'

import { BoxIcon } from '../BoxIcon'
import styles from './CardBase.styles'

interface CardBaseStockProps {
  title: string
  children: ReactNode
}

const CardBaseStock = ({ title, children }: CardBaseStockProps) => {
  const classNames = styles()

  return (
    <div className={classNames.base()}>
      <Flex direction="column">
        <div className={classNames.header()}>
          <Icon>
            <BoxIcon />
          </Icon>

          <Spacer size={2} />

          <Text weight="bold" color="neutral-700">
            {title}
          </Text>
        </div>

        {children}
      </Flex>
    </div>
  )
}

export default CardBaseStock
