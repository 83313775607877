import Confirm from 'garden/src/js/components/confirm'

import emitter from 'scripts/utils/emitter'

export const isLoggedIn = data => {
  return Boolean(data && data.email && data.email.length > 0)
}

export const isAssistedSale = data => {
  return data?.context === 'assisted-sale'
}

export const redirectToLogin = () => {
  const options = {
    textMessage:
      'Para utilizar as listas de favoritos é necessário efetuar login, deseja realizar esta operação?',
    textConfirmButton: 'Sim',
    textCancelButton: 'Não'
  }

  const callback = value => {
    if (value) {
      const hasParams = window.location.href.includes('?')
      const noCache = 'no-cache'
      const currentUrl = encodeURIComponent(
        window.location.href + (hasParams ? '&' : '?') + noCache
      )
      window.location = `/login?redirect=${currentUrl}`
    }
  }

  return new Confirm(callback, options)
}

export const isOnMobileApp = () =>
  Boolean(
    navigator.userAgent.match('Boitata-App') ||
      document.cookie.match('is_on_mobile_app=true') ||
      window.location.search.match('isOnMobileApp=true')
  )

export const emitSuccessMessage = success => {
  emitter.emit('stickyFeedback:success', {
    title: 'Sucesso:',
    content: success
  })
}

export const emitErrorsMessage = error => {
  emitter.emit('stickyFeedback:error', {
    title: 'Ops! Ocorreu o seguinte erro:',
    content: error
  })
}
