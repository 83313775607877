import { datadogRum } from '@datadog/browser-rum'

import { setIsLoading, setPanicError } from '../redux/properties'
import { useAppDispatch } from '../redux/store'
import {
  closeMarketplaceChat,
  genericPostService,
  getMarketplaceChat,
  getMarketplaceChatByThreadId,
  notifySalesForce,
  transferChatPost
} from '../services'
import type {
  ApiPostNotify,
  ApiPostPayload,
  ApiPostResponse,
  ApiThreadResponseType,
  PanicErrorType,
  TransferChatPostPayload,
  TransferChatPostResponse,
  URLParamsType
} from '../types'

function useMarketplaceChatService() {
  const dispatch = useAppDispatch()

  const get = async ({
    orderId,
    marketplaceOrder
  }: {
    orderId: string
    marketplaceOrder: string
  }): Promise<ApiThreadResponseType['data'] | undefined> => {
    try {
      dispatch(setIsLoading(true))
      const response = await getMarketplaceChat({
        orderId,
        marketplaceOrder
      })
      if (response) {
        return response
      }
    } catch (error) {
      dispatch(setPanicError(error))
      datadogRum.addError(error, {
        message: '[MARKETPLACECHAT] - error on GET thread'
      })
    } finally {
      dispatch(setIsLoading(false))
    }
  }

  const fetchByThreadId = async ({
    orderId,
    marketplaceOrder,
    threadId
  }: {
    orderId: string
    marketplaceOrder: string
    threadId: string
  }): Promise<ApiThreadResponseType['data'] | undefined> => {
    try {
      dispatch(setIsLoading(true))
      const response = await getMarketplaceChatByThreadId({
        orderId,
        marketplaceOrder,
        threadId
      })
      if (response) {
        return response
      }
    } catch (error) {
      dispatch(setPanicError(error))
      datadogRum.addError(error, {
        message: '[MARKETPLACECHAT] - error on GET by thread ID'
      })
    } finally {
      dispatch(setIsLoading(false))
    }
  }

  const post = async (
    threadId: string,
    data: ApiPostPayload,
    orderId: string,
    marketplaceOrder: string
  ): Promise<ApiPostResponse['data'] | undefined> => {
    dispatch(setIsLoading(true))
    const urlParams: URLParamsType = {
      orderId,
      marketplaceOrder,
      threadId
    }
    try {
      const response = await genericPostService({
        urlParams,
        data
      })

      return response
    } catch (error) {
      const { data, status } = error as PanicErrorType
      dispatch(
        setPanicError({
          data: data,
          status: status
        })
      )
      datadogRum.addError(error, {
        message: '[MARKETPLACECHAT] - error on POST thread'
      })
    } finally {
      dispatch(setIsLoading(false))
    }
  }

  const transfer = async ({
    threadId,
    data,
    orderId,
    marketplaceOrder
  }: {
    threadId: string
    data: TransferChatPostPayload
    orderId: string
    marketplaceOrder: string
  }): Promise<TransferChatPostResponse['data'] | undefined> => {
    dispatch(setIsLoading(true))

    try {
      const response = await transferChatPost({
        threadId,
        data,
        orderId,
        marketplaceOrder
      })

      return response
    } catch (error) {
      dispatch(setPanicError(error))
      datadogRum.addError(error, {
        message: '[MARKETPLACECHAT] - error on POST transfer'
      })
    } finally {
      dispatch(setIsLoading(false))
    }
  }

  const notify = async (
    data: ApiPostNotify,
    orderId: string,
    marketplaceOrder: string
  ): Promise<ApiPostResponse['data'] | undefined> => {
    dispatch(setIsLoading(true))
    const urlParams: URLParamsType = {
      orderId,
      marketplaceOrder
    }
    try {
      const response = await notifySalesForce({
        urlParams,
        notifyData: data
      })

      return response
    } catch (error) {
      dispatch(setPanicError(error))
      datadogRum.addError(error, {
        message: '[MARKETPLACECHAT] - error on POST notify'
      })
    } finally {
      dispatch(setIsLoading(false))
    }
  }

  const close = async (
    orderId: string,
    marketplaceOrder: string,
    threadId: string
  ): Promise<ApiPostResponse['data'] | undefined> => {
    dispatch(setIsLoading(true))
    const urlParams: URLParamsType = {
      orderId,
      marketplaceOrder,
      threadId
    }
    try {
      const response = await closeMarketplaceChat({
        urlParams
      })

      return response
    } catch (error) {
      dispatch(setPanicError(error))
      datadogRum.addError(error, {
        message: '[MARKETPLACECHAT] - error on POST close'
      })
    } finally {
      dispatch(setIsLoading(false))
    }
  }

  return { post, get, fetchByThreadId, notify, close, transfer }
}

export default useMarketplaceChatService
