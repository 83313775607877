import Mask from 'vanilla-masker'

const setPhoneMask = text => {
  switch (text.length) {
    case 11:
      return '(99) 99999-9999'

    case 10:
      return '(99) 9999-9999'

    case 9:
      return '99999-9999'

    default:
      return '9999-9999'
  }
}

const setCepMask = (value = '') => {
  return value.replace(/^(\d{5})(\d{3})+?$/, '$1-$2')
}

const maskedInput = (type, current, rule) => {
  if (current) {
    switch (type) {
      case 'money':
        Mask(current).maskMoney({ precision: 0, unit: 'R$' })
        break

      default:
        Mask(current).maskPattern(rule)
        break
    }
  }
}

const maskedText = (type, text, rule) => {
  let maskedText, maskPattern

  switch (type) {
    case 'money':
      maskedText = Mask.toMoney(text, { precision: 0, unit: 'R$' })
      break

    case 'phone':
      maskPattern = setPhoneMask(text)
      break

    case 'cpf':
      maskPattern = '999.999.999-99'
      break

    case 'cnpj':
      maskPattern = '99.999.999/9999-99'
      break

    case 'cep':
      maskPattern = setCepMask(text)
      break

    default:
      maskPattern = rule
      break
  }

  if (!maskedText) {
    maskedText = Mask.toPattern(text, maskPattern)
  }

  return maskedText
}

export default { maskedText, maskedInput }
