import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

export const Header = styled('div')(
  ({ theme }: Theme) => css`
    position: sticky;
    top: 0;
    background-color: ${theme.colors.white};
    z-index: ${theme.zIndex.modal};
    padding: ${theme.spacings.kilo};

    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    transition:
      box-shadow 0.3s ease-in,
      box-shadow 0.05s ease-out;

    &.sticky-active {
      border: 1px solid ${theme.colors.n200};
    }

    .saving {
      background-color: ${theme.colors.b100};
      padding: ${theme.spacings.bit} ${theme.spacings.byte};
      border-radius: 4px;
      color: ${theme.colors.b700};

      display: flex;
      align-items: center;
      gap: ${theme.spacings.byte};
    }
  `
)
