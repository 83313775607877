import { ReactNode } from 'react'
import { Stack, Flex, Text, Hidden, Button } from '@backyard-ui/core'
import { useMediaQuery } from '@backyard-ui/hooks'
import { LeroyLogoFilled } from '@backyard-ui/icons'
import { useClearRefinements, useStats } from 'react-instantsearch'
import { RemoveScroll } from 'react-remove-scroll'

import { IS_ON_MOBILE_APP } from 'entry-key/constants'

import { useEntryStore } from '../../../Entry.store'
import styles from './MobileFilter.styles'

interface MobileFilterProps {
  children: ReactNode
}

function MobileFilter(props: MobileFilterProps) {
  const { children } = props

  const { refine: clearFilters } = useClearRefinements()
  const isDesktop = useMediaQuery('lg')
  const store = useEntryStore(state => state.ui)
  const { nbHits } = useStats()

  const totalHits = nbHits.toLocaleString()

  return (
    <RemoveScroll allowPinchZoom enabled={!isDesktop && store.isFiltersOpened}>
      <div
        id="mobile-filter-content"
        className={styles().base({
          isOpen: store.isFiltersOpened
        })}>
        {!IS_ON_MOBILE_APP && (
          <Hidden above="lg">
            <Flex justify="center" className={styles().header()}>
              <LeroyLogoFilled fill="#fff" />
            </Flex>
          </Hidden>
        )}
        <Stack direction="column" className={styles().container()}>
          <Hidden above="lg">
            <Stack align="center" justify="space-between">
              <Text size="2xl" weight="bold">
                Filtros
              </Text>

              <Button
                appearance="neutral"
                size="sm"
                variant="ghost"
                onClick={store.toggleFilters}>
                Fechar
              </Button>
            </Stack>
          </Hidden>
          {children}
          <Hidden above="lg">
            <Stack
              className={styles().footer({
                appPaddingBottom: Boolean(IS_ON_MOBILE_APP)
              })}
              isGrow>
              <Button
                appearance="neutral"
                size="xs"
                variant="ghost"
                onClick={clearFilters}>
                Limpar filtros
              </Button>
              <Button
                size="xs"
                onClick={() => {
                  store.toggleFilters?.()
                  window.scrollTo({ top: 0, behavior: 'smooth' })
                  document
                    .getElementById('mobile-filter-content')
                    ?.scroll({ top: 0, behavior: 'smooth' })
                }}>
                Ver{' '}
                {`${totalHits} ${
                  totalHits === '1' ? 'resultado' : 'resultados'
                }`}
              </Button>
            </Stack>
          </Hidden>
        </Stack>
      </div>
    </RemoveScroll>
  )
}

export default MobileFilter
