import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  Text,
  Button,
  Visible,
  Stack,
  Heading,
  Card
} from '@leroy-merlin-br/backyard-react'

import { formatDate } from 'utils/date'

import { Avatar } from 'lmcv/components'

import { Welcome } from './components/Welcome'
import * as S from './styled'

const Status = ({ status, expireAt }) => {
  const MAP_STATUS_TEXT = {
    Pendente: '',
    Expirado: 'Expirado',
    Ativo: 'Finaliza',
    Concluído: 'Finalizado',
    Finalizado: 'Finalizado'
  }

  if (expireAt && MAP_STATUS_TEXT[status]) {
    return (
      <S.ItemCol>
        <Text color="n800" size="mega" noMargin>
          <strong>{MAP_STATUS_TEXT[status]} em:</strong>{' '}
          {formatDate(expireAt, 'DD/MM/YYYY')}
        </Text>
      </S.ItemCol>
    )
  }

  return null
}

const MessageInvites = ({ invites }) => {
  if (invites && invites > 1) {
    return `Você possui ${invites} convites para enviar para seus clientes`
  }

  if (invites && invites === 1) {
    return `Você possui ${invites} convite para enviar para seus clientes`
  }

  return 'Você atingiu o número máximo de convites para enviar'
}

const List = ({ data, remainingInvites, onClickAdd }) => (
  <>
    {data.length ? (
      <Card>
        <S.List>
          {data.map(({ id, customerName, status, projectName, expireAt }) => (
            <S.Item key={id} as={Link} to={`/cliente/${id}`}>
              <S.ItemCol>
                <Avatar name={customerName} />

                <S.Info>
                  <Stack space="bit">
                    <Heading color="n800" size="kilo" noMargin>
                      {customerName}
                    </Heading>
                    <Visible when={['untilKilo', 'kilo']}>
                      <Text color="n600" size="mega" noMargin>
                        {projectName}
                      </Text>
                    </Visible>
                    <S.Status color={status.toLowerCase()}>
                      <Text size="kilo" noMargin>
                        {status}
                      </Text>
                    </S.Status>
                  </Stack>
                </S.Info>
              </S.ItemCol>
              <S.ItemCol>
                <Stack space="bit">
                  <Text color="n500" size="mega" noMargin>
                    Nome do Projeto:
                  </Text>
                  <Text color="p500" noMargin>
                    {projectName}
                  </Text>
                </Stack>
              </S.ItemCol>
              <Status expireAt={expireAt} status={status} />
            </S.Item>
          ))}
        </S.List>

        <S.Divider />

        <S.Footer>
          <S.TextFooter>
            <MessageInvites invites={remainingInvites} />
          </S.TextFooter>

          <Button
            size="kilo"
            isDisabled={!remainingInvites}
            onClick={onClickAdd}>
            Adicionar mais clientes
          </Button>
        </S.Footer>
      </Card>
    ) : (
      <Welcome onClickAdd={onClickAdd} />
    )}
  </>
)

List.defaultProps = {
  data: PropTypes.array.isRequired,
  onClickAdd: PropTypes.func
}

export default List
