import { Button, Flex, Spacer, Text, Spinner } from '@backyard-ui/core'
import { Fragment } from 'react'

import { CardBase } from '../CardBase'
import styles from './CardSummaryOthersStock.styles'

interface CardSummaryOthersStock {
  onClose: () => void
  batches: {
    batchId: string
    quantity: number
    unit: string
    description: string
  }[]
  loading?: boolean
}

const CardSummaryOthersStock = ({
  batches,
  onClose,
  loading = false
}: CardSummaryOthersStock) => {
  if (loading) {
    return (
      <Flex justify="center" data-testid="loading">
        <Spinner appearance="primary" size="xl" />
      </Flex>
    )
  }

  return (
    <Fragment>
      <CardBase title="Estoque - Outros Lotes">
        <div className={styles().base()}>
          {!batches?.length ? (
            <div className={styles().item()}>
              <Flex justify="center">
                <Text size="sm" weight="semibold" color="neutral-700">
                  Nenhum dado disponível.
                </Text>
              </Flex>
            </div>
          ) : (
            batches.map(item => (
              <div key={item.batchId} className={styles().item()}>
                <Flex justify="space-between" align="center">
                  <Text
                    weight="bold"
                    color="neutral-700">{`Lote ${item.batchId}:`}</Text>

                  <Flex align="center">
                    <Text
                      weight="bold"
                      color="neutral-700">{`${item.quantity} ${item.unit}`}</Text>
                    <Spacer />
                    <Flex align="center">
                      <Text size="xs" color="neutral-700">
                        ({item.description})
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </div>
            ))
          )}
        </div>
      </CardBase>

      <Spacer axis="vertical" size={4} />

      <Flex justify="center">
        <Button variant="ghost" onClick={onClose}>
          Fechar
        </Button>
      </Flex>
    </Fragment>
  )
}

export default CardSummaryOthersStock
