import { FunctionComponent } from 'react'
import { Inline, Stack, Text } from '@leroy-merlin-br/backyard-react'

import { Carousel, DefaultItem } from './components'
import { Props } from './types'

const Variant: FunctionComponent<Props> = ({
  productId,
  variant: { name, types }
}) => {
  const currentTypeIndex = types.findIndex(
    ({ id }) => String(id) === String(productId)
  )

  const currentType = types[currentTypeIndex]

  return (
    <Stack space="bit">
      <Inline wrap="wrap" space="bit" alignY="baseline">
        <Text size="kilo" noMargin isBold data-testid="variant-name">
          {name}:
        </Text>
        <Text size="kilo" noMargin data-testid="variant-current">
          {currentType?.label}
        </Text>
      </Inline>
      <Carousel initialSlide={currentTypeIndex - 1}>
        {types.map(({ label, url, hasStock, id }) => {
          const isActive = String(currentType?.id) === String(id)
          const isDisabled = !hasStock && !isActive

          return (
            <DefaultItem
              key={id}
              label={label}
              url={url}
              isActive={isActive}
              isDisabled={isDisabled}
            />
          )
        })}
      </Carousel>
    </Stack>
  )
}

export default Variant
