import { SubOptionsType } from '../MarketPlace/types'

export const generateShiftReturnMessage = (
  createdAt: string,
  subOptions: SubOptionsType = []
) => {
  return {
    text: 'Agora, escolha o motivo da <strong>troca ou devolução.</strong>',
    from: 'bot',
    time: createdAt || new Date().toISOString(),
    subOptions
  }
}

export const generateSubOptionShiftReturnMessage = ({
  createdAt,
  shopName
}: {
  shopName: string
  createdAt: string
}) => {
  return {
    text: `Conte um pouco mais sobre seu problema. Assim fica mais fácil para o vendedor <strong>${shopName}</strong> ajudar você.`,
    from: 'bot',
    time: createdAt || new Date().toISOString()
  }
}
