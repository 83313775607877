import { Button, Stack } from '@leroy-merlin-br/backyard-react'

import { InvoiceSender } from 'scripts/react-components/lmcv-pro/pages/CashbackPage/components/CashbackRedeem/components/InvoiceSender'
import { ErrorMessage } from 'scripts/react-components/lmcv-pro/pages/CashbackPage/components/CashbackRedeem/components/StatusStepper/components/Content/components/ErrorMessage'

import { InvoiceStatus } from 'lmcv/pages/CashbackPage/types/invoice-status'
import { Item } from 'lmcv/pages/CashbackPage/components/Stepper/types/item'
import { ItemStatus } from 'lmcv/pages/CashbackPage/components/Stepper/types/item-status'
import { Stepper } from 'lmcv/pages/CashbackPage/components/Stepper'

import * as S from './styled'
import { useRedeemContext } from '../../../../contexts/RedeemContext'

type StatusRules = {
  [key in InvoiceStatus]: [ItemStatus, ItemStatus, ItemStatus]
}

const statusRules: StatusRules = {
  NOT_SENT: ['pending', 'pending', 'pending'],
  SENT_WAITING_ANALYSIS: ['done', 'inProgress', 'pending'],
  PAYMENT_SUCCESS: ['done', 'done', 'done'],
  PAYMENT_DENIED: ['done', 'done', 'block'],
  UNKNOWN: ['pending', 'pending', 'pending']
}

export default function Content() {
  const { invoiceStatus = 'NOT_SENT', invoiceStatusData } = useRedeemContext()

  const itemsStatus = statusRules[invoiceStatus]

  const stepperItems: Item[] = [
    {
      label: 'Enviada',
      status: itemsStatus[0]
    },
    {
      label: 'Análise',
      status: itemsStatus[1]
    },
    {
      label: 'Pagamento',
      status: itemsStatus[2]
    }
  ]

  const showButtonContinue = invoiceStatus === 'PAYMENT_SUCCESS'

  const message =
    invoiceStatus === 'PAYMENT_DENIED' && invoiceStatusData?.status.message
      ? invoiceStatusData?.status.message
      : 'Sua nota fiscal já está sendo analisada pelo nosso time e assim que aprovada seguiremos para o pagamento.'

  const isDenied = invoiceStatus === 'PAYMENT_DENIED'

  return (
    <Stack space="kilo">
      <Stepper items={Object.values(stepperItems)} />

      <S.ContentInfo>
        {isDenied ? (
          <S.DeniedCard data-testid="denied-card">
            <ErrorMessage message={message} />
            <InvoiceSender />
          </S.DeniedCard>
        ) : (
          <S.Message>
            <p>{message}</p>
          </S.Message>
        )}

        {showButtonContinue && (
          <Button size="kilo" data-testid="button-continue">
            Continuar
          </Button>
        )}
      </S.ContentInfo>
    </Stack>
  )
}
