import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Theme } from 'scripts/react-components/themes/Theme'

const baseStyles = ({ theme }: Theme) => css`
  label: title;

  width: 100%;

  display: inline-grid;
  grid-template-columns: 50% 1fr;
  grid-template-areas:
    'type button'
    'quantity shipping'
    'chat chat';
  row-gap: ${theme.spacings.bit};

  background-color: ${theme.colors.white};
  padding: ${theme.spacings.kilo};

  ${theme.media.mega} {
    grid-template-columns: 30% 70px 1fr 70px 2fr;
    grid-template-areas: 'type quantity shipping button chat';
  }
`

const typeStyles = ({ theme }: Theme) => css`
  label: title__type;

  grid-area: type;
  justify-self: start;

  ${theme.media.mega} {
    align-self: center;
  }
`

const quantityStyles = () => css`
  label: title__quantity;

  grid-area: quantity;
  justify-self: start;

  text-align: center;
`

const shippingStyles = ({ theme }: Theme) => css`
  label: title__shipping;

  grid-area: shipping;
  justify-self: end;

  ${theme.media.mega} {
    justify-self: start;
  }
`

const shiftStyles = ({ theme }: Theme) => css`
  label: title__shift;

  grid-area: button;
  justify-self: end;


  ${theme.media.mega} {
    justify-self: start;
  }
`

const chatStyles = ({ theme }: Theme) => css`
  label: title__shift;

  grid-area: chat;
  justify-self: end;

  ${theme.media.mega} {
    justify-self: end;
  }
`

export const Wrapper = styled('div')(baseStyles)
export const Type = styled('div')(typeStyles)
export const Quantity = styled('div')(quantityStyles)
export const Shipping = styled('div')(shippingStyles)
export const Shift = styled('div')(shiftStyles)
export const Chat = styled('div')(chatStyles)
