import { Header } from 'site/NewHeader/entry'
import type { HeaderProps } from 'site/NewHeader/entry'

import { InstantSearch } from '../InstantSearch'
import { Layout } from '../layout'
import { PageEntry } from '../pages/entry'
import { CategoryPage } from '../pages/category'
import { ServerProvider } from './routes.context'
import { IS_CATEGORY } from '../utils/constants'
import { IS_ON_MOBILE_APP } from '../constants'

export type CategoryFiltersLabelProps = { [key: string]: string }

export type AlgoliaConfigProps = {
  indexName: string
  retailMediaIndexName: string
  appId: string
  apiKey: string
  facets: {
    [key: string]: string
  } | null
  maxFacets: number
}
export interface RouterProps extends HeaderProps {
  /**
   * Entry Key API domain
   */
  apiDomain: string

  /**
   * Algolia category data
   */
  meta: {
    id: {
      $oid: string
    }
    tree: string
    description: {
      top: string
      footer: string
    }
  }
}

function Routes(props: RouterProps) {
  const { socialLoginProviders, isBlackWeekend, meta, algolia, ...rest } = props

  const CATEGORY_DATA = JSON.parse(String(meta))
  const ALGOLIA_DATA = JSON.parse(String(algolia))

  return (
    <ServerProvider
      value={{
        ...rest,
        meta: CATEGORY_DATA,
        algolia: ALGOLIA_DATA
      }}>
      <InstantSearch categoryPageId={CATEGORY_DATA.tree} algolia={ALGOLIA_DATA}>
        {!IS_ON_MOBILE_APP && (
          <div data-testid="entry-key-algolia-header">
            <Header
              socialLoginProviders={socialLoginProviders}
              isBlackWeekend={isBlackWeekend}
              forceRouting={IS_CATEGORY}
              algolia={ALGOLIA_DATA}
            />
          </div>
        )}

        {IS_CATEGORY ? (
          <CategoryPage />
        ) : (
          <Layout>
            <PageEntry />
          </Layout>
        )}
      </InstantSearch>
    </ServerProvider>
  )
}

export default Routes
