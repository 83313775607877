import { ChevronRight } from '@backyard-ui/icons'
import { Spinner, Text } from '@leroy-merlin-br/backyard-react'
import { AxiosResponse } from 'axios'
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { Shell } from 'scripts/react-components/shared/components'
import {
  EmailIcon,
  SMSIcon
} from 'scripts/react-components/user/recovery/components/icons'

import { useAccessDataContext } from '../../context/my-account-access-data-context'
import {
  OtpCodeParams,
  getContact,
  getContactParams,
  sendOTPCode
} from '../../services'
import { Contact } from '../../types'
import { ShowToastParams, showToast } from '../../utils/toast'
import * as S from './styled'

type Option = { title: string; icon: ReactNode }

type OptionType = 'email' | 'phone'

export type OptionToRecovery = {
  contact: string
  hash: string
  type: string
}

type loadingState = {
  loadingContacts: boolean
  loadingSendOtpCode: Record<OptionType, boolean>
}

type Options = { [key: string]: Option }

type OptionsToReceiveSecurityCodeStepProps = {
  onNextStep: () => void
}

const OptionsToReceiveSecurityCodeStep: FC<
  OptionsToReceiveSecurityCodeStepProps
> = ({ onNextStep }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const [loading, setLoading] = useState<loadingState>({
    loadingContacts: true,
    loadingSendOtpCode: {
      email: false,
      phone: false
    }
  })
  const [userContacts, setContacts] = useState<Contact[]>([])

  const { contextData, updateData, isCaptchaEnabled } = useAccessDataContext()

  const showToastParams: ShowToastParams = useMemo(
    () => ({
      type: 'critical',
      title: 'Não foi possível enviar o código.',
      content: 'Tente novamente mais tarde.'
    }),
    []
  )

  const handleSelectedOption = async (option: OptionToRecovery) => {
    if (loading.loadingSendOtpCode.email || loading.loadingSendOtpCode.phone) {
      return
    }

    setLoading(prevLoading => ({
      ...prevLoading,
      loadingSendOtpCode: {
        ...prevLoading.loadingSendOtpCode,
        [option.type]: true
      }
    }))

    updateData({
      ...contextData,
      optionToRecovery: {
        ...option
      }
    })

    const otpParams: OtpCodeParams = {
      fiscalId: contextData.fiscalId,
      hash: option.hash,
      type: option.type,
      context: 'reset_password'
    }

    try {
      await sendOTPCode(otpParams)
      onNextStep()
    } catch (error) {
      const { status } = error as AxiosResponse

      if (status === 429) {
        showToastParams.title = 'Você atingiu o limite de tentativas!'
      }

      showToast(showToastParams)
    } finally {
      setLoading(prevLoading => ({
        ...prevLoading,
        loadingSendOtpCode: {
          ...prevLoading.loadingSendOtpCode,
          [option.type]: false
        }
      }))
    }
  }

  const fetchContacts = useCallback(async () => {
    if (!executeRecaptcha) {
      return
    }

    try {
      const getContactParams: getContactParams = {
        fiscalId: contextData.fiscalId,
        recaptchaToken: isCaptchaEnabled
          ? await executeRecaptcha('contactsChangePassword')
          : null
      }

      const { data } = await getContact(getContactParams)
      setContacts(data)
      setLoading(prevLoading => ({ ...prevLoading, loadingContacts: false }))
    } catch (error) {
      const { status } = error as AxiosResponse

      const toastParams = { ...showToastParams }
      toastParams.title = 'Não foi possível encontrar os contatos.'

      if (status === 429) {
        toastParams.title = 'Você atingiu o limite de tentativas!'
      }

      showToast(toastParams)
    }
  }, [
    executeRecaptcha,
    contextData.fiscalId,
    isCaptchaEnabled,
    showToastParams
  ])

  useEffect(() => {
    fetchContacts()
  }, [fetchContacts])

  const options: Options = useMemo(
    () => ({
      email: { title: 'E-mail', icon: <EmailIcon /> },
      phone: { title: 'SMS', icon: <SMSIcon /> }
    }),
    []
  )

  const nextStepComponent = (optionType: OptionType) => {
    return loading.loadingSendOtpCode[optionType] ? (
      <Spinner data-testid="spinner" size="mega" />
    ) : (
      <ChevronRight width={18} height={18} />
    )
  }

  const renderOptionComponent = () => {
    if (loading.loadingContacts) {
      return (
        <S.Shell>
          <S.OptionContent>
            <Shell height={160} width={403} />
          </S.OptionContent>
        </S.Shell>
      )
    }

    return userContacts.map((option: OptionToRecovery, index: number) => {
      const { icon, title } = options[option.type as OptionType]

      return (
        <S.Option
          key={`password-recovery-option-${index}`}
          id={`${option.type}-option`}
          onClick={() => handleSelectedOption(option)}>
          <S.OptionContent>
            {icon}
            <S.OptionText>
              <Text isBold noMargin>
                {title}
              </Text>
              <Text noMargin>{option.contact}</Text>
            </S.OptionText>
          </S.OptionContent>
          <S.OptionIconWrapper>
            {nextStepComponent(option.type as OptionType)}
          </S.OptionIconWrapper>
        </S.Option>
      )
    })
  }

  return (
    <>
      <Text>Escolha por onde receber o código para alterar sua senha</Text>
      <S.OptionsWrapper>{renderOptionComponent()}</S.OptionsWrapper>
    </>
  )
}

export default OptionsToReceiveSecurityCodeStep
