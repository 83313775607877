import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { styleHelpers } from '@leroy-merlin-br/backyard-react'

const getBackgroundColorByTagType = tagType => {
  const colors = {
    exclusive: 'p700',
    lowestprice: 'y500',
    freeShipping: 'r700',
    outlet: 'n900',
    news: 'n800'
  }

  return colors[tagType]
}

const getColorByTagType = tagType => {
  const colors = {
    exclusive: 'white',
    lowestprice: 'n900',
    freeShipping: 'white',
    outlet: 'white',
    news: 'white'
  }

  return colors[tagType]
}

const productThumbNailContentStyles = () => css`
  label: product-thumbnail;

  max-height: 208px;
  flex: 1;
  overflow: hidden;
  position: relative;
`

const offerContentStyles = ({ theme }) => css`
  label: product-thumbnail__offer-content;

  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000px;

  font-weight: ${theme.font.weight.bold};

  position: absolute;
  bottom: 0;

  box-sizing: border-box;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 ${theme.spacings.byte};

  width: 100%;
  height: 24px;

  ${theme.media.giga} {
    height: 28px;
    padding: 0 ${theme.spacings.kilo} 2px ${theme.spacings.kilo};

    span {
      ${styleHelpers.textMega(theme)};
    }
  }
`

const typeSaleOfferStyles = ({ theme, isTypeSale }) =>
  isTypeSale &&
  css`
    label: product-thumbnail__offer-content--sale;

    background: ${theme.colors.white};
    border-bottom: 3px ${theme.colors.r600} solid;
    border-top: 3px ${theme.colors.r600} solid;
    color: ${theme.colors.r600};
  `

const typeLowestPriceOfferStyles = ({ theme, isTypeLowestPrice }) =>
  isTypeLowestPrice &&
  css`
    label: product-thumbnail__offer-content--lowest-price;

    background: ${theme.colors.y500};
    color: ${theme.colors.n900};
  `

const typeOfferStyles = ({ theme, isTypeOffer }) =>
  isTypeOffer &&
  css`
    label: product-thumbnail__offer-content--offer;

    background: ${theme.colors.r600};
    color: ${theme.colors.y500};
  `

const typeDiscountByPaymentStyles = ({ theme, isDiscountByPayment }) =>
  isDiscountByPayment &&
  css`
    label: product-thumbnail__offer-content--discount-by-payment;

    background: ${theme.colors.p600};
    color: ${theme.colors.white};

    span {
      ${styleHelpers.textKilo(theme)};
    }
  `

const typeOutletOfferStyles = ({ theme, isOutlet }) =>
  isOutlet &&
  css`
    label: product-thumbnail__offer-content--outlet;

    background: ${theme.colors.black};
    color: ${theme.colors.p500};
  `

const ProductThumbNailImageStyles = () => css`
  label: product-thumbnail__image;

  display: flex;

  height: auto;
  width: 100%;
  max-height: 206px;
  max-width: 206px;

  margin: 1px auto 0;
  transform: scale(1);
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
    will-change: transform;
  }
`

const productThumbNailMetaStyles = () => css`
  label: product-thumbnail__meta;

  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  pointer-events: none;

  left: 0px;
  right: 0px;
  top: 8px;
  z-index: 10;
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 16px);

  & button {
    pointer-events: auto;
  }
`

const productBadgeStyles = ({ theme }) => css`
  label: product-thumbnail__offer-content__badge;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 10px;
  color: ${theme.colors.white};
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1;

  padding: 5px ${theme.spacings.byte};

  border-radius: ${theme.borderRadius.tera};

  ${theme.media.kilo} {
    font-size: 12px;
  }
`

const productBadgeStylesByTagType = ({ theme, tagType }) =>
  tagType &&
  css`
    label: product-thumbnail__badge--${tagType};

    background: ${theme.colors[getBackgroundColorByTagType(tagType)]};
    color: ${theme.colors[getColorByTagType(tagType)]};
  `

export const ProductThumbNailContent = styled('div')(
  productThumbNailContentStyles
)

export const OfferContent = styled('div')(
  offerContentStyles,
  typeSaleOfferStyles,
  typeOfferStyles,
  typeLowestPriceOfferStyles,
  typeDiscountByPaymentStyles,
  typeOutletOfferStyles
)

export const ProductThumbNailImage = styled('img')(ProductThumbNailImageStyles)

export const ProductThumbNailMeta = styled('div')(productThumbNailMetaStyles)

export const ProductBadge = styled('span')(
  productBadgeStyles,
  productBadgeStylesByTagType
)
