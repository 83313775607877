import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

export const Wrapper = styled('div')(
  ({ theme }: Theme) => css`
    background-color: ${theme.colors.b100};
    border-radius: ${theme.borderRadius.giga};
    ${theme.typography.text.kilo};
    padding: ${theme.spacings.mega};
    display: flex;
    flex-direction: column;
    gap: ${theme.spacings.mega};
    align-items: flex-start;

    h3 {
      font-weight: ${theme.font.weight.bold};
    }

    ul {
      list-style: disc;
      padding-left: ${theme.spacings.peta};
    }

    button {
      ${theme.typography.text.kilo};
    }
  `
)
