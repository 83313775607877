import { AxiosResponse } from 'axios'

import { api } from './api'

type GetStockInfoProps = {
  productId: number
}

export const getStockInfo = async ({ productId }: GetStockInfoProps) => {
  try {
    const { data } = await api.get(`/assisted-sale/pre-cart/${productId}`)
    return data
  } catch (error) {
    if (error instanceof Error && 'response' in error) {
      throw error.response
    } else {
      throw new Error('An unexpected error occurred')
    }
  }
}

type GetOtherBatchesProps = {
  productId: number
  centers: string[]
}

export type GetOtherBatchesResponse = {
  productCode: string
  batches: Array<{
    batchId: string
    quantity: number
    unit: string
    description: string
  }>
}

export const getOtherBatches = async ({
  centers,
  productId
}: GetOtherBatchesProps) => {
  try {
    const { data } = await api.get<
      unknown,
      AxiosResponse<GetOtherBatchesResponse>
    >(
      `/assisted-sale/pre-cart/${productId}/batches?centers=${centers.join(',')}`
    )

    return data
  } catch (error) {
    if (error instanceof Error && 'response' in error) {
      throw error.response
    } else {
      throw new Error('An unexpected error occurred')
    }
  }
}
