import { Text } from '@backyard-ui/core'

import { CardBase } from '../CardBase'
import classNames from './CardSummaryStock.styles'

type ColorVariant = 'warning100' | 'success100' | 'default' | undefined

interface CardSummaryStockProps {
  storageCenterName: string
  storeName: string
  total: number
  reserved: number
  watermark: number
  available: number
}

const CardSummaryStock = ({
  storageCenterName,
  total,
  reserved,
  watermark,
  available
}: CardSummaryStockProps) => {
  const data: { label: string; value: number; color?: ColorVariant }[] = [
    { label: `Estoque ${storageCenterName}:`, value: available },
    { label: 'Reservado:', value: reserved },
    { label: 'Watermark:', value: watermark, color: 'warning100' },
    { label: 'Disponível:', value: total, color: 'success100' }
  ]

  return (
    <CardBase title="Estoque">
      {data.map(({ label, value, color }) => (
        <div className={classNames({ color: color })} key={label}>
          <Text weight="bold" color="neutral-700">
            {label}
          </Text>
          <Text weight="bold" color="neutral-700">
            {value}
          </Text>
        </div>
      ))}
    </CardBase>
  )
}

export default CardSummaryStock
