import { ApiChatItemType } from '../MarketPlace/types'

export const interactiveChatItem = (chatItem: ApiChatItemType) => {
  return {
    text: chatItem.message,
    from: chatItem.from,
    time: chatItem.date || new Date().toISOString(),
    attachments: chatItem.attachments
  }
}
