import { Button } from '@backyard-ui/core'

import { AlertMessage } from 'shared/components/AlertMessage'

import { SimilarProducts } from 'frontendCheckout/pages/cart/components/SimilarProduct'
import { SimilarProduct } from 'frontendCheckout/models/SimilarProduct.class'

type error = {
  key: 'stock' | 'quotation' | 'b2b_sell_unavailable' | string
  message: string
}

type props = {
  errors: error[]
  similarProducts: SimilarProduct[]
  remove: () => void
  refresh: ({ uniqueProductsCount }: { uniqueProductsCount: number }) => void
  setIsLoading: (value: boolean) => void
  isSimilarProductsEnabled?: boolean
}

const ProductError = ({
  errors = [],
  similarProducts = [],
  refresh,
  remove,
  setIsLoading,
  isSimilarProductsEnabled
}: props) => {
  const hasError = errors.length > 0
  const errorMessage =
    errors.find(error => error.key === 'stock')?.message ??
    errors.find(error => error.key === 'b2b_sell_unavailable')?.message ??
    'O produto está indisponível para a sua região. Por favor, remova do carrinho para seguir com a compra.'

  if (!hasError) {
    return null
  }

  const callback = ({
    uniqueProductsCount
  }: {
    uniqueProductsCount: number
  }) => {
    if (errors.find(error => error.key === 'stock'))
      refresh({ uniqueProductsCount })
    else remove()
  }

  const showSimilarProductsErrorMessage =
    isSimilarProductsEnabled && !!similarProducts.length

  return (
    <div className="pt-3">
      <AlertMessage type="critical">
        <div>{errorMessage}</div>
        <div className="actions mt-4 flex flex-row gap-4 flex-wrap">
          {showSimilarProductsErrorMessage && (
            <SimilarProducts
              products={similarProducts}
              callback={callback}
              setIsLoading={setIsLoading}
            />
          )}
          <Button variant="ghost" onClick={remove}>
            retirar do carrinho
          </Button>
        </div>
      </AlertMessage>
    </div>
  )
}

export default ProductError
