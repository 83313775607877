import {
  CATEGORY_PAGE_REFERRER,
  HAS_SIMPLEX_PAGE_META_TAG,
  SIMPLEX_PAGE_REFERRER
} from '../../utils/constants'

interface tagType {
  type: string
  label: string
}

type RegionalAttributesType = {
  promotionalPrice: number
  originalPrice: number
  suggestedPrice: number
  available: boolean
  availableForEcommerce: boolean
  offer: {
    enabled: boolean
    type: string
    label: string
    value: number
  }
  installments: {
    amount: number
    value: string
    totalPrice: string
  }
  discount: {
    discountType: string
    paymentMethodDiscount: string
    featureInstallmentDiscount: string
  }
  facets: {
    blackFriday: boolean
    freeShipping: boolean
    expressShipping: boolean
  }
}

interface ProductProps {
  objectID: string
  name: string
  url: string
  relevanceFields: {
    [key: string]: {
      relevance: number
    }
  }
  attributes: {
    [key: string]: string
  }
  categoryPageId: string
  regionalAttributes: {
    [key: string]: RegionalAttributesType
  }
  pictures: {
    micro: string
    normal: string
  }
  unit: string
}

export const normalize = (
  product: ProductProps,
  region: string,
  isCategoryPage = false
) => {
  const REGIONAL_DATA = product.regionalAttributes?.[region]

  const getOffer = () => {
    if (!REGIONAL_DATA.offer.enabled) {
      return null
    }

    return {
      type: REGIONAL_DATA?.offer?.type,
      label: REGIONAL_DATA?.offer?.label,
      value: REGIONAL_DATA?.offer?.value
    }
  }

  const getTag = () => {
    if (REGIONAL_DATA?.facets?.blackFriday) {
      return {
        type: 'outlet',
        label: 'Black Friday'
      }
    }

    if (REGIONAL_DATA?.facets?.freeShipping) {
      return {
        type: 'freeShipping',
        label: 'Frete Grátis'
      }
    }

    if (product.relevanceFields?.isOurLowestPrice) {
      return {
        type: 'lowestprice',
        label: 'Nosso preço + baixo'
      }
    }

    if (product.relevanceFields?.exclusiveMarkeplace) {
      return {
        type: 'exclusive',
        label: 'Exclusivo Site'
      }
    }

    if (product.relevanceFields?.novelty) {
      return {
        type: 'news',
        label: 'NOVIDADE'
      }
    }

    return null
  }

  const getPrice = (value: number) => {
    if (!value) {
      return null
    }

    const splitedPrice = String(value.toFixed(2)).split('.')

    return {
      integers: Number(splitedPrice[0]).toLocaleString(),
      decimals: splitedPrice[1]
    }
  }

  const isOutlet = (tag: tagType) => {
    return tag.type === 'outlet'
  }

  const tag = getTag()
  const hasOutlet = Boolean(tag && isOutlet(tag))

  const getInstallments = (
    installments: RegionalAttributesType['installments']
  ) => {
    if (Array.isArray(installments)) {
      return null
    }

    return installments
  }

  const getDiscount = (discount: RegionalAttributesType['discount']) => {
    if (Array.isArray(discount)) {
      return {
        discountType: '',
        paymentMethodsDiscount: ''
      }
    }

    return discount
  }

  const getUrl = (url: string) => {
    const separator = url?.includes('?') ? '&' : '?'

    if (isCategoryPage) {
      return `${url}${separator}referrer=${CATEGORY_PAGE_REFERRER}`
    }

    if (HAS_SIMPLEX_PAGE_META_TAG) {
      return `${url}${separator}referrer=${SIMPLEX_PAGE_REFERRER}`
    }

    return url
  }

  return {
    id: product.objectID,
    shortName: product.name,
    picture: product.pictures?.normal,
    unit: product.unit,
    inWishList: false,
    installments: getInstallments(REGIONAL_DATA?.installments),
    isOutlet: hasOutlet,
    tag,
    offer: getOffer(),
    price: {
      to: getPrice(REGIONAL_DATA?.promotionalPrice),
      from: getPrice(REGIONAL_DATA?.originalPrice),
      regionPrice: null,
      suggested: getPrice(REGIONAL_DATA?.suggestedPrice)
    },
    discount: getDiscount(REGIONAL_DATA?.discount),
    url: getUrl(product.url),
    trackingUrl: null,
    assistedSale: false,
    isSoldOut: !REGIONAL_DATA?.available,
    isExpressDelivery: false,
    expressDeliveryDay: '',
    name: product.name,
    services: false,
    brand: product?.attributes?.Marca ?? '',
    categoryTree: product?.categoryPageId
  }
}

export const getItemCategory = (categoryTree: string[]) => {
  if (!categoryTree.length) {
    return ''
  }

  const replaceAndTransform = (item: string) => {
    return item.replaceAll(' ', '-').toLowerCase()
  }

  if (categoryTree.length === 1) {
    return replaceAndTransform(categoryTree[0])
  }

  // Pega apenas os dois primeiros níveis da árvore
  return replaceAndTransform(categoryTree[1].split(' > ').join('|'))
}
