import { ShippingAddressType } from 'frontendMyOrders/plugins/Chat/MarketPlace/types'

export function formatFullAddress(
  shippingAddress: ShippingAddressType | undefined
) {
  if (!shippingAddress) return 'endereço não encontrado'
  const { street, number, complement, city, state } = shippingAddress

  if (street && city && state) {
    return `${street} ${number ? number + ' ' : ''}${complement ? complement + ' ' : ''}${city}/${state}`
  } else {
    return 'endereço não encontrado'
  }
}
