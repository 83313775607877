import { Fragment, useState, useEffect, useCallback, useMemo } from 'react'
import { Badge, Stack, Text } from '@leroy-merlin-br/backyard-react'

import emitter from 'scripts/utils/emitter'
import { pushDataObject, setProductView } from 'scripts/utils/data-layer'
import { sendEventToAlgolia } from 'scripts/react-components/shared/analytics/algolia.tracker'

import { getSellers } from 'catalog/services/sellers'
import { cheapestModalityOption } from 'catalog/utils/cheapest-modality-option'
import { SHIPMENTS_ERROR_STATUS } from 'catalog/utils/constants'

import { OptionInput } from './components/OptionInput'
import { ShipmentPrice } from './components/ShipmentPrice'
import { SellersListButton } from './components/SellersListButton'
import { SellersList } from './components/SellersList'
import * as S from './styled'

const Buybox = ({
  isAssistedSale,
  limitQuantity,
  regionId,
  product,
  isPostalCodeEnabled
}) => {
  const [selectedSeller, setSelectedSeller] = useState()
  const [skus, setSkus] = useState([])
  const [shipments, setShipments] = useState([])
  const [shipmentsErrorStatus, setShipmentsErrorStatus] = useState(0)

  const showButtonCondition = useMemo(() => skus.length > 3, [skus])
  const shouldRenderDefaultShipment = useMemo(() => {
    const differentRegionError =
      shipmentsErrorStatus === SHIPMENTS_ERROR_STATUS.differentRegion

    return !shipments?.length && (!shipmentsErrorStatus || differentRegionError)
  }, [shipments, shipmentsErrorStatus])

  useEffect(() => {
    const fetch = async () => {
      const sellers = await getSellers({
        productId: product.id,
        region: regionId
      })

      const isUnavailable = !sellers.length

      if (isUnavailable) {
        emitter.emit('pdp:seller:unavailable', true)
      }

      setSkus(sellers)

      const selectedSeller = sellers.find(seller => seller.selected)
      setSelectedSeller(selectedSeller)
    }

    fetch()
  }, [product.id, regionId])

  const renderShipmentMessage = useCallback(
    (currentSku, cheapestShipmentModality) => {
      if (!isPostalCodeEnabled) {
        return null
      }

      if (shouldRenderDefaultShipment) {
        return renderInitialShipmentMessage(currentSku)
      }

      return (
        <ShipmentPrice
          modality={cheapestShipmentModality?.modality}
          cost={cheapestShipmentModality?.cost}
          deadline={cheapestShipmentModality?.deadline}
          errorStatus={shipmentsErrorStatus}
        />
      )
    },
    [
      renderInitialShipmentMessage,
      isPostalCodeEnabled,
      shouldRenderDefaultShipment,
      shipmentsErrorStatus
    ]
  )

  const renderOutletMessage = useCallback(
    (currentSku, cheapestShipmentModality) => {
      if (
        currentSku.isOutlet &&
        !currentSku.isFreeShipping &&
        cheapestShipmentModality?.cost !== 0
      ) {
        return (
          <S.OutletBadgeWrapper>
            <Badge>Black Friday</Badge>
          </S.OutletBadgeWrapper>
        )
      }
    },
    []
  )

  const renderInitialShipmentMessage = useCallback(
    currentSku => {
      if (currentSku.isFreeShipping && !isAssistedSale) {
        return <Badge appearance="critical">Frete grátis</Badge>
      }

      return (
        <Text as="span" size="kilo" noMargin>
          + frete
        </Text>
      )
    },
    [isAssistedSale]
  )

  const handleSellerSelect = e => {
    sendEventToAlgolia()
    emitter.emit('buybox:sellerChange', e.target.dataset)

    const parsedSelectedSeller = JSON.parse(
      e.target?.dataset?.selectedSeller || '{}'
    )

    setSelectedSeller(parsedSelectedSeller)

    const dataObject = setProductView(e.target.dataset)
    pushDataObject(dataObject)
  }

  const getShipmentsInfoBySkuId = useCallback(
    id => {
      const skuId = id !== '' ? id : null
      return shipments.find(seller => seller.skuId === skuId)
    },
    [shipments]
  )

  useEffect(() => {
    const handleShipmentsChange = data => {
      setShipments(data.shipments)
      setShipmentsErrorStatus(data.shipmentsErrorStatus)
    }

    emitter.on('shipments:change', handleShipmentsChange)
    return () =>
      emitter.removeListener('shipments:change', handleShipmentsChange)
  }, [])

  if (skus.length < 2) {
    return null
  }

  return (
    <Fragment>
      <S.Wrapper>
        <Stack space="bit">
          {limitQuantity && (
            <Text as="span" size="kilo" isBold noMargin>
              {limitQuantity}
            </Text>
          )}

          <Stack space="bit">
            {skus.slice(0, 3).map((sku, index) => {
              const skuShipments = shipments
                ? getShipmentsInfoBySkuId(sku.id)
                : null
              const cheapestShipmentModality = skuShipments
                ? cheapestModalityOption(skuShipments.modalities)
                : null

              const hasShipment = !!cheapestShipmentModality?.modality

              return (
                <div key={sku.id}>
                  <OptionInput
                    id={`buybox_option_radio_${index}`}
                    product={product}
                    sku={sku}
                    selectedSeller={selectedSeller}
                    onChange={e => handleSellerSelect(e)}
                    isAssistedSale={isAssistedSale}
                  />
                  <S.OptionLabel
                    htmlFor={`buybox_option_radio_${index}`}
                    isSelected={selectedSeller?.id === sku.id}
                    hasShipment={hasShipment}
                    data-cy={sku.shop?.id}
                    isPostalCodeEnabled={isPostalCodeEnabled}
                  >
                    <Stack space="bit">
                      <div>
                        <S.OptionPriceValue>
                          R$ {sku.price.to.integers},{sku.price.to.decimals}
                        </S.OptionPriceValue>{' '}
                        <Text
                          as="span"
                          color="n600"
                          size="kilo"
                          isBold
                          noMargin
                        >
                          {product.unit}
                        </Text>
                      </div>
                      {renderOutletMessage(sku, cheapestShipmentModality)}
                      {renderShipmentMessage(sku, cheapestShipmentModality)}
                    </Stack>

                    <S.OptionSeller title={sku.shop.name}>
                      <Text as="span" color="n600" size="kilo" noMargin>
                        {sku.shop.name}
                      </Text>
                    </S.OptionSeller>
                  </S.OptionLabel>
                </div>
              )
            })}
            {showButtonCondition && (
              <SellersListButton
                price={`${skus[0].price.to.integers}, ${skus[0].price.to.decimals}`}
                unit={product.unit}
                openDrawer={() => emitter.emit('buybox:drawer-open')}
              />
            )}
          </Stack>
        </Stack>
      </S.Wrapper>

      {showButtonCondition && (
        <SellersList
          productId={product.id}
          productName={product.name}
          productImg={product.img}
          productBrand={product.brand}
          regionId={regionId}
          skus={skus}
        />
      )}
    </Fragment>
  )
}

export default Buybox
