import { Skeleton } from '@backyard-ui/core'
import { Heading } from '@leroy-merlin-br/backyard-react'

import { InvoiceSender } from 'scripts/react-components/lmcv-pro/pages/CashbackPage/components/CashbackRedeem/components/InvoiceSender'

import { useLoyaltyProfile } from 'shared/hooks'

import { useResize } from 'utils/hooks/use-resize'

import { Header } from 'lmcv/components'
import IconLoyalty from 'lmcv/components/Icons/IconLoyalty'

import * as S from './styled'
import { useRedeemContext } from '../../contexts/RedeemContext'
import { FavoriteStore } from './components/FavoriteStore'

export default function Redeem() {
  const { loyaltyProfile } = useLoyaltyProfile()
  const { loadingCashback } = useRedeemContext()

  const [isMobile] = useResize()

  return loadingCashback ? (
    <Skeleton width="100%" height="408px" data-testid="loading-skeleton" />
  ) : (
    <>
      {!isMobile && loyaltyProfile && (
        <Header loyaltyProfile={loyaltyProfile} data-testid="header" />
      )}

      <S.Wrapper>
        <S.Title>
          <Heading as="h1" noMargin>
            Solicitação de cashback
          </Heading>
          <IconLoyalty width={80} />
        </S.Title>

        <S.Subtitle>
          Troque seus pontos por cashback na sua conta corrente, enviando uma
          nota fiscal correspondente ao valor disponível para resgate.
        </S.Subtitle>

        <FavoriteStore />

        <S.InvoiceCard>
          <InvoiceSender />
        </S.InvoiceCard>
      </S.Wrapper>
    </>
  )
}
