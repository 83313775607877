import {
  PendingPaymentSaleOrders,
  SaleOrders
} from 'frontendMyOrders/pages/Home/components'

function Home () {
  return (
    <div>
      <h1>Lista de Pedidos</h1>
      <PendingPaymentSaleOrders />
      <SaleOrders />
    </div>
  )
}

export default Home
