import React from 'react'
import styled from '@emotion/styled'

import { SelectOption } from '../SelectOption'
import { SelectOptionType } from '../../MarketPlace/types'

interface Option {
  key: SelectOptionType | string
  label: string
}

interface SelectOptionsGroupProps {
  options: Option[]
  selected: string
  onClick: (option: SelectOptionType | string) => void
}

const GroupWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  border-radius: var(--border-radius-lg);
  width: fit-content;
`

const SelectOptionsGroup: React.FC<SelectOptionsGroupProps> = ({ options, onClick, selected }) => {
  return (
    <GroupWrapper>
      {options.map((option, index) => (
        <SelectOption
          key={option.key}
          label={option.label}
          isSelected={selected === option.key}
          isDisabled={!!selected}
          onSelect={() => onClick(option.key)}
          isFirst={index === 0}
          isLast={index === options.length - 1}
        />
      ))}
    </GroupWrapper>
  )
}

export default SelectOptionsGroup
