import { createContext, useContext, useEffect, useState } from 'react'

import { useUserResources } from 'shared/hooks'

import { FORM_DEFAULT_VALUES } from '../pages/SignUp/components/FormPF/constants'

const SignUpContext = createContext()

const SignupContextProvider = ({
  children,
  isCaptchaEnabled,
  isCaptchaEnterpriseEnabled,
  captchaKey
}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const { userControls, isLoadingResources } = useUserResources()
  const [contextData, setContextData] = useState(FORM_DEFAULT_VALUES)

  useEffect(() => {
    if (!isLoadingResources) {
      setIsLoggedIn(userControls.isLoggedIn)
    }
  }, [isLoadingResources, userControls.isLoggedIn])

  return (
    <SignUpContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        isCaptchaEnabled,
        isCaptchaEnterpriseEnabled,
        captchaKey,
        contextData,
        updateData: setContextData
      }}>
      {children}
    </SignUpContext.Provider>
  )
}

const useSignupContext = () => {
  const context = useContext(SignUpContext)

  if (!context) {
    throw new Error(
      'useSignupContext must be used within an SignupContextProvider'
    )
  }

  return context
}

export { SignupContextProvider, useSignupContext }
