const hasMoreThanTwoMethodsOfPayment = (discountByPaymentMethod) => {
  return discountByPaymentMethod.methods.length > 2
}

export const getDescriptionDiscount = (discountByPaymentMethod, cashPaymentOnly) => {
  if (!discountByPaymentMethod) {
    return ''
  }

  if (cashPaymentOnly || hasMoreThanTwoMethodsOfPayment(discountByPaymentMethod)) {
    return 'à vista'
  }

  return `à vista no ${discountByPaymentMethod.methods.join(' ou ')}`
}
