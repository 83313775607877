import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgImageOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M3.999 4h16c1.103 0 2 .897 2 2v12c0 1.103-.897 2-2 2h-16c-1.103 0-2-.897-2-2V6c0-1.103.897-2 2-2m0 2v12h16.002l-.002-12zm3.5 5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3m3 3-1.5-2-3 4h12l-4.5-6z"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgImageOutline)
const Memo = memo(ForwardRef)
export default Memo
