import { useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'

import SalesImpression from 'site/utils/dataLayer/Sales/SalesImpression'

import { useDataLayerPush } from 'utils/hooks/use-data-layer-push'

import { Widget } from '../../components/Widget'
import Banner from '../../components/Banner'
import bannerPropTypes from '../../prop-types/banner'
import * as S from './styled'

const widgetType = 'carousel_full_banner'

const FullBannerCarousel = ({
  type,
  banners = [],
  noTopSpacing,
  noBottomSpacing
}) => {
  const [sideColors, setSideColors] = useState({ left: '', right: '' })

  const dataLayerOptions = useMemo(() => {
    if (!banners.length) {
      return {}
    }

    return {
      callback: () =>
        new SalesImpression(banners, type).sendImpressionToDataLayer()
    }
  }, [banners, type])

  useDataLayerPush(dataLayerOptions)

  const handleChangeSlide = useCallback(
    swiper => {
      const currentBanner = banners[swiper.realIndex]
      setSideColors(currentBanner.pictures[widgetType].sideBackgroundColors)
    },
    [banners]
  )

  if (!banners.length) {
    return null
  }

  return (
    <Widget
      noTopSpacing={noTopSpacing}
      noBottomSpacing={noBottomSpacing}
      sideBackgroundColors={sideColors}
      full
    >
      <S.Carousel
        autoplay={{ delay: 5000 }}
        loop={banners.length > 1}
        onInit={handleChangeSlide}
        onSlideChange={handleChangeSlide}
        pagination={{ clickable: true }}
        slidesPerView={1}
        hasOffset={false}
        freeMode={false}
        data-testid="carousel"
      >
        {banners.map((banner, index) => (
          <div key={`banner-${index}-${banner.id}`}>
            <Banner
              index={index}
              position={index + 1}
              parentWidgetType={widgetType}
              {...banner}
            />
          </div>
        ))}
      </S.Carousel>
    </Widget>
  )
}

FullBannerCarousel.propTypes = {
  type: PropTypes.string.isRequired,
  banners: PropTypes.arrayOf(PropTypes.shape(bannerPropTypes)).isRequired,
  noTopSpacing: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  noBottomSpacing: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
}

export default FullBannerCarousel
