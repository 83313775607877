import {
  Stack,
  Text,
  PasswordField as PasswordBase,
  Button
} from '@leroy-merlin-br/backyard-react'
import { PasswordField } from 'user/signup/components'
import { useState, FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3'

import * as S from './styled'
import { useAccessDataContext } from '../../context/my-account-access-data-context'
import {
  NewPasswordSendParams,
  ServerErrorType,
  sendNewPassword
} from '../../services'
import { ShowToastParams, showToast } from '../../utils/toast'

type FormValues = {
  password: string
  passwordConfirmation: string
}

type CreateNewPasswordProps = {
  onNextStep: () => void
}

const CreateNewPassword: FC<CreateNewPasswordProps> = ({ onNextStep }) => {
  const { captchaKey, isCaptchaEnterpriseEnabled } = useAccessDataContext()

  return (
    <GoogleReCaptchaProvider
      useEnterprise={isCaptchaEnterpriseEnabled}
      reCaptchaKey={captchaKey}>
      <WithRecaptchaComponent onNextStep={onNextStep} />
    </GoogleReCaptchaProvider>
  )
}

const WithRecaptchaComponent: FC<CreateNewPasswordProps> = ({ onNextStep }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const { contextData, isCaptchaEnabled } = useAccessDataContext()
  const [isLoading, setIsLoading] = useState(false)

  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues: { password: '', passwordConfirmation: '' }
  })

  const onSubmit = async ({ password, passwordConfirmation }: FormValues) => {
    setIsLoading(true)

    const showToastParams: ShowToastParams = {
      type: 'critical',
      title: 'Não foi possível criar senha',
      content: 'Tente novamente mais tarde.'
    }

    if (!executeRecaptcha) {
      setIsLoading(false)

      showToast(showToastParams)

      return
    }

    const sendNewPasswordParams: NewPasswordSendParams = {
      fiscalId: contextData.fiscalId,
      code: contextData.code,
      password: password,
      password_confirmation: passwordConfirmation,
      'g-recaptcha': isCaptchaEnabled
        ? await executeRecaptcha('changePassword')
        : undefined
    }

    try {
      await sendNewPassword(sendNewPasswordParams)

      showToastParams.type = 'primary'
      showToastParams.title = 'Senha criada com sucesso!'
      showToastParams.content = ''

      showToast(showToastParams)
      onNextStep()
    } catch (error) {
      const { data, status } = error as ServerErrorType
      showToastParams.type = 'critical'

      if (status === 422) {
        const [errorMessage] = data?.errors?.password || [
          'Não foi possível criar senha, tente novamente.'
        ]

        showToastParams.title = errorMessage
        showToastParams.content = ''
      } else if (status === 429) {
        showToastParams.title = 'Você atingiu o limite de tentativas!'
      }

      showToast(showToastParams)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <S.CreateNewPasswordContainer>
      <Text>Use o formulário abaixo para alterar sua senha Leroy Merlin</Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack space="mega">
          <PasswordField
            recovery={true}
            data-testid="password"
            name="password"
            label="Nova senha"
            control={control}
            autoComplete="new-password"
            state={Boolean(errors.password) && 'invalid'}
            hint={errors.password?.message}
          />

          <Controller
            control={control}
            name="passwordConfirmation"
            rules={{
              required: 'Insira sua senha',
              validate: value =>
                value === getValues('password') || 'As senhas não conferem'
            }}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { error }
            }) => (
              <PasswordBase
                data-testid={name}
                name={name}
                label="Confirmar nova senha"
                ref={ref}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                state={error ? 'invalid' : undefined}
                hint={error?.message}
              />
            )}
          />
          <S.ButtonSubmit>
            <Button
              data-testid="submit-button"
              type="submit"
              isDisabled={!isValid}
              isLoading={isLoading}
              isStretch>
              Salvar
            </Button>
          </S.ButtonSubmit>
        </Stack>
      </form>
    </S.CreateNewPasswordContainer>
  )
}

export default CreateNewPassword
