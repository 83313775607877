import { ModalProvider } from '@leroy-merlin-br/backyard-react'
import { Fragment } from 'react'

import reactComponentLoader from 'scripts/react-component-loader'

import {
  StockInfo,
  Description,
  StockAssistedSale,
  NavbarProductDetails,
  CallCenterContent,
  AugmentedRealityFullButton,
  AugmentedRealityMiniButton,
  StampLeroyWarrant,
  UserReviewShell
} from './'
import { StockOms } from './components/StockOms'

const StockAssistedSaleWithProviders = props => {
  return (
    <Fragment>
      <ModalProvider>
        {props.isOnAssistedSaleShipment === 'true' ? (
          <div className="stock-buttons">
            <StockOms {...props} />
          </div>
        ) : (
          <StockAssistedSale {...props} />
        )}
      </ModalProvider>
    </Fragment>
  )
}

reactComponentLoader(StockInfo, '[data-stock-info-wrapper]')
reactComponentLoader(
  StockAssistedSaleWithProviders,
  '[data-stock-assisted-sale-wrapper]'
)

reactComponentLoader(
  NavbarProductDetails,
  '[data-navbar-product-details-container]'
)
reactComponentLoader(Description, '[data-product-description]')
reactComponentLoader(CallCenterContent, '[data-call-center-content]')
reactComponentLoader(StampLeroyWarrant, '[data-stamp-leroy-warrant]')
reactComponentLoader(
  AugmentedRealityMiniButton,
  '[data-augmented-reality-mini-button-wrapper]'
)
reactComponentLoader(
  AugmentedRealityFullButton,
  '[data-augmented-reality-full-button-wrapper]'
)
reactComponentLoader(UserReviewShell, '[data-user-review-shell]')
