import { css } from '@emotion/react'
import styled from '@emotion/styled'

const AttachmentContainerStyles = () => css`
  background-color: var(--color-surface-accent-softer);
  width: auto;
  min-height: 56px;
  border-radius: 8px 8px 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  max-height: 100%;
  overflow-y: auto;
  box-shadow: 0px -10px 45px 0px #00000024;
`

export const AttachmentContainer = styled('div')(AttachmentContainerStyles)

const AttachmentContentStyles = () => css`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  gap: var(--desktop-space-inset-sm);
  width: 100%;
`

export const AttachmentContent = styled('div')(AttachmentContentStyles)

const ImageWrapperStyles = () => css`
  width: 32px;
  height: 32px;
  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-pill);
  background-color: var(--color-surface-accent-soft);
`

export const ImageWrapper = styled('div')(ImageWrapperStyles)

const IconWrapperStyles = () => css`
  margin-left: auto;
  cursor: pointer;
  padding: var(--desktop-space-stack-xs);
`

export const IconWrapper = styled('div')(IconWrapperStyles)

const AttachmentInfoStyles = () => css`
  display: flex;
  flex-direction: column;
  gap: var(--desktop-space-stack-xs);
`

export const AttachmentInfo = styled('div')(AttachmentInfoStyles)
