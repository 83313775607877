import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgShareOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        d="M13.004 7.05V4a1 1 0 0 1 1-1 1 1 0 0 1 .7.29l7 7a1 1 0 0 1 0 1.42l-7 7a1 1 0 0 1-1.7-.71v-3.1h-.85a10.89 10.89 0 0 0-8.36 3.72 1 1 0 0 1-1.11.35 1 1 0 0 1-.68-.97c0-9.12 8.08-10.68 11-10.95m-.85 5.83q1.006-.003 2 .13a1 1 0 0 1 .85.99v1.59l4.58-4.59-4.58-4.59V8a1 1 0 0 1-1 1c-.91 0-8.11.2-9.67 6.43a13.07 13.07 0 0 1 7.82-2.55"
        fill={color ? Colors[color] : 'currentColor'}
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgShareOutline)
const Memo = memo(ForwardRef)
export default Memo
