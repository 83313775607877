import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'

import { Colors, type Color } from '../types/icon-colors'

interface SVGRProps {
  color?: Color
}

function SvgCogOutline (
  { color, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      ref={ref}
      fill="none"
      height="1em"
      role="img"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="m3.845 17.866-1-1.73a2.003 2.003 0 0 1 .731-2.733l.505-.292a7.7 7.7 0 0 1 0-2.223l-.505-.292a2 2 0 0 1-.731-2.732l.999-1.729c.531-.919 1.807-1.263 2.731-.732l.529.306A8.1 8.1 0 0 1 9 4.598V4c0-1.103.897-2 2-2h2c1.103 0 2 .897 2 2v.598c.68.283 1.317.657 1.896 1.112l.529-.306c.923-.533 2.2-.188 2.731.732l.999 1.729a2 2 0 0 1-.731 2.732l-.505.292a7.7 7.7 0 0 1 0 2.224l.505.292a2 2 0 0 1 .731 2.732l-.999 1.729c-.533.919-1.808 1.261-2.731.731l-.529-.306A8.1 8.1 0 0 1 15 19.402V20c0 1.103-.897 2-2 2h-2c-1.103 0-2-.897-2-2v-.598a8 8 0 0 1-1.896-1.112l-.529.306c-.921.531-2.199.187-2.73-.73M6 12c0 .465.058.927.171 1.378a1 1 0 0 1-.471 1.108l-1.124.651 1 1.729 1.144-.662a.99.99 0 0 1 1.188.141 6.1 6.1 0 0 0 2.384 1.399A1 1 0 0 1 11 18.7V20h2.002L13 18.7a1 1 0 0 1 .708-.956 6.1 6.1 0 0 0 2.384-1.399 1 1 0 0 1 1.188-.142l1.145.661.998-1.729-1.123-.649a1 1 0 0 1-.471-1.108c.113-.453.171-.917.171-1.378 0-.462-.058-.926-.17-1.378a1 1 0 0 1 .47-1.108l1.124-.649-1-1.729-1.144.661a1 1 0 0 1-1.188-.142 6.1 6.1 0 0 0-2.384-1.399A1 1 0 0 1 13 5.3V4h-2v1.3a1 1 0 0 1-.708.956 6.1 6.1 0 0 0-2.384 1.399 1 1 0 0 1-1.188.142l-1.145-.662-.998 1.729 1.123.65a1 1 0 0 1 .47 1.108C6.058 11.074 6 11.538 6 12m10 0c0 2.206-1.794 4-4 4s-4-1.794-4-4 1.794-4 4-4 4 1.794 4 4m-2 0c0-1.084-.916-2-2-2s-2 .916-2 2 .916 2 2 2 2-.916 2-2"
        fill={color ? Colors[color] : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}

const ForwardRef = forwardRef(SvgCogOutline)
const Memo = memo(ForwardRef)
export default Memo
