import { SVGProps } from 'react'

export default function IconProductPromiseProductOnPicture(
  props: SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M20 4.00002V9.26002L9.63 19.59L4.42 14.38L14.82 4.00002H20ZM21 2.00002H14.41C14.2784 1.99926 14.1479 2.02448 14.0261 2.07425C13.9043 2.12401 13.7934 2.19734 13.7 2.29002L2.29 13.68C2.10375 13.8674 1.99921 14.1208 1.99921 14.385C1.99921 14.6492 2.10375 14.9027 2.29 15.09L8.92 21.71C9.01296 21.8037 9.12356 21.8781 9.24542 21.9289C9.36728 21.9797 9.49799 22.0058 9.63 22.0058C9.76201 22.0058 9.89272 21.9797 10.0146 21.9289C10.1364 21.8781 10.247 21.8037 10.34 21.71L21.68 10.38C21.8647 10.1938 21.9689 9.94236 21.97 9.68002L22 3.00002C22 2.7348 21.8946 2.48045 21.7071 2.29291C21.5196 2.10537 21.2652 2.00002 21 2.00002Z"
        fill="black"
      />
      <path
        d="M16 9.5C16.8284 9.5 17.5 8.82843 17.5 8C17.5 7.17157 16.8284 6.5 16 6.5C15.1716 6.5 14.5 7.17157 14.5 8C14.5 8.82843 15.1716 9.5 16 9.5Z"
        fill="black"
      />
    </svg>
  )
}
