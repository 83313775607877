import styled from '@emotion/styled'
import React from 'react'

import { Button } from '../Button'

export interface ButtonProps {
  key: string
  label: string
  disabled?: boolean
  confirmOnActionMessage?: string
}

interface ButtonsGroupProps {
  buttons: ButtonProps[]
  onClick: (key: string) => void
}

const GroupWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(--desktop-space-inset-md);
`

const ButtonsGroup: React.FC<ButtonsGroupProps> = ({ buttons, onClick }) => {
  return (
    <GroupWrapper>
      {buttons.map(button => (
        <Button
          key={button.key}
          data={button}
          onClick={() => onClick(button.key)}
          disabled={button.disabled}
          confirmOnActionMessage={button.confirmOnActionMessage}
        />
      ))}
    </GroupWrapper>
  )
}

export default ButtonsGroup
